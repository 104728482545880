import React, { useContext } from 'react';
import { CommunicationContext, CommunicationContextType } from '../../FramedComponents/CommunicationContext';
import { TemplateContext } from '../BasicTypes';

interface PreviewToolbarProps {
  templateContext: TemplateContext | null;
}

export const PreviewToolbar: React.FC<PreviewToolbarProps> = (props) => {    
  const communicationContext = useContext(CommunicationContext) as CommunicationContextType;

  return <div className="preview-tab-toolbar">
        <button className={'btn btn-secondary btn-sm'} 
            onClick={()=>{
              // fetch data and trigger the window function - as ids7 would
              communicationContext.onUpdateProviderData();
            }}
        >Reload data sources</button>

        <button className={'btn btn-secondary btn-sm'}
                onClick={()=>{
                  props.templateContext?.componentStore.clearState(true);
                }}
        >Clear state</button>
    </div>;
};
