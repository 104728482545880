import React from 'react';

interface SvgRefProps {
  className?: string,
  id: string
}

export const SvgRef: React.FC<SvgRefProps> = ({ className, id }) => {
  return (
        <svg className={className}>
            <use xlinkHref={'#' + id} />
        </svg>
  );
};