import { applogger } from '../../../applogger';

export interface ISelectionRange {
  selStart: number;
  selEnd: number;
}

/**
 * Get content editable container as plain text (selection range is relative to this textual representation)
 */
export function getAsPlainText(cNode: HTMLElement | null): string {
  return cNode != null ? createSimplifiedContentModel(cNode).text : '';
}

/**
 * Set range by a structure independent selection range
 */
export function setByTranslatedSelRange(cNode: HTMLElement | null, range: ISelectionRange | null) {
  if (cNode != null && range != null) {
    trySetSelected(cNode, createSimplifiedContentModel(cNode, range).domRange);
  }
}

/**
 * Get the structure independent selection range
 */
export function getTranslatedSelRange(cNode: HTMLElement | null): ISelectionRange | null {
  return cNode != null ? createSimplifiedContentModel(cNode).range : null;
}

/**
 * Get the structure independent selection range
 */
export function getDomRangeFromSelRange(cNode: HTMLElement | null, range: ISelectionRange | null ): Range | null {
  return cNode != null && range != null ? createSimplifiedContentModel(cNode, range).domRange : null;
}

/**
 * Insert HTML at the current caret position (i.e range position)
 */
export function insertHTMLAtCaret(html: string, doc?: Document | null) {
  doc = doc ?? document;
  if (doc.getSelection == null) return;

  const sel = doc.getSelection();
  if (sel != null && sel.getRangeAt && sel.rangeCount) {
    const range = sel.getRangeAt(0);
    range.deleteContents();
    const frag = range.createContextualFragment(html);
    range.insertNode(frag);
    range.collapse(false);
  }
}

/**
 * Insert plain text at the current caret position (i.e range position)
 */
export function insertTextAtCaret(text: string, doc?: Document | null) {
  doc = doc ?? document;
  if (doc.getSelection == null) return;

  const sel = doc.getSelection();
  if (sel != null && sel.getRangeAt && sel.rangeCount) {
    const range = sel.getRangeAt(0);
    range.deleteContents();
    range.insertNode(doc.createTextNode(text));
    range.collapse(false);
  }
}

/**
 * A content editable helper that will traverse the DOM and build the corresponding text portion and relative selection. Can also update the container selection based on a range which is relative to the resulting text
 */
function createSimplifiedContentModel(container: HTMLElement, translateRange?: ISelectionRange): { text: string, range: ISelectionRange | null, domRange: Range | null } {
  // small helper to handle some HTML encoded data when producing the text projection
  const unescapeHtml = (nodeHtml: string) => nodeHtml
    .replace(/&nbsp;|\u202F|\u00A0/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&');

  // get the current selection and range
  const doc = container.ownerDocument;
  const currentSelection = doc != null && doc.getSelection
    ? doc.getSelection()
    : null;
  let currentRange = currentSelection != null && currentSelection.rangeCount > 0
    ? currentSelection.getRangeAt(0)
    : null;

  // ensure that range belongs to container
  if (currentRange != null && (!container.contains(currentRange.startContainer) || !container.contains(currentRange.endContainer))) {
    currentRange = null;
  }

  // handle building a new range based on text releative range information
  let startContainer: Node | null = null;
  let startOffset: number = 0;
  let endContainer: Node | null = null;
  let endOffset: number = 0;
    
  let lpContainer: Node | null = null;
  let lpOffset: number = 0;

  let selStart: number | null = null;
  let selEnd: number | null = null;

  // helper for setup range info from a text node
  const setupNewRangeFromText = (offset: number, node: Node, len: number) => {
    lpContainer = node;
    lpOffset = len;

    if (translateRange != null) {
      // get a narrow range by choosing the last start position and the first end positions
      if (translateRange.selStart >= offset && translateRange.selStart <= offset + len) {
        startContainer = node;
        startOffset = translateRange.selStart - offset;
        endContainer = null;
      } else if (translateRange.selStart < offset && startContainer === null) {
        startContainer = node;
        startOffset = 0;
        endContainer = null;
      }
      if (endContainer == null && translateRange.selEnd >= offset && translateRange.selEnd <= offset + len) {
        endContainer = node;
        endOffset = translateRange.selEnd - offset;
      }
    } else if (currentRange != null) {
      if (currentRange.startContainer === node) {
        selStart = offset + currentRange.startOffset;
      }
      if (currentRange.endContainer === node) {
        selEnd = offset + currentRange.endOffset;
      }
    }
  };

  // helper for setup range info from a element node
  const setupNewRangeFromElement = (offset: number, node: Node, childIndex: number) => {
    lpContainer = node;
    lpOffset = childIndex;

    if (translateRange != null) {
      if (translateRange.selStart === offset || (translateRange.selStart < offset && startContainer === null)) {
        startContainer = node;
        startOffset = childIndex;
        endContainer = null;
      }
      if (endContainer == null && translateRange.selEnd === offset) {
        endContainer = node;
        endOffset = childIndex;
      }
    } else if (currentRange != null) {
      if (currentRange.startContainer === node && currentRange.startOffset == childIndex) {
        selStart = offset;
      }
      if (currentRange.endContainer === node && currentRange.endOffset == childIndex) {
        selEnd = offset;
      }
    }
  };

  // recursive DOM visitor
  const recursiveDomVisit = (parentNode: Node, offset: number): string => {
    let text = '';
    const children = parentNode.childNodes;
    children.forEach((child, index) => {
      setupNewRangeFromElement(offset + text.length, parentNode, index);

      switch (child.nodeType) {
        case Node.TEXT_NODE:
          if (child.textContent != null) {
            const nodeText = unescapeHtml(child.textContent);
            setupNewRangeFromText(offset + text.length, child, nodeText.length);
            text += nodeText;
          }
          break;
        case Node.ELEMENT_NODE: {
          const childElement = child as Element;
          switch (childElement.tagName) {
            case 'BR':
              // we will only react to non-trailing BRs
              if (index < children.length - 1) {
                text += '\n';
              }
              break;
            default:
              if (isBlockElement(child) && !text.endsWith('\n')) {
                // we're starting a new block element without a prior newline, we need to break the row
                text += '\n';
              }
              text += recursiveDomVisit(child, offset + text.length);
              break;
          }
        }
      }
    });

    return text;
  };

  const text = recursiveDomVisit(container, 0);

  // update actual range and set to selection, if provided
  let domRange: Range | null = currentRange;
  if (translateRange != null) {
    try {
      const newRange = new Range();
      if (startContainer != null) {
        newRange.setStart(startContainer, startOffset);
      } else if (lpContainer != null) {
        newRange.setStart(lpContainer, lpOffset);
      } else {
        newRange.setStart(container, 0);
      }

      if (endContainer != null) {
        newRange.setEnd(endContainer, endOffset);
      } else if (lpContainer != null) {
        newRange.setEnd(lpContainer, lpOffset);
      } else {
        newRange.setEnd(newRange.startContainer, newRange.startOffset);
        newRange.collapse(true);
      }

      domRange = newRange;
    } catch (e) {
      applogger.error(`Failed to update content editable range: ${e}`);
    }
  }

  if (translateRange != null) {
    selStart = translateRange.selStart;
    selEnd = translateRange.selEnd;
  } else if (selStart == null) {
    // no point found, assume it's past the end
    selStart = selEnd = text.length;
  } else if (selEnd == null) {
    selEnd = selStart;
  }

  return {
    text,
    range: translateRange != null || currentRange != null ? {
      selStart: Math.min(selStart, text.length),
      selEnd: Math.min(Math.max(selEnd, selStart), text.length),        
    } : null,
    domRange,
  };
}

function trySetSelected(container: Node, range: Range | null) {
  if (range == null) return;
  const doc = container.ownerDocument;
  const currentSelection = doc != null && doc.getSelection
    ? doc.getSelection()
    : null;
  if (currentSelection != null) {
    currentSelection.removeAllRanges();
    currentSelection.addRange(range);
  }
}

const blockElements = new Set(['address', 'article', 'aside', 'blockquote', 'canvas', 'dd', 'div', 'dl', 'dt', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hr', 'li', 'main', 'nav', 'noscript', 'ol', 'p', 'pre', 'section', 'table', 'tfoot', 'ul', 'video', 'body']);

/**
 * Test if node is a block element (could perhaps be better implemented testing styles etc)
 */
export function isBlockElement(node: Node | null): boolean {
  if (node == null) return false;

  if (node.nodeType === Node.ELEMENT_NODE) {
    const e = node as Element;
    return blockElements.has(e.tagName.toLowerCase());
  }
  return false;
}
