export function condClasses(conditionalClasses: Record<string, boolean>) {
  let result = Object.entries(conditionalClasses).reduce((acc, [key, value]) => {
    if (value) {
      return `${acc} ${key}`;
    } else {
      return acc;
    }
  }, '');
  
  return result;
}
  
export function undefClasses(undefinedClasses: (string | undefined)[]) {
  return undefinedClasses.filter(c => c).join(' ');
}