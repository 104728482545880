import React from 'react';
import { SrComponent, SrComponentPropsBase, ComponentRenderContext, TemplateContext } from '../BasicTypes';
import { shouldDisplay, ReactComponentContainerProps } from './SrtComponent';
import { schema } from './Schema';
import { IsInDirectRowContext } from './SectraRow';


/*
 * HR component
 */

const hrComponentKey = 'HR';
const hrComponentSchema = {
  'id': { 'type': 'string', 'description': 'The id of the element (optional)' },
  'display': schema.PropDefinition.display,
};

export const HrReactComponent: React.FC<ReactComponentContainerProps<SrComponentPropsBase>> = (container) => {
  return renderStructureElement(<hr />, container.props, container.context, container.templateContext, 'sectra-break');
};

export const HR : SrComponent<SrComponentPropsBase> = {
  key: hrComponentKey,
  render: (props, context, templateContext, functions) => <HrReactComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: ()=>Promise.resolve(`- ${hrComponentKey}: {}`),
  toolboxName: hrComponentKey,
  schema: schema.getSchema(hrComponentKey, hrComponentSchema, [], false),
};


/*
 * RowBreak component
 */

const rowBreakComponentKey = 'RowBreak';
const rowBreakComponentSchema = {
  'id': { 'type': 'string', 'description': 'The id of the element (optional)' },
  'display': schema.PropDefinition.display,
};

export const RowBreakComponent: React.FC<ReactComponentContainerProps<SrComponentPropsBase>> = (container) => {
  if (!IsInDirectRowContext(container.context, container.templateContext)) {
    return null;
  }

  return <div className={'row-break'}></div>;
};

export const RowBreak : SrComponent<SrComponentPropsBase> = {
  key: rowBreakComponentKey,
  render: (props, context, templateContext, functions) => <RowBreakComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: ()=>Promise.resolve(`- ${rowBreakComponentKey}: {}`),
  toolboxName: rowBreakComponentKey,
  schema: schema.getSchema(rowBreakComponentKey, rowBreakComponentSchema, [], false),
};


/*
 * Vertical space component
 */

const spacerComponentKey = 'VSpace';
const spacerComponentSchema = {
  'id': { 'type': 'string', 'description': 'The id of the element (optional)' },
  'display': schema.PropDefinition.display,
  'space': { 
    'type': 
        'string', 
    'enum': ['xs', 's', 'm', 'l', 'xl'], 
    'enumDescription': ['Very small', 'Small', 'Medium', 'Large', 'Very large'],
    'description': 'Size of the vertical space',
  },
};

interface SectraVSpaceProps extends SrComponentPropsBase {
  space: 'xs' | 's' | 'm' | 'l' | 'xl';
}

export const VSpaceReactComponent: React.FC<ReactComponentContainerProps<SectraVSpaceProps>> = (container) => {
  return renderStructureElement(<div className={'vspace ' + container.props.space}></div>, container.props, container.context, container.templateContext, 'sectra-space');
};

export const VSpace : SrComponent<SectraVSpaceProps> = {
  key: spacerComponentKey,
  render: (props, context, templateContext, functions) => <VSpaceReactComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: ()=>Promise.resolve(`- ${spacerComponentKey}:
    space: m`),
  toolboxName: spacerComponentKey,
  schema: schema.getSchema(spacerComponentKey, spacerComponentSchema, [], false),
};

/*
 * Header component
 */ 

interface SectraHeaderProps extends SrComponentPropsBase {
  header?: string;
  level? : string;
}

const headerComponentKey = 'Header';
const headerComponentSchema = {
  'id': { 'type': 'string', 'description': 'The id of the element (optional)' },
  'header': { 'type': 'string', 'description': 'The header text' },
  'level': { 
    'type': 'string', 
    'enum': ['h2', 'h3', 'h4', 'text'], 
    'enumDescription': ['Format as heading level 2', 'Format as heading level 3', 'Format as heading level 4', 'Format as plain text'],
    'description': 'Header level (default is h3)', 
  },
  'display': schema.PropDefinition.display,
};

export const HeaderReactComponent: React.FC<ReactComponentContainerProps<SectraHeaderProps>> = (container) => {
  const props = container.props;
  if (props.header == null || props.header.length === 0) {
    return null;
  }

  let element: JSX.Element;
  switch (props.level) {
    case 'h2':
      element = <h2>{props.header}</h2>;
      break;
    case 'h4':
      element = <h4>{props.header}</h4>;
      break;
    case 'text':
      element = <label>{props.header}</label>;
      break;
    case 'h3':
    default: 
      element = <h3>{props.header}</h3>;
      break;
  }

  return renderStructureElement(element, props, container.context, container.templateContext, 'sectra-header');
};

export const Header : SrComponent<SectraHeaderProps> = {
  key: headerComponentKey,
  render: (props, context, templateContext, functions) => <HeaderReactComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: ()=>Promise.resolve(`- ${headerComponentKey}:
    header: MyHeader`),
  toolboxName: headerComponentKey,
  schema: schema.getSchema(headerComponentKey, headerComponentSchema, ['header'], false),
};


/* 
 * Helpers
 */

function renderStructureElement(element: JSX.Element, props: SrComponentPropsBase, context: ComponentRenderContext, templateContext: TemplateContext, containerClass?: string): JSX.Element | null {
  if (!shouldDisplay(props.display, context) || element === null) {
    return null;
  }

  if (IsInDirectRowContext(context, templateContext)) {
    return <>
            <div className={'row-break'}></div>
            {containerClass != null ? <div className={containerClass} data-component-id={props.id}>{element}</div> : element}
            <div className={'row-break'}></div>
        </>;
  } else {
    return <div className={'container' + (containerClass != null ? ' ' + containerClass : '')} data-component-id={props.id}>{element}</div>;
  }
}