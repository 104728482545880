import { applogger } from '../../applogger';
import { ParsedTemplateSpec, IRuntimeError, ScriptRunnerGetter } from '../BasicTypes';

export const expressionStateChangeMethodName: string = 'StateChangeReasonIs';
export const scriptHelperMethods: ScriptHelperMethodInfo[] = [];

export const setScriptHelperMethods = (m: ScriptHelperMethodInfo[]) => {
  scriptHelperMethods.length = 0;
  scriptHelperMethods.push(...m);
};

export interface ScriptHelperMethodParam {
  name: string;
  description?: string;
}

export interface ScriptHelperMethodInfo {
  name: string;
  alias?: string[];
  description?: string;
  parameters?: ScriptHelperMethodParam[];
  method: any;
}

export function  getScriptRunnerGetterFromWindow(): ScriptRunnerGetter | null {
  return window._getScriptRunner_ ?? null;
}

export const addRuntimeError = (error: IRuntimeError): boolean => {
  return window._addRuntimeError_ != null ? window._addRuntimeError_(error) : false;
};

export const clearRuntimeError = (): boolean => {
  return window._clearRuntimeError_ != null ? window._clearRuntimeError_() : false;
};

export function getScriptRunnerGetterFromCodeEval(fullCode: string | null | undefined): ScriptRunnerGetter | null {
  if (fullCode == null) return null;

  try {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    return new Function('return ' + fullCode)() as ScriptRunnerGetter;
  } catch (e){
    const message = `Exception during script parse with error: "${e.message}". Review the 'Script' and 'Generated code' tab for further advice.`;
    addRuntimeError({ message });
    applogger.warn(message);
    return null;
  }
}

export function generateCompleteScript(scriptTabCode?: string, pSpec?: ParsedTemplateSpec | null) {
  return 'function(_scriptRunContext_) {\n'
         + internalGenerateCompleteScript(scriptTabCode, pSpec != null ? internalGenerateInlineCodeFromSpec(pSpec) : undefined)
         + '\n}';
}

export const defaultEmptyEventHandler = `var EventHandler = {
  OnStateChange: function(get, set, extensions) { },
  OnDataProviderRetrieve: function(get, set, data) { },
  OnBeforeGetState: function(get, set, extensions) { },
}`;

const calculationsVariableName = '_calculations_';

function internalGenerateCompleteScript(scriptTabCode?: string, expressionGeneratedCode?: string) {
  return `// Some useful helper functions
${getScriptHelperMethodsJs()}

${defaultEmptyEventHandler};

${/*This needs to be before OnStateChangeInlineCode so that the user cannot tamper with it.*/scriptTabCode ?? ''}
// This event handler is executing YAML expression logic
EventHandler.OnStateChangeInlineCode = function(get, set, extensions, ${expressionStateChangeMethodName}, ${calculationsVariableName}) {
${expressionGeneratedCode?.split('\n').map(l => '  ' + l).join('\n') ?? ''}
}

return EventHandler;`;
}

function internalGenerateInlineCodeFromSpec(pSpec: ParsedTemplateSpec): string {
  if (pSpec?.AllExpressions?.length == null || pSpec.AllExpressions.length === 0) {
    return '';
  }

  // use to enable debug logging
  const debugExpression = false;
  let debugBeforeRun = '', debugAfterRun = '';
  if (debugExpression) {
    debugBeforeRun = `\n        console.log('Running the ' + ${calculationsVariableName}[i].name + ' expression for (prefix: ' + ${calculationsVariableName}[i].prefix + ', id: ' + ${calculationsVariableName}[i].id + ', propToSet: ' + ${calculationsVariableName}[i].propToSet + ') (expression: ' + inlineExpressionFunction + ')');`;
    debugAfterRun = '\n            console.log(value);';
  }

  /* 
First we declare the inline expressions the was defined pre-runtime (yaml parse time) as a lookup
on the form of name => function(prefix) { return <expression result>; } 

NOTE: expressions are built from yaml calculated properties or code sections and in production mode in IDS7
      declared in the source file before the HTML5 application starts
*/
  const code = `var inlineExpressions = {
${pSpec.AllExpressions.map(x => `  "${x.expressionName}": ${x.expression}`).join(',\n')}
};

if (${calculationsVariableName} == null || ${calculationsVariableName}.length == null) {
return;
}
${/* 
Here we run the above declared expression for all rendered components that registered a calculator referring to an expression
(the same expresion can be executed multiple times for repeated components or custom eval expression such as list summaries).
    
Thus, for each calculation provided by the props manager (running the post processing code)
fetch the expression (base on name), and, if found, run directly or through a custom eval method.
Finally, set value onto component <id> into property <propToSet>. */''}
for (var i = 0; i < ${calculationsVariableName}.length; ++i) {
var c = ${calculationsVariableName}[i];
try {${/* console.log('inline expression name', c.name, inlineExpressions) */''}
var inlineExpressionFunction = inlineExpressions[c.name];${debugBeforeRun}
if (inlineExpressionFunction != null) {
  var value = !c.customEvaluate
    ? inlineExpressionFunction(c.prefix)
    : c.customEvaluate(c.prefix, inlineExpressionFunction);${debugAfterRun}

  set(c.id, c.propToSet, value, c.setAsUserInput);
}
}
catch(e) {
if (window._addRuntimeError_) window._addRuntimeError_({ message: String(e.message), componentId: c.id, propName: c.propRead, prefix: c.prefix });
console.warn('Error while running expression: "' + c.name + '": ' + e.message);
}
}`;

  return code;
}

function getScriptHelperMethodsJs() {   
  return scriptHelperMethods
    .map(item => 'var ' + item.name + (item.alias != null ? item.alias.map(a => '=' + a).join('') : '') 
            + '=function(){return _scriptRunContext_._scriptHelperMethods_["' + item.name + '"].apply(_scriptRunContext_,arguments)};')
    .join('\n');
}
