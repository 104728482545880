import React, { useState, useEffect, useRef } from 'react';
import './SectraTooltip.scss';
import { LegacyRef } from 'react';
import { useOnContainerScroll } from '../ReactExt';

interface SectraTooltipProp {
  tooltipMessage: string | JSX.Element | JSX.Element[] | null | undefined;
  children: any;
  delayedActivation?: number;
  style?: 'warning' | 'error' | 'info';
  forceShow?: boolean;
  tooltipHeight?: number;
}

type TooltipStatus = 'top' | 'bottom' | null;

const SectraTooltip = (props: SectraTooltipProp) => {
  const elementRef = useRef<HTMLElement | null>(null);
  const [isActive, setIsActive] = useState(false as boolean);
  const [status, setStatus] = useState(null as TooltipStatus);

  const tooltipHeight = props.tooltipHeight ?? 35;
  const delay = props.delayedActivation ?? 200;

  const updateStatus = (currentIsActive: boolean, isForced?: boolean) => {
    let newStatus: TooltipStatus = null;
    if (currentIsActive || isForced === true) {
      newStatus = getTooltipPosition(elementRef.current, tooltipHeight);
    }
    if (newStatus !== status) {
      setStatus(newStatus);
    }
  };

  // update status when forceShow is changing
  useEffect(() => updateStatus(isActive, props.forceShow), [props.forceShow]);

  // monitor page scroll and re-evaluate tooltip position if shown
  useOnContainerScroll(() => { updateStatus(isActive, props.forceShow); });

  const timeout = useRef<NodeJS.Timeout | null>(null);
  const onMouseEnter = () => {
    if (timeout.current == null) {
      timeout.current = setTimeout(() => {
        timeout.current = null;
        setIsActive(true);
        updateStatus(true, props.forceShow);
      }, delay);
    }
  };

  const onMouseLeave = () => {
    if (timeout.current != null) {
      clearInterval(timeout.current);
      timeout.current = null;
    } 

    setIsActive(false);
    updateStatus(false, props.forceShow);
  };

  return (
        <div className="Tooltip-Wrapper" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={elementRef as LegacyRef<HTMLDivElement>}>
            {props.children}
            {status != null && props.tooltipMessage != null && props.tooltipMessage !== '' 
              ? <div className={`Tooltip-Tip ${status} ${props.style ?? 'info'}`}><div>{props.tooltipMessage}</div></div>
              : null }
        </div>
  );
};

export default SectraTooltip;

function getTooltipPosition(element: HTMLElement | null, spaceNeeded: number): 'top' | 'bottom' {
  const container = document.querySelector('.srt-container');
  if (container == null || element == null) return 'top';

  const containerClientTop = container.getBoundingClientRect().top;
  const elementClientTop = element.getBoundingClientRect().top;

  // Either we don't have enough space in the container
  if (elementClientTop - containerClientTop < spaceNeeded) {
    return 'bottom';
  }

  // Or the element is scrolled to top of page or beyond
  const scrollSpaceLeft = elementClientTop - (container.parentElement?.offsetTop ?? 0);
  return scrollSpaceLeft < spaceNeeded ? 'bottom' : 'top';
}