import React, { useEffect, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { applogger } from './applogger';
import { Ids7ProviderData } from './Components/BasicTypes';
import { usePrevious } from './Components/ReactExt';
import { getDataProviderData } from './DataproviderFetch';
import { DefaultValues } from './DefaultValues';
import { DropzoneModal } from './DropZone';
import { ReadFromFile } from './FileSaveLoad';
import { sendAndForget, sendAndWaitForResponse } from './FramedComponents/CommunicationHelper';
import { IBuildIds7PackageParams, IDeleteTemplateVersionsParams, IGetBuildInfoParams, IInitializeParams, IInterFrameCom, IPushHistoryParams, ISetSuggestedTemplate, ITemplatePreviewEnabledParams, ITemplateShareParams } from './FramedComponents/IInterFrameCom';
import { parseEhrJson } from './Helpers/EhrHelper';
import { routeToPath } from './Helpers/NavigationHelper';
import { AppColorMode } from './NavigatonBar';
import { baseUrl, IServerCommunication, SrtGenBuild, SrtGenTemplate, SrtSite, SrtTemplateFolder } from './ServerCommunication';

export interface TemplateAdminFrameProps {
  server: IServerCommunication;
  showingLatestVersion: boolean;
  onShowingLatestVersionChange: (isShowing: boolean) => void;
  setUnsaved: (unsaved: boolean) => void;
  appColorMode: AppColorMode;
  currentTemplateFolder?: SrtTemplateFolder;
  isUnsaved: boolean;
  providerNames: string[];
  saveChanges: () => Promise<SrtGenTemplate | null>;
  setNewSrtGenTemplateObject: (template: SrtGenTemplate) => void;
  serverIsUpgrading: boolean;
}

export const TemplateAdminFrame: React.FC<TemplateAdminFrameProps> = (props) => {
  const suggestedTemplateId = useParams<any>().id as string;
  const templateVersionParam = useParams<any>().version as string;
  const suggestedTemplateVersion = templateVersionParam != null && parseInt(templateVersionParam, 10) > 0 ? parseInt(templateVersionParam) : undefined;
  const [otherActiveUsers, setOtherActiveUsers] = useState<string[]>([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [currentTemplateVersions, setCurrentTemplateVersions] = useState<number[] | null>(suggestedTemplateVersion ? [suggestedTemplateVersion] : null);
  const [iframeContentWindow, setIframeContentWindow] = useState<WindowProxy>();
  const [iframeInitialized, setIframeInitialized] = useState<boolean>(false);
  const [fileLoaded, setFileLoaded] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  let history = useHistory();

  const downloadBlob = (blob: Blob, filename: string) => {
    let dataUrl = URL.createObjectURL(blob);
    const e = document.createElement('a');
    e.setAttribute('href', dataUrl);
    e.setAttribute('download', filename);
    e.style.display = 'none';
    document.body.appendChild(e);
    e.click();
    document.body.removeChild(e);
    URL.revokeObjectURL(dataUrl);
  };

  useEffect(() => {
    if (iframeRef.current != null) {
      setIframeContentWindow(iframeRef.current?.contentWindow as WindowProxy);
      iframeRef.current.addEventListener('load', onLoad);
    }

    return () => {
      window.removeEventListener('message', eventListener);
      window.removeEventListener('load', onLoad);
    };
  }, [iframeRef]);

  function onLoad() {
    window.addEventListener('message', eventListener);
  }

  async function eventListener(event: MessageEvent) {
    try {
      let message: IInterFrameCom<any> = JSON.parse(event.data);
      switch (message.type) {
        case 'GetAllSites': {
          const resp = await props.server.getSites();
          event.ports[0].postMessage({ type: 'GetAllSites', data: resp } as IInterFrameCom<SrtSite[]>);
          break;
        }
        case 'GetTemplateShare': {
          const templateId = message.data as string;
          const resp = await props.server.getTemplateShare(templateId);
          event.ports[0].postMessage({ type: 'GetTemplateShare', data: resp } as IInterFrameCom<number[] | null>);
          break;
        }
        case 'SetTemplateShare': {
          const data = message.data as ITemplateShareParams;
          await props.server.shareTemplate(data.templateId, data.sites?.map(x => x.Id) ?? null);
          break;
        }
        case 'GetPreviewEnabled': {
          const templateId = message.data as string;
          const resp = await props.server.getAvailableForPreview(templateId);
          event.ports[0].postMessage({ type: 'GetPreviewEnabled', data: resp } as IInterFrameCom<boolean>);
          break;
        }
        case 'SetPreviewEnabled': {
          const data = message.data as ITemplatePreviewEnabledParams;
          await props.server.setAvailableForPreview(data.templateId, data.enabled);
          break;
        }
        case 'SaveChanges': {
          const resp = await props.saveChanges();
          event.ports[0].postMessage({ type: 'SaveChanges', data: resp } as IInterFrameCom<SrtGenTemplate | null>);
          break;
        }
        case 'GetBuildInfo': {
          const data = message.data as IGetBuildInfoParams;
          const resp = await props.server.getBuildInfo(data.templateId, data.templateVersion);
          event.ports[0].postMessage({ type: 'GetBuildInfo', data: resp } as IInterFrameCom<SrtGenBuild | null>);
          break;
        }
        case 'BuildIds7Package': {
          const data = message.data as IBuildIds7PackageParams;
          const build = await props.server.buildIds7Package(data.templateId, data.templateVersion, data.jsCode, data.templateFields, data.reportVersion);
          let bld = await props.server.getBuildInfo(data.templateId, data.templateVersion);
          downloadBlob(build, data.templateMetadata.title + '-v' + (data.templateMetadata.versionMajor ?? 0) + '.' +  (data.templateMetadata.versionMinor ?? 1) + '.' + data.templateVersion + (bld != null ? '.' + bld.BuildNumber : '')  + '.srtpkg');
          event.ports[0].postMessage({ type: 'BuildIds7Package' } as IInterFrameCom<void>);
          break;
        }
        case 'DeleteTemplateVersions': {
          const data = message.data as IDeleteTemplateVersionsParams;
          await props.server.deleteTemplateVersions(data.templateId, data.templateVersions);
          event.ports[0].postMessage({ type: 'DeleteTemplateVersions' } as IInterFrameCom<void>);
          break;
        }
        case 'SetNewSrtGenTemplate':
          props.setNewSrtGenTemplateObject(message.data as SrtGenTemplate);
          break;
        case 'SetUnsaved':
          props.setUnsaved(message.data as boolean);
          break;
        case 'GetTemplateVersionsById': {
          const versions = await props.server.getTemplateVersionsById(message.data);
          event.ports[0].postMessage({ type: 'GetTemplateVersionsById', data: versions } as IInterFrameCom<SrtGenTemplate[]>);
          break;
        }
        case 'PushHistory': {
          const data = message.data as IPushHistoryParams;
          if (data.options != null) {
            history.push(data.url, data.options);
          } else {
            history.push(data.url);
          }
          break;
        }
        case 'SetProviderData': {
          const providerData = await getDataProviderData();
          sendAndForget({ type: 'SetProviderData', data: providerData } as IInterFrameCom<Ids7ProviderData | null>, iframeRef.current?.contentWindow);
          break;
        }
        case 'IframeMessagingReady': {
          setIframeInitialized(true);
          break;
        }
        case 'FileLoaded':
          setFileLoaded(true);
          break;
        default:
          applogger.error('Got unexected message', event.data);
      }
    } catch (error) {
      if ((event.data?.source?.indexOf('react-devtools') ?? -1) === -1){
        applogger.error('Got unexpected error while handling iframe message: ', event.data, ' ERROR: ', error);
      }
    }
  }

  useEffect(() => {
    async function fetchAndSetOtherActiveUsers() {
      setOtherActiveUsers(await props.server.getActiveUsersForTemplate(suggestedTemplateId));
    }

    let pullInterval: NodeJS.Timeout; 
    async function updateActiveUsers() {
      const PULL_INTERVAL = 1 * 1000 * 60;
      await fetchAndSetOtherActiveUsers();
      pullInterval = setInterval(async () => {
        await fetchAndSetOtherActiveUsers();
      }, PULL_INTERVAL);
    }

    if (suggestedTemplateId !== 'fromFile' && suggestedTemplateId !== 'new') {
      updateActiveUsers();
    }

    return () => {
      props.server.disconnectCurrentUserFromTemplates();
      clearInterval(pullInterval);
    };
  }, [suggestedTemplateId]);

  useEffect(() => {
    function initialize(fromFile: boolean = false) {
      const data: IInitializeParams = { appColorMode: props.appColorMode, currentTemplateFolder: props.currentTemplateFolder,
        isUnsaved: props.isUnsaved, providerNames: props.providerNames,
        suggestedTemplate: { templateId: suggestedTemplateId, templateVersion: suggestedTemplateVersion, fromFile: fromFile } };
      if (suggestedTemplateId !== 'new' && suggestedTemplateId !== 'demo' && suggestedTemplateId !== 'fromFile') {
        props.server.getTemplateById(suggestedTemplateId, suggestedTemplateVersion).then(async template => {
          data.suggestedTemplate.template = template;
          sendAndForget<IInitializeParams>({ type: 'Initialize', data: data }, iframeContentWindow);
        });
      } else {
        sendAndForget<IInitializeParams>({ type: 'Initialize', data: data }, iframeContentWindow);
      }

    }
  
    if (iframeContentWindow != null && iframeInitialized && (suggestedTemplateId != 'fromFile' || fileLoaded)) {
      initialize(suggestedTemplateId === 'fromFile');
    }
  }, [iframeContentWindow, iframeInitialized, fileLoaded]);

  function updateTheme() {
    sendAndForget({ type: 'SetAppColorMode', data: props.appColorMode } as IInterFrameCom<AppColorMode>, iframeContentWindow);
  }

  useEffect(() => {
    async function getTemplateIds() {
      const templateVersions = (await props.server.getTemplateVersionsById(suggestedTemplateId)).map(x => x.Version ?? -1);
      setCurrentTemplateVersions(templateVersions);
      const latestTemplateVersion = Math.max(...templateVersions);
      const newShowingCurrentTemplateVersion = latestTemplateVersion === suggestedTemplateVersion;
      if (props.showingLatestVersion !== newShowingCurrentTemplateVersion) {
        props.onShowingLatestVersionChange(newShowingCurrentTemplateVersion);
      }
    }
    if (suggestedTemplateVersion) {
      getTemplateIds();
    }
  }, [suggestedTemplateId, suggestedTemplateVersion]);

  useEffect(() => {
    updateTheme();
  }, [props.appColorMode]);

  useEffect(() => {
    sendAndForget({ type: 'SetCurrentTemplateFolder', data: props.currentTemplateFolder } as IInterFrameCom<SrtTemplateFolder>, iframeContentWindow);
  }, [props.currentTemplateFolder]);

  useEffect(() => {
    sendAndForget({ type: 'SetIsUnsaved', data: props.isUnsaved } as IInterFrameCom<boolean>, iframeContentWindow);
  }, [props.isUnsaved]);

  useEffect(() => {
    sendAndForget({ type: 'SetProviderNames', data: props.providerNames } as IInterFrameCom<string[]>, iframeContentWindow);
  }, [props.providerNames]);

  // will run first time and every time the templateId or version is changed
  const prevTemplateId = usePrevious(suggestedTemplateId, '');
  useEffect(() => {
    async function handleLoadedTemplate() {
      const data: ISetSuggestedTemplate = { templateVersion: suggestedTemplateVersion, templateId: suggestedTemplateId, fromFile: false };
      const updateMessage: IInterFrameCom<ISetSuggestedTemplate> = { type: 'SetSuggestedTemplate', data: data };
      if (!suggestedTemplateId || suggestedTemplateId == 'new' || suggestedTemplateId == 'demo') {
        await sendAndWaitForResponse<ISetSuggestedTemplate, void>(updateMessage, iframeContentWindow);
        // fake retrieve from data provider
        const providerData = await getDataProviderData();
        sendAndForget<Ids7ProviderData | null>({ type: 'SetProviderData', data: providerData } as IInterFrameCom<Ids7ProviderData | null>, iframeContentWindow);
      } else if (suggestedTemplateId == 'fromFile') {
        setShowImportModal(true);

        // fake retrieve from data provider
        const providerData = await getDataProviderData();
        sendAndForget<Ids7ProviderData | null>({ type: 'SetProviderData', data: providerData } as IInterFrameCom<Ids7ProviderData | null>, iframeContentWindow);
      } else {
        try {
          props.server.getTemplateById(suggestedTemplateId, suggestedTemplateVersion).then(async template => {
            data.template = template;
            data.clearComponentData = suggestedTemplateId !== prevTemplateId;
            await sendAndWaitForResponse<ISetSuggestedTemplate, void>(updateMessage, iframeContentWindow);
            // fake retrieve from data provider
            const providerData = await getDataProviderData();
            sendAndForget<Ids7ProviderData | null>({ type: 'SetProviderData', data: providerData } as IInterFrameCom<Ids7ProviderData | null>);
          });
        } catch (e) {
          applogger.error('loading template from server failed', e);
          alert('failed server load of template see log for details');
        }
      }
    }
    if (iframeInitialized) {
      handleLoadedTemplate();
    }
  }, [suggestedTemplateId, suggestedTemplateVersion, iframeInitialized]);

  function loadLatestTemplate() {
    if (currentTemplateVersions !== null) {
      history.push(routeToPath('/' + suggestedTemplateId + '/' + Math.max(...currentTemplateVersions)), { skipSavePrompt: true });
    }
  }

  async function handleFileResult(t: SrtGenTemplate | SrtGenTemplate[]) {
    if (Array.isArray(t)) {
      await props.server.importFormPackage(props.currentTemplateFolder?.Id ?? 0, t);
      history.push('');
    } else {
      await sendAndWaitForResponse({ type: 'SetSuggestedTemplate', data: { templateVersion: suggestedTemplateVersion, templateId: suggestedTemplateId,
        template: t, fromFile: true } }, iframeContentWindow);
      props.setUnsaved(true);
    }
  }

  const showNotLatestVersionWarning = !props.showingLatestVersion && suggestedTemplateId !== 'new' && suggestedTemplateId !== 'fromFile' && suggestedTemplateId !== 'demo';

  return (
    <div className={'TemplateAdmin' + (showNotLatestVersionWarning ? ' NotLatestVersion' : '')}>
        {props.serverIsUpgrading ? <Alert className="app-warning" bsStyle="warning">Server is going down for a version upgrade soon. Please save your changes and log out.</Alert> : null}
        {otherActiveUsers.length > 0 ? 
          <Alert className="app-warning" bsStyle="warning">
            Other user(s) are currently also viewing and or editing this form: {otherActiveUsers.reduce((prev, curr) => prev + ', ' + curr)}
          </Alert> : null
        }
        {showNotLatestVersionWarning
          ? <Alert className="app-warning" bsStyle="warning">
                <strong>You are not editing the latest version.</strong> Click <a href="#" onClick={evt => { loadLatestTemplate(); evt.preventDefault(); }}>here</a> to load the latest version.
            </Alert>
          : null}
        <div className="MainContent">
            {showImportModal ?
                <DropzoneModal onCancel={() => {
                  setShowImportModal(false);
                  history.push(routeToPath('/'));
                }}
                    onFileAvailable={(f: File) => {
                      setShowImportModal(false);
                      if (f.name.indexOf(".json") !== -1) {
                        var reader = new FileReader();
                        reader.readAsText(f);
                        reader.onload = async ev => {
                            let parsedEhrTemplate = parseEhrJson(reader.result as string);
                            const template: SrtGenTemplate = {Template: {JsCode: DefaultValues.emptyScript, Yaml: parsedEhrTemplate, JsonSpec: ""}, CreatedBy: "system", Id: "NewOehr", Deleted: false, Description: "", FolderId: 0, Name: ""}
                            await handleFileResult(template);
                        }
                        reader.onerror = ev => {
                            console.error("Could not read opt file");
                        }
                      } else {
                        ReadFromFile([f]).then(async (t) => {
                          await handleFileResult(t);
                        });
                      }
                    }}
                />
              : ''
            }
            <iframe sandbox="allow-scripts allow-modals" className="theme-dark" style={{ width: '100%', border: 0 }} ref={iframeRef} src={baseUrl() + '/TemplateView'} title="TemplateView" />
        </div>
    </div>
  );
};