import { applogger } from '../../applogger';

interface ProviderPropSpec {
  name: string;
  description: string;
  hasChildren?: boolean;
}

export const Ids7BuiltInProviders: { [providerName: string]:{ description: string, items: { name: string, description: string }[] } } = {
  'SectraIds7': {
    description: 'Sectra PACS built-in data source',
    items: [
      { name: 'PatientAge', description: 'The current age of the patient' },
      { name: 'PatientSex', description: 'The sex of the patient' },
      { name: 'RequestAccessionNumber', description: 'The accession number, i.e. the ID of the request' },
      { name: 'RequestCaseHistory', description: 'The case history' },
      { name: 'RequestReason', description: 'The reason for examination' },
      { name: 'ExamBodyPart', description: 'The examined body part' },
      { name: 'ExamBreastDensity', description: 'The breast density for the examination' },
      { name: 'ExamDate', description: 'The date when the examination was performed' },
      { name: 'ExamDateTime', description: 'The date and time when the examination was performed' },
      { name: 'ExamDescription', description: 'The type of examination' },
      { name: 'ExamDoseInformation', description: 'Dose information (if available)' },
      { name: 'ExamHeader', description: 'Text suitable for an exam header. Consist of date and time and exam description or body part and modality if the description is empty' },
      { name: 'ExamModalities', description: 'Which type of modalities that was used in the examination' },
      { name: 'ExamId', description: 'The ID of the examination' },
      { name: 'CurrentUserName', description: 'The name of the currently logged in user' },
      { name: 'CurrentDate', description: 'The current date' },
      { name: 'CurrentDateTime', description: 'The current date and time' },
      { name: 'CurrentDateTime', description: 'The current date and time' },
      { name: 'freefield[id]', description: 'IDS7 can display patient, request and examination properties that are specific to each Sectra PACS installation. Some of these "free fields" are configured in WISE and some are only available in worklists that use Sectra Enterprise Worklist Engine.' },
    ],
  }, 
  'SectraLesionTracking': {
    description: 'Sectra PACS built-in data source for lesion information',
    items: [
      { 'name': 'Findings', description: 'List of all the findings in Sectra Lesion Tracking.' },
    ],
  },
};

export function codeCompletionDataArg(context: string): { context: string, items: ProviderPropSpec[] } {
  // Get latest loaded provider data
  let allProvidersData = window.getProviderData();
  let providerDataDict = Object.assign({}, ...allProvidersData.map((x) => ({ [x.providerName]: x.providerData?.[0]?.properties })));

  applogger.debug('codeCompletionDataArg called with providerData', allProvidersData, providerDataDict);
    
  if (context == null || context.indexOf('.') < 0) {
    const providers = allProvidersData
      .map(x => ({ name: x.providerName, description: Ids7BuiltInProviders[x.providerName]?.description, hasChildren: true }));

    return { context: '', items: providers };
  } else {
    // handle known providers manually so that we can provide better help
    const providerName = context.split('.')[0];
    if (Ids7BuiltInProviders[providerName] != null) {
      var items: ProviderPropSpec[] = Ids7BuiltInProviders[providerName].items
        .map(x => ({ name: x.name, description: x.description, hasChildren: false }));

      return { context: providerName + '.', items };
    }
        
    // go into the object to before last .
    let { obj, path } = getByPathExceptLast(providerDataDict, context);
    applogger.debug('getByPathExceptLast returned ', obj, path, ' for ', providerDataDict, context);
    if (!obj || isPrimitive(obj)){
      return { context: context, items: [] };
    }

    return { context: path + '.', items: Object.keys(obj).map(x=>({ name: x, description: '' })) };
  }
}

function getByPathExceptLast(obj: any, path: string) {
  if (path == null || path.length === 0) return obj;

  let origPath = path;
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, '');           // strip a leading dot
  path = path.replace(/\.[a-zA-Z0-9]*$/, ''); // strip last dot and all after it

  applogger.debug('getByPath ', obj, path, origPath);
  const parts = path.split('.');
  parts.forEach(p => {
    if (obj === undefined) {
      return obj;
    }
    obj = obj?.[p];
  });

  return { obj, path };
}

function isPrimitive(test: any) {
  return test !== Object(test);
}
