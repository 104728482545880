/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { applogger } from './applogger';
import { SrtGenTemplate } from './ServerCommunication';

export function saveToFile(template: SrtGenTemplate) {
  let e: HTMLAnchorElement = document.getElementById('saveDoc') as HTMLAnchorElement;
  if (!e){
    e = document.createElement('a');
    e.id = 'saveDoc';
  }
    
  const subject = { ...template };
  if (!subject.Template){
    alert('no form data');
  } else {
    if (subject.Template?.JsonSpec !== '') {
      subject.Template = { ...subject.Template, JsonSpec: '' };
    }
    let s = JSON.stringify(subject);
    const f = new Blob([s], { type: 'application/json' });
    e.href = URL.createObjectURL(f);

    e.download = template.Name + '-v' + (template.SpecVersion ?? '0') + (template.Version != null ? '.' + template.Version : '') + '.ssrt';
    document.body.appendChild(e); // Required for this to work in FireFox
    e.click();
  }
}

export function saveAllTemplatesToFile(templates: SrtGenTemplate[]) {
  let e: HTMLAnchorElement = document.getElementById('saveDoc') as HTMLAnchorElement;
  if (!e){
    e = document.createElement('a');
    e.id = 'saveDoc';
  }

  const skipped = [];
  const subjects = [];
  for (let template of templates) {
    const subject = { ...template };
    if (!subject.Template) {
      skipped.push(subject.Name);
    } else {
      subjects.push(subject);
    }
  }
  let serializedSubjects = JSON.stringify(subjects);
  const blob = new Blob([serializedSubjects], { type: 'application/json' });
  e.href = URL.createObjectURL(blob);

  e.download = 'FormPackage.fp';
  document.body.appendChild(e);
  e.click();
}

export function ReadFromFile(files: File[] | FileList) {
    
  let resolvePromise: (s: SrtGenTemplate)=>void;
  let rejectPromise: ()=>void;
    
  let p = new Promise<SrtGenTemplate>((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });
    
  if (files && files.length > 0) {
    let reader = new FileReader();
    reader.onload = function (e) {
      if (e && e.target) {
        let contents = e.target.result as string;
        resolvePromise(JSON.parse(contents));
      } else {
        rejectPromise();
      }
    };
    reader.readAsText(files[0]);
  } else {
    // Set timeout to make reject promise available - returning p first
    setTimeout(()=>rejectPromise(), 1);
  }
    
  return p;
}

export function CreateFileInputClickAndReadFromFile() {
  let e: HTMLInputElement = document.getElementById('loadDoc') as HTMLInputElement;
  if (!e){
    e = document.createElement('input');
    e.id = 'loadDoc';
    e.type = 'file';
    e.accept = '.ssrt';
  }

  let resolvePromise: (s: SrtGenTemplate)=>void;
  let rejectPromise: ()=>void;
  let p = new Promise<SrtGenTemplate>((resolve, reject) =>{
    resolvePromise = resolve;
    rejectPromise = reject;
  });

  e.onchange = (event: Event) => {
    let files = (event.target as HTMLInputElement).files;
    if (files){
      ReadFromFile(files).then(resolvePromise);
    }
  };
  try {
    e.click();
  } catch (ex){
    applogger.error(ex);
  }
  return p;
}

