import * as React from 'react';

import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Grid } from 'react-bootstrap';
import { BasicHelpReactComponent } from '../Help';

export interface SectraRowProps extends React.HTMLProps<HTMLLabelElement> {
  children?: React.ReactNode;
  labelText?: string;
  labelFor?: string;
  marginTop?: number;
  marginBottom?: number;
  helpContentHtml?: string;
  helpContent?: string;
  abbrTitle?: string;
  className?: string;
  containerMinWidth?: number;
  fullWidthRender?: boolean;
  labelColSize?: number;
  labelInnerOverride?: JSX.Element;
}

function dec2hex(dec: number) {
  return dec.toString(16).padStart(2, '0');
}

function generateId(len: number) {
  var arr = new Uint8Array(len / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

export class SectraRow extends React.Component<SectraRowProps, {}> {

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: any, context: any) {
    super(props, context);
  }

  render() {
    const {
      labelFor,
      labelText,
      children,
      marginTop,
      marginBottom,
      abbrTitle,
      className,
      fullWidthRender,
      labelColSize,
      containerMinWidth,
      labelInnerOverride,
      helpContentHtml,
      helpContent,
      ...props
    } = this.props;

    const label = labelText ? <label htmlFor={labelFor} {...props}>{labelText}</label> : <React.Fragment />;
    const abbrLabel = <abbr title={abbrTitle}>{label}</abbr>;
    const rowStyle = {
      marginTop: marginTop != null ? marginTop.toString() + 'rem' : undefined, 
      marginBottom: marginBottom != null ? marginBottom.toString() + 'rem' : undefined,
    };

    let containerStyle: React.CSSProperties | undefined = undefined;
    if (props.hidden === true) {
      containerStyle = {
        visibility: 'hidden',
        height: 0,
        width: 0,
        margin: 0,
      };
    }

    const labelInner = labelInnerOverride
            ?? <>{abbrTitle ? abbrLabel : label}</>;

    const lblColSize = Math.max(1, Math.min(labelColSize ?? 4, 11));
    return (
            <Grid style={containerStyle} hidden={props.hidden}>
                <Row className={(className != null ? className : 'show-grid') + (fullWidthRender ? ' fullwidth' : '')} style={rowStyle}>
                    <Col xs={fullWidthRender === true ? 12 : lblColSize}>
                        {labelInner}
                        {helpContentHtml || helpContent ? <BasicHelpReactComponent id={generateId(10)} isLabelHelp={true} posAlignment="right" content={helpContent} contentHtml={helpContentHtml} /> : null}
                    </Col>
                    <Col xs={fullWidthRender === true ? 12 : 12 - lblColSize} style={containerMinWidth != null ? { minWidth: containerMinWidth } : undefined}>
                        {children}
                    </Col>
                </Row>
            </Grid>
    );
  }
}