/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { SrComponent, SrComponentPropsBase } from '../BasicTypes';
import { schema } from './Schema';
import { ReactComponentContainerProps } from './SrtComponent';
import './HtmlBox.scss';
import { SvgRef } from '../Generic/SvgRef';
import { SvgSymbol } from '../SvgSymbol';
import { buttonComponentKey } from './SectraButton';

interface HtmlBoxProps extends SrComponentPropsBase {
  contentHtml?: string;
  includeCloseButton?: boolean;
}

const htmlBoxSchema = schema.mergeSchemaProps(schema.DefaultSchemaProps, {
  'contentHtml': { 'type': 'string', 'description': 'Content as HTML' },
  'includeCloseButton': { 'type': 'boolean', 'description': 'Includes a close button if set to true' },
});

export const HtmlBoxComponent: React.FC<ReactComponentContainerProps<HtmlBoxProps>> = (container) => {
  return (
    container.props.display ?
      <ul className="container sectra-component-list">
          <li style={{ marginBottom: 0 }}>
            <Grid><Row className="show-grid" style={{ overflowX: 'auto' }}>
              <Col xs={12} style={{ paddingRight: '7px' }}>
                {container.props.includeCloseButton ?
                <>
                  <SvgSymbol />
                  <button className="btn btn-link delete pull-right" style={{ padding: 0 }}
                    onClick={() => container.functions.compositeUpdate([{ componentId: container.props.id, propName: 'display', value: !container.props.display }])}>
                    <SvgRef id="icon-ListDelete" className={'svg-icon delete'} />
                  </button>
                </>
                  : null
                }
                <span dangerouslySetInnerHTML={{ __html: container.props?.contentHtml ?? '' }}></span>
              </Col>
            </Row></Grid>
          </li>
        </ul>
      : null
  );
};

const htmlBoxComponentKey = 'HtmlBox';
export const HtmlBox : SrComponent<HtmlBoxProps> = {
  key: htmlBoxComponentKey,
  render: (props, context, templateContext, functions) => <HtmlBoxComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: () => Promise.resolve(`- ${buttonComponentKey}:
    label: Toggle HtmlBox
    style: Question
    toggleComponentId: htmlBox${schema.peekNextIdNum()}
- ${htmlBoxComponentKey}:
    id: htmlBox${schema.getNextIdNum()}
    contentHtml: '<span>Html content</span>'`),
  toolboxName: htmlBoxComponentKey,
  schema: schema.getSchema(htmlBoxComponentKey, htmlBoxSchema, undefined, false),
};