/* eslint-disable @typescript-eslint/indent */
import { baseUrl } from './ServerCommunication';
import { applogger } from './applogger';
import { Ids7ProviderData, Ids7ProviderDataResult } from './Components/BasicTypes';

export async function getDataProviderData() {
    let url = baseUrl() + '/api/DataProviderInternal/v1/GetStructuredData';
    let resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-csrf-token': localStorage.getItem('csrf') ?? '',
       },
    });
    
    if (resp.ok) {
        try {
            let data = await resp.json();
            applogger.debug('retrieved from datasource: ', data);
            let ids7FormatData = ToIds7DataProviderData(data.propValues);
            applogger.debug('faked dataprovider data is:', ids7FormatData);
            deepFreezeObject(ids7FormatData);
            return ids7FormatData;
        } catch (e) {
            applogger.error(e);
            return null;
        }
    } else {
        alert('Error - ' + resp.status + ' ' + resp.statusText);
        return null;
    }
}

export function setProviderData(ids7FormatData: Ids7ProviderData | null) {
  if (ids7FormatData != null) {
    (window as any).setProviderData(ids7FormatData);
    return true;
  }
  return false;
}

export async function runDataProviderFetch() {
  setProviderData(await getDataProviderData());
}

function deepFreezeObject(obj: any) {
    if (obj === null) return;
    if (Array.isArray(obj)) {
        for (let element of obj) {
            deepFreezeObject(element);
        }
        Object.freeze(obj);
    } else if (typeof obj === 'object') {
        const propNames = Object.getOwnPropertyNames(obj);
        for (const name of propNames) deepFreezeObject(obj[name]);
        Object.freeze(obj);
    }
}

function ToIds7DataProviderData(providers: object) {
    const results: Ids7ProviderDataResult[] = [];
    for (const [provider, providerValue] of Object.entries(providers)) {
        results.push({ providerName: provider, providerData: [{ properties: providerValue }] });
    }
    let ids7FormatData: Ids7ProviderData = {
        results: results,
    };
    return ids7FormatData;
}
