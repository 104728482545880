import React from 'react';
import { SrComponent, SrComponentPropsBase } from '../BasicTypes';
import { SvgRef } from '../Generic/SvgRef';
import { schema } from './Schema';
import { IsInDirectRowContext } from './SectraRow';
import { ReactComponentContainerProps, shouldDisplay } from './SrtComponent';

export const buttonClickEventPropName = 'onClick';
export const buttonComponentKey = 'Button';
const buttoComponentSchema = {
  'id': { 'type': 'string', 'description': 'The id of the element (optional)' },
  'label': { 'type': 'string', 'description': 'The button text/label (required)' }, 
  'color': { 'type': 'string', 'enum':['blue', 'gray', 'warning', 'danger'], 'enumDescription': ['Blue layout (default in light theme)', 'Gray layout (default in dark theme)', 'Warning layout (yellow)', 'Danger layout (red)'], 'description': 'The button layout/style (default: gray in dark theme, blue in light theme)' }, 
  'padding': { 'type': 'string', 'enum':['normal', 'component'], 'enumDescription': ['Normal button padding', 'Padding matching height of a component'], 'description': 'The button padding (default: normal when not in a row context, component when in a row context)' },
  'posAlignment': { 'type': 'string', 'enum': ['left', 'center', 'right'], 'enumDescription': ['Align left', 'Align center (middle)', 'Align right'], 'description': 'Alignment of the button, applicable for a non-row context (default: right)' },
  'toggleComponentId': { 'type': 'string', 'description': 'Id of component to toggle display attribute' },
  'style': { 'type': 'string', 'enum': ['Default', 'Question'], 'description': 'Button style (label will be disregarded if other than Default is specified)' },
  'posOffset': { 'type': ['string', 'number'], 'description': 'Fine-tuning possibilities for the button, applicable for a non-row context, on the form of _[top]_ _[left]_ (i.e. space separated, default: [none])' },
  'disabled': schema.PropDefinition.disabled,
  'display': schema.PropDefinition.display,
  [buttonClickEventPropName]: { 'type': 'string', 'description': 'An event expression that fires when the button is clicked (can be captured in scripts as well)' },
};

type SrtButtonColorType = 'blue' | 'gray' | 'warning' | 'danger';

interface SrtButtonProps extends SrComponentPropsBase {
  disabled?: boolean;
  color?: SrtButtonColorType;
  padding?: 'normal' | 'component';
  posAlignment?: 'left' | 'center' | 'right';
  posOffset?: string | number;
  toggleComponentId?: string;
  style?: string;
}

export const ButtonReactComponent: React.FC<ReactComponentContainerProps<SrtButtonProps>> = (container) => {
  const props = container.props;
  const context = container.context;
  const isDefaultStyle = props.style == null || props.style === 'Default' ;
  if (!shouldDisplay(props.display, context) || isDefaultStyle && props.label == null || isDefaultStyle && props.label === '') {
    return null;
  }

  const inRowContext = IsInDirectRowContext(container.context, container.templateContext);
  const padding = props.padding ?? (inRowContext ? 'component' : 'normal');
  const alignment = props.posAlignment ?? 'right';

  let buttonStyle: React.CSSProperties | undefined = undefined;
  if (!inRowContext && props.posOffset != null) {
    let parts = String(props.posOffset).split(' ', 2);
    let top = Number(parts[0]);
    let left = parts.length > 1 ? Number(parts[1]) : NaN;

    buttonStyle = { position: 'absolute', zIndex: 10 };
    buttonStyle.top = (!isNaN(top)) ? top : undefined;
    if (alignment === 'right') {
      buttonStyle.right = (!isNaN(left)) ? left : undefined;
    } else {
      buttonStyle.left = (!isNaN(left)) ? left : undefined;
    }
  }

  const defaultColor: SrtButtonColorType = context.theme === 'dark' ? 'gray' : 'blue';
  let btnClassName = 'btn-default btn ' + getButtonLayoutClass(props.color, defaultColor);
  if (padding === 'component') {
    btnClassName += ' component-padding';
  }

  function onClick() {
    if (props.toggleComponentId != null) {
      const newVal = !container.templateContext.componentStore.getStoreValue(props.toggleComponentId, 'display');
      container.functions.compositeUpdate([
        { componentId: props.toggleComponentId, propName: 'display', value: newVal },
      ], false);
    }
    container.functions.runScripts(-1, [{ type: 'event', compId: props.id, propName: undefined }]);
  }

  const button = <button 
        style={buttonStyle}
        className={isDefaultStyle ? btnClassName : 'no-btn'}
        onClick={() => onClick()} 
        disabled={props.disabled === true}
        >{isDefaultStyle ? props.label : <SvgRef id={('icon-' + props.style)} className={('svg-icon ' + props.style?.toLowerCase())} />}</button>;

  let containerClassName = 'sectra-button' + (!inRowContext ? ' container' : '') + (buttonStyle != null ? ' absolute' : '');
  if (!inRowContext && alignment !== 'right') {
    containerClassName += ' align-' + alignment;
  }

  return <><div className={containerClassName} data-component-id={props.id}>{button}</div></>;
};

export const Button : SrComponent<SrtButtonProps> = {
  key: buttonComponentKey,
  render: (props, context, templateContext, functions) => <ButtonReactComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: ()=>Promise.resolve(`- ${buttonComponentKey}:
    label: Run`),
  toolboxName: buttonComponentKey,
  schema: schema.getSchema(buttonComponentKey, buttoComponentSchema, [], false),
  expressionPreprocessor: (propName, expression, props) => propName === buttonClickEventPropName
    ? `[IsEvent(prefix+"${props.id}")?(${expression}):null][1]`
    : expression,
};

function getButtonLayoutClass(layout: SrtButtonColorType | undefined, def: SrtButtonColorType): 'btn-primary' | 'btn-gray' | 'btn-warning' | 'btn-danger' | null {
  switch (layout ?? def) {
    case 'blue': return 'btn-primary';
    case 'gray': return 'btn-gray';
    case 'warning': return 'btn-warning';
    case 'danger': return 'btn-danger';
    default: return def !== layout ? getButtonLayoutClass(def, def) : 'btn-primary';
  }
}
