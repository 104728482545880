import React from 'react';
import { ContentEditableContentController, asControllableElement } from './ContentController';
import { getAsPlainText, getDomRangeFromSelRange, getTranslatedSelRange, insertHTMLAtCaret, insertTextAtCaret, isBlockElement, ISelectionRange, setByTranslatedSelRange } from './ContentEditableHelper';

export interface TextAreaProps {
  id?: string;
  value: string | null | undefined;
  onUpdate?: (value: string | null) => void;
  className?: string,
  placeholder?: string;
  disabled?: boolean;
  rows?: number | 'auto';
  minRows?: number;
  maxRows?: number;
  lineWrap?: boolean;
  markupCustomStylingOnPaste?: boolean;
  showCustomStyling?: boolean;
  speechEnabled?: boolean;
  renderOpts?: HTMLOutputSettings;
  rowsToHeight?: (rows: number) => number;
}

export interface HTMLOutputSettings {
  pre?: string;
  normalFont?: string | null;
  normalSize?: string;
  smallSize?: string;
  h1Size?: string;
  h2Size?: string;
  h3Size?: string;
}

const htmlOutputRatio = 10 / 9; /* 9 corresponds to 10pt in the editor*/

export function fontSizeToHtmlFontSizeProp(fontSize: string | number | null): string {
  if (fontSize == null) return '';
  if (typeof fontSize === 'number') return String(fontSize * htmlOutputRatio) + 'pt';
  fontSize = String(fontSize);
  if (fontSize.endsWith('%')) {
    return fontSize;
  }
  const val = parseInt(fontSize);
  return !isNaN(val) && isFinite(val) && val > 0 && val < 200 ? String(val * htmlOutputRatio) + 'pt' : '';
}

const defaultPreFont = 'Consolas';
const possiblePreFonts = ['Consolas', 'Courier New', 'monospace', 'Menlo', 'Monaco'];
const defaultSmallFontSize = '80%';

const defaultH1FontSize = '200%';
const defaultH2FontSize = '150%';
const defaultH3FontSize = '117%';

export class SectraTextArea extends React.Component<TextAreaProps> {
  isFocused: boolean = false;

  mouseIsDown: boolean = false;

  rPos: ISelectionRange | null = null;

  knownDomModification: boolean = false;

  el = React.createRef<HTMLDivElement>();

  shouldComponentUpdate(nextProps: TextAreaProps): boolean {
    const el = this.el.current;
    if (!el) return true;

    const props = this.props;
    const newHtml = this.getHtmlFromUntrustedValue(nextProps.value);
    const doUpdate = newHtml !== el.innerHTML
            || props.id !== nextProps.id
            || props.className !== nextProps.className
            || props.placeholder !== nextProps.placeholder 
            || props.disabled !== nextProps.disabled
            || props.rows !== nextProps.rows
            || props.minRows !== nextProps.minRows
            || props.maxRows !== nextProps.maxRows
            || props.lineWrap !== nextProps.lineWrap
            || props.rowsToHeight !== nextProps.rowsToHeight;

    this.knownDomModification = false;
    return doUpdate;
  }

  render() {
    const props = this.props;
    const editorStyle: React.CSSProperties = { };
    const lineWrap = props.lineWrap !== false;
    if (!lineWrap) {
      editorStyle.whiteSpace = 'nowrap';
      editorStyle.overflowX = 'scroll';
    }
    
    const r2h = props.rowsToHeight ?? rowsToHeight;
    const rows = props.rows != null && props.rows !== 'auto' && !isNaN(Number(props.rows)) ? Number(props.rows) : 'auto';
    if (rows !== 'auto') {
      editorStyle.height = r2h(rows + (lineWrap ? 0 : 1)) + 'px';
    } else {
      if (props.minRows != null) {
        editorStyle.minHeight = r2h(props.minRows + (lineWrap ? 0 : 1)) + 'px';
      }
      if (props.maxRows != null) {
        editorStyle.maxHeight = r2h(props.maxRows + (lineWrap ? 0 : 1)) + 'px';
      }
    }

    this.rPos = this.isFocused ? getTranslatedSelRange(this.el.current) : this.rPos;
    const outputHtml = this.getHtmlFromUntrustedValue(props.value);
    return React.createElement('div', {
      id: props.id,
      className: props.className,
      placeholder: props.placeholder,
      disabled: props.disabled === true,
      style: editorStyle,
      ref: this.el,
      onInput: this.onInput,
      onPaste: this.onPaste,
      /* Handle restore of cursor position between blur and focus as default focus resets position to 0, 0 */
      onFocus: () => {
        this.isFocused = true;
        if (!this.mouseIsDown) {
          // we're click, i.e. setting a new cursor position
          setByTranslatedSelRange(this.el.current, this.rPos);
        }
      },
      onBlur: () => {
        this.isFocused = false;
        this.rPos = getTranslatedSelRange(this.el.current);
      },
      onMouseDown: () => this.mouseIsDown = true,
      onMouseUp: () => this.mouseIsDown = false,
      contentEditable: props.disabled !== true,
      'data-speech-id': props.speechEnabled !== false !== false ? props.id : undefined,
      'data-speech-enabled': props.speechEnabled !== false ? 'true' : undefined,
      dangerouslySetInnerHTML: { __html: outputHtml },
    });
  }

  onInput = () => {
    this.knownDomModification = true;
    this.props.onUpdate?.(this.getCurrentElementValue());
  };

  onPaste = (evt: React.ClipboardEvent<HTMLElement>) => {
    evt.preventDefault();

    if (evt.clipboardData == null) {
      return;
    }

    const element = (this.el.current ?? (evt.target as HTMLDivElement));
    if (this.props.markupCustomStylingOnPaste ?? true) {
      const pastedHtml: string = evt.clipboardData.getData('text/html');
      if (pastedHtml !== '') {
        this.knownDomModification = true;

        // clean-up paste HTML
        const valueFromHtml = (getValueFrom(pastedHtml, getTextStyleOfCursor(element)) ?? '')
        // replace some leading and trailing newline (as they are added by by our own editor)
          .replace(/^\n/, '').replace(/\n{1,2}$/, ''); 
        const cleanedInsertHtml = getValueAsBasicHtml(valueFromHtml, true);

        insertHTMLAtCaret(cleanedInsertHtml, element.ownerDocument);
    
        this.props.onUpdate?.(this.getCurrentElementValue());

        return;
      }
    }
        
    const pastedText: string = evt.clipboardData.getData('text/plain');
    if (pastedText !== '') {
      this.knownDomModification = true;

      insertTextAtCaret(pastedText.replace(/\r\n/g, '\n'), element.ownerDocument);

      this.props.onUpdate?.(this.getCurrentElementValue());
    }
  };

  componentDidUpdate() {
    const element = this.el.current;
    const outputHtml = this.getHtmlFromUntrustedValue(this.props.value);
    if (element != null && outputHtml !== element.innerHTML) {
      element.innerHTML = outputHtml;
    }

    if (this.isFocused) {
      setByTranslatedSelRange(element, this.rPos);
    }
  }

  componentDidMount() {
    const element = this.el.current;
    if (!element) return;

    const getSelection = () => {
      const selection = this.isFocused ? getTranslatedSelRange(element) : this.rPos;
      return selection ?? { selStart: 0, selEnd: 0 };
    };

    const setSelection = (selection: ISelectionRange) => {
      this.rPos = selection;
      if (this.isFocused) {
        setByTranslatedSelRange(element, selection);
      }
    };
        
    asControllableElement(element).contentController = new ContentEditableContentController(
      () => getAsPlainText(element),
      getSelection,
      setSelection,
      (text: string) => {
        const selectionRange = getSelection();
        const range = getDomRangeFromSelRange(element, selectionRange);
        if (range != null && element.ownerDocument != null) {
          range.deleteContents();
          range.insertNode(element.ownerDocument.createTextNode(text));
          this.knownDomModification = true;

          // collapse range to end and set
          const insertOffset = text.length - (selectionRange.selEnd - selectionRange.selStart);
          selectionRange.selStart = selectionRange.selEnd = selectionRange.selEnd + insertOffset;
          setSelection(selectionRange);

          const editorValue = getValueFrom('<div>' + element.innerHTML + '</div>', null);
          this.props.onUpdate?.(editorValue);
        }
      },
    );
  }

  componentWillUnmount() {
    if (this.el.current) delete asControllableElement(this.el.current).contentController;
  }

  normalizeHtml(html: string | null | undefined): string {
    return getValueAsBasicHtml(getValueFrom(html, null));
  }

  getHtmlFromUntrustedValue(value: string | null | undefined): string {
    const valueAsHtml = getValueAsBasicHtml(value);
    let normalizedHtml = this.normalizeHtml(valueAsHtml);

    if (this.props.showCustomStyling ?? true) {
      // apply styling of markup
      const preformatFont = this.props.renderOpts?.pre ?? defaultPreFont;
      const smallFontSize = String(this.props.renderOpts?.smallSize ?? defaultSmallFontSize);
      const h1FontSize = String(this.props.renderOpts?.h1Size ?? defaultH1FontSize);
      const h2FontSize = String(this.props.renderOpts?.h2Size ?? defaultH2FontSize);
      const h3FontSize = String(this.props.renderOpts?.h3Size ?? defaultH3FontSize);
      normalizedHtml = normalizedHtml
        .replace(/&lt;(\/?(em|i|strong|b|pre|mono|small|h1|h2|h3))&gt;/gi, (m: string, p1: any) => { 
          const isClosing = String(p1).startsWith('/');
          if (isClosing) return `${m}</span>`;

          const tagName = String(p1).startsWith('/') ? String(p1).substr(1).toUpperCase() : String(p1).toUpperCase();
          let spanStyle = '';
          switch (tagName) {
            case 'EM':
            case 'I':
              spanStyle = 'font-style: italic';
              break;
            case 'STRONG':
            case 'B':
              spanStyle = 'font-weight: bold';
              break;
            case 'SMALL':
              spanStyle = 'font-size: ' + smallFontSize;
              break;
            case 'PRE':
            case 'MONO':
              spanStyle = `font-family: ${preformatFont}`;
              break;
            case 'H1':
              spanStyle = 'font-weight: bold; font-size: ' + h1FontSize;
              break;
            case 'H2':
              spanStyle = 'font-weight: bold; font-size: ' + h2FontSize;
              break;
            case 'H3':
              spanStyle = 'font-weight: bold; font-size: ' + h3FontSize;
              break;
            default:
              console.log(`Unknown tag name encountered: ${tagName}`);
              return `<span>${m}`;
          }
          return `<span style="${spanStyle}">${m}`;
        });
    }

    const containerStyle: string[] = [];

    if (this.props.renderOpts?.normalSize != null && this.props.renderOpts.normalSize !== '') {
      containerStyle.push(`font-size: ${this.props.renderOpts.normalSize}`);
    }
    if (this.props.renderOpts?.normalFont != null && this.props.renderOpts.normalFont !== '') {
      containerStyle.push(`font-family: ${this.props.renderOpts.normalFont}`);
    }
    if (containerStyle.length > 0) {
      normalizedHtml = `<span style="${containerStyle.join(';')}">${normalizedHtml}</span>`;
    }

    return normalizedHtml;
  }

  getCurrentElementValue() {
    if (this.el.current == null) return '';
    return getValueFrom(this.el.current.innerHTML, null);
  }
}

function rowsToHeight(rows: number) : number {
  return 6 + 18 * rows;
}

function escapeHtml(html: string): string {
  return html
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
  // replace multiple spaces with every other &nbsp; to allow for correct HTML rendering 
    .replace(/ {2,}/g, (str) => str.length % 2 
      ? ' &nbsp;'.repeat(str.length / 2) + ' '
      : '&nbsp; '.repeat(str.length / 2))
  // replace leading/trailing spaces with &nbsp; to allow for correct HTML rendering 
    .replace(/^ | $/gm, '&nbsp;');
}

function unescapeHtml(html: string): string {
  return html
    .replace(/&nbsp;|\u202F|\u00A0/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&');
}

function getValueAsBasicHtml(value: string | null | undefined, noLastLineBr?: boolean): string {
  if (value == null || value === '') return '';

  const parts = String(value).split(/\r?\n/);
  const lastIndex = parts.length - 1;
  const htmlParts = parts.map((s, i) => (s.length > 0 ? escapeHtml(s) : '')
         + (noLastLineBr !== true || i !== lastIndex ? '<br/>' : ''));

  return htmlParts.join('');
}

function getValueFrom(html: string | null | undefined, markupCustomStyling: TextAreaTextStyle | null): string | null {
  if (html == null) {
    return null;
  }

  // read data into a dom parser
  const domparser = new DOMParser();
  const doc = domparser.parseFromString(html, 'text/html');
  const bodyNodes = doc.getElementsByTagName('body');
  if (bodyNodes.length === 0) {
    return null;
  }

  // process HTML into rows
  const baseNode = bodyNodes[0];
  let text = '';
  const recursiveDomVisit = (parentNode: Node, parentTextStyle: TextAreaTextStyle) => {
    const children = parentNode.childNodes;
    children.forEach((child, index) => {
      switch (child.nodeType) {
        case Node.TEXT_NODE: 
          if (child.textContent != null) {
            text += unescapeHtml(child.textContent);
          }
          break;
        case Node.ELEMENT_NODE: {
          const childElement = child as Element;
          const elementStyle = getTextStyle(childElement);
          if (markupCustomStyling != null) text += getMarkupTags(parentTextStyle, elementStyle, markupCustomStyling);

          switch (childElement.tagName) {
            case 'BR':
              // we will only react to non-trailing BRs
              if (index < children.length - 1) {
                text += '\n';
              }
              break;
            default:
                            
              if (isBlockElement(child) && !text.endsWith('\n') && text.length > 0) {
                // we're starting a new block element without a prior newline, we need to break the row
                text += '\n';
              }
                            
              recursiveDomVisit(child, elementStyle);
              break;
          }

          if (markupCustomStyling != null) text += getMarkupTags(elementStyle, parentTextStyle, markupCustomStyling);
        }
      }
    });
  };

  recursiveDomVisit(baseNode, markupCustomStyling ?? { strong: false, emphasis: false, preformat: false });
    
  if (markupCustomStyling != null) {
    // clean-up
    text = text
      .replace(/<\/(pre|mono|em|strong)><\1>/g, '')
      .replace(/<(pre|mono|em|strong)><\1>(.+)<\/\1><\/\1>/gs, '<$1>$2</$1>'); 
  }

  return text;
}

interface TextAreaTextStyle {
  strong: boolean; 
  emphasis: boolean;
  preformat: boolean;
}

// Get markup tags given that we go from "from" to "to". 
// If a container context is given we ignore style transition that's already set by the container context
function getMarkupTags(from: TextAreaTextStyle, to: TextAreaTextStyle, containerContext?: TextAreaTextStyle | null) {
  let ret = '';
  if (!containerContext?.strong && !from.strong && to.strong) ret += '<strong>';
  if (!containerContext?.emphasis && !from.emphasis && to.emphasis) ret += '<em>';
  if (!containerContext?.preformat && !from.preformat && to.preformat) ret += '<mono>';
    
  if (!containerContext?.preformat && from.preformat && !to.preformat) ret += '</mono>';
  if (!containerContext?.emphasis && from.emphasis && !to.emphasis) ret += '</em>';
  if (!containerContext?.strong && from.strong && !to.strong) ret += '</strong>';
  return ret;
}

function getTextStyleOfCursor(container: HTMLElement): TextAreaTextStyle {
  const doc = container.ownerDocument;
  if (doc != null && doc.getSelection != null) {
    const sel = doc.getSelection();
    if (sel != null && sel.getRangeAt && sel.rangeCount) {
      const range = sel.getRangeAt(0);
      let node: Node | null = range.startContainer;
      while (node != null && node.nodeType != Node.ELEMENT_NODE) {
        node = node.parentNode;
      }
      return getTextStyleFromStyle(node != null ? window.getComputedStyle(node as Element) : undefined);
    }
  }

  return {
    strong: false,
    emphasis: false,
    preformat: false,
  };
}

function getTextStyle(node: Node | null | undefined): TextAreaTextStyle {
  while (node != null && node.nodeType != Node.ELEMENT_NODE) {
    node = node.parentNode;
  }
  return getTextStyleFromStyle((node as HTMLElement)?.style);
}

function getTextStyleFromStyle(style: CSSStyleDeclaration | null | undefined): TextAreaTextStyle {
  return {
    strong: style?.fontWeight === 'bold' || (style?.fontWeight != null && Number(style?.fontWeight) >= 700),
    emphasis: style?.fontStyle === 'italic',
    preformat: String(style?.fontFamily ?? '').split(',')
      .some(f => {
        const nfont = f.trim().toUpperCase();
        return possiblePreFonts.some(font => font.toUpperCase() === nfont);
      }),
  };
}