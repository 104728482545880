/* eslint-disable @typescript-eslint/no-unused-expressions */
import { applogger } from '../applogger';
import { CodeSectionPart, CodeSectionsResponse, ComponentLookup } from './BasicTypes';
import { GetNumberAsTextFormatter } from './NumberHelper';
import { expressionStateChangeMethodName } from './SrtComponent/ScriptRunnerHelper';
import { IsEmpty, IsEmptyExt } from './SrtComponent/ScriptHelperMethods';

export function codeSectionPartsToText(parts: CodeSectionPart[], langCode: string | undefined): string {
  const NumberAsString = GetNumberAsTextFormatter(langCode);
    
  var ret = parts
    .map(x => {
      let value = x.value;
      if (value != null && typeof value === 'number') {
        value = NumberAsString(value);
      }

      return value;
    })
    .join('')
    .trimLeft();

  return ret;
}

export interface CodeSectionParseError {
  name: string;
  expression: string;
  index?: number;
  error: string;
}

export function getCodeSectionScript(codeSection: CodeSectionsResponse<any>, dynamicScopeIds: string[], compLookup: ComponentLookup, doValidateExpression: boolean, parseErrors: CodeSectionParseError[] | null) {
  // One codeSection containing what prop to set, and multiple sections (i.e. -text: "" if:"")
  // where each text can contain multiple calculations, i.e. "{functionY()} {x.value}"

  if (codeSection.codeSections.length === 0) {
    return;
  }
    
  // build all funcs
  let codeSectionExpressions: { parts: CodeSectionPart[], condition: string | null, separator: string | null, filter: boolean | null }[] = [];
  for (let f = 0; f < codeSection.codeSections.length; ++f) {
    const section = codeSection.codeSections[f];
    const codeSectionText = section.sectionText;
    const filter = section.smartFilter ?? null;
    const blocks = getAllCodeBlocks(codeSectionText);
    const csParts: CodeSectionPart[] = [];
    let lastIndex = 0;
    blocks.forEach(codeBlock => {
      // handle before
      let before = codeSectionText.substring(lastIndex, codeBlock.startPos);
      if (before.length > 0) {
        csParts.push({ value: JSON.stringify(before), type: 'text', filter });
      }
      lastIndex = codeBlock.endPos;

      let evalExp = codeBlock.code.length > 2 ? codeBlock.code.substring(1).slice(0, -1) : null;
      if (evalExp === null) {
        // not valid, code, retain as is
        csParts.push({ value: JSON.stringify(codeBlock.code), type: 'text', filter });
        return;
      }

      // check for type formatter
      let autoType: 'text' | 'label' | 'value' =  evalExp.endsWith('.label') ? 'label' : 'value';
      let typeMatch = evalExp.match(/:(text|label|value)$/);
      if (typeMatch != null) {
        autoType = typeMatch[1] as any;
        evalExp = evalExp.substr(0, evalExp.length - autoType.length - 1);
      }

      if (evalExp.length === 0) {
        // not valid, code, retain as is
        csParts.push({ value: JSON.stringify(codeBlock.code), type: 'text', filter });
        return;
      }

      const pResult = codeBlock.code.length > 2 ? parseExpression(evalExp, dynamicScopeIds, compLookup) : null;
      if (pResult != null) {
        try {
          if (doValidateExpression) {
            eval(`var evalTest = function (){ return ${pResult.parsed};}`);
          }
          csParts.push({ value: '(' + pResult.parsed + ')', type: autoType, filter, components: pResult.components });
        } catch (e) {
          const error = `${codeSection.propNameRead ?? codeSection.propNameToSet}[${f}].text`;
          applogger.debug(error);
          if (parseErrors) {
            parseErrors.push({ name: error, index: codeBlock.startPos, expression: pResult.expression, error: e.message });
          }
          csParts.push({ value: JSON.stringify(codeBlock.code), type: 'text', filter });
          return;
        }
      }
    });

    // handle tail part
    const tail = codeSectionText.substring(lastIndex);
    if (tail.length > 0) {
      csParts.push({ value: JSON.stringify(tail), type: 'text', filter });
    }

    // check for condition
    let pcResult: ExpresionParseResult | null = null;
    let ifCond = section.condition ?? '';
    if (ifCond.startsWith('=')) {
      ifCond = ifCond.substr(1);
    }
    if (ifCond.length > 0) {
      pcResult = parseExpression(ifCond, dynamicScopeIds, compLookup);
      if (pcResult != null) {
        try {
          if (doValidateExpression) {
            eval(`var evalTest = function (){ return ${pcResult.parsed};}`);
          }
        } catch (e) {
          const error = `${codeSection.propNameRead ?? codeSection.propNameToSet}[${f}].if`;
          applogger.debug(error);
          if (parseErrors) {
            parseErrors.push({
              name: error,
              expression: pcResult.expression, 
              error: e.message,
            });
          }
                   
          continue;
        }
      }
    }

    if (csParts.length === 0) {
      // the expression is empty and thus no actual parts, but yet we need to have at least one part of an expression to reflect the empty string
      csParts.push({ value: JSON.stringify(''), type: 'text', filter });
    }

    const x = { parts: csParts, condition: pcResult?.parsed ?? null, separator: section.codeSectionSpacerString ?? '\n', filter };
    codeSectionExpressions.push(x);
  }

  // prepend a spacer if more than one one expression after each expression
  if (codeSectionExpressions.length > 1) {
    codeSectionExpressions.forEach(cse => {
      if (cse.parts.length > 0) {
        cse.parts.unshift({ type: 'spacer', value: JSON.stringify(cse.separator ?? '\n'), filter: cse.filter });
      }
    });
  }

  let expressionResultParts = codeSectionExpressions
    .map(cse => {
      // string building expression (creating "Text"+get('x','y') ... ) or if custom eval 
      let expressionResult = '[' + cse.parts.map(p => `{value:${p.value},type:${JSON.stringify(p.type)}${p.filter != null ? `,filter:${JSON.stringify(p.filter)}` : ''}${p.components != null ? ',components:' + JSON.stringify(p.components) : ''}}`).join(',') + ']';
      if (cse.condition != null) {
        expressionResult = `${cse.condition} ? (${expressionResult}) : ${codeSection.customEvaluate == null ? "''" : '[]'}`;
      }
      return expressionResult;
    });

  // TODO: could perhaps be better expressed using a custom array join method
  let expressionResult = expressionResultParts[0];
  if (expressionResultParts.length > 1) {
    expressionResult = '(' + expressionResult + ').concat(' + expressionResultParts.slice(1).join(',') + ')';
  }

  return expressionResult;
}


function getAllCodeBlocks(block: string | null | undefined): { startPos: number, endPos: number, code: string }[] {
  if (block == null) {
    return [];
  }

  let ret: { startPos: number, endPos: number, code: string }[] = [];
  let code = getCodeBlock(block);
  while (code != null) {
    ret.push(code);
    code = getCodeBlock(block, code.endPos);
  }
    
  return ret;
}

function getCodeBlock(block: string, startIndex?: number): { startPos: number, endPos: number, code: string } | null {
  if (block == null) {
    return null;
  }

  startIndex = startIndex != null ? startIndex : 0;
  if (block.charAt(startIndex) !== '{') {
    startIndex = block.indexOf('{', startIndex);
  }
  if (startIndex < 0) {
    return null;
  }

  let currPos = startIndex;
  let openBrackets = 0;
  let stillSearching = true;
  let waitForChar: string | boolean = false;

  while (stillSearching && currPos <= block.length) {
    let currChar = block.charAt(currPos);

    if (!waitForChar) {
      switch (currChar) {
        case '{':
          openBrackets++;
          break;
        case '}':
          openBrackets--;
          break;
        case '"':
        case "'":
          waitForChar = currChar;
          break;
        case '/':
          var nextChar = block.charAt(currPos + 1);
          if (nextChar === '/') {
            waitForChar = '\n';
          } else if (nextChar === '*') {
            waitForChar = '*/';
          }
      }
    } else {
      if (currChar === waitForChar) {
        if (waitForChar === '"' || waitForChar === "'") {
          block.charAt(currPos - 1) !== '\\' && (waitForChar = false);
        } else {
          waitForChar = false;
        }
      } else if (currChar === '*') {
        block.charAt(currPos + 1) === '/' && (waitForChar = false);
      }
    }

    currPos++;
    if (openBrackets === 0) {
      stillSearching = false;
    }
  }

  return {
    startPos: startIndex,
    endPos: currPos,
    code: block.substring(startIndex, currPos),
  };
}

const keywords: { [id: string]: boolean } = {
  'false': true,
  'true': true,
  'function': true,
  'return': true,
};

const prefixStr = '#'; // if to change, replace (?:#) in below regex (both)
const varRegEx = /^(?:#)?[a-zA-Z_$][a-zA-Z_$0-9]*(\.[a-zA-Z_$][a-zA-Z_$0-9]*)*$/;
const tokenRegEx = /\\"|\\'|"(?:\\"|[^"])*"|'(?:\\'|[^'])*'|(?:#)?[a-zA-Z_$][a-zA-Z_$0-9]*(\.[a-zA-Z_$][a-zA-Z_$0-9]*)*(\s*\()?|\s+|[+-,.]+|.+?/g;

export interface ExpresionParseResult {
  parsed: string;
  expression: string;
  components: string[];
}

function scopeIdEquals(a: string[], b: string[]) {
  if (a == null || b == null) return false;
  if (a.length != b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function parseExpression(expression: string, dynamicScopeIds: string[], compLookup: ComponentLookup): ExpresionParseResult | null {
  const m = expressionIfRewrite(expression).match(tokenRegEx);
  if (m == null) {
    return null;
  }
  const components = [];
  const tokens = [];
  for (let i = 0; i < m.length; ++i) {
    let token = m[i];
    if (token.match(varRegEx) && keywords[token] == null) {
      const isRawExpression = token.startsWith(prefixStr);
      const varExp = isRawExpression ? token.substr(prefixStr.length) : token;
      const parts = varExp.split('.');
      const possibleComponentId = parts.length < 2 ? varExp : parts[0];
      const possiblePropName = parts.length < 2 ? 'value' : parts[1];
      const additionalChainedProps = parts.length > 2 ? '.' + parts.splice(2).join('.') : '';
      if (isRawExpression) {
        token = `get('${possibleComponentId}','${possiblePropName}',false)`;
        components.push(possibleComponentId);
      } else if (compLookup[possibleComponentId] != null) {
        const prevToken = m[i - 1];
        const nextToken = m[i + 1];
        if (nextToken === ')' && prevToken != null && prevToken.length > 1 
                    && prevToken.substr(0, prevToken.length - 1) === expressionStateChangeMethodName) {
          // reference to raw variable passed to StateChangeReasonIs (expressionStateChangeMethodName) method, 
          // pass it as a string instead of fetching variable value
          token = JSON.stringify(token);
        } else {
          const varScope = compLookup[possibleComponentId]
            .find(c => scopeIdEquals(dynamicScopeIds, c.dynamicScopeIds))?.dynamicScopeIds 
                            ?? compLookup[possibleComponentId][0].dynamicScopeIds;

          if (varScope.length === 0) {
            // not a dynamic variable, straight-up get
            token = `get('${possibleComponentId}','${possiblePropName}',false)` + additionalChainedProps;
          } else if (scopeIdEquals(dynamicScopeIds, varScope)) {
            // same scope, use prefix
            token = `get(prefix+'${possibleComponentId}','${possiblePropName}',false)` + additionalChainedProps;
          } else {
            // TODO: potentially provide a up to a point where the scope is equal (however currently nestled dynamic scope isn't supported)
            // var is in different scope
            token = `GetDynamicAll(${JSON.stringify(varScope)},${JSON.stringify(possibleComponentId)},${JSON.stringify(possiblePropName)},get)` + additionalChainedProps;
          }

          components.push(possibleComponentId);
        }
      }
    }
    tokens.push(token);
  }

  return {
    parsed: tokens.join(''),
    expression: expression,
    components: components,
  };
}

interface SmartFilterItem {
  part: CodeSectionPart;
  type: 'value' | 'bound' | 'text';
  isEmpty: boolean;
  index: number;
  row: number;
  canFilter: boolean;
  rowBound?: boolean;
  tagBound?: boolean;
  pair?: SmartFilterItem;
  skip?: boolean;
  isKeptConditionalRow?: boolean;
  specialBreakBound?: boolean;
  components?: string[];
}

export function applySmartCodeSectionFilter(parts: CodeSectionPart[], filterDefault: boolean): CodeSectionPart[] {
  if (parts == null) {
    return [];
  }

  // get smart items (a mapping around split parts)
  let items = getSmartItems(parts, filterDefault);

  // filter rows for smart conditional rows, i.e ends with ||{variable}
  filterSmartConditionalRows(items);

  // filter paired bound where within there's empty texts ( pb1,text?,empty,text?,pb2 )
  filterPairedBounds(items);

  // find any variable within two bounds that can be eliminated
  filterEmptyVariables(items);

  // lets now search for trailing non-row bounds and empty rows
  cleanUpLeadingBoundsAndEmptyRows(items);

  // map items back to parts
  return items
    .filter(item => item.skip !== true)
    .map(item => item.part);
}

const specialBreak = '||';
const specialBreakEsc = '\\||';
const specialBreakReplaceMatch = /(?<!\\)\|\|/g;
const specialBreakChar = String.fromCharCode(0x13);
//const specialBreakReplaceRevert = /(?<=\s)\u0013\s|\u0013/g;

function filterSmartConditionalRows(items: SmartFilterItem[]) {
  // filter rows for smart conditional rows, i.e ends with ||{variable}
  // we're looking for something ending with "[breakChar],[empty-text-part]{0,1},{variable},[empty-text-part]{0,1},[endOfRowOrSequence]"
  for (let endOfRowIndex = 0; endOfRowIndex <= items.length; ++endOfRowIndex) {
    const endOfRow = endOfRowIndex < items.length ? items[endOfRowIndex] : null;
    if (endOfRow != null && (endOfRow.canFilter !== true || endOfRow.rowBound !== true)) {
      // either not something we can filter or not a row bound (end of sequence is treated as a row bound)
      continue;
    }

    let idx = endOfRowIndex;
        
    // look at the next one
    let peek = items[--idx];
    if (peek == null || !peek.canFilter) continue;

    if (peek.type === 'text') {
      if (peek.part.value != null && peek.part.value.trim() !== '') {
        // this is not a only whatspace part, i.e. not matching what we're looking for
        continue;
      }

      peek = items[--idx];
      if (peek == null || !peek.canFilter) continue;
    }

    const varPart = peek;
    if (varPart.type !== 'value') {
      // not matching what we're looking for
      continue;
    }

    peek = items[--idx];
    if (peek == null || !peek.canFilter) continue;

    if (peek.type === 'text') {
      if (peek.part.value != null && peek.part.value.trim() !== '') {
        // this is not a only whatspace part, i.e. not matching what we're looking for
        continue;
      }

      peek = items[--idx];
      if (peek == null || !peek.canFilter) continue;
    }

    // now check for the break char
    const boundPart = peek;
    if (boundPart.specialBreakBound !== true) {
      // not matching what we're looking for
      continue;
    }

    // set skip for logic items from idx..endOfRowIndex (i.e. we're not outputting this in the report) 
    for (let i = idx; i < endOfRowIndex && i < items.length; ++i) {
      items[i].skip = true;
    }

    const varValue = varPart.part.value;
    const varHasValue = !IsEmptyExt(varValue);
    boundPart.isKeptConditionalRow = varHasValue;
    if (!varHasValue) {
      const rowToEliminate = varPart.row;
      for (let i = idx - 1; i >= 0;--i) {
        if (items[i].row !== rowToEliminate || !items[i].canFilter) break;
        items[i].skip = true;
      }
      if (endOfRow != null) {
        endOfRow.skip = true;
      }
    }
  }
}

function filterPairedBounds(items: SmartFilterItem[]) {
  for (let j = 0; j < items.length; ++j) {
    const item = items[j];
    if (item.type === 'bound' && item.pair != null) {
      let eliminate = false;
      for (let i = item.index + 1; i < item.pair.index; ++i) {
        const tItem = items[i];
        if (tItem.type === 'value') {
          if (tItem.isEmpty && tItem.canFilter) {
            // there's an empty value in this stretch, mark for elimination
            eliminate = true;
          } else {
            // there's a value here, keep
            eliminate = false;
            break;
          }
        }
        if (tItem.type === 'bound' && tItem.rowBound) {
          // we do not allow row-breaks in a paired section
          eliminate = false;
          break;
        }
      }
      if (eliminate) {
        for (let i = item.index + 1; i <= item.pair.index; ++i) items[i].skip = true;
      }

      // skip forward
      j = item.pair.index + 1;
    }
  }
}

function filterEmptyVariables(items: SmartFilterItem[]) {
  for (let j = 0; j < items.length; ++j) {
    const item = items[j];
    if (item.type !== 'value' || !item.canFilter) {
      continue;
    }

    const doFilter = item.isEmpty;
    if (doFilter) {
      item.skip = true;
      // lets remove backwards until we hit other than text
      for (let i = j - 1; i >= 0; --i) {
        const tItem  = items[i];
        if (tItem.type !== 'text' || !tItem.canFilter) {
          if (tItem.type === 'bound' && tItem.tagBound) {
            break;
          }
          if (tItem.canFilter && tItem.type === 'bound' && !tItem.rowBound) {
            tItem.skip = true;
          }
          break;
        }
        tItem.skip = true;
      }

      // lets move forward until we hit other than text
      for (let i = j + 1; i < items.length; ++i) {
        const tItem  = items[i];
        if (tItem.type !== 'text' || !tItem.canFilter) {
          break;
        }
        tItem.skip = true;
      }
    }
  }
}

function cleanUpLeadingBoundsAndEmptyRows(items: SmartFilterItem[]) {
  function isEmptyBlock(item: SmartFilterItem) {
    const part = item.part;
    if (IsEmpty(part.value)) {
      return true;
    }
    // test if in practice empty
    if (item.type === 'text' && String(part.value).replace(/<\/?(em|i|strong|b|pre|mono|small|h1|h2|h3)[^>]*>/g, '').trim() === '') {
      return true;
    }

    return false;
  }


  // process items and keep track of each row
  let ri = { row: -1, empty: false, leadingBoundSkipped: false, rowHasSkipped: true };
  for (let j = 0; j < items.length; ++j) {
    const item = items[j];
    if (item.row !== ri.row) {
      // first on row
      ri.row = item.row;
      ri.empty = item.isKeptConditionalRow !== true && (item.skip || item.isEmpty);
      ri.leadingBoundSkipped = false;
    }

    if (!ri.empty) continue; // we want to skip forward to next row

    ri.rowHasSkipped = ri.rowHasSkipped || (item.skip ?? false);
        
    if (item.skip || isEmptyBlock(item)) continue; // this items is empty, not interesting to count
    if (item.type === 'bound' && !item.tagBound) {
      if (item.rowBound) {
        // we've come to the end of the row and we're still an empty row, skip the row-bound as well
        item.skip = true;
        continue;
      } else if (!ri.leadingBoundSkipped && ri.rowHasSkipped) {
        // allow skipping first non-row bound (it's a leading bound that follows skipped items, i.e. prior removed items)
        item.skip = true;
        ri.leadingBoundSkipped = true;
        continue;
      }
    }

    ri.empty = ri.empty && item.isEmpty;
  }

  // check for trailing new-line effects when filtering last line
  if (items.length > 0) {
    const last = items[items.length - 1];
    // if we've skipped the last part of the report and it's not a newline we shouldn't end with an upstream newline
    if (last.skip && last.rowBound !== true) {
      for (let j = items.length - 2; j >= 0; j--) {
        const item = items[j];
        if (item.skip !== true) {
          if (item.rowBound === true) {
            item.skip = true;
          }
          break;
        }
      }

    }
  }

  // ensure no rows start with leading space due to filtering
  let rowHasContent = false;
  let hasSkips = false;
  for (let j = 0; j < items.length; ++j) {
    const item = items[j];
    if (item.rowBound === true) {
      rowHasContent = false;
      hasSkips = false;
    } else if (item.skip === true) {
      hasSkips = true;
    } else if (hasSkips && !rowHasContent) {
      rowHasContent = true;
      // clean-up one leading space
      if (item.type === 'text' && String(item.part.value).startsWith(' ')) {
        item.part.value = String(item.part.value).substr(1);
      }
    }
  }
}

function getSmartItems(parts: CodeSectionPart[], filterDefault: boolean) {
  const bounds = [ ';', '.', ',' ];
  const tagBounds = ['<', '>'];
  const pairedOpen = ['('];
  const pairedClose = [')'];
  const rowBounds = ['\n'];
  const allBounds = [...bounds, ...pairedOpen, ...pairedClose, ...rowBounds, ...tagBounds, specialBreakChar];

  // build list of smart filter items where each code section part is wrapped in a holder item 
  // and where we break all parts (when not a value) on a boundry character
  let items: SmartFilterItem[] = [];
  let rowIndex = 0;
  parts.forEach(p => {
    if (p.type === 'value') {
      items.push({
        part: { ...p }, 
        type: 'value',
        isEmpty: IsEmpty(p.value),
        canFilter: (p.filter ?? filterDefault) === true,
        row: rowIndex,
        index: items.length,
        components: p.components,
      });
      rowIndex += p.value != null ? (String(p.value).match(/\n/g) || []).length : 0;
    } else if (p.value != null) {
      // clean-up row-feed chars (have no value)
      p.value = p.value.replace(/\r/g, '');

      // replace special-break unless escaped and remove esacpe of special break
      p.value = p.value.replace(specialBreakReplaceMatch, specialBreakChar);
      p.value = p.value.replace(specialBreakEsc, specialBreak);

      let before: string;
      let start = 0;
      for (let i = 0; i < p.value.length; ++i) {
        const char = p.value[i];
        if (allBounds.indexOf(char) >= 0) {
          // take part before and add to 
          before = p.value.substr(start, i - start);
          if (before.length > 0) {
            items.push({
              part: { ...p, value: before }, 
              type: 'text',
              isEmpty: p.value == null || p.value === '',
              canFilter: (p.filter ?? filterDefault) === true,
              row: rowIndex,
              index: items.length,
            });
          }
          let isRowBound = rowBounds.indexOf(char) >= 0;
          let isTagBound = tagBounds.indexOf(char) >= 0;
          items.push({
            part: { ...p, value: char != specialBreakChar ? char : '' },
            type: 'bound',
            isEmpty: char == specialBreakChar,
            canFilter: (p.filter ?? filterDefault) === true,
            row: rowIndex,
            rowBound: isRowBound,
            tagBound: isTagBound,
            specialBreakBound: char == specialBreakChar,
            index: items.length,
            skip: items.length === 0 && p.type === 'spacer', // always skip the leading spacer
          });
          if (isRowBound) ++rowIndex;
          start = i + 1;
        }
      }
      before = p.value.substr(start, p.value.length - start);
      if (before.length > 0) {
        items.push({
          part: { ...p, value: before }, 
          type: 'text',
          isEmpty: IsEmpty(p.value),
          canFilter: (p.filter ?? filterDefault) === true,
          row: rowIndex,
          index: items.length,
        });
      }
    }
  });
    
  // setup pairs for paired bounds
  let pStack: { item: SmartFilterItem, pIndex: number }[] = [];
  for (let j = 0; j < items.length; ++j) {
    const item = items[j];
    if (item.type === 'bound') {
      let pIndex = pairedOpen.indexOf(item.part.value);
      if (pIndex >= 0) {
        pStack.push({ item, pIndex });
      }
      pIndex = pairedClose.indexOf(item.part.value);
      if (pIndex >= 0) {
        let peek = pStack[pStack.length - 1];
        if (peek.pIndex === pIndex) {
          // match
          let pairItem = peek.item;
          pairItem.pair = item;
          pStack.pop();
        }
      } 
    }
  }

  // revert paired bounds that doesn't contain a variable (pb1,text,pb2)
  for (let j = 0; j < items.length; ++j) {
    const item = items[j];
    if (item.type === 'bound' && item.pair != null && item.pair.index == item.index + 2 && items[item.index + 1].type === 'text') {
      item.type = 'text';
      item.pair.type = 'text';
      delete item.pair;
    }
  }

  return items;
}

function expressionIfRewrite(val: string): string {
  if (val == null) return '';

  function getReplacement(method: string, params: string[]): string | null {
    if (method === 'If' || method === 'IfThen') {
      return params.length > 0
        ? '(' + params[0].trim() + '?' + (params.length > 1 ?  '(' + params[1].trim() + ')' : 'undefined') + ':'  + (params.length > 2 ?  '(' + params[2].trim() + ')' : 'undefined') + ')'
        : 'undefined';
    }

    return null;
  }

  function getParam(fromIdx: number): number {
    const sStack: string[] = [];
    let inStr: '"' | "'" | null = null;
    for (let i = fromIdx; i < val.length; ++i) {
      const c = val[i];
      if (inStr != null) {
        if (c === inStr) {
          // break unless escaped
          if (i === 0 || val[i - 1] != '\\') inStr = null;
        }
      } else if (c === '"' || c === "'") {
        inStr = c;
      } else if ((c === ',' || c === ')') && sStack.length === 0) return i;
      else if (c === '(') sStack.push(')');
      else if (c === '{') sStack.push('}');
      else if (c === '[') sStack.push(']');
      else if (c === sStack[sStack.length - 1]) sStack.pop();
    }
    return -1;
  }

  const methods = ['If', 'IfThen'];
  for (let i = 0; i < methods.length; ++i) {
    // Find method of focus
    const method = methods[i];
    let idx = val.indexOf(method);
    while (idx !== -1) {
      const methodStart =  idx;
      idx += method.length;
      if (val[idx] == '(') {
        // Lets search ahead for all parameters
        const params: string[] = [];
        while (idx !== -1 && val[idx] != ')') {
          // extract a param
          const from = idx + 1;
          const to = idx = getParam(from);
          params.push(val.substring(from, to));
        }

        // Unable to parse code
        if (idx === -1) break;

        // Do replacement if a replacement is found
        const replacement = getReplacement(method, params);
        if (replacement != null) {
          const methodEnd = idx + 1;
          const before = val.substring(0, methodStart);
          const after = val.substring(methodEnd);
          val = before + replacement + after;
          idx += replacement.length - (methodEnd - methodStart);
        }
      }

      // Find next occurance unless we've reach the end 
      idx = val.indexOf(method, idx);
    }
  }

  return val;
}