import { IInterFrameCom } from './IInterFrameCom';

export function sendAndForget<T>(interFrameCom: IInterFrameCom<T>, source?: Window | WindowProxy | null) {
  source?.postMessage(JSON.stringify(interFrameCom), '*');
}

export function sendAndWaitForResponse<T, R>(interFrameCom: IInterFrameCom<T>, source?: Window | WindowProxy | null){
  return new Promise<R>((res, rej) => {
    const ch = new MessageChannel();
    ch.port1.onmessage = (resp: MessageEvent) => {
      try {
        res(resp.data.data);
      } catch {
        rej();
      }
    };
    source?.postMessage(JSON.stringify(interFrameCom), '*', [ch.port2]);
  });
}
