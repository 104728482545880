/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-redeclare */
import React from 'react';
import { SectraInput } from './SectraBaseComponent/SectraInput';
import { GetRowComponent } from './SectraRow';
import { ReactComponentContainerProps  } from './SrtComponent';
import { SrValueComponentPropsBase, SrComponent } from '../BasicTypes';
import { SchemaProperties, schema } from './Schema';
import { FormDataOutput } from './FormDataOutput';
import { IsEmpty } from './ScriptHelperMethods';
import { GetNumberAsTextFormatter } from '../NumberHelper';
import { dateComponentKey } from '../Toolbox/ComponentNames';

// Compontent base props
export interface ComponentBaseProps extends SrValueComponentPropsBase {
  placeholder?: string;
  size?: string | number;
  prefix?: string;
  suffix?: string;
}

export const componentBaseSchema: SchemaProperties = schema.mergeSchemaProps(schema.DefaultSizeSchemaPart, {
  'placeholder': { 'type': ['string', 'null'], 'description': 'Input placeholder value.' },
  'value': { 'type': 'string', 'description': schema.ValueDescription },
  'prefix': schema.PropDefinition.prefix,
  'prefixStyle': schema.PropDefinition.prefixStyle,
  'suffix': schema.PropDefinition.suffix,
  'suffixStyle': schema.PropDefinition.suffixStyle,
});

// Text component props
interface ComponentTextProps extends ComponentBaseProps {
  speechEnabled?: boolean;
}

const componentTextSchema: SchemaProperties = schema.mergeSchemaProps(componentBaseSchema, {
  'value': { 'type': ['string', 'null'], 'description': 'An initial value for the text field' },
  'speechEnabled': { 'type': 'boolean', 'description': 'Whether to allow speech into this field or not (from IDS7 24.1, default: true)' },
});

// Date component props
interface ComponentDateProps extends ComponentBaseProps {
  enforceValueRange?: 'none' | 'loose' | 'strict';
  isFocused?: boolean; 
  minValue?: number | string;
  maxValue?: number | string;
}

const componentDateSchema: SchemaProperties = schema.schemaDropProps(schema.mergeSchemaProps(componentBaseSchema, {
  'value': { 'type': ['string', 'null'], 'description': 'An initial value for the date field' },
  'minValue': { 'type': ['string'], 'description': 'Min date allowed, e.g. "2020-01-02" or "=new Date().toLocaleDateString()" (meaning today).' }, 
  'maxValue': { 'type': ['string'], 'description': 'Max date allowed, e.g. "2020-01-02" or "=new Date().toLocaleDateString()" (meaning today).' }, 
  'enforceValueRange': { 'type': 'string', 'enum': ['strict', 'loose', 'none'], 'enumDescription': ['The component value must be a valid number within range, i.e. min/max value', 'The component value must be a valid number within range or null', 'No validation is performed'], 'description': 'Enforce that the component value is valid and within range (default: loose).' }, 
}), ['placeholder']);


// The generic React input component
interface InputComponentProps extends ComponentTextProps, ComponentDateProps {
  dataType: | 'text' | 'date';
}

const InputComponent: React.FC<ReactComponentContainerProps<InputComponentProps>> = (container) => {
  const props = container.props;
  const numFormatter = GetNumberAsTextFormatter(container.templateContext.langCode);

  let value: string | number = props.value;
  if (props.dataType === 'text') {
    if (IsEmpty(value)) {
      value = '';
    } else if (typeof value === 'number') {
      value = numFormatter(value);
    }
  } else if (props.dataType === 'date') {
    value = formatDateValueForOuput(props.value) ?? '';
  }

  return GetRowComponent(props, container.context, container.templateContext,
        <>
        <FormDataOutput id={props.id} name={props.name ?? props.inherritedLabel ?? props.id} value={value} type={props.dataType} mandatory={props.mandatory} 
            worklistAttribute={props.worklistAttribute} freeField={props.freeField} />
        <SectraInput
            id={props.id}
            type={props.dataType}
            className={'form-control'}
            onInputChange={(val: string) => {
              let newValue: string | number | null = val;
              // since writing usually is a stream of updates let us set user input without running scripts (false)
              // and instead issue a delayed script runner so that we can consolidate events
              container.functions.setUserInput(props.id, 'value', newValue, false);
              container.functions.runScripts(props.dataType === 'text' ? 200 : 25, [{ type: 'setUserInput', compId: props.id, propName: 'value' }]);
            }}
            value={value ?? ''}
            name={props.name ?? props.inherritedLabel ?? props.id}
            style={{ maxWidth: '100%' }}
            placeholder={props.placeholder}
            disabled={props.disabled === true}
            min={props.dataType !== 'date' ? props.minValue : formatDateValueForOuput(props.minValue)}
            max={props.dataType !== 'date' ? props.maxValue : formatDateValueForOuput(props.maxValue)}
            onFocus={props.dataType === 'date' ? () => {
              container.functions.setUserInput(props.id, 'isFocused', true, false);
            } : undefined}
            onBlur={props.dataType === 'date' ? () => {
              // just leave focus update focus (set to false)
              container.functions.setUserInput(props.id, 'isFocused', false, false);
            } : undefined}
            autoComplete={'off'}
            speechEnabled={props.speechEnabled !== false && props.dataType !== 'date'} />
        
        </>);
};

//
// The text component
//

const textComponentKey = 'Text';
export const Text : SrComponent<ComponentTextProps> = {
  key: textComponentKey,
  render: (props, context, templateContext, functions) => <InputComponent props={{ ...props, dataType: 'text' }} context={context} templateContext={templateContext} functions={functions}/>,
  template: () => Promise.resolve(`- ${textComponentKey}:
    id: text${schema.getNextIdNum()}`),
  getInitValues: () => ({ 'value': null }),
  toolboxName: textComponentKey,
  schema: schema.getSchema(textComponentKey, componentTextSchema),
  normalizeDataSourceValue: (value) => {
    if (typeof(value) === 'object'){
      return JSON.stringify(value);
    }
    return String(value);
  },
};

//
// The date component
//

export const SectraDate : SrComponent<ComponentDateProps> = {
  key: dateComponentKey,
  render: (props, context, templateContext, functions) => <InputComponent props={{ ...props, dataType: 'date', size: props.size ?? 'm' }} context={context} templateContext={templateContext} functions={functions} />,
  template: () => Promise.resolve(`- ${dateComponentKey}:
    id: date${schema.getNextIdNum()}`),
  getInitValues: () => ({ 'value': null }),
  toolboxName: dateComponentKey,
  schema: schema.getSchema(dateComponentKey, componentDateSchema),
  onStateChangeRunner: (props, set) => {
    // update value unless focused (user is still writing), enforce value is disabled 
    if (props.isFocused !== true && props.enforceValueRange !== 'none') {
      // update value to a valid valid
      set(props.id, 'value', getValidDateInputValue(props), false);
    }
  },
  normalizeDataSourceValue: (value) => {
    if (value != null && value instanceof Date) {
      return value.toLocaleDateString();
    }
    if (value != null && typeof value === 'number') {
      return new Date(+value).toLocaleDateString();
    }
    const p = Date.parse(value);
    if (p > 0) {
      value = new Date(p).toLocaleDateString();
    }
        
    return value;
  },
};

function getValidDateInputValue(props: ComponentDateProps): string | null {
  let currentValue = props.value != null ? String(props.value) : null;   
  const currentValueAsInt: number | null = currentValue != null
    ? Date.parse(currentValue)
    : (props.enforceValueRange !== 'strict' ? null : new Date().getTime());
     
  if (currentValueAsInt != null) {
    const minAsInt = props.minValue != null ? Date.parse(String(props.minValue)) : null;
    const maxAsInt = props.maxValue != null ? Date.parse(String(props.maxValue)) : null;

    if (minAsInt != null && currentValueAsInt < minAsInt) {
      currentValue = new Date(minAsInt).toLocaleDateString();
    } else if (maxAsInt != null && currentValueAsInt > maxAsInt) {
      currentValue = new Date(maxAsInt).toLocaleDateString();
    }
  }

  return currentValue;
}

function formatDateValueForOuput(val: any): string | undefined {
  const dValAsInt = val != null ? Date.parse(String(val)) : NaN;
  return dValAsInt != 0 && !isNaN(dValAsInt) ? formatDateForOutput(new Date(dValAsInt)) : undefined;
}

function formatDateForOutput(date: Date): string {
  const offset = date.getTimezoneOffset();
  const outputDate = new Date(date.getTime() - (offset * 60 * 1000));
  return outputDate.toISOString().split('T')[0];
}