import React from 'react';

interface ErrorBoundaryState{
  hasError: boolean;
  errorMessage?: string;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error.message };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <><h1>Something went wrong.</h1>
                <p>{this.state.errorMessage ?? ''}</p>
                <p>This message might get stuck. You can try to reload</p>
                <button className={'btn btn-primary'} onClick={()=>{this.setState({ hasError: false });}}>Reload</button>
            </>;
    }

    return this.props.children;
  }
}