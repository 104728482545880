/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IAnnotationInfo, ICanvasAnnotation, IPosition, MapItem, ModifyResult } from '../SectraCanvasAnnotation';

interface Span {
  left: number;
  right: number;
  y: number;
}

interface CheckSpan extends Span{
  direction: number;
}

interface PixelData {
  width: number;
  height: number;
  data: Uint32Array;
}

interface Rect extends Span {
  y2: number;
}

export class CanvasFillAnnotation implements ICanvasAnnotation {
  private spansToFill: Span[] = [];

  protected colorIndex: number;

  protected currentCtx: CanvasRenderingContext2D | null;

  constructor(colorIndex: number) {
    this.colorIndex = colorIndex;
    this.currentCtx = null;
    this.floodFill = this.floodFill.bind(this);
    this.setNewBasePoint = this.setNewBasePoint.bind(this);
    this.getBoundingRect = this.getBoundingRect.bind(this);
    this.spansToFill = [];
  }

  getType(): string {
    return 'FloodFill';
  }

  setColorIndex(idx: number) { this.colorIndex = idx; }

  getColorIndex(): number { return this.colorIndex; }

  getPoints(): IPosition[] {
    const retval = [];
    for (let span of this.spansToFill) {
      retval.push({ x: span.left, y: span.y });
      retval.push({ x: span.right, y: span.y });
    }
    return retval;
  }

  setPoints(points: IPosition[]) {
    if (points.length === 0) {
      return;
    }
    for (let i = 0; i < points.length; i += 2) {
      this.spansToFill.push({ left: points[i].x, right: points[i + 1].x, y: points[i].y });
    }
  }

  getColor(ctx: CanvasRenderingContext2D) {
    const fillstyle = (ctx.fillStyle as string);
    const r = fillstyle.substr(1, 2);
    const g = fillstyle.substr(3, 2);
    const b = fillstyle.substr(5, 2);
    return 'ff' + b + g + r;
  }

  floodFill(ctx: CanvasRenderingContext2D, x: number, y: number, fillColor: number) {
    const fillColorStr = this.getColor(ctx);
    const spansToCheck: CheckSpan[] = [];
    fillColor = parseInt(fillColorStr, 16);

    function getPixel(pixelData: PixelData, gx: number, gy: number) {
      if (gx < 0 || gy < 0 || gx >= pixelData.width || gy >= pixelData.height) {
        return -1;  // impossible color
      } else {
        return pixelData.data[gy * pixelData.width + gx];
      }
    }

    const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);

    const pixelData : PixelData = {
      width: imageData.width,
      height: imageData.height,
      data: new Uint32Array(imageData.data.buffer),
    };

    const targetColor = getPixel(pixelData, x, y);
    
    if (targetColor !== fillColor) {
      
      const addSpan = (left: number, right: number, ay: number, direction: number) => {
        spansToCheck.push({ left, right, y: ay, direction });
      };
      
      const checkSpan = (left: number, right: number, cy: number, direction: number) => {
        let inSpan = false;
        let start: number = 0;
        let cx: number = 0;
        for (cx = left; cx < right; ++cx) {
          const color = getPixel(pixelData, cx, cy);
          if (color === targetColor) {
            if (!inSpan) {
              inSpan = true;
              start = cx;
            }
          } else {
            if (inSpan) {
              inSpan = false;
              addSpan(start, cx - 1, cy, direction);
            }
          }
        }
        if (inSpan) {
          inSpan = false;
          addSpan(start, cx - 1, cy, direction);
        }
      };
      
      addSpan(x, x, y, 0);
      
      for (let i = 0; i < spansToCheck.length; i++) {
        let span = spansToCheck[i];
        if (span == null) {
          break;
        }
        const { left, right, y: iy, direction } = span;
        
        let l = left;
        for (l = left; getPixel(pixelData, l, iy) === targetColor; l--);
        ++l;
        
        let r = right;
        for (r = right; getPixel(pixelData, r, iy) === targetColor; r++);

        const lineOffset = iy * pixelData.width;
        pixelData.data.fill(fillColor, lineOffset + l, lineOffset + r);
        this.spansToFill.push({ left: l, right: r, y: iy });
        
        if (direction <= 0) {
          checkSpan(l, r, iy - 1, -1);
        } else {
          checkSpan(l, left, iy - 1, -1);
          checkSpan(right, r, iy - 1, -1);
        }
        
        if (direction >= 0) {
          checkSpan(l, r, iy + 1, +1);
        } else {
          checkSpan(l, left, iy + 1, +1);
          checkSpan(right, r, iy + 1, +1);
        }     
      }
      ctx.putImageData(imageData, 0, 0);
    }
  }

  startDraw(ctx: CanvasRenderingContext2D, pos: IPosition, map: MapItem[] | null | undefined): boolean {
    const oldLineJoin = ctx.lineJoin; ctx.lineJoin = 'miter';
    const oldLineCap = ctx.lineCap; ctx.lineCap = 'butt';
    const oldWidth = ctx.lineWidth; ctx.lineWidth = 1;
    const oldSmooth = ctx.imageSmoothingEnabled; ctx.imageSmoothingEnabled = false;
    this.floodFill(ctx, pos.x, pos.y, this.colorIndex);

    ctx.lineJoin = oldLineJoin;
    ctx.lineCap = oldLineCap;
    ctx.lineWidth = oldWidth;
    ctx.imageSmoothingEnabled = oldSmooth;

    return true;
  }

  stopDraw(): ModifyResult {
    return { isValid: true, redraw: false };
  }


  setPoint(pos: IPosition): boolean {
    return true;
  }

  redrawToCanvas(ctx: CanvasRenderingContext2D): void {
    const oldLineJoin = ctx.lineJoin; ctx.lineJoin = 'miter';
    const oldLineCap = ctx.lineCap; ctx.lineCap = 'butt';
    const oldWidth = ctx.lineWidth; ctx.lineWidth = 2;
    const oldSmooth = ctx.imageSmoothingEnabled; ctx.imageSmoothingEnabled = false;
    const path = new Path2D();
    for (let i = 0; i < this.spansToFill.length; i++) {
      let span = this.spansToFill[i];
      path.moveTo(span.left, span.y);
      path.lineTo(span.right, span.y);
    }
    ctx.stroke(path);
    ctx.lineJoin = oldLineJoin;
    ctx.lineCap = oldLineCap;
    ctx.lineWidth = oldWidth;
    ctx.imageSmoothingEnabled = oldSmooth;
  }

  getBoundingRect(): Rect {
    let x1 = Number.MAX_VALUE;
    let x2 = 0;
    let y1 = Number.MAX_VALUE;
    let y2 = 0;
    for (let span of this.spansToFill) {
      if (span.left < x1) {
        x1 = span.left;
      }
      if (span.right > x2) {
        x2 = span.right;
      }
      if (span.y < y1) {
        y1 = span.y;
      }
      if (span.y > y2) {
        y2 = span.y;
      }
    }
    return { left: x1, right: x2, y: y1, y2: y2 };
  }

  getWithinActionBox(pos: IPosition): boolean {
    const boundingRect = this.getBoundingRect();
    return pos.x >= boundingRect.left && pos.x <= boundingRect.right && pos.y >= boundingRect.y && pos.y <= boundingRect.y2;
  }

  setNewBasePoint(pos: IPosition): boolean {
    const boundingRect = this.getBoundingRect();
    const centerPoint = { x: Math.round((boundingRect.left + boundingRect.right) / 2), y: Math.round((boundingRect.y + boundingRect.y2) / 2) };
    const xDiff = Math.round(pos.x - centerPoint.x);
    const yDiff = Math.round(pos.y - centerPoint.y);
    for (let i = 0; i < this.spansToFill.length; i++) {
      const currSpan = this.spansToFill[i];
      currSpan.left += xDiff;
      currSpan.right += xDiff;
      currSpan.y += yDiff;
    }
    return true;
  }

  getAnnotationInfo(): IAnnotationInfo {
    return {
      type: this.getType(),
      colorIndex: this.getColorIndex(),
      points: this.getPoints(),
    };
  }
}