import React from 'react';
import { Grid } from 'react-bootstrap';
import './Help.scss';
import { useVersion } from '../CustomHooks/useVersion';


export const About: React.FC = () => {
  const [version, year, buildNr] = useVersion();

  return (
    <Grid>
      <h1>LABEL</h1>
      <table className="help-table">
        <tbody>
          <tr>
            <td>Product</td>
            <td>Sectra Forms</td>
          </tr>
          <tr>
            <td>Version</td>
            <td>{version.substr(0, version.lastIndexOf('.'))}</td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'top' }}>Manufacturer</td>
            <td>Sectra AB<br />Teknikringen 20<br />SE-583 30 Linköping<br />Sweden<br /><a href="https://www.sectra.com/">www.sectra.com</a></td>
          </tr>
          <tr>
            <td>Contact</td>
            <td><a href="https://www.sectra.com/medical/contact/support/">www.sectra.com/medical/contact/support</a></td>
          </tr>
        </tbody>
      </table>

      <h1>Product Details</h1>
      <table className="help-table">
        <tbody>
          <tr>
            <td>Patch number</td>
            <td>{version.split('.')[2]}</td>
          </tr>
          <tr>
            <td>Build number</td>
            <td>{buildNr}</td>
          </tr>
          <tr>
            <td>Year of Patch Release</td>
            <td>{year}</td>
          </tr>
        </tbody>
      </table>

      <h1>Copyright &amp; Legal</h1>
      <div className="box">
        <p>&copy; Sectra AB, Sweden, {year}</p>
        <p>
          All rights are reserved. Reproduction or transmission in whole or part, in any form or by any means,
          electronic, mechanical or otherwise, is prohibited without written consent of the copyright owner.
        </p>
        <p>
          Copyrights and all other proprietary rights in any software and related documentation (&quot;Software&quot;)
          made available to you rest exclusively with Sectra AB. No title or ownership in the Software is conferred to you.
          Use of the Software is subject to the end user license conditions as are available on request.
        </p>
        <p>
          To the maximum extent permitted by law, you shall not decompile and/or reverse engineer the software or any part thereof.
        </p>
      </div>
      <h1>Compliance to Regulations and Standards</h1>
      <div className="box">
        <p>The Management System of Sectra AB conforms to ISO 9001, ISO 27001, ISO 27017 and ISO27018.</p>
      </div>
      <h1>Trademarks and Patents</h1>
      <div className="box">
        <p>Sectra, Sectra Imtec and the Sectra logotype, are registered trademarks of Sectra AB.</p>
        <p>Image Exchange Portal logotype is a registered trademark of Sectra Products UK Limited.</p>
        <p>
          IDS7, Sectra BizTrack, Sectra CloudFlex, Sectra CTMA, Sectra DoseTrack, Sectra IEP, Sectra IMA, Sectra Image Central, Sectra Image Lab,
          Sectra LiteView, Sectra UniView, Sectra One, Sectra One Connect, Sectra Open Archive, Sectra VNA, Sectra UserInfluence, WISE,
          Sectra Upload &amp; Store App, Sectra Upload &amp; Store Web, Sectra Upload &amp; Store Auto and Sectra Access Portal are trademarks of Sectra AB.
        </p>
        <p>Sectra Preop Online and Sectra OneScreen are trademarks of Sectra AB.</p>
        <p>
          Windows is a registered trademark of Microsoft Corporation in the United States and other countries.
          IpadOS, Mac, Safari, iPhone and iPad are registered trademarks of Apple Inc. in the United States and other countries.
        </p>
        <p>Android is a trademark of Google LLC.</p>
        <p>All other names/products with ® / ™ are registered/unregistered trademarks of the respective manufacturer.</p>
        <p>
          The intellectual property of Sectra includes a number of patents, for full information refer to:<br />
          <a href="https://sectra.com/medical/about-sectra/patents/">https://sectra.com/medical/about-sectra/patents</a>
        </p>
      </div>
    </Grid>
  );
};