import React from 'react';
import { FormDataOutput } from './FormDataOutput';
import { SectraRow } from './SectraBaseComponent/SectraRowOverride';
import { SrComponent, SrValueComponentPropsBase, SrComponentPropsBase, ComponentRenderContext, TemplateContext } from '../BasicTypes';
import { ReactComponentContainerProps, shouldDisplay } from './SrtComponent';
import { expressionPattern, schema } from './Schema';

import { SrtContainer } from './SrtContainer';
import { SectraCheckButton } from './SectraBaseComponent/SectraCheckButtonOverride';
import { RowBreak, VSpace } from './SectraStructure';
import { colSplitSchema, rowSpacingSchema, SrRowSpacing } from '../TemplateDefaults';

interface RowProps extends SrComponentPropsBase {
  hidden?: boolean;
  fullWidth?: boolean;
  minWidth?: number;
  checkbox?: boolean;
  description?: string;
  checkboxFunc?: 'display' | 'none';
  spacing?: SrRowSpacing;
  colSplit?: string | number;
  allowRowWrap?: boolean;
  helpContentHtml?: string;
  helpContent?: string;
  value?: boolean;
  autoHide?: boolean;
  noVisableChildren?: boolean;
}

const rowComponentKey = 'Row';
const rowComponentSchema = schema.mergeSchemaProps(schema.DefaultComponentSchemaPart, {
  'id': { 'type': 'string', 'description': 'The id of the element' },
  'label': { 'type': ['string', 'null'], 'description': 'Label for the row' }, 
  'fullWidth': { 'type': ['string', 'boolean'], 'description': 'Component renders in full width, where label is rendered before/above the child components (default: false)', 'pattern': expressionPattern },
  'minWidth': { 'type': ['string', 'number'], 'description': 'Minimum row width allowed (number or string expression, default: [none]).', 'pattern': expressionPattern }, 
  'colSplit': {
    'anyOf': [
      colSplitSchema,
      { 'type': 'string', 'description': 'Column split given by an expression (only the first value is needed, i.e. 1 through 11)', 'pattern': expressionPattern },
    ],
    'description': colSplitSchema.description,
  },
  'hidden': schema.PropDefinition.hidden,
  'display': schema.PropDefinition.display,
  'allowRowWrap': { 'type': ['string', 'boolean'], 'description': 'Whether to allow components on row to wrap on several rows when needed (default: true)', 'pattern': expressionPattern },
  'checkbox': { 'type': ['string', 'boolean'], 'enumDescription': ['Display row label with a checkbox', 'Normal behaviour (default)'], 'description': 'Displayed as a checkable row (default: false)', 'pattern': expressionPattern },
  'checkboxFunc': { 'type': 'string', 'enum': ['display', 'none'], 'enumDescription': ['Row value state (checked or not) controls whether to display children or not', 'No function, checked or not is just reflected in the row value state'], 'description': 'A checkable row will by default toggle display of child components, set to none to override behaviour (default: display)' },
  'description': { 'type': ['string', 'null'], 'description': 'Description of this row (tooltip on row label)' }, 
  'helpContentHtml': { 'type': 'string', 'description': 'If set, add help after label that will parse the given HTML and display it when clicked.' },
  'helpContent': { 'type': 'string', 'description': 'If set, add help after label that will display this value when clicked.' },
  'spacing': {
    'anyOf': [
      rowSpacingSchema,
      { 'type': 'string', 'description': 'Spacing given by an expression', 'pattern': expressionPattern },
    ],
    'description': rowSpacingSchema.description,
  },
  'value': { 'type': ['string', 'boolean'], 'description': 'An initial value for the checkable row', 'enumDescription': ['Checked', 'Not checked (default)'] },
  'autoHide': { 'type': ['string', 'boolean'], 'description': 'Hide entire row when there are no viable children (default: true)', 'enumDescription': ['Auto-hide row (default)', 'No auto-hide (label is shown even when there are no visable children)'] },
});

export const RowReactComponent: React.FC<ReactComponentContainerProps<RowProps>> = (container) => {
  const props = container.props;
  if (!shouldDisplay(props.display, container.context)) {
    return null;
  }

  let containerRC = { ...container.context, rowContext: true };

  let labelInnerOverride: JSX.Element | undefined = undefined;
  if (props.checkbox === true) {
    labelInnerOverride = <>
            <FormDataOutput id={props.id} type="checkbox" value={props.value} />
            <SectraCheckButton
                id={props.id}
                name={props.inherritedLabel ?? props.id}
                value={props.label ?? ''}
                checked={props.value}
                onStateChange={(b: boolean) => { container.functions.setUserInput(props.id, 'value', b); }} />
        </>;
  }

  let displayChildren = true;
  if (props.checkbox === true && (props.checkboxFunc == null || props.checkboxFunc === 'display')) {
    displayChildren = props.value ?? false;
  }

  const spacing = props.spacing ?? container.templateContext.defaults.rowSpacing;
  const className =  'show-grid sectra-row' 
        + (spacing != null && spacing != 'normal' ? ' spacing-' + spacing : '')
        + (props.allowRowWrap === false ? ' nowrap' : '');

  const hidden = props.hidden === true || (props.autoHide !== false && props.noVisableChildren === true && props.checkbox !== true);
  const minWidth = props.minWidth != null && !isNaN(+props.minWidth) && isFinite(+props.minWidth) ? +props.minWidth : undefined;
  const labelColSize = getLabelColSizeFromSplit(props.colSplit ?? container.templateContext.defaults.defaultColSplit);

  return <SectraRow className={className} title={props.description} labelText={props.label} hidden={hidden} marginTop={props.hidden !== true ? undefined : 0}
            fullWidthRender={props.fullWidth} labelInnerOverride={labelInnerOverride} data-component-id={props.id} containerMinWidth={minWidth}
            labelColSize={labelColSize} helpContent={props.helpContent} helpContentHtml={props.helpContentHtml}>
        {displayChildren 
          ? <SrtContainer parentId={props.id} renderContext={containerRC} templateContext={container.templateContext} functions={container.functions} /> 
          : <div className={!props.fullWidth ? 'empty' : undefined} style={{ visibility: 'hidden', height: 0, width: 0, flexGrow: 0, margin: 0 }}>
            <SrtContainer parentId={props.id} renderContext={containerRC} templateContext={container.templateContext} functions={container.functions} /> 
        </div>}
    </SectraRow>;
};

export const Row : SrComponent<RowProps> = {
  key: rowComponentKey,
  render: (props, context, templateContext, functions) => <RowReactComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  getInitValues: () => ({ value: false }),
  template: ()=>Promise.resolve(`- ${rowComponentKey}:
    label: MyRow
    components:
    - Text:
        id: text${schema.getNextIdNum()}
    - Number:
        id: number${schema.getNextIdNum()}
    `),
  toolboxName: 'Row',
  schema: schema.getSchema(rowComponentKey, rowComponentSchema, [], false),
  onStateChangeRunner: (props, set, get, context, templateContext) => {
    const prefix = context.prefix ?? '';
    const childData = context.currentParsedComponent.children
      .map(c => c.id)
      .filter(x => x != null)
      .map(x => {
        const id = prefix + x;
        const cc = templateContext.runtime.getRenderContextByComponentId(id);
        return {
          id: id, 
          hidden: get(id, 'hidden', true) === true,
          display: shouldDisplay(get(id, 'display', true), cc),
          hasPrefix: get(id, 'prefix', true) != null,
          isRowBreak: cc?.currentParsedComponent.component == RowBreak,
          isVspace: cc?.currentParsedComponent.component == VSpace,
        };
      });

    const visableChildren = childData.filter(x => !x.hidden && x.display);
    for (let i = 0; i < visableChildren.length - 1; ++i) {
      const item = visableChildren[i];
      const next = visableChildren[i + 1];
      const hasTrailingPrefix = !item.isRowBreak && !item.isVspace
                && !next.isRowBreak && !next.isVspace && next.hasPrefix;

      set(item.id, 'hasTrailingPrefix', hasTrailingPrefix, true);
    }
        
    const noVisableChildren = visableChildren.length === 0 
            || visableChildren.every(x => x.isRowBreak || x.isVspace);

    // setup intenal property no visable children
    set(props.id, 'noVisableChildren', childData.length > 0 && noVisableChildren, true);
  },
};

export function IsInDirectRowContext(context: ComponentRenderContext, templateContext: TemplateContext) {
  let inRowContext = context.rowContext === true;
  if (!inRowContext || context.parentComponentId == null) {
    return inRowContext;
  }

  let component = templateContext.runtime.getComponentById(context.parentComponentId);
  return component == null || component.componentName === Row.key;
}

export function GetRowComponent(props: SrValueComponentPropsBase, context: ComponentRenderContext, templateContext: TemplateContext, inner: JSX.Element, containerClass?: string): JSX.Element {
  if (!shouldDisplay(props.display, context)) {
    return <></>;
  }

  const isNotHidden = props.hidden !== true;
  const prefix = (props as any)?.prefix;
  const prefixStyle = (props as any)?.prefixStyle ?? 'normal';
  const suffix = (props as any)?.suffix;
  const suffixStyle = (props as any)?.suffixStyle ?? 'normal';
  const hasSuffix = suffix != null && isNotHidden;
  const hasPrefix = prefix != null && isNotHidden;

  const size = (props as any)?.size ?? 'fill';
  const sizeIsNumber = typeof(size) === 'number' || !isNaN(Number(size));

  const containerClassNames = [
    (size !== 'fill' ? 'input-size-' + (sizeIsNumber ? 'custom' : size) : null), 
    (hasSuffix ? 'has-suffix' : null),
    (!hasSuffix && isNotHidden && (props as any)?.hasTrailingPrefix === true ? 'has-trailing-prefix' : null),
    containerClass,
  ];

  let containerStyle: React.CSSProperties = { };
  if (props.hidden === true) {
    containerStyle.visibility = 'hidden';
    containerStyle.height = 0;
    containerStyle.flexGrow = 0;
    containerStyle.width = 0;
    containerStyle.margin = 0;
  } else if (sizeIsNumber) {
    containerStyle.width = Math.max(Number(size), 0) + 'rem';
  }

  const containerClassName = containerClassNames.filter(x => x != null && x.length > 0).join(' ');
  const ret = <>
        { hasPrefix ? <span className={'inline-text info-text prefix' + (prefixStyle != 'normal' ? ' ' + prefixStyle : '')} data-component-id={props.id}>{prefix}</span> : null}
        <div style={containerStyle} className={containerClassName.length > 0 ? containerClassName : undefined} data-component-id={props.id}>
            { inner }
        </div>
        { hasSuffix ? <span className={'inline-text info-text suffix' + (suffixStyle != 'normal' ? ' ' + suffixStyle : '')} data-component-id={props.id}>{suffix}</span> : null}
    </>;

  const labelColSize = getLabelColSizeFromSplit(templateContext.defaults.defaultColSplit);
  return IsInDirectRowContext(context, templateContext) || props.hidden === true
    ? ret
    : <SectraRow className={'show-grid'} fullWidthRender={context.rowContext === true} labelColSize={labelColSize}>{ ret }</SectraRow>;
}

export function getLabelColSizeFromSplit(split?: string | number): number {
  if (split == null) {
    return 4;
  }

  const asInt = typeof split === 'number' ? split : parseInt(split.toString(), 10);
  return Math.max(1, Math.min(asInt, 11));
}