/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-prototype-builtins */
import { Components } from './SrtComponent/Components';
import { ReportOutput } from './SrtComponent/ReportOutput';
import { MandatoryFieldProps, MandatoryFields } from './SrtComponent/MandatoryFields';
import { CodeSectionParseError, getCodeSectionScript, parseExpression } from './CodeSections';
import { SummaryProps } from './SrtComponent/Summary';
import { applogger } from '../applogger';
import { buttonClickEventPropName } from './SrtComponent/SectraButton';
import { Component, ComponentLookup, FreeFieldSpec, ParsedComponent, ParsedComponentExpression, ParsedTemplateSpec, ParseError, SrComponent, SrContainerComponentPropsBase, SrValueComponentPropsBase, TemplateMetadata, TemplateSpec, WhenEmptyOptions } from './BasicTypes';
import { checkboxComponentKey, dateComponentKey, numberComponentKey } from './Toolbox/ComponentNames';

export const rootElementId: string = '#Root';
export const dynamicContextVariable: string = 'ListItem';
export const reportComponentId: string = '#Report';
export const mandatoryFieldsComponentId: string = '#MandatoryFields';
export const autoComponentIdPrefix: string = '#Auto';

const AssumeExpressionProps: { [propName: string]: boolean } = {
  'hidden': true,
  'disabled': true,
  'display': true, 
  [buttonClickEventPropName]: true,
};

export const isSpecialVariable = (compId?: string | null) => {
  if (compId == null) {
    return false;
  }

  return isAutoAddedComponent(compId)
        || compId === rootElementId
        || compId.startsWith(autoComponentIdPrefix)
        || compId.endsWith(dynamicContextVariable);
};

export const isAutoAddedComponent = (compId: string | null) => {
  return compId === reportComponentId || compId === mandatoryFieldsComponentId;
};

// Gets the Tagname i.e. HR or Dropdown
function getNameOfComponent(v: any): string {
  let single: string | undefined = undefined;
  for (let x in v) {
    if (v.hasOwnProperty(x)) {
      single = x;
      break;
    }
  }
  return String(single);
}

// tests if a component can be in a dynamic scope and thus whether prefix should be used or not
const getDynamicScopeIds = (parent: ParsedComponent<any> | null) => {
  const ret: string[] = [];
  while (parent != null) {
    if (parent.component.dynamicChildScope) {
      ret.push(parent.id);
    }
    parent = parent.parent;
  }
  return ret;
};

const getGlobalNameType = (componentName: string): number => {
  if (componentName === checkboxComponentKey) {
    return 3;
  } else if (componentName === dateComponentKey) {
    return 2;
  } else if (componentName === numberComponentKey) {
    return 1;
  } else {
    return 0;
  }
};

const ListAutoComponentName = 'ListAutoComponent';

export function ParseTemplateSpec(spec: TemplateSpec, validateExpressions: boolean): ParsedTemplateSpec {
  const ret: ParsedTemplateSpec = getEmptyDefaultParsedSpec();
  const t0 = performance != null ? performance.now() : null;

  try {
    if (spec.Metadata == null) {
      ret.ParseErrors.push({ error: 'No metadata!' });
      spec.Metadata = {} as TemplateMetadata;
    }
    if (spec.Metadata.explicitDataSources == null) {
      spec.Metadata.explicitDataSources = [];
    }
    if (spec.ReportOutput == null) {
      spec.ReportOutput = [];
    }
    if (spec.Components == null) {
      ret.ParseErrors.push({ error: 'No components!' });
      spec.Components = [];
    }

    ret.Metadata = spec.Metadata;
    ret.SourceSpec = spec;
    ret.TemplateFields = [];

    // Process components into parsed components
    let autoId = 0;
    const getAutoId = ()=>autoComponentIdPrefix + (autoId++);
    const componentLookup: ComponentLookup = {};
    const addToComponentLookup = (comp: ParsedComponent<any>) => {
      if (componentLookup[comp.id] == null) {
        componentLookup[comp.id] = [];
      }
      componentLookup[comp.id].push(comp);
    };

    const compParser = (componentsSpec: Component[] | undefined, parent?: ParsedComponent<any>, path?: string): ParsedComponent<any>[] | null => {
      if (componentsSpec == null) {
        return null;
      }

      const containerPath = (path ?? '.Components');
      try {
        const parsed: ParsedComponent<any>[] = [];
        componentsSpec.forEach((compSpec, idx) => {
          const componentName: string = getNameOfComponent(compSpec);
          if (componentName == null) {
            ret.ParseErrors.push({ error: `Unknown component structure: ${JSON.stringify(compSpec)}`, path: containerPath });
            return;
          }

          const compPath = `${containerPath}[${idx}].${componentName}`;
          const component = Components[componentName];
          const initValues = component.getInitValues != null ? component.getInitValues() : {};
          const args = { ...initValues, ...(compSpec[componentName] ?? {}) } as SrContainerComponentPropsBase; // source is the object in the components list, i.e. {"SingleRowInputString": { label: ...} }
          const worklistAttr = ((args as any) as SrValueComponentPropsBase).worklistAttribute;
          const freeField = ((args as any) as SrValueComponentPropsBase).freeField as FreeFieldSpec;
          if (worklistAttr || freeField) {
            ret.TemplateFields.push({
              Name: args.id,
              GlobalName: worklistAttr ?? '',
              Type: worklistAttr ? getGlobalNameType(componentName) : 0,
              Path: [],
              Destination: freeField?.id ? 'free-field.' + freeField.id : '',
              ClearDestinationOnEmpty: freeField?.whenEmpty && freeField.whenEmpty === WhenEmptyOptions.clear ? true : false });
          }
          if (!(componentName in Components)) {
            ret.ParseErrors.push({ error: `Unknown component: ${componentName}`, path: compPath, componentId: args.id });
            return;
          }

          // set inherited label
          args.inherritedLabel = args.label != null ? args.label : (parent?.args.inherritedLabel ?? null);

          // set label as a spec property for components that cannot have label 
          // (backwards compatability and a conveniance when for instance writing report texts)
          if (args.label == null && args.inherritedLabel != null) {
            const schemaProperties = component.schema.properties?.[componentName]?.properties;
            if (schemaProperties != null && schemaProperties.label == null) {
              args.label = args.inherritedLabel;
            }
          }

          // setup id and ensure that component has an id
          const initialId = args.id != null ? String(args.id) : null;
          args.id = args.id ?? getAutoId();

          const pc: ParsedComponent<any> = {
            id: args.id,
            oEhrId: args.oEhrId,
            args: args,
            componentKey: JSON.stringify(args),
            componentName: componentName,
            componentPath: compPath,
            expressions: [],
            expresionByPropName: {},
            parent: parent ?? null,
            component: component,
            children: [],
            sourceId: initialId,
            dynamicScopeIds: getDynamicScopeIds(parent ?? null),
            parseErrors: [],
          };

          // Add dummy variable for dynamic context information
          if (component.dynamicChildScope) {
            addToComponentLookup({
              id: dynamicContextVariable,
              args: {},
              componentKey: '',
              componentName: ListAutoComponentName,
              componentPath: compPath + '.' + dynamicContextVariable,
              expressions: [],
              expresionByPropName: {},
              parent: pc,
              component: component,
              children: [],
              sourceId: dynamicContextVariable,
              dynamicScopeIds: getDynamicScopeIds(pc),
              parseErrors: [],
            } as ParsedComponent<any>);
          }

          // setup children
          if (component.getChildren) {
            // custom childrens definition
            let items = component.getChildren(args);
            items.forEach(cItem => {
              let parsedChildren = compParser(cItem.children, pc, compPath + '.' + cItem.propPath + '.components');
              if (parsedChildren != null) {
                pc.children.push(...parsedChildren);
              }
            });
          } else if (args.components && args.components.length) {
            // assume args.components
            let parsedChildren = compParser(args.components as Component[], pc, compPath + '.components');
            if (parsedChildren != null) {
              pc.children = parsedChildren;
            }
          }

          addToComponentLookup(pc);
          parsed.push(pc);
        });

        // return if parsed if any component was successfully parsed, or if there were none to parse, otherwise return null indicating that all failed to parse
        return parsed.length > 0 || componentsSpec.length === 0 ? parsed : null;
      } catch (e) {
        applogger.error(e);
        ret.ParseErrors.push({ error: `Exception while parsing: ${e}`, path: containerPath });
        return null;
      }
    };

    const parsedComponents = spec.Components != null ? compParser(spec.Components) : [];
    if (parsedComponents != null) {
      ret.Root.children = parsedComponents;
    }

    const reportSpecArgs = { id: reportComponentId, inherritedLabel: null, hidden: true, display: true, preventUserEdit: true, reportTemplate: spec.ReportOutput } as SummaryProps;
    ret.Root.children.push({
      id: reportComponentId,
      args: reportSpecArgs,
      componentKey: JSON.stringify(reportSpecArgs),
      componentName: 'ReportOutput',
      componentPath: '',
      expressions: [],
      expresionByPropName: {},
      parent: ret.Root,
      component: ReportOutput,
      children: [],
      sourceId: null,
      dynamicScopeIds: [],
      parseErrors: [],
    });

    const mandatoryDisplay = ret.Metadata.mandatoryFields ?? 'display';
    if (mandatoryDisplay !== 'no-display') {
      const mandatoryFieldsArgs = { id: mandatoryFieldsComponentId, display: true, displayType: mandatoryDisplay } as MandatoryFieldProps;
      ret.Root.children.push({
        id: mandatoryFieldsComponentId,
        args: mandatoryFieldsArgs,
        componentKey: JSON.stringify(mandatoryFieldsArgs),
        componentName: 'MandatoryFields',
        componentPath: '',
        expressions: [],
        expresionByPropName: {},
        parent: ret.Root,
        component: MandatoryFields,
        children: [],
        sourceId: null,
        dynamicScopeIds: [],
        parseErrors: [],
      });
    }

    ret.ComponentLookup = componentLookup;


    // get list of all parsed components (skip list item "dummy" components added)
    const compontents =  Object.keys(componentLookup)
      .filter(x => x != dynamicContextVariable || componentLookup[x][0].componentName !== ListAutoComponentName)
      .map(id => ({ id, components: componentLookup[id] }));

    // Find any duplicates
    compontents
      .filter(x => x.components.length > 1)
      .forEach(dup => {
        dup.components.forEach((c, i) => {
          ret.ParseErrors.push({ error: `Duplicate id "${c.id}" found for component: ${c.componentName}`, path: c.componentPath, componentId: c.id });

          // resolve duplicate
          if (i !== 0) {
            const newId = getAutoId();
            applogger.log('Temporary rewrite of component id from ' + c.id + ' to ' + newId);
            c.id =  c.args.id = newId;
          }
        });

      });

    // Find any malformed component ids
    const validIdentifier = /^[a-zA-Z_ÅÄÖåäö$][0-9a-zA-Z_ÅÄÖåäö$]*$/;
    compontents.forEach(ca => ca.components.forEach(c => {
      if (!isSpecialVariable(c.id) && !validIdentifier.test(c.id)) {
        ret.ParseErrors.push({ error: `Invalid identifier name "${c.id}" found for component: ${c.componentName}`, path: c.componentPath, componentId: c.id });
      } else if (isSpecialVariable(c.sourceId)) {
        ret.ParseErrors.push({ error: `Unallowed identifier name "${c.id}" found for component: ${c.componentName}`, path: c.componentPath, componentId: c.id });
      }
    }));

    ret.AllExpressions = SetupComponentExpressions(ret.Root.children, componentLookup, validateExpressions, ret.ParseErrors);
  } catch (e) {
    applogger.error(e);
    ret.ParseErrors.unshift({ error: `Fatal error while parsing template spec: ${e}` });
  }
    
  if (t0 != null) applogger.debug(`${performance.now() - t0} - ms elapsed while building component structure`);

  return ret;
}

function SetupComponentExpressions(children: ParsedComponent<any>[], componentLookup: ComponentLookup, validateExpressions: boolean, parseErrors?: ParseError[]): ParsedComponentExpression[] {
  let allExpressions: ParsedComponentExpression[] = [];
  children.forEach(child => {
    let oKeys = Object.keys(child.args);
    oKeys.forEach(propName => {
      let propValue = child.args[propName];
      if (typeof propValue === 'string' && (propValue.startsWith('=') || propValue.startsWith(':=') || AssumeExpressionProps[propName] === true)) {
        // lets assume this is a calculated field
        const setAsUserInput = propValue.startsWith(':=');
        let expString = setAsUserInput 
          ? propValue.substr(2)
          : (propValue.startsWith('=') ? propValue.substr(1) : propValue);

        let initValue = undefined;
        const initValueMatch = expString.match(/\|\|init=(.+)$/);
        if (initValueMatch) {
          expString = expString.substr(0, expString.length - initValueMatch[0].length);
          const initValueExp = initValueMatch[1];
          try {
            initValue = eval(initValueExp);
          } catch (e) {
            const error = { error: `Error for ${child.id}.${propName} (init=): ${e} (init value expression evaluated: ${initValueExp})`, path: child.componentPath, componentId: child.id, propName: propName };
            applogger.debug(error);
            child.parseErrors.push(error);
            if (parseErrors) parseErrors.push(error);
          }
        }

        if (expString != null && expString !== '' && child.component.expressionPreprocessor != null) {
          expString = child.component.expressionPreprocessor(propName, expString, child.args);
        }
                
        let expression: string | null = null;
        const pResult = parseExpression(expString, child.dynamicScopeIds, componentLookup);
        if (pResult != null) {
          expression = `function(prefix) { return ${pResult.parsed}; }`;
          try {
            // applogger.debug('testing expression', expression)
            if (validateExpressions) {
              eval('var evalTest = ' + expression);
            }
            if (initValue !== undefined) {
              child.args[propName] = initValue;
            } else {
              delete child.args[propName];
            }
          } catch (e) {
            const error = { error: `Error for ${child.id}.${propName}: ${e} (expression evaluated: ${pResult.expression})`, path: child.componentPath, componentId: child.id, propName: propName };
            applogger.debug(error);
            child.parseErrors.push(error);
            if (parseErrors) parseErrors.push(error);
            expression = null;
          }
        }

        if (expression != null) {
          let exp: ParsedComponentExpression = {
            componentId: child.id,
            componentPropName: propName,
            componentPropNameRead: propName,
            expression: expression,
            expressionName: `${child.id}.${propName}`,
            setAsUserInput: setAsUserInput,
          };

          child.expressions.push(exp);
          child.expresionByPropName[exp.componentPropName] = exp;
          allExpressions.push(exp);
        }
      }
    });

    // handle potential code section properties
    if (child.component.getCodeSections) {
      let csItems = child.component.getCodeSections(child.args);
            
      csItems.forEach(codeSection => {
        const errors: CodeSectionParseError[] = [];
        const expressionResult = getCodeSectionScript(codeSection, codeSection.executeInParentDyanmicContext !== true ? child.dynamicScopeIds : [...child.dynamicScopeIds, child.id], componentLookup, validateExpressions, errors);
        errors.forEach(e => {
          const error = { 
            error: `${e.error} (expression evaluated: ${e.expression})`,
            componentId: child.id,
            propName: e.name,
            path: child.componentPath,
          };

          child.parseErrors.push(error);
          if (parseErrors) parseErrors.push(error);
        });

        let expression: string = `function(prefix) { return ${expressionResult}; }`;
        let exp: ParsedComponentExpression = {
          componentId: child.id,
          componentPropName: codeSection.propNameToSet,
          componentPropNameRead: codeSection.propNameRead ?? codeSection.propNameToSet,
          expression: expression,
          expressionName: `${child.id}.${codeSection.propNameToSet}`,
          setAsUserInput: false,
          customEvaluate: codeSection.customEvaluate,
        };

        child.expressions.push(exp);
        child.expresionByPropName[exp.componentPropName] = exp;
        allExpressions.push(exp);
      });
    }

    if (child.children.length > 0) {
      allExpressions.push(...SetupComponentExpressions(child.children, componentLookup, validateExpressions, parseErrors));
    }
  });

  return allExpressions;
}
const emptyRoot: ParsedComponent<any> = {
  id: rootElementId,
  args: {},
  component: {} as SrComponent<any>,
  children: [],
  componentKey: '',
  componentName: '',
  componentPath: '',
  expressions: [],
  expresionByPropName: {},
  parent: null,
  sourceId: null,
  dynamicScopeIds: [],
  parseErrors: [],
};

const emptyDefaultParsedSpecInternal: ParsedTemplateSpec = {
  Metadata: {} as TemplateMetadata,
  DataSources: [],
  Root: emptyRoot,
  SourceSpec: { Metadata: {} as TemplateMetadata, DataSources: [], Components: [], ReportOutput: [] },
  AllExpressions: [],
  ParseErrors: [],
  ComponentLookup: {},
  TemplateFields: [],
  ReturnRtf: false,
};
const emptyDefaultParsedSpecJson = JSON.stringify(emptyDefaultParsedSpecInternal);
export const getEmptyDefaultParsedSpec = () => JSON.parse(emptyDefaultParsedSpecJson) as ParsedTemplateSpec;
