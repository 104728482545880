import React, { useEffect, useState } from 'react';
import { GuideTarget, guideSteps } from './content';
import { condClasses, undefClasses } from '../Helpers/ClassHelpers';


const FOUCS_TIMEOUT_DURATION = 2000;

let focusTimeout: any = null;


function findTargetElement(target: GuideTarget) {
  if (target.type && target.name) {
    const typeElements = document.querySelectorAll(`[data-highlight-type="${target.type}"]`);
  
    for (const typeElement of typeElements) {
      const namedElement = typeElement?.querySelector(`[data-highlight-name="${target.name}"], [title="${target.name}"]`);

      if (namedElement) return namedElement;
    }

    return null;
  }

  return null;
}


type HighlightTargetProps = {
  step: number;
};
export const HighlightTarget: React.FC<HighlightTargetProps> = (props) => {
  
  // TargetING
  const target = guideSteps[props.step].target;
  let [ targetElement, setTargetElement ] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!target) {
      setTargetElement(null);
    } else {
      setTargetElement(findTargetElement(target) as HTMLElement | null);
    }
  }, [ target ]);


  // Focus
  let [focus, setFocus] = useState(false);
  useEffect(() => {
    setFocus(true);

    clearTimeout(focusTimeout);
    focusTimeout = setTimeout(() => {
      setFocus(false);
    }, FOUCS_TIMEOUT_DURATION);
  }, [ target ]);


  // Styling
  const hidden = !target || !targetElement;

  const bounds = targetElement?.getBoundingClientRect();
  const variables = {
    '--x': `${bounds?.left ?? 0}`,
    '--y': `${bounds?.top ?? 0}`,
    '--width': `${bounds?.width ?? 0}`,
    '--height': `${bounds?.height ?? 0}`,
  } as React.CSSProperties;
  
  return <div className="guide-highlight-element-wrapper">
    <div className={`guide-highlight-element ${undefClasses([target?.class])} ${condClasses({ focus: focus && !hidden })}`} style={variables} />
  </div>;
};
  