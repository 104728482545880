import React, { CSSProperties } from 'react';
import { FreeFieldSpec, SrMandatoryType, WhenEmptyOptions } from '../BasicTypes';

export interface FormDataOutputProps {
  id: string;
  value: string | number | boolean | undefined | null;
  type?: 'number' | 'text' | 'checkbox' | 'date';
  name?: string;
  mandatory?: SrMandatoryType;
  worklistAttribute?: string;
  freeField?: FreeFieldSpec;
}

// This helper communicates form data and mandatory to IDS7
export const FormDataOutput: React.FC<FormDataOutputProps> = (props) => {
  const propsValue = props.value != null && Array.isArray(props.value)
    ? (props.value.length === 0 ? null : (props.value.length === 1 ? props.value[0] : String(props.value)))
    : props.value;

  let type = props.type;
  if (type == null) {
    let vType = typeof propsValue;
    switch (vType) {
      case 'number': 
        type = vType;
        break;
      case 'boolean':
        type = 'checkbox';
        break;
      case 'string':
        type = 'text';
        break;
      default:
        type = 'text';
        break;
    }
  }

  const cssHidden: CSSProperties = { visibility: 'hidden', height: 0, width: 0, margin: 0 };
  const dataDestinationClearEmpty = props.freeField && props.freeField.whenEmpty === WhenEmptyOptions.clear ? 'free-field.' + props.freeField.id : null;
  const dataDestinationIgnoreEmpty = props.freeField && props.freeField.whenEmpty === WhenEmptyOptions.noUpdate ? 'free-field.' + props.freeField.id : null;

  if (type === 'checkbox') {
    const checked = (propsValue === 'false' || propsValue === 'False' || propsValue === '0') 
      ? false 
      : Boolean(propsValue);
            
    // We currently do not handle mandatory for boolean options as the value can just be true/false
    return <input type="checkbox" name={props.id} data-field-type={type} data-skip-in-report="yes" checked={checked} hidden={true} 
        data-sectra-worklist-attribute-name={props.worklistAttribute} readOnly={true} style={cssHidden} 
        data-sectra-destination-clear-empty={dataDestinationClearEmpty} data-sectra-destination-ignore-empty={dataDestinationIgnoreEmpty} />;
  }

  const value: string | number | undefined = propsValue != null 
    ? (typeof propsValue !== 'number' ? String(propsValue) : propsValue)
    : undefined;

  // Currently this solution has a knwon issue with mandatory fields using name for alert fields which can be bypassed which then could hid a previously defined (sequential HTML order) input field with and id overlapping the name used for the downstream mandatory non-filled out field
  // Poential solution with current IDS7 interface: Force mandatory warning field rendering before rendering any value fields
  const mandatory = props.mandatory !== 'none' ? props.mandatory : undefined;
  const valueField = typeof value !== 'number'
    ? <input name={props.id} type="hidden" data-field-type={type} data-skip-in-report="yes" value={value ?? ''}
            data-sectra-worklist-attribute-name={props.worklistAttribute}
            data-sectra-destination-clear-empty={dataDestinationClearEmpty} data-sectra-destination-ignore-empty={dataDestinationIgnoreEmpty} />
    : <input name={props.id} type="number" data-field-type={type} data-skip-in-report="yes" value={value} hidden={true} readOnly={true} style={cssHidden}
            data-sectra-worklist-attribute-name={props.worklistAttribute}
            data-sectra-destination-clear-empty={dataDestinationClearEmpty} data-sectra-destination-ignore-empty={dataDestinationIgnoreEmpty} />;
  const mandatoryWarningField = mandatory !== undefined && (value == null || value === '')
    ? <input type="hidden" name={props.name ?? props.id} data-field-type={type} data-src-component-id={props.id} data-field-completion-action={mandatory} data-skip-in-report="yes" data-is-empty="true" />
    : null;

  return <>{valueField}{mandatoryWarningField}</>;
};

