/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import { SvgRef } from './SvgRef';

export interface GenericTableRowData {
  values: string[];
  titles?: (string | undefined)[];
  className?: string;
  iconId?: string;
  deleted?: boolean;
  extraButtons?: ExtraButton[];
}

export interface ExtraButton {
  onClick: (clickedRow: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconId: string;
  iconClassName?: string;
  disabled?: boolean;
  title?: string;
}

export interface GenericTableProps {
  headers: string[];
  tableRows?: GenericTableRowData[];
  onClick?: (clickedIndex: number) => void;
  onDelete?: (clickedIndex: number) => void;
  onUndo?: (clickedIndex: number) => void;
  onDragStart?: (e: React.DragEvent, index: number) => void;
  hiddenXsColumns?: number[];
  loadingMessage?: string;
  emptyMessage?: string;
}

export const GenericTable: React.FC<GenericTableProps> = (props) => {
  function onDragOver(e: React.DragEvent) {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  }

  function onDragStart(e: React.DragEvent, index: number) {
    if (props.onDragStart) {
      let el = e.currentTarget.children.item(0)?.children?.item(0) ?? e.currentTarget;
      e.dataTransfer.setDragImage(el, 0, 0);
      props.onDragStart(e, index);
    }
  }

  if (props.tableRows == null) {
    return <p>{props.loadingMessage ?? 'Loading, please wait...'}</p>;
  }
  if (props.tableRows?.length === 0) {
    return <p><i>{props.emptyMessage ?? 'Currently no data available'}</i></p>;
  }

  const hasActionRow = props.onDelete || props.tableRows?.some(x => x.extraButtons);
  const isXsHidden = (idx: number) => props.hiddenXsColumns != null && props.hiddenXsColumns.indexOf(idx) !== -1;

  return <>
        <table className="table table-list">
            <thead>
                <tr>
                    {props.headers.map((x, i) => <th className={isXsHidden(i)  ? 'hidden-xs' : undefined} key={i}>{x}</th>)}
                    {hasActionRow ? <th key="actionRow"></th> : null}
                </tr>
            </thead>
            <tbody>
                {props.tableRows?.map((x, i) => {
                  const columns = x.values.map((v, j) => new GenericTableColumn(v, x.titles?.[j]));
                  const rowClassName = x.className != null 
                    ? x.className + ' ' + (x.deleted ? ' deleted' : '')
                    : (x.deleted ? 'deleted' : '');

                  return <tr 
                        key={i} 
                        draggable={props.onDragStart != null}
                        style={{ cursor: props.onDragStart != null || props.onClick != null ? 'pointer' : undefined }}
                        className={rowClassName}
                        onClick={() => props.onClick ? props.onClick(i) : null} 
                        onDragOver={onDragOver} 
                        onDragStart={e => onDragStart(e, i)}
                    >
                        {columns.map((col, colIdx) => 
                            <td className={isXsHidden(colIdx) ? 'hidden-xs' : undefined} title={col.title} key={colIdx}>
                                {colIdx === 0 && x.iconId 
                                  ? <span style={{ marginLeft: '-2rem', marginRight: '.73rem' }}>
                                        <SvgRef id={x.iconId} className="svg-icon"></SvgRef>
                                    </span>
                                  : null}
                                <span>{col.value}</span>
                            </td>)}
                        {hasActionRow ?
                            <td className="actionButtons" key="actionRow" onClick={evt => evt.stopPropagation()}>
                                {x.extraButtons?.map((xb, iconIndex) =>
                                    <button key={iconIndex} className="btn btn-link" onClick={e => xb.onClick(i, e)} disabled={xb.disabled} title={xb.title}>
                                        <SvgRef id={xb.iconId} className={'svg-icon' + (xb.iconClassName ? (' ' + xb.iconClassName) : '')} />
                                    </button>,
                                )
                                }
                                {props.onDelete && !x.deleted &&
                                    <button className="btn btn-link chevron delete" title="Delete" onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      props.onDelete && props.onDelete(i);
                                      return false;
                                    }
                                    }>
                                        <SvgRef id="icon-ListDelete" className={'svg-icon delete'} />
                                    </button>
                                }
                                {props.onUndo && x.deleted &&
                                    <button className="btn btn-link chevron" title="Undo deletion" onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      props.onUndo && props.onUndo(i);
                                      return false;
                                    }}>
                                        <SvgRef id="icon-Undo" className="svg-icon undo" />
                                    </button>
                                }
                            </td>
                          : null}
                    </tr>;
                })}
            </tbody>
        </table>
    </>;
};

class GenericTableColumn {
  readonly title?: string;

  readonly value: string;

  constructor(value: string, title?: string) {
    this.value = value;
    this.title = title;
  }
}
