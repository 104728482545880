import React, { useEffect } from 'react';
import { Grid } from 'react-bootstrap';
import './Help.scss';
import templateAdminImg from './images/FormAdmin.png';
import templateEditorImg from './images/TemplateEditor.png';
import templateSpecImg from './images/TemplateSpec.png';
import codeCompleteImg from './images/CodeComplete.png';
import scriptsEditorImg from './images/ScriptsEditor.png';
import formDataImg from './images/FormData.png';
import formStateImg from './images/FormState.png';
import buildImg from './images/Build.png';
import providerPageImg from './images/ProviderPage.png';
import { About } from './About';

const engLink = 'https://training.sectra.com/course/view.php?id=1562';
const sweLink = 'https://training.sectra.com/course/view.php?id=1566&lang=sv';

export const UsersGuide: React.FC = () => {
  const smartFilterCodeBlock = `Lesion size ||{[lSizeW, lSizeH, lSizeD]}
  Width: {lSizeW}
  Height: {lSizeH}
  Depth: {lSizeD}`;

  const globalVariablesCodeBlock = `// globally defied variables:
var global = window.global ?? {
  lumbarPartsList: [], // Maps index of selected lumbar part to its values
};
window.global = global;`;

  const providerExample = `- Text:
  id: text0
  dataarg: MyProvider.MyField`;

  const setProviderDataExample = `- EventHandler.OnDataProviderRetrieve = function(get, set, data) {
  var modified = data[0].providerData[0].properties.mySqlProvider[0].Id + " _modified";
  set('textarea0', 'value', modified);
}`;

  const explicitDatasourcesExample = `Metadata:
  explicitDataSources:
  - GenericDataProvider`;

  const outputIdentifierExample = `Metadata:
  outputIdentifier: MyForwardingProvider`;

  const forwardingProvidersExample = `Metadata:
  forwardingProviders:
  - MyForwardingProvider`; 

  const usingForwardingProviderExample = `- Text:
  id: text0
  dataarg: MyForwardingProvider.MyField`;

  const specialVariableProperties = `- index - The 1-based (i.e. starting from 1 for the first item) index of the current item (also available as value or default when no property is given, i.e {ListItem}))
- zindex - The 0-based index of the current item
- count - The number of repeated items
- prefix - The current item prefix
- prefixBase - The prefix base for the current item. I.e. prefix = prefixBase + index`;

  const sectraFormsEntity = 'Sectra Forms';
  const srDataprovidersEntity = 'SR Dataproviders';

  useEffect(() => {generateToc();}, []);

  return (
    <Grid>
      <h1>Users Guide</h1>
      <div id="toc">
        <h3>Table of Contents</h3>
      </div>

      <div id="ug-contents" className="section-count">
        <h2>Disclaimer</h2>
        <p>
          {sectraFormsEntity} is a tool for managing and building rich, interactive forms for use within IDS7 Reporting.
          The manufacturer (Sectra) cannot be held responsible for the clinical relevance and/or function of built
          forms, and forms created in the system will not be covered by the Sectra product CE marking /
          FDA approval.
        </p>
        <p>
          All reporting forms should be regarded as support for the diagnostic workflow and the responsible
          clinician is, as always, responsible for the accuracy of the signed report. The manufacturer (Sectra)
          therefore recommends that built forms are quality assured through a formal test routine with
          documented test results.
        </p>


        <h2>Getting started</h2>
        <p>
          To help you get started, there are some instruction videos available
          in <a href={engLink}>English</a> and <a href={sweLink}>Swedish</a>. You
          can also check out the example components available in the Sectra folder.
        </p>
        <p>
          {sectraFormsEntity} provides a framework for building rich, interactive forms for use within IDS7 Reporting.
          Within the product, the forms are composed using
          building blocks/components. These components can further interact with each other to achieve synoptic
          forms, interactive functions and provide derived information from interactive elements such as graphs
          or diagrams. While the use of these components enables faster and more efficient form development,
          the underlying web architecture is still accessible through JavaScript allowing advanced customizations.
        </p>
        <p>
          In {sectraFormsEntity}, users can also share form specifications with others
          and manage form versions. Furthermore, the product provides a version-controlled presentence of
          these form specifications, as well as a register of which form specifications have been built for
          potential clinical use.
        </p>
        <p>
          A key functionality of a Sectra Form is the ability to retrieve data from custom data
          providers. {sectraFormsEntity} can build such forms and use mock data to test the data provider integration when building.
          To simplify building and managing data providers another tool called {srDataprovidersEntity} can be installed locally
          at your site. {sectraFormsEntity} can import mock data from data providers created in {srDataprovidersEntity}.
        </p>

        <h3>Start using the product</h3>
        <p>
          To start use the product you will need to be granted permission to a site. Contact a {sectraFormsEntity} site admin or Sectra
          to be added to a site.
        </p>
        <h3>Supported languages</h3>
        <p>
          The product supports the following UI languages:
        </p>
        <ul>
          <li>
            English
          </li>
        </ul>
        <p>
          Note that this does not restrict using this product to produce forms in another language. The product
          includes language defaults for:
        </p>
        <ul>
          <li>English</li>
          <li>Swedish</li>
        </ul>

        <h3>Key concepts</h3>
        The following concepts are important to understand before using the {sectraFormsEntity}.
        <h4>Form specification</h4>
        <p>
          A form in the system is fully and deterministically described by the form specification. Thus,
          the form specification dictates both all graphical elements / visual aspects of the form as well as
          any potential function / logic, calculations etc. The form specification is in this regard the high-level
          source code. The form specification is in turn composed of a specification and script part. Using the
          scripts is optional in most form development but needed for some advanced behaviours.
        </p>
        <h4>Form version</h4>
        <p>
          Each form handled in the system has a version that ensures that the form version
          number unambiguously defines the form with all its properties, functions, etc. The form version
          consists of three parts (version integers); a <i>major</i>, <i>minor</i> and a <i>save-version</i>. The first two are controlled
          by the user, while the third, <i>save</i>-version, is a serial number that is updated automatically when the
          form is saved in the system.
        </p>
        <h4>Built form</h4>
        <p>
          By <i>building</i> the form, the application translates / builds the form specification into a runnable
          form package that can be imported into Sectra PACS. A built form also gets a
          unique <i>building</i>-number that identifies which form specification and which version of the product was used
          to build the form.
        </p>
        <h4>Component</h4>
        <p>
          The {sectraFormsEntity} application comes with a set of components with which a form can be created.
          These components are like re-usable building blocks that can be manipulated by their properties to
          achieve different behaviours, among which interactivity with each other. The component specification
          is a key part of the form specification, see <a href="#Form_specification">Form specification.</a>
        </p>

        <h3>Overview</h3>
        <p>{sectraFormsEntity} consists of three major interfaces.</p>
        <ul>
          <li>Form administration</li>
          <li>Form editor</li>
          <li>Data providers</li>
        </ul>
        <h4>Form administration</h4>
        <p>
          The purpose of this interface is to create an overview of the forms that exists in the system. The
          interface makes it possible to organize forms in different folders, open existing forms, start a new one or remove a form from
          the system.
        </p>
        <h4>Form editor</h4>
        <p>
          The form administration interface provides the means for working with a form in the system.
          The interface includes the following main parts (from left to right).
        </p>
        <ul>
          <li><b>Component toolbox</b> - Easy access to existing components</li>
          <li><b>Specification pane</b> - See and modify the form specification (component specifications and scripts)</li>
          <li><b>Preview pane</b> - Live preview of the form (runnable) with the following major tabs; visual preview,
  state and generated structured information, available data sources as well as function for building
  the form.</li>
        </ul>
        <h4>Data providers</h4>
        <p>
          The data providers interface allows the user to setup and manage mock providers that will be used within the {sectraFormsEntity} application.
          Real data providers needs to be hosted on a location that your local Sectra PACS have access to. The Sectra application SR Dataproviders can be
          used to simplify creating and managing data providers. It also has an export function that can be used to import mock data into {sectraFormsEntity}.
        </p>


        <h2>Form administration</h2>
        <p>
          The form administration shown in the figure below provides an overview of the forms that exists in the system.
        </p>
        <img src={templateAdminImg} alt="FormAdmin" />
        <ol>
          <li>Form name</li>
          <li><a href="#Form_version">Form version</a></li>
          <li>Last modified date and time</li>
          <li>List all form versions</li>
          <li><a href="#Deleting_a_form">Delete form button</a> (Red Cross)</li>
          <li><a href="#Creating_a_new_form">New form button</a></li>
          <li><a href="#The_Demo_form">Demo form button</a></li>
          <li>Import from file button</li>
          <li>New folder button</li>
          <li>Rename folder button</li>
          <li>Delete folder button</li>
          <li>Folder view</li>
        </ol>
        <h3>Creating a new form</h3>
        <p>
          By clicking the &apos;New&apos; form button the user starts the form editor at a blank state. The
          form will not be stored as a new form in the system until the user actively saves that form
          for the first time.
        </p>
        <h3>Creating a demo form</h3>
        <p>
          This functions is similar to the <a href="#Creating_a_new_form">New form</a> function but instead of starting the form administration
          interface from a blank state the specification is populated with a demo specification. The purpose of this
          demo specification is to showcase some of the components available and some of the concepts/functions
          that can be achieved using these components.
        </p>
        <h3>Deleting a form</h3>
        <p>
          By clicking the delete form button (i.e. the red cross to the right) for a form the user is prompted with a dialog asking to confirm that this
          form is no longer of interest, and should be removed from the system. Deleting a form will not
          permanently remove it from the system as it might be needed in order to determine which
          form specification were built in a form package previously exported from the solution.
        </p>
        <h3>Managing folders</h3>
        <p>
          When working with folders, the currently selected folder is always targeted. When creating a new form or folder, it will be created inside the
          selected folder. Only empty folders can be deleted.
        </p>
        <p>
          Forms, folders, and sub-folders can all be moved by drag&apos;n&apos;drop. The drop location can only be a folder. 
        </p>


        <h2>Form editor</h2>
        <p>
          Using the form administration interface, the user can create/maintain forms as well as preview
          them and build them for Sectra PACS.
        </p>
        <img src={templateEditorImg} alt="FormEditor" />
        <p>The interface has the following key concepts.</p>
        <ol>
          <li><a href="#Using_the_toolbox">Component toolbox</a></li>
          <li><a href="#Specification_editor">Form specification editor</a></li>
          <li><a href="#Form_preview">Form preview</a></li>
          <li><a href="#Saving_a_form">Saving changes</a></li>
          <li><a href="#Export_as_file">Export as file</a></li>
          <li>Back - Navigates user back to the form administration page</li>
          <li><a href="#Form_color_mode">Color mode</a></li>
        </ol>
        <h3>Saving a form</h3>
        <p>
          Changes are not automatically saved, and thus leaving the application without saving changes will result
          in the loss of any potential unsaved changes. The application will however warn the user if there are
          unsaved changes when the user tries to leave the administration interface without saving changes. The
          &apos;Save changes&apos; button will also indicate whether there are unsaved changes by lighting up if there is
          changes to be saved. Each time the user saves a form, it will result in a new save-version of it,
          and previous versions are retrievable. It is recommended to save often to minimize the risk of losing work.
        </p>
        <h3>Specification editor</h3>
        <p>
          The interactive specification editor is a text-based editor which allows the user to maintain and modify
          a form specification in the system. The specification is expressed as a hierarchical structure (object
          structure) with three top-level concepts;
        </p>
        <ul>
          <li><i>Metadata</i> - Information about the form such as name, description, and version</li>
          <li><i>ReportOutput</i> - The textual representation of the information generated by the form</li>
          <li><i>Components</i> - The components of which the form is comprised</li>
        </ul>
        <img src={templateSpecImg} alt="FormSpec" />
        <p>
          The specification format is written in an object representation language <a href="https://en.wikipedia.org/wiki/YAML">YAML</a>, initially developed to
          provide an object specification which is more human readable than other object representation formats
          such as JSON. Each hierarchical level (object structure) is solely defined by indentation and key, value
          separation is done using the colon-character (:).
        </p>
        <p>
          The specification editor also provides context aware code-completion through pressing CTRL+SPACE. This
          is especially useful since it is an integrated and convenient means to explore the available components
          and their respective component properties.
        </p>
        <img src={codeCompleteImg} alt="CodeComplete" />
        <h3>Using the toolbox</h3>
        <p>
          The toolbox is located in the left pane menu and provides an easy access interface to inserting components
          into the specification editor. The component is inserted where the cursor is, so place the cursor at the
          position where you would like to insert the component before clicking the toolbox quick-button.
        </p>
        <h3>Component IDs and variables</h3>
        <p>
          All <a href="#Component">components</a> have an <i>id</i> property (however optional to specify for some). The <i>id</i> property is used
          to refer to the component in <a href="#Expressions">expressions</a>. It is also used as the key in the key-value pair that is generated
          to the <a href="#Form_data">form data</a>.
        </p>
        <h3>Other special component properties</h3>
        <p>Some component properties are generic and have a special meaning.</p>
        <ul>
          <li>
            <i>dataarg</i> - This property indicates that the component should receive data from a data source. Use code-completion
            to navigate available data sources and data argument values.
          </li>
          <li><i>mandatory</i> - This property indicates to Sectra PACS whether the field must be set or not</li>
          <li>
            <i>display</i> - This property indicates whether the component should be part of the form or not (typically
            determined by an expression). If the component is not displayed, it will not be displayed nor be part
            of the form data (structured information) generated.
          </li>
          <li>
            <i>hidden</i> - This property indicates whether the component should be hidden (not visible) or not. If
            hidden, any value set will still be part of the form data (structured information) generated.
          </li>
          <li>
            <i>components</i> - This property holds a list of child components and is applicable for container components
            such as <i>Row</i>, <i>Section</i>, <i>List</i>, etc.
          </li>
        </ul>
        <h3>Navigation from preview to specification</h3>
        <p>
          As the number of components used within a form grows, it becomes harder to find the corresponding
          specification for a specific component in the preview-tab. In order to navigate and focus on the component
          shown, use <i>CTRL+ALT+Left mouse click</i> to highlight the component in the specification pane.
        </p>
        <h3>Expressions</h3>
        <p>
          A key part in achieving interactive forms is the ability to write expressions. An expression should
          start with the equals character (=) which indicates that the specified property value given is an expression
          (the leading equals character can be omitted for properties where there is no other allowed string value,
          such as for the <i>display</i> property where <span className="mono">true/false</span> apart from an expression are the only allowed values).
          All expressions are executed as JavaScript with special handling for component variable referencing. An
          example expression would be <span className="mono">&apos;Eq(myComponentId, &apos;test&apos;)&apos;</span> which is a boolean expression
          returning <span className="mono">true</span> or <span className="mono">false</span> and, if used as a <i>display</i> property expression means that the
          component will display if the value of <span className="mono">myComponentId</span> equals <i>test</i>. See the <a href="#Generated_code">Generated code</a> tab
          for a complete list of pre-defined JavaScript functions that can be used. Also note that an expression may refer to any other function
          defined in the <a href="#The_scripts_editor">scripts tab</a>.
        </p>
        <p>
          As explained in the <a href="#Form_state">Form state</a> section, component properties is represented in layers where the
          expressions by default set values in the top layer (effectively overriding the user input layer below). This
          for example allows modifying a value based on the value itself, for instance keeping a number within
          bounds, without destroying the user input, meaning that when circumstances change (the bound is lifted)
          the value can revert to the user input value. While this is very convenient, it will not work well for
          conditional sets of input such as triggered by other component change for instance using <span className="mono">StateChangeReasonIs</span> In
          these cases you must issue a user level set. This can be done with an expression
          using the starting with := instead of just equals (=).
        </p>
        <p>
          In a dynamic / repeated context such as a list component where the list content may be rendered multiple
          times (multiple list items added), component references become unambiguous. Therefore, all component
          ids in a repeated context are prefixed with the container (e.g. list) id and the repeated content index number
          (starting from 1 with the first item). However, when using expressions, a component within the same
          repeated content item referencing a sibling component will automatically be referenced correctly, without
          any prefixing. If an expression references a component from outside the repeated content item an array
          with all the values is received. However, it is also possible to do raw referencing by escape component
          reference checks (ensures that the referenced component actually exists). To escape component reference
          checks the reference needs to start with a hash-sign (&apos;#&apos;). For example; &apos;&#123;#lesion1zone&#125;&apos; instead of just
          &apos;&#123;zone&#125;&apos; as it would be used within the first (1) repeated content item. Note that the latter &apos;&#123;zone&#125;&apos; will
          return an array with all values of &apos;&#123;zone&#125;&apos; in a repeated context.
          Furthermore, in a repeated content item there is also a hidden variable available named ListItem, with
          the following properties;
        </p>
        <pre>{specialVariableProperties}</pre>
        <h3>Report writing and in-text variable referencing</h3>
        <p>
          Another key part of {sectraFormsEntity} is the support for writing report texts. The report text output part
          of {sectraFormsEntity} is defined in the <i>ReportOutput</i>-section of the specification,
          see <a href="#Specification_editor"> Specification editor</a> section. It is however recommended to use a <i>Summary</i> component
          to format a visible report for the user and refer to that report output in the <i>ReportOutput</i>-section of the specification.
        </p>
        <h4>Text formatting</h4>
        <p>
          Text formatting can be achieved by using the following allowed HTML tags (note that all other HTML
          will be disregarded);
        </p>
        <ul>
          <li>&lt;i&gt; or &lt;em&gt; - <i>Emphasis / Italics</i></li>
          <li>&lt;b&gt; or &lt;strong&gt; - <b>Strong / Bold</b></li>
          <li>
            &lt;pre&gt; - <span className="mono">Preformatted</span>, i.e. using a Monospace font meaning that the character width is fixed. This
            is particularity convenient when outputting tabular data.
          </li>
          <li>&lt;h1&gt;, &lt;h2&gt; and &lt;h3&gt; - <span>Different sized headers</span></li>
        </ul>
        <p>Default fonts can be set on the defaults property in the Metadata.</p>
        <h4>Variable/script referencing</h4>
        <p>
          In order to build a report, some parts of the text must refer to form data. This is done by using the curly
          brackets &apos;&#123;&apos; and &apos;&#125;&apos;. For example, the following text refer to the component with the id of &apos;PatientAge&apos;
          and its property <i>value</i>; <span className="mono">&apos;Patient age is &#123;PatientAge&#125;&apos;</span>. A reference may also read other properties
          such as the <i>displayValue</i> property of an option box (not the stored value but rather the display value
          shown in the interface differentiated by the <i>options</i> and <i>optionValues</i> properties), e.g. <span className="mono">&apos;Option value
          is &#123;Option1.displayText&#125;&apos;</span>. Anything within the curly brackets will be treated as an <a href="#Expressions">expression</a> and
          can as of such also be plain JavaScript.
        </p>
        <h4>Smart filtering style</h4>
        <p>
          Another key concept of the report writing is the use of smart filtering. Smart filtering is optional (activated
          by default) and means eliminating portions of the report that refers to <span className="mono">undefined</span> values (i.e.
          non-mandatory parts the clinician chooses to not report on). For example, in the following text <span className="mono">&apos;lesion
          size is &#123;lSize&#125;, location is &#123;lLocation&#125;&apos;</span>, if both variables are <span className="mono">undefined</span> the
          output will be empty. If only <span className="mono">lSize</span> is set, the resulting output will be <span className="mono">&apos;lesion size
          is &lt;lSize&gt;&apos;</span>. If only <span className="mono">lLocation</span> is set, the output will be <span className="mono">&apos;location
          is &lt;lLocation&gt;&apos;</span>. Smart filtering will break on comma (,), dot (.), and semi-colon
          (;). Furthermore, references to empty content within parentheses will be omitted in that scope first. If
          there are no boundary characters in between two variables and one of them is <span className="mono">undefined</span>, any text
          to the left of the <span className="mono">undefined</span> variable will be omitted when triggering smart filtering
          (unless it&apos;s the start of that row). In the above example, removing the comma and leaving lSize empty will just result
          in the <span className="mono">&apos;&lt;lLocation&gt;&apos;</span> while on the other hand, if lLocation is empty the result will still
          become <span className="mono">&apos;location is &lt;lLocation&gt;&apos;</span>. However, such behaviors could instead be manually
          controlled using an if/then/else type of expression,
          e.g. <span className="mono">&apos;&#123;If(IsEmpty(lLocation), &apos;a&apos;, &apos;b&apos;)&#125;&apos;</span>.
        </p>
        <p>
          While the <i>Summary</i> component allows for setting an inclusion criterion for a text section (part of the
          report) by use of the <i>if</i> property, smart filtering style provides a convenient in-place way of providing
          an inclusion criteria. A row-level inclusion criteria can be specified in smart filtering mode by ending
          the row with <span className="mono">&apos;||&#123;&lt;variable&gt;&#125;&apos;</span>. The row will be omitted if the trailing expression
          is empty or false. If an array is provided, i.e. <span className="mono">&apos;... ||&#123;[&lt;v1&gt;, &lt;v2&gt;]&#125;&apos;</span> the row will
          be omitted if all variables are empty. This could be useful to exclude a title of report section if all child rows turned out empty and thus omitted.
          E.g.
        </p>
        <pre>{smartFilterCodeBlock}</pre>
        <h3>Error handling</h3>
        <p>
          There are several ways by which a specification can be broken / generate errors. At the highest level,
          the specification (<a href="#Specification_editor">YAML</a>) must be valid. The specification must also conform to the definition of the
          used components and any reference to an unknown property or using an invalid property value will
          generate an error. Finally, at runtime a specification might also generate errors due to mistakes in
          expressions or scripts.
        </p>
        <p>
          Errors are always displayed at the top of the preview, with references to which part of the specification
          is likely to be causing the error. Note that since upstream errors in the specification might cause violations
          later in the specification, the references given could occasionally be misleading.
        </p>
        <h3>The scripts editor</h3>
        <p>
          The scripts editor provides an interface to define <a href="https://en.wikipedia.org/wiki/JavaScript">JavaScript</a> code like for 
          instance functions that in turn can be referred within expressions in the specifications part. By defining straight-up JavaScript code this
          also provides a mean to react to any form state change and provide custom functionality. By using the
          pre-defined functions <i>get</i> and <i>set</i> the script can interact with component properties and change the
          component state at will. Scripts can be perceived as an unlimited extension to the capabilities of defining
          inline expressions (and can alter all property, even those not allowing expressions). However, raw scripts
          are a double-edged sword and could both cause poor performance and errors. Furthermore, since scripts
          are effectively de-coupled from the component specification, forms with raw scripts are generally
          harder to maintain. Therefore, it is recommended to use expressions to initiate conditional behaviour
          with the support of scripts defined functions, and refrain from using raw scripts unless needed.
        </p>
        <img src={scriptsEditorImg} alt="ScriptsEditor" /> 
        <h4>Global variables</h4>
        <p>
          If you just define a global variable in scripts, it might not have the desired effect. The full code in scripts
          runs ONCE if in Ids7, and ONCE PER CHANGE. This will make your definition of the global variable
          run if you change a value in the form when running preview mode, but not if running in ids7. To define
          global variables, you can use this pattern:
        </p>
        <pre>{globalVariablesCodeBlock}</pre>
        <h3>Generated code</h3>
        <p>
          The generated code tab shows the complete JavaScript code that is executed on each state change. It
          can provide insights to how to achieve more advanced functionality and what is causing errors that
          occurred because of malformed JavaScript code in the scripts editor. The generated code tab also shows
          all pre-defined JavaScript helper functions such as <span className="mono">Eq, If, IsEmpty, And, Or,</span> etc.
        </p>
        <h3>Form preview</h3>
        <p>
          The form preview provides an interactive preview of the form as if executed in Sectra PACS. Apart from testing the form
          logic and visual representation the form preview also serves as a means to investigate generated form
          data (structured information) given the current input state (for further information on form state, see
          the <a href="#Form_state">Form state</a> section).
        </p>
        <h4>Form color mode</h4>
        <p>
          As Sectra PACS comes with two color modes (light and dark theme), the theme can also be changed in
          the application. This is especially useful when working with an SVG and SVG-styling so that the diagram / graphic is styled according to the
          theme to provide the optimal visibility of diagram features (<i>Painter</i>-component).
        </p>
        <h3>Form data</h3>
        <p>
          A key part of structured reporting is to generate structured information. The tab <i>Form data</i> displays the structured data generated by the
          form (1). Form output can also be images, diagrams or graphs (2).
        </p>
        <img src={formDataImg} alt="FormData" />
        <h3>Form state</h3>
        <p>
          A form in {sectraFormsEntity} is continuously holding a <i>form state</i>. The state reflects
          the value of each component described in two different layers. The base layer (not part of the form state)
          is dictated by the specification, i.e. the pre-runtime default values of all the components that comprise
          the form. The second layer is the user input layer, which holds any adjustments made by the user
          at runtime, i.e. data that was entered by the user while filling out the form. The third and top layer is
          alterations made to these properties by expressions or potentially via scripts. That means, that if an
          expression cancels a value overriding the user input value, the form will revert to the previously entered
          user input (can be achieve by not setting a value or by setting undefined, which thus clears the top layer
          script override). Expression will set in the top layer when starting with = (user input layer when starting
          with :=), and scripts may pass <span className="mono">false</span> (user level set) an optional fourth parameter to
          the <span className="mono">set</span> function.
        </p>
        <img src={formStateImg} alt="FormState" />
        <p>
          The form state UI thus provides the means to effectively <i>dump</i> the current form state, to be <i>restored</i> at
          any later point. These concepts are highlighted in the above figure.
        </p>
        <ol>
          <li>Current form state</li>
          <li>A specification of a form state to restore</li>
          <li>Restore the form state from the specification below</li>
          <li>Write the current form state to the restore input dialog</li>
        </ol>
        <h3>Build</h3>
        <p>
          Building a specification into an executable structured reporting package is a key concept in {sectraFormsEntity},
          see <a href="#Built_form">Built form</a>. Building a form is available from the <i>Build</i> tab.
        </p>
        <img src={buildImg} alt="build" />
        <p>
          This interface both allows to Create a new build (1). Before building is possible the form must be saved.
          Saving the form into a new uniquely identifying <i>save</i>-version
          ensures that the build version is traceable back to an existing corresponding form version.
        </p>
        <p> 
          The tab also show all data providers required by the form (2) as well as all saved versions of the form (3).
        </p>
        <h3>Help</h3>
        <p>
          The help tab in {sectraFormsEntity} provides a description of all the built-in script functions available, as well as a complete
          component and property specification. Components and component property descriptions are also readily available via code completion.
        </p>


        <h2>Form export/import</h2>
        <p>
          It is possible to export / import a form specification (includes both the specification part as well as the scripts part) or form package.
        </p>
        <h3>Importing from file</h3>
        <p>
          Importing from file is initiated from the form administration interface, see
          the <a href="#Form_administration">Form administration</a> section.
          By clicking &apos;Import from file&apos;-button the form administration page is loaded with an import dialog.
          Drag&apos;n&apos;drop a file on the drop-area or click and manually locate the specifications file (.ssrt-format).
        </p>
        <h3>Export as file</h3>
        <p>
          At any time (and without saving changes) it is possible to export the current form specification. By
          clicking the &apos;Export as file&apos; button a <i>.ssrt</i>-file is downloaded (typically stored in the default web browser
          download folder).
        </p>
        <h3>Export form package (Only available for site admins)</h3>
        <p>
          By pressing the &apos;Export all forms&apos; button a <i>.fp</i>-file is downloaded. (Depending on how many forms you have in your system and how big they are,
          this action might take a while.) This form package file will contain the latest version of all your current forms and can be imported to another system using the
          &apos;Import from file&apos; button.
        </p>
        <p><b>Note: </b>Importing a form package will <b>not</b> keep the folder structure of the exported forms. All forms will be imported to the currently selected folder.</p>

        <h2>Working with data providers</h2>
        <p>
          Data providers are used to retrieve data from external sources into forms.
        </p>
        
        <h3>Creating data providers</h3>
        <p>
          Data providers need to be implemented as web services following a
          certain interface. How to create your own data providers are specified in the DevelopersGuide.StructuredReportTemplates.pdf.
          To simplify creation of data providers Sectra has created a product called SR Dataproviders that can be installed on your local site.
        </p>

        <h3>Managing provider mocks</h3>
        <p>
          Since the {sectraFormsEntity} are hosted in cloud and will not have access to your data providers (unless they would be publicly available on the
          internet which probably you do not want) you will need to use Mock data in the {sectraFormsEntity} when trying the data provider integration. Data
          providers can be managed in the data providers page (in form administration page press Data Provider in the top navigation bar).
        </p>
        <img src={providerPageImg} alt="ProviderPage" />
        <ol>
          <li>Id - This is what will identify the provider and used in the form spec to retrieve data from it</li>
          <li>Uid - This is used by IDS7 to identify providers.</li>
          <li>Version - This is used by IDS7 to identify specific versions of providers and check compatibility towards different forms.
            Specifying version is not yet supported in SR Dataprovider, so those providers should always have version 1.
          </li>
          <li>Delete button - Delete a provider mock</li>
          <li>Add button - Create a new provider mock, clicking it will allow you to specify an Id, Uid, Version and Mock Data of a new provider</li>
          <li>Add selected button - Can be pressed after providers have been loaded and will add the selected providers to the list of available
            providers.
          </li>
          <li>Load button - Browse exported providers (from SR Dataproviders) and import them as mocks</li>
        </ol>
        <p>
          You can press a provider in the list to edit its mock (Test) data.
        </p>

        <h3>Using data providers in form specification</h3>
        <p>
          The easiest way to use data providers is by using the dataarg attribute available on all input-components. If there are a provider called MyProvider
          that has a textfield in its mock data called MyField, a text-component would be populated with the MyField value with the following spec.
        </p>
        <pre>
            {providerExample}
        </pre>

        <h3>Using data providers in script</h3>
        <p>
          There is an event triggered on data provider retrieval. This event can be hooked in to achieve for example
          custom parsing of data or calculations based on retrieved data.
        </p>
        <p>
          In the scripts tag, you can define EventHandler.OnDataProviderRetrieve.
          This function will get three params, a getter, a setter, and the data provider data.
        </p>
        <p>
          The data retrieved is the full provider data, a trick to find into your object correctly is to 
          do a console.log of the data you are trying to parse to see the structure. I.e. <code>console.log(data[0].providerData[0].properties</code>
        </p>
        <p>
          The set method can be used either to directly set a value or property of a component. I.e. <span className="mono">set(&apos;myComponentId
          &apos;, &apos;propertyId&apos;)</span> or you can just store the data to a custom defined variable - the componentId and property does not need
          to exist. This way you can use the value i.e. in the EventHandler.OnStateChange function. Example:
        </p>
        <pre>
          {setProviderDataExample}
        </pre>
        <p>
          The OnDataProviderRetrieve function is called after any population of dataarg fields.
        </p>
        <p>
          <b>Note: </b>If provider data is only handled with a script (i.e. no dataarg is used for the provider) the provider must be defined
          in the explicitDataSources array, see example below.
        </p>
        <pre>
          {explicitDatasourcesExample}
        </pre>
        <p>
          The format of the data is defined by IDS7. It is a list of items with properties providerName and providerData.
          It is only interesting to use other than providerData[0] if using forwarding provider data.
          All normal providers only send one item in the array.
        </p>

        <h3>Forwarding providers</h3>
        <p>
          Forwarding providers is used to forward data from one report into another one created at a later time for the same patient.
          To forward data from one form, it must specify its outputIdentifier in the Metadata i.e.
        </p>
        <pre>
          {outputIdentifierExample}
        </pre>
        <p>To consume forwarded data from MyForwardingProvider, this must be specified as a forwardingProvider in the Metadata i.e.</p>
        <pre>
          {forwardingProvidersExample}
        </pre>
        <p>To recieve the latest data from a field within a forwarding provider, dataarg can be used i.e.</p>
        <pre>
          {usingForwardingProviderExample}
        </pre>
        <p>To access other than the latest data the script function OnDataProviderRetrieve must be used.</p>
      </div>
      <About />
    </Grid>
  );
};

function generateToc() {
  var toc = '';
  var level = 1;

  (document.getElementById('ug-contents') ?? { 'innerHTML': '' }).innerHTML =
      (document.getElementById('ug-contents') ?? { 'innerHTML': '' }).innerHTML.replace(
        /<h([\d])>([^<]+)<\/h([\d])>/gi,
        function (str, openLevel, titleText, closeLevel) {
          if (openLevel != closeLevel) {
            return str;
          }

          if (openLevel > level) {
            toc += (new Array(openLevel - level + 1)).join('<ul class="toc-' + openLevel + '">');
          } else if (openLevel < level) {
            toc += (new Array(level - openLevel + 1)).join('</ul>');
          }

          level = parseInt(openLevel);

          var anchor = titleText.replace(/ /g, '_');
          toc += '<li><a href="#' + anchor + '">' + titleText
              + '</a></li>';

          return '<h' + openLevel + ' id="' + anchor + '" class="anchor">'
              + titleText + '</h' + closeLevel + '>';
        },
      );

  if (level) {
    toc += (new Array(level + 1)).join('</ul>');
  }

  (document.getElementById('toc') ?? { 'innerHTML': '' }).innerHTML += toc; 
}