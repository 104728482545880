
export const guideSteps = [
  {
    id: 'welcome',
    title: 'Welcome',
    content: `
      Welcome to Sectra Forms!
  
      Since this is your first time here, we have prepared some content for you, readily available in the following links:
 
      [English](https://training.sectra.com/enrol/index.php?id=1562) | [Swedish](https://training.sectra.com/course/view.php?id=1566)



      If you would like to hide this message forever, press the <b style="display: inline-block;">Don't show again</b> button.
  
      To continue with this introduction, press the next button.
      `,
  },
  {
    id: 'sectra-folder',
    title: 'The Sectra folder',
    content: `
      This is a Sectra folder, containing Sectra curated forms, 
      as well as some introductory forms to help you get started with creating your own forms.

      You can also find the forms created and used in the tutorials from the previous page in this folder.

      To use a form from this folder, click on one of the folders underneath the Sectra folder, 
      and then drag and drop the form you wish to use into one of your own folders.
      `,
    target: {
      type:  'sectra',
      name: 'Sectra',
      class: 'arrow-right',
    },
  },
  {
    id: 'shared-folder',
    title: 'The Shared folder',
    content: `
    This is the Shared folder. You can find forms shared by other users in this folder, either to take inspiration from when creating your own, or to use directly.
    `,
    target: {
      type:  'shared',
      name: 'Shared Forms',
      class: 'arrow-right',
    },
  },
  {
    id: 'help-tab',
    title: 'More information',
    content: `
    For more information and help, you can check out the 'Help' tab. In there you can find the following tabs: 'Release Notes', 'User Guide' as well as check out the 'About' page.
    `,
    target: {
      type:  'nav',
      name: 'Help',
      class: 'arrow-bottom',
    },
  },
] satisfies GuideStep[] as GuideStep[];


// Preprocess

formatSteps();
function formatSteps() {
  // Cleanup of content.
  guideSteps.forEach(step => {

    // Create links for [text](link)
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    step.content = step.content.replaceAll(linkRegex, '<a href="$2">$1</a>');

    // Create br for \n\n\n
    step.content = step.content.replaceAll(/\n(\s*\n){2,}/g, '<br><br>\n\n');

    // Create paragraphs for \n\n
    step.content = step.content.split(/\n\s*\n/).map(p => '<p>' + p.trim() + '</p>').join('\n');
  });
}


// Types

export type GuideStep = {
  id: string;
  title: string;
  content: string;
  target?: GuideTarget;
};

export type GuideTarget = {
  type: GuideHighlightTypes;
  name: string;
  class: GuideTargetClasses;
};

export type GuideHighlightTypes = 'local' | 'shared' | 'sectra' | 'nav';
type GuideTargetClasses = 'highlight' | `arrow-${'left' | 'top' | 'right' | 'bottom'}`;