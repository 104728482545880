/* eslint-disable react/no-children-prop */
import { Tabs, Tab } from 'react-bootstrap';
import React from 'react';
import { Component, SrComponent, SrValueComponentPropsBase } from '../BasicTypes';
import { ReactComponentContainerProps, shouldDisplay } from './SrtComponent';
import { expressionPattern, schema } from './Schema';

import './SectraTabs.scss';
import { SrtContainer } from './SrtContainer';
import { HrReactComponent } from './SectraStructure';
import { applogger } from '../../applogger';

const tabsSchema = {
  'id': { 'type': 'string', 'description': 'The id of the element' },
  'borderBottom': { 'type': ['string', 'boolean'], 'description': 'Display a bottom border (default: false)', 'enumDescription': ['Display border', 'No border (default)'] },
  'activeTab': { 'type': 'string', 'description': 'The name of the tab' },
  'hidden': schema.PropDefinition.hidden,
  'display': schema.PropDefinition.display,
  'tabsHiddenFilter': {
    'anyOf': [
      { 'type': 'array', 'description': 'Array containing tabindicies that should be hidden' },
      { 'type': 'string', 'description': 'Filter as an expression', 'pattern': expressionPattern },

    ],
    'description': 'Array containing tabindicies that should be hidden',
  },
  'tabsDisplayFilter': {
    'anyOf': [
      { 'type': 'array', 'description': 'Array containing tabindicies that should not be displayed' },
      { 'type': 'string', 'description': 'Filter as an expression', 'pattern': expressionPattern },

    ],
    'description': 'Array containing tabindicies that should not be displayed',
  },
  'tabs': {
    'type': 'array',
    'items': {
      'type': 'object',
      'required': ['tabname', 'components'],
      'properties': {
        'tabname': { 'type': 'string', 'description': 'The name of the tab' },
        'hidden': schema.PropDefinition.hidden,
        'display': schema.PropDefinition.display,
        'components': schema.DefaultComponentSchemaPart.components,
      },
    },
    'minItems': 1,
    'description': 'The values to select among.',
  },
};

interface TabsProps extends SrValueComponentPropsBase {
  borderBottom?: boolean;
  display?: boolean;
  hidden?: boolean;
  tabs?: TabItem[];
  tabsHiddenFilter?: number[];
  tabsDisplayFilter?: number[];
  activeTab?: string;
}

interface TabItem extends SrValueComponentPropsBase {
  tabname: string;
  components: Component[];
}

export const SectraTabsComponent: React.FC<ReactComponentContainerProps<TabsProps>> = (container) => {
  const props = container.props;
  if (!shouldDisplay(props.display, container.context)) {
    return null;
  }
  if (!Array.isArray(props.tabs)) {
    applogger.warn('Tab component is missing a valid tabs specification');
    return null;
  }

  const activeTab = GetVerifiedActiveTabName(props.activeTab, props.tabs);
  if (activeTab === '') {
    // no visable tabs
    return null;
  }

  const component = container.context.currentParsedComponent;
  return (
        <div hidden={props.hidden === true} className="SectraTabs" data-component-id={props.id}>
            <Tabs animation={false} activeKey={activeTab} id={props.id} onSelect={(tabName: any) => {
              container.functions.setUserInput(props.id, 'activeTab', tabName != null ? String(tabName) : '');
            }}>
                {props.tabs.map((tabProps, i) => {
                  if (tabProps.display === false || FilterOutCurrentTab(i, props.tabsDisplayFilter)) {
                    return;
                  }
                  const tabPath = component.componentPath + `.tabs[${i}]`;
                  const tabChildren = component.children.filter(c => c.componentPath.startsWith(tabPath));
                  const tabHidden = FilterOutCurrentTab(i, props.tabsHiddenFilter) || tabProps.hidden === true;
                  return <Tab key={i} eventKey={tabProps.tabname} title={tabProps.tabname} className="tabItem" data-component-path={tabPath} tabClassName={tabHidden ? 'hideTab' : ''} data-dynamic-hidden={tabProps.tabname !== activeTab ? props.id + ',' + tabProps.tabname : undefined}>
                        <SrtContainer parentId={props.id} children={tabChildren} renderContext={container.context} templateContext={container.templateContext} functions={container.functions} />
                    </Tab>;
                })}
            </Tabs>
            { props.borderBottom === true ?  <HrReactComponent props={props} context={container.context} templateContext={container.templateContext} functions={container.functions} /> : null}
        </div>
  );
};

const tabsKey = 'SectraTab';
export const SectraTab : SrComponent<TabsProps> = {
  key: tabsKey,
  render: (props, context, templateContext, functions) => <SectraTabsComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  getChildren: (props: TabsProps) => 
    props.tabs?.map((tab, idx) => ({ propPath: `tabs[${idx}]`, children: tab.components })) ?? [],
  template: ()=>Promise.resolve(`- ${tabsKey}:
    id: tab${schema.getNextIdNum()}
    tabs: 
    - tabname: x
      components: 
      - Text: 
          id: textInTab
    `),
  toolboxName: 'Tabs',
  schema: schema.getSchema(tabsKey, tabsSchema, [], false),
  dynamicUnhide: (node, id, tabToSet, setter) => {
    // show the right tab-pane so that the consequent focus will work
    node.parentElement?.childNodes.forEach(x => (x as HTMLElement).classList?.remove('active'));
    node.classList.add('active');

    // update component state to show this tab
    setter(id, 'activeTab', tabToSet);
  },
};

function GetVerifiedActiveTabName(name: any, tabs: TabItem[]): string {
  if (name != null && Array.isArray(tabs)) {
    const tab = tabs.find(x => x.tabname == name);
    if (tab != null && tab.hidden !== true && tab.display !== false) return String(tab.tabname);
  }
  return Array.isArray(tabs) && tabs.length > 0 
    ? String(tabs.find(tab =>  tab.hidden !== true && tab.display !== false)?.tabname ?? '') 
    : '';
}

// Returns true if current tab index exists in the filterList.
function FilterOutCurrentTab(currentTabIndex: number, filterList: number[] | undefined): boolean {
  return filterList != null && filterList instanceof Array && filterList.indexOf(currentTabIndex) !== -1;
}