import React from 'react';

export const FormsSvg = () => (
<svg className="image" version="1.1" id="forms-logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 164 164" enableBackground="new 1 0 164 164" xmlSpace="preserve">
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="82.05" y1="628" x2="82.05" y2="732" gradientTransform="matrix(1 0 0 1 0 -628)">
	<stop offset="0.5" stopColor="#0D192D" stopOpacity="0.7" />
	<stop  offset="1" stopColor="#0D192D" />
</linearGradient>
<path id="forms-logo-path" fillRule="evenodd" clipRule="evenodd" fill="url(#SVGID_1_)" d="M10.1,0H154c5.6,0,10.1,4.5,10.1,10.1V154
	c0,5.6-4.5,10.1-10.1,10.1H10.1C4.5,164,0,159.5,0,153.9V10.1C0,4.5,4.5,0,10.1,0z"/>
<g opacity="0.8">
	<polygon fill="#FFFFFF;" points="129,52.09 35.56,52.09 35,22.18 129,22.18 	"/>
</g>
<g opacity="0.8">
	<polygon fill="#FFFFFF;" points="64.91,69.18 43.49,69.18 73.39,99.09 116.18,99.09 116.18,69.18 	"/>
</g>
<g opacity="0.8">
	<polygon fill="#FFFFFF;" points="35,77.67 35,99.09 35,141.82 64.91,141.82 64.91,107.58 	"/>
</g>
</svg>
);
