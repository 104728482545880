import { ISelectionRange } from './ContentEditableHelper';

interface IContentController {
  /**
     * Gets the content type
     */
  getType(): string;
}

interface ITextContentController extends IContentController {
  /**
     * Gets the content type
     * @returns string (single line input) or text (multiline, i.e. \n) can occur
     */
  getType(): 'string' | 'text';

  /**
     * Get the text in the specified range from the html element if possible
     * @param start The index from where to get the text (default 0).
     * @param length The length of the text to get (default length of text).
     */
  getText(start?: number, length?: number): string;

  /**
     * Get the length of the current text in the html element. 
     */
  getTextLength(): number;

  /**
     * Get the current selection range in the html element.
     * Returns the start and length of the current selection range.
     */
  getSelection(): [number, number];

  /**
     * Set the selection range in the html element if possible.
     * @param start The start of the range.
     * @param length The lenght of the range (default end of the string).
     */
  setSelection(start: number, length?: number): void;

  /**
     * Replace the current selection in the html element with the specified text.
     * @param text The string to replace the selected text with (default empty string).
     */
  replaceSelection(text?: string): void;
}

interface IOptionContentController extends IContentController {
  /**
     * Gets the content type
     */
  getType(): 'option';

  /**
     * Get whether multiple selected options are allowed or not
     */
  getIsMultichoice(): boolean;

  /**
     * Get the available options
     */
  getOptions(): IOptionInfo[];

  /**
     * Get the selected options
     */
  getSelected(): string[];

  /**
     * Select an option
     * @param id The option id to select. Setting null will clear all options
     * @param selected Optional paramter to set whether the selected option should be selected or not. Default to true for single-choice and toggle for multichoice.
     */
  select(id: string | null | undefined, selected?: boolean): void;
}

interface IOptionInfo {
  id: string;
  names: string[];
}

export class InputContentController implements ITextContentController {
  private container: HTMLInputElement;

  private setNewValue: (value: string)=>void;

  constructor(element: HTMLInputElement, setNewValue: (value: string)=>void) {
    this.container = element;
    this.setNewValue = setNewValue;
  }

  getType(): 'string' | 'text' { return 'string'; }

  setSelection(start: number, length?: number): void {
    this.container.setSelectionRange(start, start + (length ?? 0));
  }

  getText(start?: number, length?: number): string {
    const text = this.container.value;
    return start != null || length != null 
      ? text.substring(start ?? 0, (start ?? 0) + (length ?? text.length))
      : text;
  }

  getSelection(): [number, number] {
    return [this.container.selectionStart ?? 0, (this.container.selectionEnd ?? this.container.value.length - 1) - (this.container.selectionStart ?? 0)];
  }

  getTextLength(): number {
    return this.container.value.length;
  }

  replaceSelection(text?: string): void {
    this.container.setRangeText(text ?? '', this.container.selectionStart ?? 0, this.container.selectionEnd ?? this.container.value.length - 1, 'end');
    this.setNewValue(this.container.value);
  }
}


export class ContentEditableContentController implements ITextContentController {
  private componentGetValue: ()=>string;

  private componentSetSelection: (selection: ISelectionRange)=>void;

  private componentGetSelection: () => ISelectionRange;

  private componentReplaceSelection: (text: string)=>void;

  constructor(getValue: () => string, getSelection: () => ISelectionRange, setSelection: (selection: ISelectionRange)=>void, replaceSelection: (text: string)=>void) {
    this.componentGetValue = getValue;
    this.componentGetSelection = getSelection;
    this.componentSetSelection = setSelection;
    this.componentReplaceSelection = replaceSelection;
  }

  getType(): 'string' | 'text' { return 'text'; }

  setSelection(start: number, length?: number): void {
    this.componentSetSelection({ selStart: start, selEnd: start + (length ?? 0) });
  }

  getText(start?: number, length?: number): string {
    const text = this.componentGetValue();
    return start != null || length != null 
      ? text.substring(start ?? 0, (start ?? 0) + (length ?? text.length))
      : text;
  }

  getSelection(): [number, number] {
    const selection = this.componentGetSelection();
    return selection != null 
      ? [selection.selStart, selection.selEnd - selection.selStart]
      : [0, 0];
  }

  getTextLength(): number {
    return this.componentGetValue().length;
  }

  replaceSelection(text?: string): void {
    this.componentReplaceSelection(text ?? '');
  }
}


export class OptionsContentController implements IOptionContentController {
  private componentGetMultichoice: ()=>boolean;

  private componentGetOptions: ()=>IOptionInfo[];

  private componentGetSelected: ()=>string[];

  private componentSelect: (id: string, selected: boolean)=>void;

  constructor(isMultichoice: ()=>boolean, getOptions: ()=>IOptionInfo[], getSelected: ()=>string[], select: (id: string, selected: boolean)=>void) {
    this.componentGetMultichoice = isMultichoice;
    this.componentGetOptions = getOptions;
    this.componentGetSelected = getSelected;
    this.componentSelect = select;
  }

  getType(): 'option' {
    return 'option';
  }

  getIsMultichoice(): boolean {
    return this.componentGetMultichoice();
  }

  getOptions(): IOptionInfo[] {
    return this.componentGetOptions();
  }

  getSelected(): string[] {
    return this.componentGetSelected();
  }

  select(id: string, selected?: boolean): void {
    this.componentSelect(id, selected ?? (this.componentGetMultichoice() ? this.getSelected().indexOf(id) === -1 : true));
  }
}

// Helper method
export function asControllableElement(element: HTMLElement) {
  return (element as any) as ElementContentController;
}

export interface ElementContentController {
  contentController?: IContentController;
}