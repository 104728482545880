import React from 'react';
import { SrComponent, ComponentRenderContext, SrValueComponentPropsBase } from '../BasicTypes';
import { ReactComponentContainerProps } from './SrtComponent';
import { schema } from './Schema';

import { GetRowComponent } from './SectraRow';
import { SectraSelect } from './SectraBaseComponent/SectraSelectOverride';
import { getSiblingIds } from './SectraListSection';
import { FormDataOutput } from './FormDataOutput';
import { ISectraOptionsComplexValue, ISectraOptionsValue, SchemaOptionsProperty, SectraOptionsHelper } from './SectraOptionsBase';
import { IOptionValues, ISingleOptionValue } from './SectraBaseComponent/SectraOptionBase';
import { GetterType } from '../BasicTypes';

interface DropdownProps extends SrValueComponentPropsBase {
  options: ISectraOptionsValue[];
  prefix?: string;
  suffix?: string;
  size?: string;
  placeholder?: string;
  uniqueInRepeatedElements?: boolean;

  // internal state of disabled options when req. unique in repeated elements
  disabledOptions?: boolean[];
}

const getFilteredMutiChoiceOptions = () => {
  const deepClone =  JSON.parse(JSON.stringify(SchemaOptionsProperty));
  // TODO: implement when/if we get support for multiselect (NOTE: this will not really make sense for uniqueInRepeatedElements which thus should probably be disabled)
  delete deepClone.anyOf[0].items.anyOf[0].properties.exclusive;
  return deepClone;
};

const componentDropdownSchema = schema.mergeSchemaProps(schema.DefaultSizeSchemaPart, {
  'options': getFilteredMutiChoiceOptions(),
  'value': { 'type': 'string', 'description': schema.ValueDescription },
  'placeholder': { 'type': ['string', 'null'], 'description': "A placeholder text, i.e. typically 'Please choose an option...'" },
  'prefix': schema.PropDefinition.prefix,
  'prefixStyle': schema.PropDefinition.prefixStyle,
  'suffix': schema.PropDefinition.suffix,
  'suffixStyle': schema.PropDefinition.suffixStyle,
  'uniqueInRepeatedElements': { 'type': 'boolean', 'enumDescription': ['Require unique values in the "global" repeated element scope, e.g. two list items may not have the same value (used values will become disabled)', 'Default behavior without any repeated content checks (default)'], 'description': 'Require unique values in repeated elements such as lists' },
});

// The React options component
const SectraDropdownComponent: React.FC<ReactComponentContainerProps<DropdownProps>> = (container) => {
  const props = container.props;
  // ensure values are string to aviod comparision issues in downsteam button-component
  const optionValues = SectraOptionsHelper.getValues(props.options, true);
  const optionTexts = SectraOptionsHelper.getTexts(props.options);

  return GetRowComponent(props, container.context, container.templateContext, <>
        <FormDataOutput id={props.id} name={props.name ?? props.inherritedLabel ?? props.id} value={props.value} mandatory={props.mandatory}
            worklistAttribute={props.worklistAttribute} freeField={props.freeField} />
        <SectraSelect
            id={props.id}
            name={props.name ?? props.inherritedLabel ?? props.id}
            onStateChange={(val: ISingleOptionValue | null)=> {
              container.functions.setUserInput(props.id, 'value', val);
            }}
            optionValues={optionValues}
            optionTexts={optionTexts}
            defaultOptionText={props.placeholder}
            disabled={props.disabled === true}
            disabledOptions={props.disabledOptions}
            descriptions={SectraOptionsHelper.getDescriptions(props.options)}
            preventOutput={true}
            value={props.value ?? ''}
        />
    </>, 'sectra-dropdown' + (props.size === 'fill' ? ' fill' : ''));
};

//
// The dropdown component
//

const dropDownKey = 'Dropdown';
export const SectraDropdown : SrComponent<DropdownProps> = {
  key: dropDownKey,
  render: (props, context, templateContext, functions) => <SectraDropdownComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: () => Promise.resolve(`- ${dropDownKey}:
    id: drop${schema.getNextIdNum()}
    options:
    - Malignant
    - Benign`),
  toolboxName: dropDownKey,
  schema: schema.getSchema(dropDownKey, componentDropdownSchema, ['options']),
  getInitValues: () => ({ 'value': null }),
  onStateChangeRunner: (props, set, get, context) => {
    const optionValues = SectraOptionsHelper.getValues(props.options, true);
    const preDisabled = SectraOptionsHelper.getDisabled(props.options);
    const [disabledOptions, newValue] = getDisabledOptionsAndAutoValue(props, context, optionValues, preDisabled, get);
    set(props.id, 'disabledOptions', disabledOptions, true);

    set(props.id, 'value', newValue, false);
        
    // update display value and description
    const option = newValue != null ? (props.options[optionValues.indexOf(newValue)] as ISectraOptionsComplexValue | undefined) : undefined;
    const dpValue = option?.text ?? newValue?.toString() ?? '';
    const description = option?.description ?? dpValue;
    set(props.id, 'displayValue', dpValue, true);
    set(props.id, 'description', description, true);
  },
};

function getDisabledOptionsAndAutoValue(props: DropdownProps, context: ComponentRenderContext, optionValues: IOptionValues, preDisabled: boolean[], get: GetterType): [boolean[] | undefined, ISingleOptionValue | null] {
  const uniqueInRepeatedElements = props.uniqueInRepeatedElements === true 
        && context.listCount != null && context.listCount > 0 
        && context.prefixBase != null;

  if (uniqueInRepeatedElements) {
    return getDisabledOptionsAndAutoValueWhenUnique(context, props, optionValues, preDisabled, get);
  }

  let val = props.value;
  // validate value (value is not allowed if not in optionValues or if null without a placeholder)
  if ((props.placeholder == null && val == null) || (val != null && optionValues.indexOf(val) === -1)) {
    let avIdx = 0;
    val = null;
    while (avIdx < optionValues.length) {
      if (preDisabled?.[avIdx] === true) continue;
      val = optionValues[avIdx++];
      break;
    }
  }

  return [preDisabled.every(opt => opt === false) ? undefined : preDisabled, val];
}

function getDisabledOptionsAndAutoValueWhenUnique(renderContext: ComponentRenderContext, props: DropdownProps, optionValues: IOptionValues, disabledOptions: boolean[], get: GetterType): [boolean[], ISingleOptionValue | null] {
  const disabled = [...disabledOptions];
  const listCount = renderContext.listCount ?? 0;
  const values: (ISingleOptionValue | null)[] = getSiblingIds(renderContext.prefixBase ?? '', renderContext.currentParsedComponent.id, listCount)
    .map(id => get(id, 'value', false));

  // evaluate values
  let myVal: ISingleOptionValue | null = null;
  for (let i = 0, avIdx = 0; i < listCount; ++i) {
    let val = values[i];

    // validate value (value is not allowed if not in key set or if null without a placeholder)
    if ((props.placeholder == null && val == null) || (val != null && optionValues.indexOf(val) === -1)) {
      while (avIdx < optionValues.length) {
        const currAutoValueIndex = avIdx++;
        if (disabledOptions?.[currAutoValueIndex] === true) continue;
        let autoValue = optionValues[currAutoValueIndex];
        if (values.indexOf(autoValue) >= 0) continue; 
        // not taken, i.e. first viable value, assign
        values[i] = val = autoValue;
        break;
      }
    }

    if (i == renderContext.listIndex) {
      myVal = val;
    }
  }      

  // update array of disable marking all "taken" values as disabled
  for (let i = 0; i < listCount; ++i)  {
    let val = values[i];
    if (i == renderContext.listIndex) continue;
    let idx = val != null ? optionValues.indexOf(val) : -1;
    if (idx >= 0) {
      disabled[idx] = true;
    }
  }

  return [disabled, myVal];
}