import React from 'react';
import { ComponentRenderContext, SrtComponentFunctions, ParsedComponent, TemplateContext } from '../BasicTypes';

export interface SrtContainerProps {
  parentId: string;
  renderContext: ComponentRenderContext;
  templateContext: TemplateContext;
  functions: SrtComponentFunctions;
  children?: ParsedComponent<any>[];
}

interface SrtContainerChildRendererProps extends SrtContainerProps {
  id: string;
  componentProps: { [propName: string]: any };
}

export const SrtContainerChildRenderer: React.FC<SrtContainerChildRendererProps> = (props: SrtContainerChildRendererProps) => {
  // All data that belongs to the component - as a Redux useSelector - i.e. will trigger component rerender if value gets updated.
  const storeData = props.templateContext.componentStore.useReactState(props.id);

  // create the props to send the component
  const childProps = {
    ...props.componentProps, 
    ...storeData?.userInputProps,
    ...storeData?.calculatedProps,
    id: props.id,
  };

  if (props.templateContext.runtime.registerRuntimeComponent(props.id, props.renderContext)) {
    props.functions.runScripts(50, [{ type: 'componentAdded', compId: props.id, propName: undefined }]);
  }

  // When parsing, the correct component was found via the name and the ComponentsDictionary. A component (a js object) have a render function. Calling this calls render in the implementing component
  return <>{ props.renderContext.currentParsedComponent.component.render(childProps, props.renderContext, props.templateContext, props.functions) }</>;
};

// Renderer of components 
export const SrtContainer: React.FC<SrtContainerProps> = (props: SrtContainerProps) => {
  const idPrefix = (props.renderContext.prefix ? props.renderContext.prefix : '');
  const childComponents = props.children ?? props.renderContext.currentParsedComponent.children ?? [];
  const childItems = childComponents.map((c: ParsedComponent<any>, i: number) => {
    const id: string = idPrefix + c.args.id;
    const renderContext: ComponentRenderContext = {
      ...props.renderContext,
      currentParsedComponent: c,
      parentComponentId: props.parentId,
      siblingIndex: i,
      siblingCount: childComponents.length,
    };

    return <SrtContainerChildRenderer key={i} id={id} componentProps={{ ...c.args, id: id }} templateContext={props.templateContext} renderContext={renderContext} functions={props.functions} parentId={props.parentId} />;
  });

  return <>{ childItems }</>;
};
