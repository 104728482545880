import React, { useEffect, useRef, useState, useMemo } from 'react';
import { applogger } from '../../applogger';
import { SrComponent, SrComponentPropsBase, ComponentRenderContext } from '../BasicTypes';
import { getDefaultBgColor, ReactComponentContainerProps, shouldDisplay } from './SrtComponent';
import { expressionPattern, schema } from './Schema';

import createPlotlyComponent from 'react-plotly.js/factory';
import './SectraGraph-plotly.scss'; /* Explicit plotly-js styles loading since it seems to not load correctly in production build + preview mode */


const Plotly = require('plotly.js-basic-dist-min');
const Plot = createPlotlyComponent(Plotly);

interface GraphProps extends SrComponentPropsBase {
  hidden?: boolean;
  display?: boolean;
  layout?: any;
  data?: any;
  storeImageResult?: boolean;
  storeImageScale?: number;
  storeImageBackgroundColor?: string
}

const fontDef = {
  'type': 'object',
  'description': 'Sets the title font.',
  'properties': {
    'family': { 'type': 'string', 'description': "HTML font family - the typeface that will be applied by the web browser. The web browser will only be able to apply a font if it is available on the system which it operates. Provide multiple font families, separated by commas, to indicate the preference in which to apply fonts if they aren't available on the system. " },
    'size': { 'type': 'number', 'description': 'Sets the font size' },
    'color': { 'type': 'string', 'description': 'Sets the font color' },
  },
};

const axisDef = {
  'type': 'object',
  'description': 'Configure graph axis',
  'properties': {
    'title': {
      'anyOf': [
        { 'type': 'string', 'description': 'The title of the axis' },
        {
          'type': 'object',
          'description': 'The title of the axis',
          'properties': {
            'text': { 'type': 'string', 'description': 'Sets the title of this axis' },
            'font': fontDef,
          },
        },
      ],
    },
    'type': { 'type': 'string', 'enum': ['-', 'linear', 'log', 'date', 'category', 'multicategory'], 'description': 'Sets the axis type. By default, plotly attempts to determined the axis type by looking into the data of the traces that referenced the axis in question.' },
    'ticks': { 'type': 'string', 'description': "Determines whether ticks are drawn or not. If \"\", this axis' ticks are not drawn. If \"outside\" (\"inside\"), this axis' are drawn outside (inside) the axis lines." },
    'showticklabels': { 'type': 'boolean', 'description': 'show/hide tick labels (defaults to true)' },
    'tickmode': { 'type': 'string', 'enum': ['auto', 'linear', 'array'], 'description': 'Sets the tick mode for the axis "auto" or "linear" or "array"' },
    'tick0': { 'type': 'number', 'description': 'Sets the placement of the first tick' },
    'dtick': { 'type': 'number', 'description': 'Sets the step in-between ticks' },
    'ntick': { 'type': 'number', 'description': 'Specifies the maximum number of ticks' },
    'ticklen': { 'type': 'number', 'description': 'Sets the length of the ticks' },
    'tickwidth': { 'type': 'number', 'description': 'Sets the width of the ticks' },
    'tickcolor': { 'type': 'string', 'description': 'Sets the color of the ticks' },
    'range': {
      'type': 'array',
      'minItems': 2,
      'maxItems': 2,
      'items': {
        'type': ['number', 'string'],
    
      },
      'description': 'Sets the range of this axis. If the axis `type` is "log", then you must take the log of your desired range (e.g. to set the range from 1 to 100, set the range from 0 to 2). If the axis `type` is "date", it should be date strings, like date data, though Date objects and unix milliseconds will be accepted and converted to strings. If the axis `type` is "category", it should be numbers, using the scale where each category is assigned a serial number from zero in the order it appears.',
    },
  },
};

const dataDef = {
  'description': 'The data to be plotted described in an array whose elements are trace objects of various types (e.g. scatter, bar etc). Please consult the plotly manual for further information.',
  'anyOf': [
    {
      'type': 'array',
      'items': {
        'type': 'object',
        'properties': {
          'x': {
            'type': 'array', 'description': 'Sets the x coordinates as a list of numbers.',
            'items': { 'type': ['number', 'string'] },
          },
          'y': {
            'type': 'array', 'description': 'Sets the y coordinates as a list of numbers.',
            'items': { 'type': ['number', 'string'] },
          },
          'name': { 'type': 'string', 'description': 'Sets the trace name. The trace name appear as the legend item (default: "trace {index}")' },
          'mode': {
            'anyOf': [
              { 'type': 'string', 'enum': ['lines', 'markers', 'text', 'lines+markers', 'text+markers', 'text+lines', 'text+lines+markers', 'none'], 'enumDescription': ['Line', 'Marker', 'Text', 'Line with marker (default)', 'Text with marker', 'Text with line', 'Text with line and marker', 'None'] },
              { 'type': 'string', 'description': 'Other mode' },
            ],
            'description': 'flaglist string. Any combination of "lines", "markers", "text" joined with a "+" OR "none". Examples: "lines", "markers", "lines+markers", "lines+markers+text", "none". Determines the drawing mode for this scatter trace. If the provided `mode` includes "text" then the `text` elements appear at the coordinates. Otherwise, the `text` elements appear on hover. If there are less than 20 points and the trace is not stacked then the default is "lines + markers". Otherwise, "lines".', 
          },
          'line': {
            'type': 'object',
            'description': 'Sets line properties for the trace (only applicable for scatter).',
            'properties': {
              'color': { 'type': 'string', 'description': 'Sets the line color, eg. "red", "blue" or "f95041".' },
              'width': { 'type': 'number', 'description': 'Sets the line width as a number (default: 2).' },
              'dash': { 
                'anyOf': [
                  { 'type': 'string', 'enum': ['solid', 'dot', 'dash', 'longdash', 'dashdot', 'longdashdot'], 'enumDescription': ['Solid line (default)', 'Dotted line', 'Dashed line', 'Long dashed line', 'Dash/Dotted line', 'Long dash/dotted line'] },
                  { 'type': 'string', 'description': 'Exact dash specification, eg "5px, 10px, 2px, 2px".' },
                ],
                'description': 'Sets the dash style of lines. Set to a predefined dash type or a custom dash, eg "5px, 10px, 2px, 2px" (default: solid).' },
              'shape': { 'type': 'string', 'enum': ['linear', 'spline', 'vhv', 'hvh', 'vh', 'hv'], 'enumDescription': ['Straight lines (default)', 'Smooth line'], 'description': 'Sets the line shape (default: linear).' },
              'smoothing': { 'type': 'number',  'minimum': 0, 'maximum': 1.3, 'description': 'The degree of smothing for spline, between 0 and 1.3 (default: 1.0)' },
            },
          },
          'marker': {
            'type': 'object',
            'description': 'Sets marker properties for the trace (only applicable for scatter).',
            'properties': {
              'symbol': {
                'anyOf': [
                  { 'type': 'string', 'enum': getMarkerSymbolVariants(['circle', 'square', 'diamond', 'cross', 'x', 'triangle-up', 'triangle-down', 'triangle-left', 'triangle-right', 'triangle-ne', 'triangle-se', 'triangle-sw', 'triangle-nw', 'pentagon', 'hexagon', 'hexagon2', 'octagon', 'star', 'hexagram', 'star-triangle-up', 'star-triangle-down', 'star-square', 'star-diamond', 'diamond-tall', 'diamond-wide', 'hourglass', 'bowtie', 'circle-cross', 'circle-x', 'square-cross', 'square-x', 'diamond-cross', 'diamond-x', 'cross-thin', 'c-thin', 'asterisk', 'hash', 'y-up', 'y-down', 'y-left', 'y-right', 'line-ew', 'line-ns', 'line-ne', 'line-nw', 'arrow-up', 'arrow-down', 'arrow-left', 'arrow-right', 'arrow-bar-up', 'arrow-bar-down', 'arrow-bar-left', 'arrow-bar-right']) },
                  { 'type': 'string', 'description': 'Other marker symbol' },
                ],
                'description': 'Marker symbol (default: circle)',
              },
              'opacity': { 'type': 'number', 'minimum': 0, 'maximum': 1, 'description': 'Sets the marker opacity between 0 and 1 (default: 1).' },
              'color': { 'type': 'string', 'description': 'Sets the marker color, eg. "red", "blue" or "f95041" (default: line color)' },
              'size': { 'type': 'number', 'description': 'Sets the marker size as a number (default: 6).' },
              'line': {
                'type': 'object',
                'description': 'Sets line properties for the trace (only applicable for scatter).',
                'properties': {
                  'color': { 'type': 'string', 'description': 'Sets the line color, eg. "red", "blue" or "f95041". Note: must be coupled with a postive line width property value to be visable' },
                  'width': { 'type': 'number', 'description': 'Sets the line width as a number (default: 0).' },
                },
              },
            },
          },
          'type': {
            'anyOf': [
              { 'type': 'string', 'enum': ['scatter', 'bar', 'pie'], 'enumDescription': ['Line/scatter (default)', 'Bar (column)', 'Pie'] },
              { 'type': 'string', 'description': 'Other type' },
            ],
            'type': 'string', 'description': 'Sets the graph type (default: scatter)',
          }, 
        },
      },
    },
    {
      'type': 'string',
      'description': 'Function that returns a data object',
      'pattern': expressionPattern,
    },
  ],
};

const graphSchema = {
  'id': { 'type': 'string', 'description': 'The id of the element' },
  'hidden': schema.PropDefinition.hidden,
  'display': schema.PropDefinition.display,
  'layout': {
    'type': 'object',
    'description': 'The layout of the plot – non-data-related visual attributes such as the title, annotations etc.',
    'properties': {
      'title': { 'type': 'string', 'description': 'The title of the graph' },
      'font': fontDef,
      'showlegend': { 'type': 'boolean', 'description': 'Determines whether or not a legend is drawn. Default is `true` if there is a trace to show and any of these: a) Two or more traces would by default be shown in the legend. b) One pie trace is shown in the legend. c) One trace is explicitly given with `showlegend: true`.' },
      'width': { 'type': 'number', 'description': "Sets the plot's width (in px)" },
      'height': { 'type': 'number', 'description': "Sets the plot's height (in px)" },
      'paper_bgcolor': { 'type': 'string', 'description': 'Sets the background color of the paper where the graph is drawn' },
      'plot_bgcolor': { 'type': 'string', 'description': 'Sets the background color of the plotting area in-between x and y axes.' },
      'xaxis': axisDef,
      'yaxis': axisDef,
    },
  },
  'data': dataDef,
  'storeImageResult': { 'type': ['string', 'boolean'], 'enumDescription': ['Store image', 'Do not storage image'], 'description': 'Whether the resulting image is stored as part of the report or not (default: true)', 'pattern': expressionPattern },
  'storeImageScale': { 'type': 'number', 'description': 'Scale factor at which the resulting image is stored (default: 1.5)' },
  'storeImageBackgroundColor': { 'type': 'string', 'description': 'Sets a custom background color of the saved graph (default: as displayed).' },
};

const defaultLayout = {
  showlegend: true,
  plot_bgcolor: '#0000',
  paper_bgcolor: '#0000',
  legend: {
    bgcolor: '#0000',
  },
};

export const SectraGraphReact: React.FC<ReactComponentContainerProps<GraphProps>> = (container) => {
  const props = container.props;
  // When defining data using a function in the script tab, the properties appear to become readonly.
  // This causes issues, particularly for the line.color prop, as plotly appears to assign some of the props it receives.
  // To avoid this, we make a fresh copy of the data object so that plotly won't interfere with state values
  const dataJson = props.data != null ? JSON.stringify(props.data) : null;
  const data = useMemo(() => dataJson != null ? JSON.parse(dataJson) : null, [props.data]);
  const layout = getLayout(container.context, props.layout);
  const theme = container.context.theme;

  // handle image data
  const [imageData, setImageData] = useState('');
  const updateRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(()=>{
    // if we're already waiting to run, clear the timeout
    if (updateRef.current != null) {
      clearTimeout(updateRef.current);
    }

    // runner for doing the work
    const runSetImageData = () => {
      applogger.debug('calculate and set new image data for graph');
      getCanvasDataUrl(props.id, props.storeImageBackgroundColor ?? getDefaultBgColor(theme, false), props.storeImageScale ?? 1.5).then(dataUrl => {
        if (dataUrl != null) {
          setImageData(dataUrl);
        } else if (shouldDisplay(props.display, container.context)) {
          applogger.warn('Failed to get data URL for graph image, try again in a while');
          // Failed to get a data URL, try again in ½ second
          updateRef.current = setTimeout(() => {
            updateRef.current = null;
            runSetImageData();
          }, 500);
        }
      });
    };

    // start delayed
    updateRef.current = setTimeout(() => {
      updateRef.current = null;
      runSetImageData();
    }, 100);
  }, [props.display, props.hidden, props.storeImageBackgroundColor, dataJson, JSON.stringify(layout)]);
    
  if (!shouldDisplay(props.display, container.context)) {
    return null;
  }

  return (
        <div className={'SectraGraph'} hidden={props.hidden === true}>
            { React.createElement(Plot, {
              divId: props.id,
              config: { staticPlot: true },
              data: data,
              layout: layout,
              onError: (err) => { applogger.error('error: ', err); },
            }) }
            {props.storeImageResult !== false && imageData?.length > 0
              ? <input type="hidden" name={props.id} data-field-type="image/png" value={imageData} readOnly={true}></input> 
              : null}
        </div>);
};

const key = 'Graph';
export const SectraGraph: SrComponent<GraphProps> = {
  key,
  render: (props, context, templateContext, functions) => <SectraGraphReact props={props} context={context} templateContext={templateContext} functions={functions} />,
  template: () => Promise.resolve(`- ${key}:
    id: graph${schema.getNextIdNum()}
    data:
    - y: [1.5, 1, 1.3, 0.7, 0.8, 0.9]
      type: line
    - y: [1, 0.5, 0.7, -1.2, 0.3, 0.4]
      type: bar`),
  toolboxName: 'Graph',
  schema: schema.getSchema(key, schema.mergeSchemaProps(schema.DefaultComponentSchemaPart, graphSchema), ['id'], false),
};

async function getCanvasDataUrl(containerId: string, bgColor: string, scale?: number): Promise<string | null> {
  // Get SVG elements from DOM
  const containerSvgs = document.getElementById(containerId)
    ?.querySelectorAll('svg');

  if (containerSvgs == null || containerSvgs.length === 0) {
    applogger.debug('no svgs');
    return null;
  }

  const fSourceSvg = containerSvgs[0];

  // Create a new canvas
  scale = scale == null || scale <= 0 ? 1.25 : scale;
  const width = fSourceSvg.width.animVal.value;
  const height = fSourceSvg.height.animVal.value;
  if (width == 0 || height == 0) {
    return null;
  }

  const canvas = document.createElement('canvas');
  canvas.width = width * scale;
  canvas.height = height * scale;

  // Get canvas ctx
  const ctx = canvas.getContext('2d');
  if (ctx == null) {
    applogger.warn('no ctx!');
    return null;
  }

  // draw background color first
  ctx.fillStyle = bgColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // setup transform
  ctx.setTransform(scale, 0, 0, scale, 0, 0);
    
  // Draw all SVGS to canvas
  for (let i = 0; i < containerSvgs.length; ++i) {
    let svg = containerSvgs[i];
    if (bgColor == null || svg.style?.backgroundColor == null || svg.style.background == '#0000') {
      svg = svg.cloneNode(true) as SVGSVGElement;
      svg.style.backgroundColor = '#0000';
    }

    await drawImageUrlToCanvas(ctx, getSvgDataUrl(svg));
  }

  return canvas.toDataURL('image/png');
}

function getSvgDataUrl(element: SVGSVGElement) {
  return 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(element))));
}

function drawImageUrlToCanvas(ctx: CanvasRenderingContext2D | null, image: string) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.onload = () => {
      ctx?.drawImage(img, 0, 0);
      resolve(ctx);
    };
    img.onerror = reject;
    img.src = image;
  });
}


function getMarkerSymbolVariants(coreTypes: string[]): string[] {
  return coreTypes.flatMap(t => ([t, t + '-open', t + '-dot', t + '-open-dot']));
}

function getLayout(context: ComponentRenderContext, layoutProps: any) {
  const theme = context.theme;
  const themeFontColor = theme === 'dark' ? '#bdbdbd' : 'rgba(0, 0, 0, 0.8)';
  const themeGridColor = theme === 'dark' ? '#566275' : '#b2b6bd';
  const themeZeroLineColor = theme === 'dark' ? '#999' : '#777';

  let layout = { ...defaultLayout, ...layoutProps };
  if (layout.font == null) layout.font = {};
  if (layout.font.color == null) layout.font.color = themeFontColor;
  if (layout.xaxis == null) layout.xaxis = {};
  if (layout.xaxis.showline == null) layout.xaxis.showline = true;
  if (layout.xaxis.gridcolor == null) layout.xaxis.gridcolor = themeGridColor;
  if (layout.xaxis.zerolinecolor == null) layout.xaxis.zerolinecolor = themeZeroLineColor;
  if (layout.yaxis == null) layout.yaxis = {};
  if (layout.yaxis.showline == null) layout.yaxis.showline = true;
  if (layout.yaxis.gridcolor == null) layout.yaxis.gridcolor = themeGridColor;
  if (layout.yaxis.zerolinecolor == null) layout.yaxis.zerolinecolor = themeZeroLineColor;

  return layout;
}