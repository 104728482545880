import React from 'react';

export const IconSheet: React.FC = () => <svg display="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <symbol id="spx-ico-brand-logo-sectra" viewBox="0 0 128 24">
        <path d="M19.627,1.125C18.649,0.375,17.216,0,15.326,0H5.783c-1.89,0-3.325,0.375-4.308,1.125  C0.491,1.875,0,2.977,0,4.43v2.535C0,8.171,0.261,9.12,0.784,9.813c0.522,0.693,1.519,1.339,2.991,1.937l6.41,2.592  c0.703,0.285,1.142,0.575,1.318,0.869c0.176,0.294,0.263,0.812,0.263,1.553c0,0.655-0.092,1.126-0.278,1.41  c-0.185,0.285-0.482,0.427-0.89,0.427c-0.399,0-0.689-0.133-0.869-0.399c-0.181-0.266-0.271-0.693-0.271-1.282v-1.666H0v4.287  c0,1.462,0.491,2.571,1.474,3.326C2.457,23.623,3.893,24,5.783,24h9.544c1.975,0,3.43-0.41,4.366-1.232  c0.935-0.821,1.403-2.101,1.403-3.838v-2.621c0-1.49-0.19-2.604-0.57-3.34c-0.38-0.736-1.045-1.308-1.994-1.716l-7.464-3.148  c-0.741-0.304-1.204-0.563-1.389-0.776C9.493,7.114,9.401,6.837,9.401,6.495c0-0.351,0.102-0.632,0.306-0.84  c0.204-0.209,0.472-0.314,0.805-0.314c0.389,0,0.686,0.135,0.89,0.406c0.204,0.27,0.306,0.667,0.306,1.189v0.299h9.387V4.43  C21.095,2.977,20.605,1.875,19.627,1.125z" />
        <polygon points="23.089,23.829 38.016,23.829 38.016,18.431 32.333,18.431 32.333,13.531 38.016,13.531   38.016,8.133 32.333,8.133 32.333,5.541 38.016,5.541 38.016,0.142 23.089,0.142 " />
        <path d="M55.237,0h-9.657c-2.07,0-3.525,0.425-4.365,1.274c-0.84,0.85-1.261,2.31-1.261,4.38v13.247  c0,1.757,0.451,3.045,1.353,3.867C42.208,23.59,43.633,24,45.579,24h9.657c2.089,0,3.587-0.396,4.494-1.189  c0.906-0.793,1.361-2.096,1.361-3.91v-4.729h-9.387v2.749c0,0.589-0.097,1.021-0.292,1.296c-0.195,0.276-0.496,0.414-0.905,0.414  c-0.408,0-0.705-0.136-0.89-0.406c-0.185-0.271-0.278-0.705-0.278-1.303V7.05c0-0.598,0.092-1.033,0.278-1.303  c0.185-0.271,0.482-0.406,0.89-0.406c0.428,0,0.734,0.131,0.918,0.392c0.186,0.262,0.278,0.701,0.278,1.318v1.724h9.387V5.655  c0-2.099-0.425-3.566-1.275-4.401C58.966,0.418,57.44,0,55.237,0z" />
        <polygon points="62.287,5.541 67.045,5.541 67.045,23.829 76.232,23.829 76.232,5.541 80.989,5.541   80.989,0.142 62.287,0.142 " />
        <path d="M99.52,11.167c1.367-0.342,2.345-0.928,2.934-1.759c0.589-0.831,0.883-2.044,0.883-3.639  c0-2.003-0.461-3.442-1.382-4.316c-0.922-0.873-2.455-1.31-4.601-1.31H82.371v23.687h9.245v-9.771h1.025  c0.493,0,0.84,0.126,1.04,0.377c0.199,0.252,0.299,0.691,0.299,1.318v8.076h9.387v-6.096c0-2.022-0.299-3.542-0.897-4.558  C101.871,12.159,100.887,11.49,99.52,11.167z M93.673,8.518c-0.204,0.247-0.548,0.37-1.032,0.37h-1.025V5.541h1.025  c0.493,0,0.84,0.123,1.04,0.37c0.199,0.247,0.299,0.688,0.299,1.324C93.979,7.843,93.877,8.271,93.673,8.518z" />
        <path d="M122.523,0.142h-14.471l-4.173,23.687h9.543l0.527-5.128h2.649l0.527,5.128h9.572L122.523,0.142z   M114.29,13.474l0.997-8.048l0.954,8.048H114.29z" />
    </symbol>
    <symbol id="spx-ico-commands-user" viewBox="0 0 24 24">
        <g className="accent" fill="currentColor">
            <path d="M16.374,6.969c0-3.439-1.945-5.671-4.35-5.671c-2.403,0-4.35,2.231-4.35,5.671   c0,2.002,0.955,3.576,1.686,4.923c0.404,0.744,0.084,1.318-0.57,1.828L12,17.75l3.23-4.054c-0.633-0.505-0.936-1.072-0.538-1.804   C15.422,10.545,16.374,8.971,16.374,6.969z" />
        </g>
        <g className="base">
            <path d="M21.026,19.76v-1.586c0-0.173-0.032-0.336-0.078-0.493c-0.011-0.037-0.023-0.075-0.036-0.111   c-0.113-0.312-0.308-0.596-0.565-0.857c-0.023-0.023-0.045-0.046-0.069-0.069c-0.804-0.774-2.123-1.356-3.3-1.931l-3.414,4.284   l-3.128,0L7.037,14.73c-1.17,0.57-2.471,1.149-3.266,1.916c-0.022,0.021-0.043,0.043-0.065,0.065   c-0.129,0.131-0.247,0.266-0.343,0.409c-0.002,0.002-0.004,0.005-0.005,0.007c-0.093,0.14-0.163,0.289-0.218,0.443   c-0.013,0.036-0.024,0.073-0.035,0.11C3.059,17.838,3.026,18,3.026,18.174v1.615C5.528,21.155,8.623,21.98,12,21.98   C15.401,21.98,18.516,21.144,21.026,19.76z" />
        </g>
    </symbol>
</svg>;
