export type ISingleOptionValue = string | number | boolean;
export type IOptionValues = ISingleOptionValue[];
export type IOptionValue = ISingleOptionValue | IOptionValues;
export type INullableOptionValue = IOptionValue | null;

export const SectraOptionHelper = {
  isSingleValue: (val: INullableOptionValue) => typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean',
  valueEq: (a: INullableOptionValue, b: INullableOptionValue) => {
    if (!Array.isArray(a) || !Array.isArray(b)) {
      // Not an array, normal compare
      return a === b;
    }

    // Array matching
    return a.length === b.length && a.every((v, i) => v === b[i]);
  },

  matchSingle: (a: ISingleOptionValue, comp: INullableOptionValue | undefined, allowMultiple: boolean | undefined): boolean => {
    if (comp == null) {
      return false;
    }
    if (allowMultiple === false || !Array.isArray(comp)) {
      // either no multiple allowed or comp isn't an array
      return a === comp;
    }

    // comp is array, check if is in array
    return comp.indexOf(a) >= 0;
  },

  normalizeValue: (value: any, allowedValues: IOptionValues, allowMultiple: boolean | undefined): INullableOptionValue => {
    let items: IOptionValues = [];
    if (value != null) {
      if (SectraOptionHelper.isSingleValue(value)) {
        items.push(value);
      } else if (Array.isArray(value)) {
        value.forEach(v => { items.push(SectraOptionHelper.isSingleValue(v) ? v : String(v)); });
      } else {
        items.push(String(value));
      }
    }

    let values: IOptionValues = allowedValues.filter(x => items.indexOf(x) >= 0);
    return allowMultiple === true 
      ? values
      : (values.length === 0 ? null : values[0]);
  },

  valueAsOptionValues: (value: INullableOptionValue): IOptionValues => 
    value == null ? [] : (Array.isArray(value) ? value : [value]),

  addToCurrent: (current: INullableOptionValue, val: INullableOptionValue, allowedValues: IOptionValues, allowMultiple: boolean | undefined) => {
    if (allowMultiple !== true) {
      return val;
    }

    const value = current == null 
      ? [val]
      : (SectraOptionHelper.isSingleValue(current) ? [current, val] : [...(current as IOptionValues), val]);

    return SectraOptionHelper.normalizeValue(value, allowedValues, true);
  },

  removeFromCurrent(current: INullableOptionValue, val: INullableOptionValue, allowMultiple: boolean | undefined) {
    if (allowMultiple !== true) {
      return null;
    }

    if (current == null) {
      return null;
    }
    if (SectraOptionHelper.isSingleValue(current)) {
      return (current == val) ? null : current;
    }
    let values = (current as IOptionValues).filter(x => x != val);
    return values.length === 0
      ? null
      : values.length === 1 ? values[0] : values;
  },
};