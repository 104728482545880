import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { HighlightTarget } from './GuideHighlightElement';
import { GuideModal } from './GuideModal';

import './Guide.scss';
import { IServerCommunication } from '../ServerCommunication';


type GuideProps = {
  server: IServerCommunication;
  wasLogin: boolean;
  onGuideShow: () => void;
};

export const Guide: React.FC<GuideProps> = (props) => {
  const [ step, setStep ] = useState(0);
  const [ visible, setVisible ] = useState(false);

  useEffect(() => {
    if (props.wasLogin) {
      props.server?.getShowTutorial().then((show) => {
        setVisible(show);
        props.onGuideShow();
      });
    }
  }, [ props.server ]);



  const close = (forever: boolean) => {
    setVisible(false);
    if (forever) {
      props.server.setShowTutorial(false);
    }
  };

  if (!visible) return <></>;
  
  return createPortal(<>
    <HighlightTarget step={step} />

    <GuideModal step={step} setStep={setStep} close={close} />
  </>, document.documentElement);
};