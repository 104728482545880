import React from 'react';
import { Button } from 'react-bootstrap';
import { guideSteps } from './content';


type GuideModalProps = {
  step: number;
  setStep: (value: number) => void;
  close: (forever: boolean) => void;
};

export const GuideModal: React.FC<GuideModalProps> = (props) => {
  const {
    title,
    content,
  } = guideSteps[props.step];
  
  const isLastStep = props.step === guideSteps.length - 1;
  const isFirstStep = props.step === 0;
  
  const next = () => props.setStep(props.step + 1);
  const prev = () => props.setStep(props.step - 1);
  const done = () => props.close(false);
  const closeOnce = () => props.close(false);
  const closeAlways = () => props.close(true);
  
  return <>
      <div className="guide modal-content">
        <h2 className="header">{title}</h2>
        <div className="content sectra-scroll-area" dangerouslySetInnerHTML={{ __html: content }}/>
        <div className="footer">
          <Button onClick={closeOnce} className="btn btn-secondary">Close</Button>
          <Button onClick={closeAlways} className="btn btn-secondary">Don&apos;t show again</Button>

          <div className="flex-grow"/>

          <Button onClick={prev} className="btn btn-secondary" disabled={isFirstStep}>Previous</Button>
          {!isLastStep ?
            <Button onClick={next} className="btn btn-primary">Next</Button>
            : // else
            <Button onClick={done} className="btn btn-primary">Done</Button>
          }
        </div>
      </div>
    </>;
};
  