import { useState } from 'react';
import { useEffect } from 'react';
import versionTxt from '../../../../../versions.txt';

async function readVersionFile() {
  const textResponse = await fetch(versionTxt, { cache: 'no-cache' });
  const text = await textResponse.text();
  return text.split('\n') as [string, string, string];
}

export function useVersion() {
  const [version, setVersion] = useState<[string, string, string]>([
    ' - ', // version
    ' - ', // year
    ' - ', // build
  ]);

  useEffect(() => {
    let cancel = false;
    readVersionFile().then(newVersion => !cancel && setVersion(newVersion));

    return () => {
      cancel = true;
    };
  }, []);

  return version;
}
