/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { Navbar as BNavbar, Nav as Nav2, NavItem, NavDropdown } from 'react-bootstrap';
import { SvgRef } from './Components/Generic/SvgRef';
import { IconSheet } from './Resources/IconSheet';
import { Switch, Route, useHistory } from 'react-router-dom';
import { routeToPath } from './Helpers/NavigationHelper';
import { inDemoMode, SrtSite, UserRole } from './ServerCommunication';

export type AppColorMode = 'theme-light' | 'theme-dark';

interface NavProps {
    colorMode: AppColorMode,
    onColorModeChange: (x: AppColorMode) => void,
    menus: MenuItem[];
    sites: SrtSite[] | undefined;
    requestSiteChange: (siteId: number)=>void;
    showLogout: boolean;
    role: UserRole;
}

interface MenuItem {
    text: string;
    disabled?: boolean;
    title?: string;
    action?: ()=>void;
}

const NavHeader: React.FC = () => {
    return (
        <BNavbar.Header>
            <BNavbar.Brand>
              <a id="logo-link" href="/">
                <div className="logo-container">
                    <SvgRef className="sectra-logo" id="spx-ico-brand-logo-sectra" />
                </div>
                <span id="logo-text">Forms</span>
              </a>
            </BNavbar.Brand>
            <BNavbar.Toggle />
        </BNavbar.Header>
    );
};

export const Nav: React.FC<NavProps> = ({ colorMode, onColorModeChange, menus, sites, requestSiteChange, showLogout, role }) => {
    const history = useHistory();
    const mode = 
      <Nav2 style={{ marginLeft: 30 }} className={'clickable'} onClick={_ => onColorModeChange(colorMode === 'theme-dark' ? 'theme-light' : 'theme-dark')}>
          Mode: {colorMode === 'theme-dark' ? 'Dark' : 'Light'}
      </Nav2>;
    const signInOut = showLogout ?
      <Nav2 pullRight={true} title="Sign out" className={'clickable'} style={{ marginRight:20 }}>
          <NavItem className='navbar-signin-out' href={'/MicrosoftIdentity/Account/SignOut'}>
              Sign out
          </NavItem>
      </Nav2> :
      <>
      <Nav2 pullRight={true} title={'Sign in'} className="clickable" style={{ marginRight: 20 }}>
          <NavItem className='navbar-signin-out' href="/MicrosoftIdentity/Account/SignIn">Sign in/Sign up</NavItem>
      </Nav2>
      {process.env.NODE_ENV !== 'production' ? 
      <Nav2 pullRight={true} title="Debug Sign in" className="clickable" style={{ marginRight: 20 }}>
        <NavItem className='navbar-signin-out' href="/debugsignin">Debug sign in</NavItem>
      </Nav2>
      : null}
      </>;
    const siteChange = (sites != null && sites.length > 1) 
        ? <NavDropdown id="site-dropdown" title="Site" className={'nav navbar-nav clickable'}>
             {sites.map(a => 
                <NavItem key={a.Id} className={a.IsCurrent ? 'disabled' : 'clickable'} onClick={!a.IsCurrent ? () => requestSiteChange(a.Id) : undefined}>{a.Name}</NavItem>,
            )}     
        </NavDropdown>
        : <></>;
    const help = showLogout ?
      <Nav2 title='Help' className={'clickable'} style={{ marginLeft: 20, marginRight: 20 }} onClick={() => history.push(routeToPath('/help'))}>
        Help
      </Nav2> : null;
    const releaseNotes =
      <Nav2 title='Release Notes' className='clickable' style={{ marginLeft: 20, marginRight: 20 }} onClick={() => history.push(routeToPath('/help'))}>
        Release Notes
      </Nav2>;
    const usersGuide =
      <Nav2 title='Users Guide' className='clickable' style={{ marginRight: 20 }} onClick={() => history.push(routeToPath('/help/usersguide'))}>
        Users Guide
      </Nav2>;
    const about =
      <Nav2 title='About' className='clickable' style={{ marginRight: 20 }} onClick={() => history.push(routeToPath('/help/about'))}>
        About
      </Nav2>;
    const adminStuff = role === UserRole.Admin ? 
      <Nav2 pullRight={true} title='Administer users' className={'clickable'} style={{ marginRight:20 }} onClick={() => history.push(routeToPath('/UserAdmin'))}>
        User admin
      </Nav2>
      : role === UserRole.SectraAdmin ?
      <Nav2 pullRight={true} title='Sectra admin' className={'clickable'} style={{ marginRight:20 }} onClick={() => history.push(routeToPath('/SectraAdmin'))}>
        Sectra admin
      </Nav2> : null;
    const defaultNav = <div>
                            {siteChange}
                            {mode}
                            {!inDemoMode && showLogout
                                ? <Nav2 style={{ marginLeft: 30 }} className={'clickable'} onClick={
                                    ()=> history.push(routeToPath('/DataProviders'))
                                }>
                                    Data providers
                                </Nav2>
                                : null }
                            {help}
                            {signInOut}
                            {adminStuff}
                        </div>;
    return (
        <div data-highlight-type="nav">
            <BNavbar collapseOnSelect className="navbar-fixed-top">
                <NavHeader />
                <BNavbar.Collapse>
                    <Switch>
                        <Route exact path={routeToPath('/')}>
                          {defaultNav}
                        </Route>
                        <Route path={routeToPath('/Account/SignOut')}>
                          {defaultNav}
                        </Route>
                        <Route exact path={routeToPath('/DataProviders')} children={<div>
                            {siteChange}
                            {mode}
                            <Nav2 style={{ marginLeft: 30 }} className={'clickable'} onClick={
                                ()=> history.push(routeToPath('/'))
                            }>
                               Forms 
                            </Nav2>
                            {signInOut}
                        </div>}/>
                        <Route path={routeToPath('/help')}>
                          {mode}
                          {releaseNotes}
                          {usersGuide}
                          {about}
                          {signInOut}
                          <Nav2 style={{ marginRight: 20 }} className='clickable pull-right' onClick={
                              ()=> history.push(routeToPath('/'))
                          }>
                              Back
                          </Nav2>
                        </Route>
                        <Route exact path={routeToPath('/:id/:version?')} children={<div>
                            {mode}
                            {menus.map((m, i) => {
                                return (
                                    <Nav2 title={m.title} disabled={m.disabled} key={i} className={!m.action || m.disabled ? (m.disabled ? 'disabled' : '') : 'clickable'} onClick={_ => {if (!!m.action && !m.disabled) m.action();}} pullRight style={{ marginRight:20 }} >
                                        {m.text}
                                    </Nav2>);
                            })}
                        </div>} />
                    </Switch>
                </BNavbar.Collapse>
            </BNavbar>
            <IconSheet />
        </div>
    );
};