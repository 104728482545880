/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import './HelpPage.scss';
import { getHelpData, ComponentInfo, ComponentInfoProperty } from './HelpData';
import { ScriptHelperMethodInfo, ScriptHelperMethodParam, scriptHelperMethods } from './Components/SrtComponent/ScriptRunnerHelper';

const ComponentDescription: React.FC<{ ci: ComponentInfo }> = ({ ci }) => {
  return (<div className="help-page">
        <h4>{ci.toolboxName}{ci.name != ci.toolboxName ? ' (specification name: ' + ci.name + ')' : ''}</h4>
        {ci.description != null ? <p>{ci.description}</p> : null}
        <table>
        <tbody>
            <tr key={0}><th>Property</th><th>Description</th></tr>
            {renderComponentProperties(ci.properties).map((c, i) => <tr key={i + 1}>
                <td>{c.propertyName}</td>
                <td>{c.description}</td>                
            </tr>)}
        </tbody>
        </table>
        </div>);
};

const MethodDescription: React.FC<{ method: ScriptHelperMethodInfo }> = ({ method }) => {
  return <div className="help-page">
        <h4>{method.name} {method.alias != null ? <span>(alias: {method.alias.join(', ')})</span> : null}</h4>
        <p>{method.description}</p>
        {method.parameters != null && method.parameters.length > 0
          ? <table>
                <tbody>
                    <tr key={0}><th>Parameter</th><th>Description</th></tr>
                    {method.parameters.map((p, i) => <tr key={i + 1}>
                        <td>{p.name}</td>
                        <td>{p.description}</td>
                    </tr>)}
                </tbody>
            </table>
          : null
        }
    </div>;
};

const helpData = getHelpData();

export const HelpPage: React.FC = () => {
  const isProdMode = process.env.NODE_ENV === 'production';
  const engLink = 'https://training.sectra.com/course/view.php?id=1562';
  const sweLink = 'https://training.sectra.com/course/view.php?id=1566&lang=sv';
  return <div>
        <h3>Introduction videos</h3>
        <p>To help you get started, there are some instruction videos available
          in <a href={engLink}>English</a> and <a href={sweLink}>Swedish</a>.</p>
        <h3>Users Guide</h3>
        <p>Right click and select open in new tab/window. <a href="/help/usersguide" target="_blank">Users Guide</a></p>
        <h3>Available script commands</h3>
        {!isProdMode ? <a href="#" onClick={(e) => {
          downloadScriptMarkdown();
          e.preventDefault(); 
          return false;
        }}>Download the following section as a Markdown document</a> : null}
        <p>This section describes the available script helper commands (available in expressions and scripts).</p>
        { scriptHelperMethods
          .filter(m => m.description != null)
          .map((m, i) => <MethodDescription method={m} key={i} />)}
        
        <h3>Available components</h3>
        {!isProdMode ? <a href="#" onClick={(e) => {
          downloadComponentMarkdown(e.ctrlKey ? 'detailed' : 'simple');
          e.preventDefault(); 
          return false;
        }}>Download the following section as a Markdown document</a> : null}
        <p>This section describes the properties that can be set for each component.
            Required arguments are marked with an asterisk: <span className="required-mark">*</span>.</p>
        {helpData
          .map((ci, i) => <ComponentDescription ci={ci} key={i} />)}
    </div>;
};

interface ComponentHelpInfo {
  propertyName: JSX.Element;
  description: JSX.Element;
}

function renderComponentProperties(cip: ComponentInfoProperty[]): ComponentHelpInfo[] {
  return cip.map((p, i) => {
    const description: JSX.Element[] = [];
    if (p.type.length > 0) {
      description.push(<div key={i + '_type'} className="property-type">Type: <span>{p.type.join(', ')}</span></div>);
    } 
    if (p.description != null) {
      description.push(<div key={i + '_description'} className="description-text">{p.description}</div>);
    }
    if (p.valueOptions.length > 0) {
      description.push(
                    <div className="allowed-values" key={i + '_set_values'}>
                        Allowed values are:<br/>
                        {p.valueOptions.map((v, ix) => 
                            <span key={ix}>
                                {v.name}
                                {v.description != null ? <> - <span className="value-description">{v.description}</span></> : null}
                            </span>)
                        }
                    </div>);
    }

    if (p.arrayItem.length > 0) {
      description.push(
                    <div className="array-type" key={i + '_array_type'}>
                        <span>{p.arrayItem.length > 1 ? 'Array item types (any of the following ' + p.arrayItem.length + ' types):' : 'Array item type:'}</span>
                        <ul>
                        {renderComponentProperties(p.arrayItem).map((ci, ix) => <li key={ix}>{ci.description}</li>)}
                        </ul>
                    </div>);
    }

    if (p.objProps.length > 0) {
      description.push(
                    <div className="obj-props" key={i + '_obj_props'}>
                        <span>Object properties:</span>
                        <ul>
                        {renderComponentProperties(p.objProps).map((ci, ix) => <li key={ix}><span>{ci.propertyName}</span> {ci.description}</li>)}
                        </ul>
                    </div>);
    }
            
    let ret : ComponentHelpInfo = {
      propertyName: <>{p.name}{p.required ? <span className="required-mark">*</span> : null}</>,
      description: <>{description}</>,
    };

    return ret;
  });
}

function getMarkdownComponentProperties(cip: ComponentInfoProperty[]): string[] {
  const ret: string[] = [];

  cip.forEach(p => {
    if (p.name != null && p.name.length > 0) {
      ret.push('- *Name:* **' + markdownEscape(p.name) + '**' + (p.required ? ' (\\*)' : '') + `, *type:* ${p.type.join(', ')}` + '\n');
    } else {
      ret.push(`- *Type:* ${p.type.join(', ')}` + '\n');
    }

    if (p.description != null) {
      ret.push(markdownEscape(p.description).split('\n').map(l => '  ' + l).join('\n') + '\n');
    }

    if (p.valueOptions.length > 0) {
      ret.push('  *Allowed values are:*\n');
      ret.push(... p.valueOptions
        .map(v => '  - ' + v.name + (v.description != null ? ` *- ${markdownEscape(v.description)}*` : '')));
      ret.push('');
    }

    if (p.arrayItem.length > 0) {
      ret.push('  ' + (p.arrayItem.length > 1
        ? '*Array item types (any of the following ' + p.arrayItem.length + ' types)*'
        : '*Array item type*') + '\n');

      // add with indent
      ret.push(...getMarkdownComponentProperties(p.arrayItem).map(line => '  ' + line));
    }

    if (p.objProps.length > 0) {
      ret.push('  *Object properties:*\n');
      // add with indent 
      ret.push(...getMarkdownComponentProperties(p.objProps).map(line => '  ' + line));
    }
  });

  return ret;
}

function markdownEscape(content: string): string {
  if (content == null) return '';
  let escape = String(content).trim()
    .replace(/\[/g, '\\[')
    .replace(/\]/g, '\\]')
    .replace(/\*/g, '\\*')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  return escape;
}

function getSimpleMarkdownComponentProperties(cip: ComponentInfoProperty[]): string[] {
  const ret: string[] = [];

  cip.forEach(p => {
    if (p.name != null && p.name.length > 0) {
      ret.push('  - **' + markdownEscape(p.name) + '**' + (p.required ? ' (\\*)' : '') + (p.description != null ? `: ${markdownEscape(p.description)}` : ''));
    }
  });

  return ret;
}

function downloadComponentMarkdown(type: 'detailed' | 'simple', headerLevelPrefix: '' | '#' | '##' | '###' = '#') {
  const content = helpData
    .map(ci => `${headerLevelPrefix}# ${ci.toolboxName}${ci.name != ci.toolboxName ? ' (specification name: ' + ci.name + ')' : ''}\n`
                + (ci.description != null ? markdownEscape(ci.description) + '\n' : '')
                + '\n'
                + headerLevelPrefix + '## Properties\n' 
                + (type === 'detailed' ? getMarkdownComponentProperties(ci.properties) : getSimpleMarkdownComponentProperties(ci.properties)).join('\n'))
    .join('\n\n');

  fileDownloadText('available-components.md', content);
}

function getSimpleMarkdownScriptParameters(params: ScriptHelperMethodParam[]): string[] {
  const ret: string[] = [];

  params.forEach(p => {
    ret.push('  - **' + markdownEscape(p.name) + '**' + (p.description != null ? `: ${markdownEscape(p.description)}` : ''));
  });

  return ret;
}

function downloadScriptMarkdown(headerLevelPrefix: '' | '#' | '##' | '###' = '#') {
  const content = scriptHelperMethods
    .filter(m => m.description != null)
    .map(m => `${headerLevelPrefix}# ${m.name}${m.alias != null && m.alias.length > 0 ? ' (alias: ' + m.alias.join(', ') + ')' : ''}\n`
            + (m.description != null ? markdownEscape(m.description) + '\n' : '')
            + (m.parameters != null && m.parameters.length > 0
              ? '\n' + headerLevelPrefix + '## Parameters\n' + getSimpleMarkdownScriptParameters(m.parameters).join('\n')
              : ''),
    )
    .join('\n\n');

  fileDownloadText('available-script-commands.md', content);
}

function fileDownloadText(filename: string, text: string) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}