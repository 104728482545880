import { DefaultValues } from "../DefaultValues";
import Yaml from 'js-yaml';
import { TemplateSpec } from "../Components/BasicTypes";
import { SectraDropdown } from "../Components/SrtComponent/SectraDropdown";
import { SectraDate, Text } from "../Components/SrtComponent/SectraInput";
import { Row } from "../Components/SrtComponent/SectraRow";
import { Header, HR } from "../Components/SrtComponent/SectraStructure";
import { Number } from "../Components/SrtComponent/SectraInputNumber";
import { ListSection } from "../Components/SrtComponent/SectraListSection";
import { TextArea } from "../Components/SrtComponent/TextArea";
import { Options } from "../Components/SrtComponent/SectraOptions";

interface ListItem {
    value: string;
    label: string;
    validation?: Validation;
}

interface Range {
  min: number;
}

interface Validation {
  range: Range;
}

interface Input {
    list?: ListItem[];
    suffix?: string;
    defaultValue?: any;
    validation?: Validation;
    type?: string;
}

interface Tree {
    id: string;
    localizedName: string;
    rmType: string;
    inContext?: boolean;
    min: number;
    max: number;
    children?: Tree[];
    inputs?: Input[];
}

interface ParsedEhrTemplate {
    templateId: string;
    version: string;
    defaultLanguage: string;
    tree: Tree;
}

interface SectraRow {
    label: string;
    components: any[];
}

interface SectraList {
    id: string;
    itemName: string;
    addButtonText: string;
    minCount: number;
    maxCount?: number | null;
    components: any[];
    numItems: number;
    collapseSummary?: string;
}

interface EhrOutputComponent {
    id: string;
    oEhrId: string;
}

interface DropdownOption {
    text: string;
    value: string;
}

interface ItemValue {
    id: string;
    oEhrId: string;
    options?: (DropdownOption|string)[];
    placeholder?: string;
    display?: boolean;
    value?: string | number;
    minValue?: number;
    maxValue?: number;
}

interface Item {
    [component: string]: ItemValue;
}

export function parseEhrJson(jsonStr: string): string {
    const ehrJson = JSON.parse(jsonStr) as ParsedEhrTemplate;
    const templateSpec = Yaml.load(DefaultValues.emptyYaml) as TemplateSpec;
    templateSpec.ReportOutput = [{ text: "{GetOEhrDefaultOutput()}" }];
    templateSpec.Components = [];
    templateSpec.Metadata.title = ehrJson.templateId;

    const splittedVersion = ehrJson.version.split(".");
    templateSpec.Metadata.versionMajor = parseInt(splittedVersion[0]);
    templateSpec.Metadata.versionMinor = parseInt(splittedVersion?.[1] ?? "0");
    templateSpec.Metadata.lang = ehrJson.defaultLanguage;

    const ehrOutputComponents: EhrOutputComponent[] = [];
    parseComponents(ehrJson.tree, templateSpec, 2, "", ehrOutputComponents);

    templateSpec.Components.push({ [TextArea.key]: { id: "OEhrOutput", hidden: true, value: "=GetOEhrOutput()" } });

    const newYaml = Yaml.dump(templateSpec, {lineWidth: 300});
    return newYaml;
}

function addListOrRow(list: SectraList | null, row: SectraRow, parsedYaml: TemplateSpec) {
    if (list !== null) {
        list.collapseSummary = "";
        for (let comp of row.components) {
            for (let el of Object.values(comp) as any) {
                list.collapseSummary += "{" + el.id + "} ";
                // Each component should only have one key e.g. Number or Text etc.
                break;
            }
        }
        list.components.push({ [Row.key]: row });
        parsedYaml.Components.push({ [ListSection.key]: list });
    } else {
        parsedYaml.Components.push({ [Row.key]: row });
    }
}

function santizeId(id: string) {
    return id.replace("-", "_");
}

function replaceNonAscii(id: string) {
    return id
        .replace(/å/g, "aa")
        .replace(/ä/g, "ae")
        .replace(/ö/g, "oe")
        .replace(/Å/g, "Aa")
        .replace(/Ä/g, "Ae")
        .replace(/Ö/g, "Oe");
}

function parseComponents(tree: Tree, parsedYaml: TemplateSpec, headerLevel: number, oEhrId: string, ehrOutputComponents: EhrOutputComponent[]) {
    oEhrId = oEhrId === "" ? tree.id : oEhrId + "/" + tree.id;
    tree.id = santizeId(tree.id);
    if (oEhrId === "macro/category") {
        return;
    }
    tree.id = replaceNonAscii(tree.id);
    let list: SectraList | null = null;
    let row: SectraRow = { label: tree.localizedName ?? tree.id, components: [] }
    if (tree.max === -1 || tree.max > tree.min && tree.max > 1) {
        // This is a really ugly way of keeping track of identifiers used, we should build a lookup instead
        const yamlText = Yaml.dump(parsedYaml);
        let listId = tree.id + "_list";
        if (yamlText.indexOf("id: " + listId) !== -1) {
            let nr = 0;
            while (yamlText.indexOf("id: " + listId + nr) !== -1) {
                nr++;
            }
            listId = listId + nr;
        }

        list = {
            id: listId,
            itemName: tree.localizedName + " {index}",
            addButtonText: "Add new entry",
            minCount: tree.min,
            maxCount: tree.max === -1 ? null : tree.max,
            components: [],
            numItems: tree.min <= 1 && (tree.max > 1 || tree.max === -1) ? 1 : tree.min,
        };
    }

    // Current element is a leaf, handle input
    if (tree?.inputs?.length === 1) {
        handleInput(tree, tree.inputs[0], parsedYaml, oEhrId, row, list, ehrOutputComponents);
        addListOrRow(list, row, parsedYaml);
    } else if ((tree.inputs?.length ?? 0) > 1) {
        for (let input of tree.inputs ?? []) {
            handleInput(tree, input, parsedYaml, oEhrId, row, list, ehrOutputComponents);
        }
        addListOrRow(list, row, parsedYaml);
    } else if (!tree?.inputs && !tree?.children) {
        handleInput(tree, {}, parsedYaml, oEhrId, row, list, ehrOutputComponents);
        addListOrRow(list, row, parsedYaml);
    }

    // Keep recursing down
    else if (tree.children) {
        if (tree.rmType === "ELEMENT") {
            /* Not sure about this, but if we are at an element only read the first child. Reason for this is that volume got a second children with an interval
              that seems to be the upper and lower limit of the volume. However no values are given so we would treat this as a new input field which is not available in
              screenshots from the template.
            */
            parseComponents(tree.children[0], parsedYaml, headerLevel, oEhrId, ehrOutputComponents);
        } else {
            if (tree.rmType === "CLUSTER") {
                parsedYaml.Components.push({ [Header.key]: { header: tree.localizedName, level: "h" + headerLevel } })
                if (headerLevel < 4) {
                    headerLevel++;
                }
            }
            for (let child of tree.children) {
                parseComponents(child, parsedYaml, headerLevel, oEhrId, ehrOutputComponents);
            }
            if (tree.rmType === "CLUSTER") {
                const prevItem = parsedYaml.Components[parsedYaml.Components.length-1];
                const isHR = prevItem[HR.key] != null;

                if (!isHR) {
                    parsedYaml.Components.push({ [HR.key]: {} });
                }
            }
        }
    }
}

function handleInput(tree: Tree, input: Input, parsedYaml: TemplateSpec, oEhrId: string, row: SectraRow, list: SectraList | null, ehrOutputComponents: EhrOutputComponent[]) {
    let id = input.suffix ? tree.id + "_" + input.suffix : tree.id;
    // Special handling, remove category and encoding since they produce errors...
    if (tree.id === "category" || tree.id === "encoding") {
        return;
    }
    // Special handling for code phrases that are directly under tree.
    if (!input.suffix && !input.list && tree.rmType === "CODE_PHRASE" || tree.id === "category") {
        let codePhraseId = oEhrId + "|code";
        let termId = oEhrId + "|terminology";
        let newTree = { ...tree, id: id + "code", rmType: "CODE_PHRASE_HANDLED" };
        handleInput(newTree, input, parsedYaml, codePhraseId, row, list, ehrOutputComponents);
        newTree.id = id + "term";
        handleInput(newTree, input, parsedYaml, termId, row, list, ehrOutputComponents);
        if (tree.id === "category") {
            newTree.id = id + "value";
            handleInput(newTree, input, parsedYaml, oEhrId + "|value", row, list, ehrOutputComponents);
        }
        return;
    }
    id = santizeId(id);

    // This is a really ugly way of keeping track of identifiers used, we should build a lookup instead
    let yamlText = Yaml.dump(parsedYaml);
    if (yamlText.indexOf("id: " + id) !== -1) {
        let nr = 0;
        while (yamlText.indexOf("id: " + id + nr) !== -1) {
            nr++;
        }
        id = id + nr;
    }
    
    oEhrId = input.suffix ? oEhrId + "|" + input.suffix : oEhrId;
    const itemValue: ItemValue = input.list 
        ? { 
            id: id, 
            options: input.list.map(x => (x.label !== x.value ? { text: x.label, value: x.value } : x.value)), 
            oEhrId: oEhrId,
        } 
        : { 
            id: id, 
            oEhrId: oEhrId
        };
    const isNumeric = tree.rmType === "DV_QUANTITY" || tree.rmType === "DV_PROPORTION" || tree.rmType === "DV_COUNT";
    const isDropdown = input.list && (input.list.length > 3 || input.list.map(x => x.label).join().length > 32);
    if (isNumeric && (input?.type === "DECIMAL" || input?.type === "INTEGER") && (tree?.inputs?.find(x => x.validation) || tree?.inputs?.find(x => x?.list?.find(y => y.validation)))) {
      // For now just take the first input with a min value.
      let validation = tree?.inputs?.find(x => x.validation)?.validation ?? tree?.inputs?.find(x => x?.list?.find(y => y.validation))?.list?.find(x => x.validation)?.validation;
      if (validation) {
        itemValue.minValue = validation.range.min;
      }
    }
    if (input.defaultValue) {
      itemValue.value = input.defaultValue;
    }
    if (isDropdown) {
      itemValue.placeholder = "Välj...";
    }

    if (tree.inContext) {
        itemValue.display = false;
    }

    setValueOfStdInputs(itemValue);
    // For now, set all dates as now as default
    if (tree.id === "datum_tid_ankomst_till_labb") {
      itemValue.value = (new Date()).toISOString();
    }

    const inputType = input.list
        ? isDropdown ? SectraDropdown.key : Options.key
        : isNumeric
            ? Number.key : Text.key;

    const item: Item = { [inputType]: itemValue };
    
    addItem(item, row, list);

    if (tree.rmType === "DV_PROPORTION" && input.suffix === "denominator") {
        const proportionType = { 
            [Number.key]: { 
                id: id + "type", 
                hidden: true, 
                value: 4,
                oEhrId: oEhrId.substring(0, oEhrId.indexOf("|")) + "|type", 
            } 
        };

        addItem(proportionType, row, list);
    }

    ehrOutputComponents.push({ id: itemValue.id, oEhrId: itemValue.oEhrId });
}

function addItem(item: any, row: SectraRow, list: SectraList | null) {
    if (list !== null) {
        if (list.maxCount === null) {
            delete list.maxCount;
        }
    }
    row.components.push(item);
}

function setValueOfStdInputs(itemValue: ItemValue) {
    const ehrAttribute = itemValue.oEhrId.split("/").pop();
    switch(ehrAttribute) {
        case "language|code":
            itemValue.value = "sv";
            break;
        case "language|terminology":
            itemValue.value = "ISO_639-1"
            break;
        case "territory|code":
            itemValue.value = "SE";
            break;
        case "territory|terminology":
            itemValue.value = "ISO_3166-1";
            break;
        case "composer|id":
        case "composer|id_scheme":
        case "composer|id_namespace":
            itemValue.value = "sectra";
            break;
        case "composer|name":
            itemValue.value = "Sectra";
            break;
        case "category|code":
        case "category|value":
            itemValue.value = "422";
            break;
        case "category|terminology":
            itemValue.value = "openehr";
            break;
        case "subject|id":
            itemValue.value = "subId";
            break;
        case "subject|id_scheme":
            itemValue.value = "subScheme";
            break;
        case "subject|id_namespace":
            itemValue.value = "subNamespace";
            break;
        case "subject|name":
            itemValue.value = "subName";
            break;
        case "encoding|code":
            itemValue.value = "code";
            break;
        case "encoding|terminology":
            itemValue.value = "test";
            break;
    }
}
