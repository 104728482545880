/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useReducer, useState, useRef, useContext } from 'react';
import './TemplateAdmin.scss';
import { Toolbox } from '../Components/Toolbox/Toolbox';
import { Editor, editorManipulation } from '../Components/Editor/Editor';
import { Viewer } from '../Components/Viewer/Viewer';
import { Tabs, Tab } from 'react-bootstrap';
import { SrtGenTemplate, SrtTemplateDtoExtended } from '../ServerCommunication';
import { ScriptsEditor } from '../Components/Editor/ScriptsEditor';
import { ParseTemplateSpec, getEmptyDefaultParsedSpec } from '../Components/SrtTemplateSpec';
import { ParseYaml, YamlParseError } from '../YamlToJson';
import { DefaultValues } from '../DefaultValues';
import { applogger } from '../applogger';
import { ParsedComponent, ParsedTemplateSpec, ParseError, TemplateContext, TemplateSpec } from '../Components/BasicTypes';
import { generateCompleteScript } from '../Components/SrtComponent/ScriptRunnerHelper';
import { initalizeTemplateContext } from '../Components/TemplateContextHelper';
import { CommunicationContext, CommunicationContextType } from './CommunicationContext';
import { ISetSuggestedTemplate } from './IInterFrameCom';

interface TemplateAdminState {
  templateId: string;
  yaml: string;
  parsed: ParsedTemplateSpec | null;
  // list of previous contents, to enable undo functionality
  undoState: Array<string>;
}

interface SetContentAction {
  type: 'setContent',
  newContent: string;
}

interface LoadContentAction {
  type: 'loadContent'
  loadData: SrtGenTemplate;
}

interface SetGuidAction {
  type: 'setGuid';
  guid: string;
}

interface UndoContentAction {
  type: 'undoContent'
}

type TAReducerFunctionAction = SetContentAction | UndoContentAction | LoadContentAction | SetGuidAction;

interface TemplateAdminProps {
  suggestedTemplate?: ISetSuggestedTemplate;
}

const TemplateAdmin: React.FC<TemplateAdminProps> = (props) => {
  const communicationContext = useContext(CommunicationContext) as CommunicationContextType;

  const TAReducerFunction = (prevState: TemplateAdminState, action: TAReducerFunctionAction): TemplateAdminState => {
    const t0 = performance != null ? performance.now() : null;

    //applogger.debug("starting reducer", action);
    let ret: TemplateAdminState = { yaml: '', undoState: [], parsed: null, templateId: prevState.templateId };
    switch (action.type) {
      case 'setGuid':
        ret = { ...prevState, templateId: action.guid };
        break;
      case 'undoContent':
        if (prevState.undoState && prevState.undoState.length > 0) {
          let newUndoState = [...prevState.undoState];
          let newContent = newUndoState.pop();
          applogger.debug('undo - going from content length ' + prevState.yaml.length + ' to ' + newContent?.length);
          ret.yaml = newContent ?? '';
          ret.undoState = newUndoState;
          ret.parsed = parseTemplate(ret.yaml);
          communicationContext.setUnsaved(true);
        } else {
          applogger.info('Was no previous state when trying undo');
          ret = prevState;
        }
        break;
      case 'setContent':
        if (ret.yaml == prevState.yaml) {
          applogger.debug('Was same content, ignoring state change');
          ret = prevState;
        } else {
          // Add the new content to the undo list
          let newUndoList = [...prevState.undoState, prevState.yaml];
          if (newUndoList.length > 10) {
            newUndoList.shift();
          }
          ret.yaml = action.newContent;
          ret.undoState = newUndoList;
          ret.parsed = parseTemplate(ret.yaml);
          communicationContext.setUnsaved(true);
          //applogger.debug("setContent with newVal", action, " did set undo content length to ", ret.undoState.length);
        }
        break;
      case 'loadContent':
        ret.templateId = action.loadData.Id;
        ret.yaml = action.loadData.Template?.Yaml ?? '';
        ret.undoState = [];
        ret.parsed = parseTemplate(ret.yaml);
        communicationContext.setUnsaved(false);
        break;
      default:
        throw 'Reducer came to default action';
    }

    if (t0 != null) {
      applogger.info(`${performance.now() - t0} - ms elapsed running the reducer (includes time for YAML parse and building component structure)`);
    }

    // applogger.debug('Reducer was called and returned ', ret, prevState)
    fullScriptCode.current = generateCompleteScript(templateScriptTabCode, ret.parsed);
    templateContext.current = getTemplateContext(ret.parsed); // TODO templateContext is a state variable and should be included in the state
        
    return ret;
  };

  // Intruduce state variables and setters for them
  // useState returns a pair, a state variable called content and a function to set it, taking string as default state val is of type string
  const [templateScriptTabCode, setTemplateScriptTabCodeInternal] = useState<string>('');
  const [templateFolderId, setTemplateFolderId] = useState(communicationContext.folderId);
    
  const fullScriptCode = useRef<string>('');
  let lastYaml: string | null = null;
  let lastParse: ParsedTemplateSpec | null = null;
  const templateContext = useRef<TemplateContext | null>(null);
    
  // TODO - the complete flow for how code is "inserted" and executed seems rather obscure. Refactoring would be good.
  // This is called when the reducer for the appstate runs (which it does not on normal code change)
  // then the fullcode is used here (which was set when code was edited)
  // now we get a new templateContext which have a runtime where we somehow have changed the "scriptRunnerGetter"
  function getTemplateContext(newSpec?: ParsedTemplateSpec | null): TemplateContext | null {
    const spec = newSpec ?? templateContext.current?.templateSpec;

    return spec != null 
      ? initalizeTemplateContext(spec, { fullCode: fullScriptCode.current, prevContext: templateContext.current }) 
      : templateContext.current;
  }

  function getValidationContext() {
    // static ref to providerNames, thus needs to be a reference, i.e. RefState usage        
    return { 
      dataSources: communicationContext.providerNames.map(n => ({
        name: n, 
        description: undefined, /* TODO: Add descriptions of the providers? */
      })), 
    };
  }

  useEffect(()=>{
    return () => {
      // Unloading template admin
      if (templateContext.current?.componentStore.unload) {
        applogger.debug('Issuing an component state store unload');
        templateContext.current.componentStore.unload();
      }
    };
  }, []);

  useEffect(() => {
    if (!props.suggestedTemplate) return;
    if (!props.suggestedTemplate.templateId || props.suggestedTemplate.templateId == 'new' || props.suggestedTemplate.templateId == 'demo') {
      // Was new template
      let guid = newGuid();
      setState({ type: 'setGuid', guid: guid });
    } else if (props.suggestedTemplate.fromFile && props.suggestedTemplate.template != null) {
      loadTemplate(props.suggestedTemplate.template, true);
    } else if (props.suggestedTemplate.template != null) {
      loadTemplate(props.suggestedTemplate.template, false, props.suggestedTemplate.clearComponentData);
    }

    // Set script default
    if (props.suggestedTemplate?.templateId === 'new') {
      setTemplateScriptTabCode(DefaultValues.emptyScript);
    } else if (props.suggestedTemplate?.templateId === 'demo') {
      setTemplateScriptTabCode(DefaultValues.demoScript);
    }
  }, [props.suggestedTemplate, props.suggestedTemplate?.templateId, props.suggestedTemplate?.templateVersion]);

  function setTemplateScriptTabCode(code: string) {
    fullScriptCode.current = generateCompleteScript(code, state.parsed);
    templateContext.current = getTemplateContext();
    setTemplateScriptTabCodeInternal(code);
  }

  // nice dev feutures
  window.clearState = () => {
    templateContext.current?.componentStore.clearState(true);
  };

  // state.content is the YAML code
  // useReducer gives a state variable and a setter function as well, but the function is given more arguments and the setting is done in the provided function
  const [state, setState] = useReducer(TAReducerFunction,
    props.suggestedTemplate?.templateId === 'demo' ? DefaultValues.demoYaml : (props.suggestedTemplate?.templateId === 'new' ? DefaultValues.emptyYaml : ''),
    (yaml: string) => ({ yaml, parsed: yaml !== '' ? parseTemplate(yaml) : null, undoState: [], templateId: newGuid(), templateName: '' } as TemplateAdminState));

  // update when yaml spec or script has changed
  useEffect(() => {
    if (props.suggestedTemplate == null) {
      return;
    }
    //applogger.debug("start TA YAML/script change measurement");
    var t0 = performance != null ? performance.now() : null;

    // report back on new values in state.yaml or code so that it can be accessible when pressing save/export
    const currentSrtTemplateObject = getCurrentAsSrtGenTemplateObject(props.suggestedTemplate.templateVersion);
    if (currentSrtTemplateObject != null) {
      communicationContext.setNewSrtGenTemplateObject(currentSrtTemplateObject);
    }

    if (t0 != null) applogger.debug(`${performance.now() - t0} - ms elapsed while reacting to YAML/script change in TA`);
  }, [state.yaml, templateScriptTabCode, props.suggestedTemplate, props.suggestedTemplate?.templateVersion]);

  // start clock on TA render
  //applogger.debug("start TA render measurement");
  const startTime = performance != null ? performance.now() : null;
  useEffect(() => {
    if (startTime != null) applogger.debug(`${performance.now() - startTime} - ms elapsed while rendering TA`);
  });


  const [activeTab, setActiveTab] = useState<string>('Yaml');

  useEffect(() => {
    setTemplateFolderId(communicationContext.folderId);
  }, [communicationContext.folderId]);

  function undo() {
    applogger.debug('trying undo');
    setState({ type: 'undoContent' });
  }

  function setContent(s: string) {
    setState({ type: 'setContent', newContent: s });
  }

  function getCurrentAsSrtGenTemplateObject(templateVersion: number | undefined) {
    if (!state.parsed?.Metadata) {
      return null;
    }

    let templateDto: SrtTemplateDtoExtended = {
      JsCode: templateScriptTabCode,
      Yaml: state.yaml,
      dataProviders: state.parsed?.DataSources ?? [],
      metadata: state.parsed?.Metadata,
      JsonSpec: JSON.stringify(state.parsed.SourceSpec),
    };

    let ret: SrtGenTemplate = {
      Id: state.templateId,
      FolderId: templateFolderId,
      Name: state.parsed?.SourceSpec?.Metadata?.title,
      Description: state.parsed?.SourceSpec?.Metadata?.description,
      Created: new Date(),
      CreatedBy: window.SRTBSettings?.User ?? 'Unknown user',
      Deleted: false,
      Template: templateDto,
      Version: templateVersion,
      SpecVersion: (state.parsed?.SourceSpec?.Metadata?.versionMajor ?? 0) + '.'
                + (state.parsed?.SourceSpec?.Metadata?.versionMinor ?? 1),
    };

    return ret;
  }

  useEffect(() => {
    
  }, [props.suggestedTemplate]);

  function loadTemplate(template: SrtGenTemplate, fromFile: boolean, clearComponentData?: boolean) {
    if (fromFile) {
      template.Id = newGuid(); // if save to server, dont accidentally overwrite server data
    }

    applogger.debug('loading template');
    if (clearComponentData !== false && templateContext.current != null) {
      applogger.info('clearing (all) component data');
      templateContext.current.componentStore.clearState(false);
      templateContext.current.runtime.clearRuntimeComponents();
    }
        
    setTemplateScriptTabCode(template.Template?.JsCode ?? '');
    template.FolderId = communicationContext.folderId;
    setState({ loadData: template, type: 'loadContent' });
  }

  let [codeBehind, setCodeBehind] = useState('');

  function parseTemplate(yaml: string)  {
    if (yaml === lastYaml) {
      applogger.debug('re-parse, reuse last parse');
      return lastParse;
    }

    applogger.debug('parsing new YAML');
    const yamlParseResult = ParseYaml(yaml, getValidationContext());
    const parseResult = (yamlParseResult as YamlParseError)?.errors
      ? { ...(lastParse ?? getEmptyDefaultParsedSpec()), ParseErrors: (yamlParseResult as YamlParseError).errors }
      : ParseTemplateSpec(yamlParseResult as TemplateSpec, true);
    parseResult.DataSources = HandleInlineDataSources(parseResult.Root.children, parseResult.Metadata.explicitDataSources, parseResult.ParseErrors,
      communicationContext.providerNames, parseResult.Metadata.forwardProviders);
    // Needs to be set since this is what is saved in the DB (and then loaded for the packageing)
    parseResult.SourceSpec.DataSources = parseResult.DataSources;

    lastYaml = yaml;
    lastParse = parseResult;
    return parseResult;
  }

  return (
    <>
    {/* The main page - a toolbox of buttons, the yaml editor and the previews */}
    {props.suggestedTemplate != null ?
      <>
      <Toolbox onInsert={(s: string) => {
        if (!editorManipulation.insertCodeAtCursor(s)) {
          // Fallback to append
          setContent(state.yaml + '\r\n' + s);
        }
      }} onClear={() => setContent(DefaultValues.emptyYaml)} onUndo={() => undo()} disabled={activeTab !== 'Yaml'} appColorMode={communicationContext.appColorMode} />

      <Tabs animation={false} activeKey={activeTab} id="editors" className="editorTabs"
          onSelect={(tabName: any) => {
            setActiveTab(tabName);
            setCodeBehind(fullScriptCode.current);
          }}>

          <Tab eventKey="Yaml" title="Specification" >
              <Editor content={state.yaml} onChange={(s: string) => setContent(s)} appColorMode={communicationContext.appColorMode} getValidationContext={getValidationContext} />
          </Tab>
          <Tab eventKey="Script" title="Script">
              <ScriptsEditor
                  onChange={(val: string) => {
                    setTemplateScriptTabCode(val);
                    communicationContext.setUnsaved(true);
                  }}
                  content={templateScriptTabCode}
                  appColorMode={communicationContext.appColorMode}
              />
          </Tab>
          <Tab eventKey="GeneratedCode" title="Generated Code" >
              <pre className={'generated-code'}>{codeBehind}</pre>
          </Tab>
          <Tab eventKey="Json" title="Json Specification">
              <pre className={'generated-code'}>{state.parsed != null ? JSON.stringify(state.parsed.SourceSpec, null, 2) : ''}</pre>
          </Tab>
      </Tabs>
      <Viewer templateContext={templateContext.current} // TODO templateContext is a state variable and should be included in the state
          templateId={props.suggestedTemplate.templateId}
          templateVersion={props.suggestedTemplate.templateVersion}
          reportVersion={(state?.parsed?.Metadata?.reportVersion ?? 'v1') === 'v2' ? 2 : 1}
          templateFields={state?.parsed?.TemplateFields ?? []}
          fullScriptCode={fullScriptCode.current}
          parseErrors={state.parsed?.ParseErrors}
          yaml={state.yaml}
          focusSpec={() => {
            if (activeTab !== 'Yaml') {
              setActiveTab('Yaml');
            }
          }} />
          </>
      : null }
    </>
  );
};

export default TemplateAdmin;

function newGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0,
      // eslint-disable-next-line
            v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function HandleInlineDataSources(children: ParsedComponent<any>[], explicitDataSources: string[], errorList: ParseError[], availableProviders: string[],
  forwardingProviders: string[]): string[] {
  // build lookup over defined data providers
  const dataProviderSet: { [key:string]:boolean } = {};
  function recursive(currentChildren: ParsedComponent<any>[]){
    currentChildren.forEach(c => {
      const provider: string | undefined = c.args?.dataarg;
      if (provider) {
        const providerName = provider.split('.')[0];
        if (availableProviders.indexOf(providerName) !== -1) {
          dataProviderSet[providerName] = true;
        } else if (forwardingProviders && forwardingProviders.indexOf(providerName) !== -1) {
          // skip forwarding providers
        } else {
          // provider used was not any of build in types nor of a specified type
          errorList.push({ error: "Undefined provider '" + providerName + "'", propName: 'dataarg', ch: undefined, componentId: c.id, path: c.componentPath });
        }
      }
      recursive(c.children);
    });
  }
  recursive(children);

  if (explicitDataSources) {
    explicitDataSources.forEach(providerName => {
      if (availableProviders.indexOf(providerName) === -1) {
        errorList.push({ error: "Undefined provider '" + providerName, propName: 'explicitDataSources' });
      } else {
        dataProviderSet[providerName] = true;
      }
    });
  }

  const providers: string[] = [];
  for (const provider in dataProviderSet) {
    providers.push(provider);
  }
  return providers;
}
