/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { editorManipulation } from './Components/Editor/Editor';
import { ParseError, IRuntimeError, TemplateContext } from './Components/BasicTypes';

export interface ErrorHandlerProps {
  parseErrors: ParseError[];
  templateContext: TemplateContext | null;
}

let runtimeErrors: IRuntimeError[] = [];

export const ErrorHandler: React.FC<ErrorHandlerProps> = (props) => {
  // handle runtime errors
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_, setRuntimeErrors] = useState([] as IRuntimeError[]);

  window._addRuntimeError_ = (error: IRuntimeError) => {
    if (runtimeErrors.some(e => e.componentId == error.componentId && e.propName == error.propName && e.message == error.message)) {
      // already exist, break
      return false;
    }
    
    runtimeErrors = [...runtimeErrors, error];

    // async update state, "async" via timeout (with 0ms) to ensure we don't trigger re-render from other component render method
    setTimeout(() => {  setRuntimeErrors(runtimeErrors); }, 0);
    return true;
  };

  window._clearRuntimeError_ = () => {
    // async update state
    runtimeErrors = [];
    setTimeout(() => { setRuntimeErrors(runtimeErrors); }, 0);
    return true;
  };

  if ((props.parseErrors == null || props.parseErrors.length === 0) && runtimeErrors.length === 0) {
    return null;
  }

  let output: JSX.Element[] = [];
  if (props.parseErrors != null && props.parseErrors.length > 0) {
    output.push(
      <div key={'parseErrors'}>
        <ul>
          { props.parseErrors.map((e, i) => {
            let line: number | null = e.line ?? null;
            if (line == null && e.path != null) {
              line = editorManipulation.getLineForPath(e.path + (e.propName != null ? '.' + e.propName : ''));
            }

            let internal: JSX.Element;
            if (line != null) {
              const lineNumber = line;
              internal = <a href="#" onClick={(event)=>{
                editorManipulation.highlightLine(lineNumber);
                event.preventDefault();
              }}>YAML error: <span style={{ whiteSpace: 'pre-wrap' }}>{e.error}</span> at line: {line}</a>;
            } else {
              internal = <>YAML error: {e.error}{e.path != null && e.path.length > 0 ? ' at path ' + e.path : ''}</>;
            }
            return <li key={i}>{internal}</li>;
          })}
        </ul>
      </div>,
    );
  }

  if (runtimeErrors.length > 0) {
    output.push(
      <div key={'runtimeErrors'}>
        <ul>
          { runtimeErrors.map((e, i) => {
            const component = e.componentId != null ? props.templateContext?.runtime.getComponentById(e.componentId) : null;
            const line = component != null
              ? editorManipulation.getLineForPath(component.componentPath + (e.propName != null ? '.' + e.propName : ''))
              : null;

            let internal: JSX.Element;
            if (line != null) {
              const lineNumber = line;
              internal = <a href="#" onClick={(event)=>{
                editorManipulation.highlightLine(lineNumber);
                event.preventDefault();
              }}>Runtime error: {e.message} at line: {line}</a>;
            } else {
              internal = <>Runtime error: {e.message}{e.componentId != null ? `at: ${e.componentId}${e.propName != null ? '.' + e.propName : ''}` : ''}</>;
            }
            return <li key={i}>{internal}</li>;
          })}
        </ul>
      </div>,
    );
  }

  return <div className="error-list"><h2 key={0}>Errors</h2>{output}</div>;
};