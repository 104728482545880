/* eslint-disable @typescript-eslint/naming-convention */
import { Components } from './SrtComponent/Components';
import Ajv, { AdditionalPropertiesParams, EnumParams } from 'ajv';
import { languages } from './SrtComponent/Languages';
import { templateDefaultsSchema as templateDefaultsSchema } from './TemplateDefaults';
import { applogger } from '../applogger';
import { TemplateSpec, ParseError } from './BasicTypes';

const componentSchemas = Object.keys(Components).map(k => Components[k]).map(x => x.schema);
const langCodeList = languages.map(l => l.code);
const langCodeDesc = languages.map(l => l.name);

export interface IValidationContext {
  dataSources: IDataSourceInfo[]
}

export interface IDataSourceInfo {
  name: string;
  description?: string;
}

function getSchema(context: IValidationContext) {
  const explicitDataSourceDescription = 'The name of an explicit data source that needs to be included (note that data sources are automatically included when referred to using the dataarg property)';
  const explicitDataSources = {
    'type': 'array',
    'description': 'List of additional providers not referred to using dataarg properties which are needed for this form (script dependencies)',
    'items': context.dataSources.length > 0
      ? {
        'type': 'string',
        'enum': context.dataSources.map(s => s.name),
        'enumDescription': context.dataSources.map(s => s.description), 
        'description': explicitDataSourceDescription,
      }
      : { 
        'type': 'string',
        'description': explicitDataSourceDescription,
      },
  };

  return {
    '$schema': 'http://json-schema.org/draft-07/schema#',
    '$id': 'http://example.com/srt.builder.schema.json',
    'title': 'Sectra Forms specification schema',
    'type': 'object',
    'required': ['Metadata', 'Components', 'ReportOutput'],
    'additionalProperties': false,
    'properties': {
      'Metadata': {
        'type': 'object',
        'required': ['lang', 'title', 'description'],
        'properties': {
          'lang': { 'type': 'string', 'description': 'The form main language.', 'enum': langCodeList, 'enumDescription': langCodeDesc },
          'title': { 'type': 'string', 'description': 'Form name, i.e. the name of the form visible in most lists of forms' },
          'description': { 'type': 'string', 'description': 'Form description' },
          'versionMajor': { 'type': 'integer', 'description': 'Form major version' },
          'versionMinor': { 'type': 'integer', 'description': 'Form minor version' },
          'defaults': templateDefaultsSchema,
          'outputIdentifier': { 'type': 'string', 'description': "If defined, the report will output it's data as a forwarding provider, and thus enable other forms to use it" },
          'mandatoryFields': { 'type': 'string', 'enum': ['display', 'prohibit-only', 'no-display'], 'enumDescription': ['Display all empty mandatory fields at the bottom of the form (default)', 'Display only prohibitted fields (i.e. fields which cannot be skipped) at the bottom of the from', 'Do not display any mandatory fields'], 'description': 'Controlls the display of mandatory fields at the bottom of the form (default: display)' },
          'forwardProviders': {
            'type': 'array',
            'items': {
              'type': 'string',
              'description': 'The name of the forwarding data source as configured in that form (outputIdentifier)',
            },
            'description': 'Forwarding data sources as configured in their form (outputIdentifier)',
          },
          'reportVersion': { 'type': 'string', 'description': 'Report version interface', 'enum': ['v1', 'v2'], 'enumDescription': ['Supported in IDS7 versions >= 22.1, can not use tables', 'Supported in IDS7 versions >= 24.2, possible to use tables'] },
          'explicitDataSources': explicitDataSources,
        },
      },
      'ReportOutput': {
        'type': 'array',
        'items': {
          'type': 'object',
          'required': ['text'],
          'properties': {
            'text': { 'type': 'string', 'description': 'Text to go into the report. If multiple they will be appended.' },
            'if': { 'type': 'string', 'description': 'If the text should be included or not' },
            'separator': { 'type': 'string', 'description': 'The separator to have between this text item and the next. Defaults to newline' },
          },
        },
        // "minItems": 0,
        'description': 'The text to go to the report', 
      },
      'Components': {
        'type': 'array',
        'description': 'Any component.',
        'items': {
          '$ref' : 'srt.builder.component.json#/definitions/component',
        },
      },
    },
  };
}

const schemaDefinitions = {
  '$id': 'http://example.com/srt.builder.component.json',
  'definitions': {
    'component': { 'anyOf': componentSchemas },
  },
};

export function getSchemaInfo(context: IValidationContext): ISchema {
  return {
    Schema: getSchema(context), 
    Definitions: schemaDefinitions,
  };
}

export interface ISchema {
  Schema: any;
  Definitions: any;
}

export const validateSpec = (data: TemplateSpec, context: IValidationContext, errors?: ParseError[]) => {
  try {
    let validator = getValidator(context);
    let valid = validator(data);
    if (!valid) {
      if (errors != null && validator.errors != null) {
        let filter: { path: string, item: ParseError, keyword: RegExp }[] = [];
        let vErrors = validator.errors
          .sort((a, b) => a.dataPath.length < b.dataPath.length ? 1 : (a.dataPath.length > b.dataPath.length ? -1 : 0))
          .map(e => { 
            const path = e.dataPath;
            let item = { error: e.message ?? '', path: path, propName: e.propertyName, keyword: e.keyword };

            if (e.keyword === 'additionalProperties' && (e.params as AdditionalPropertiesParams).additionalProperty) {
              item.propName = (e.params as AdditionalPropertiesParams).additionalProperty;
              item.error = "Property '" + item.propName + "' is not allowed (" + item.error + ')';

              // Temporary special handling of data sources for backwards compatability
              if (item.propName == 'DataSources') {
                item.error = "\n'DataSources'-specification is no longer needed. All data sources referred to using ‘dataarg’-properties will automatically be included. Forwarding providers can be specified under the ‘Metadata’-section (forwardProviders), as well as data source that is explicitly needed because of usage from scripts alone (explicitDataSources). \n\nPlease also note that any external data sources first needs to be recorded as a known builder data provider in the data providers admin (add as an ‘External dataprovider’).\n";
              }

              filter.push({ path: path, item: item, keyword: /required|anyOf/ });
            } else if (e.keyword === 'enum') {
              item.error += ' (' + ((e.params as EnumParams).allowedValues ?? []).join(', ') + ')';
              filter.push({ path: path, item: item, keyword: /required|anyOf/ });
            } else if (e.keyword === 'type') {
              filter.push({ path: path, item: item, keyword: /required|anyOf/ });
            } else if (e.keyword === 'required' && !path.endsWith(']')) {
              filter.push({ path: path, item: item, keyword: /required|anyOf/ });
            } else if (e.keyword === 'required' && e.schemaPath === '#/anyOf/0/required') {
              item.error = 'Unrecognized reference';
              if (filter.every(i => i.path.length < path.length)) {
                filter.push({ path: path + ' ', item: item, keyword: /required|anyOf/ });
              }
            }

            return item;
          });

        if (filter.length) {
          filter.forEach(f => {
            vErrors = vErrors.filter(i => {
              return !(f.item !== i && (i.path.length < f.path.length || i.path.length == f.path.length && i.keyword === 'anyOf') && f.path.startsWith(i.path) && f.keyword.test(i.keyword));
            });
          });
        }

        // applogger.debug(validator.errors, filter);
        errors.push(...vErrors);
      }

      return false;
    }
  } catch (e) {
    if (errors != null) {
      errors.push({ error: `Unknown validation error: ${e}` });
    }

    applogger.error('Cannot validate spec', e);
    return false;
  }

  return true;
};

//
// Pre-compile the schema and reuse, saves around 40ms per validation
//
var _ajvValidator: Ajv.ValidateFunction;
var _ajvValidatorContextKey: string;

function getValidator(context: IValidationContext): Ajv.ValidateFunction {
  const contextKey = JSON.stringify(context);
  if (_ajvValidator && contextKey === _ajvValidatorContextKey) return _ajvValidator;

  applogger.debug('Compiling new schema validator');
  const ajv = new Ajv();
  ajv.addSchema(schemaDefinitions);
    
  _ajvValidator = ajv.compile(getSchema(context));
  _ajvValidatorContextKey = contextKey;
  return _ajvValidator;
}
