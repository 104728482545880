

const browserLangSeparator = (1.1).toLocaleString().substr(1, 1);
export function GetDecimalSeparator(langCode: string | undefined) {
  if (langCode != null && Intl.NumberFormat != null) {
    return new Intl.NumberFormat(langCode).format(1.1).substr(1, 1);
  }
    
  return browserLangSeparator;
}

export function NumberAsString(val: number | null, langCode?: string) {
  return GetNumberAsTextFormatter(langCode)(val);
}

export function GetNumberAsTextFormatter(langCode?: string) {
  const formatter =  langCode != null && Intl.NumberFormat != null ? new Intl.NumberFormat(langCode, { useGrouping: false }) : null;
  return formatter != null 
    ? (val: number | null) => val != null ? formatter.format(val) : ''
    : (val: number | null) => val != null ? Number(val).toLocaleString(undefined, { useGrouping: false }) : '';
}

export function NumberAsStringFull(val: number | null, langCode?: string) {
  const formatter = langCode != null && Intl.NumberFormat != null ? new Intl.NumberFormat(langCode) : null;
  return val != null ? (formatter != null ? formatter.format(val) : Number(val).toLocaleString()) : '';
}

export function GetNumberAsTextFormatterFixed(digits: number, langCode?: string) {
  const formatter =  langCode != null && Intl.NumberFormat != null ? new Intl.NumberFormat(langCode, { useGrouping: false, maximumFractionDigits: digits, minimumFractionDigits: digits }) : null;
  return formatter != null 
    ? (val: number | null) => val != null ? formatter.format(val) : ''
    : (val: number | null) => val != null ? Number(val).toLocaleString(undefined, { useGrouping: false, maximumFractionDigits: digits, minimumFractionDigits: digits }) : '';
}