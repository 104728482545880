import Yaml from 'js-yaml';
import { ParseError, TemplateSpec } from './Components/BasicTypes';
import { IValidationContext, validateSpec } from './Components/Validator';

export interface YamlParseError {
  errors: ParseError[];
}

export const ParseYaml = function (yaml: any, validationContext: IValidationContext | false): TemplateSpec | YamlParseError {
  try {
    // parse yaml and return javascript object
    //let t0 = performance != null ? performance.now() : null;
    const data = Yaml.safeLoad(yaml) as TemplateSpec;
    //if (t0 != null) applogger.debug(`${performance.now() - t0} - ms elapsed doing YAML conversion into JS-object`);

    if (!validationContext) {
      return data;
    }
        
    let errors: ParseError[] = [];
    let ret: TemplateSpec | YamlParseError = data;

    //t0 = performance != null ? performance.now() : null;
    if (!validateSpec(data, validationContext, errors)) {
      ret = { errors: errors };
    }
    //if (t0 != null) applogger.debug(`${performance.now() - t0} - ms elapsed doing spec validation`);

    return ret;
  } catch (e) {
    let message = e.message != null ? String(e.message) : '';
    let line = e.mark?.line != null ? Number(e.mark?.line) + 1 /*zero-based lines from parser, we communicate 1-based index lines*/ : undefined; 
    let column = e.mark?.column  != null ? Number(e.mark?.column) : undefined;

    return { errors: [{ error: message, line: line, ch: column }] };
  }
};