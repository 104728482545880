import React, { useEffect, useRef, useState } from 'react';
import './Editor.scss';
import { Controlled as CodeMirror } from 'react-codemirror2-react-17';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/lucario.css';
import 'codemirror/theme/eclipse.css';
import 'codemirror/keymap/sublime';
import 'codemirror/keymap/vim';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/display/autorefresh';
import { useCookieState } from '../ReactExt';
import { AppColorMode } from '../../NavigatonBar';

interface LogicEditorProps {
  content: string;
  appColorMode: AppColorMode;
  onChange: (s: string) => void;
}

export const ScriptsEditor: React.FC<LogicEditorProps> = (props) => {
  const [keybinding, setKeyBinding] = useCookieState('sublime', 'sc-kb');
  const [lineWrapping, setLineWrapping] = useCookieState(false, 'sc-lw');
  const [content, setContent] = useState(props.content);
  const signalChangeTimeout = useRef(null as NodeJS.Timeout | null);

  useEffect(() => { setContent(props.content); }, [props.content]);
    
  return (
    <div className="CodeEditor Editor">
        <div className="EditorHeader">
            <div>
                <button className="btn btn-sm btn-secondary" style={{ marginRight: '.4rem' }} onClick={()=>setLineWrapping(!lineWrapping)}>{lineWrapping ? 'Line wrapping' : 'No line break'}</button>
                <button className="btn btn-sm btn-secondary" onClick={()=>setKeyBinding(keybinding === 'sublime' ? 'vim' : 'sublime')}>{keybinding === 'vim' ? 'Disable' : 'Enable'} Vim mode</button>
            </div>
        </div>
        <CodeMirror
          value={content}
          onBeforeChange={(editor, data, value) => {
            if (value !== content) {
              setContent(value);
              if (signalChangeTimeout.current != null) {
                clearTimeout(signalChangeTimeout.current);
                signalChangeTimeout.current = null;
              }

              // delay update until stack is empty (timeout call with 0 delay) as indentSelection causes multiple updates
              signalChangeTimeout.current = setTimeout(() => {
                signalChangeTimeout.current = null;
                props.onChange(value);
              }, 0);
            }
          }}
          onChange={()=>{}}
          options={{
            lineNumbers: true,
            lineWrapping: lineWrapping,
            theme: props.appColorMode == 'theme-dark' ? 'lucario' : 'eclipse',
            keyMap: keybinding,
            mode: 'javascript',
            viewportMargin: Infinity,
            autoRefresh: true,
          }}
        />
    </div>
  );
};