/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { SvgRef } from './SvgRef';
import type { GuideHighlightTypes } from '../../Tutorial/content';

import './Treeview.scss';
 
export interface TreeNode {
  id: string;
  text: string;
  children: TreeNode[];
  parent: TreeNode | null;
}

export interface TreeviewProps {
  rootNode: TreeNode;
  selectedId: string;
  sortChildren?: boolean;
  onNodeSelect: (selected: string) => void;
  onDrop?: (event: React.DragEvent, newParent: TreeNode) => void;
  onDragStart?: (event: React.DragEvent, draggedItem: TreeNode) => void;
  onDragOver?: (event: React.DragEvent) => void;
  disabled?: boolean;
  highlightType: GuideHighlightTypes;
}

export type TreeNodeCollapsedStatus = { [id: string]: boolean };

interface TreeviewItemsProps {
  children: TreeNode[];
  selectedId: string;
  sortChildren?: boolean;
  setSelected: (nodeId: string)=>void;
  nodeStatus: TreeNodeCollapsedStatus;
  lockedCollapsedState: TreeNodeCollapsedStatus;
  setCollapsed: (nodeId: string, collapsed: boolean)=>void;
  level?: number;
  onDrop?: (event: React.DragEvent, newParent: TreeNode) => void;
  onDragStart?: (event: React.DragEvent, draggedItem: TreeNode) => void;
  onDragOver?: (event: React.DragEvent) => void;
  disabled?: boolean;
}

const TreeviewItems: React.FC<TreeviewItemsProps> = (props) => {
  const [dragOverId, setDragOverId] = useState<string | null>(null);

  const level = props.level ?? 0;
  const children =  props.sortChildren !== false
    ? props.children.sort((a, b) => a.id > b.id ? 0 : (b.id > a.id ? -1 : 0))
    : props.children;

  return <ul>
        {children.map(node => {
          const hasChildren = node.children?.length !== 0;
          const isActive = node.id == props.selectedId;
          const folderIsLooked = props.lockedCollapsedState[node.id] === false || level === 0;
          const folderIsOpen = props.nodeStatus[node.id] === false || folderIsLooked;

          const setCollapsed = hasChildren
            ? (evt: React.MouseEvent) => {
              evt.stopPropagation();

              if (!folderIsLooked) {
                props.setCollapsed(node.id, folderIsOpen);
              }
            } 
            : undefined;

          const isCollapsed = props.nodeStatus[node.id] !== false;

          const onDragStart = (e: React.DragEvent) => props.onDragStart?.(e, node);
          const onDrop = (e: React.DragEvent) => props.onDrop?.(e, node);

          const spanClassNames = [isActive ? 'active' : undefined, dragOverId == node.id ? 'drag-hover' : undefined].filter(x => x != null);
          if (props.disabled) {
            spanClassNames.push('tree-disabled');
          }
          return <li key={node.id} draggable={level != 0} onDragEnd={()=>setDragOverId(null)} onDragStart={onDragStart} onDrop={onDrop} onDragOver={props.onDragOver} className={`folder${isActive ? ' active' : ''}${folderIsLooked ? ' locked' : ''}`}>
                <span data-highlight-name={node.text} onDragOver={()=>setDragOverId(node.id)} onDragLeave={()=>setDragOverId(id => node.id == id ? null : id)} onDrop={()=>setDragOverId(null)} className={spanClassNames.length > 0 ? spanClassNames.join(' ') : undefined} onClick={()=>props.setSelected(node.id)}>
                    <span onClick={setCollapsed}>{!hasChildren 
                      ? <SvgRef id="icon-Folder" />
                      : (folderIsOpen 
                        ? <SvgRef id="icon-FolderOpen" />
                        : <SvgRef id="icon-FolderPlus" />)}
                    </span>
                    <span>{node.text}</span>
                </span>
                {hasChildren && !isCollapsed
                  ? <TreeviewItems disabled={props.disabled} children={node.children} selectedId={props.selectedId} setSelected={props.setSelected} nodeStatus={props.nodeStatus} setCollapsed={props.setCollapsed} lockedCollapsedState={props.lockedCollapsedState} level={level + 1} onDragStart={props.onDragStart} onDrop={props.onDrop} sortChildren={props.sortChildren} />
                  : null}
            </li>;
        })}
    </ul>;
};


export const Treeview: React.FC<TreeviewProps> = (props) => {
  const rootNode = props.rootNode;

  const [collapsedState, setCollapsedState] = useState<TreeNodeCollapsedStatus>(() => getNodeCollpasedInitStatus(rootNode, 2));
  const lockedCollapsedState = getPathNodeSelected(rootNode, props.selectedId);

  function setNodeCollapsed(nodeId: string, collapsed: boolean) {
    setCollapsedState(s => ({ ...s, [nodeId]: collapsed }));
  }

  return (
        <div className="Treeview" data-highlight-type={props.highlightType}>
            <TreeviewItems children={[rootNode]} selectedId={props.selectedId} setSelected={props.onNodeSelect} nodeStatus={collapsedState} setCollapsed={setNodeCollapsed}
              lockedCollapsedState={lockedCollapsedState} disabled={props.disabled} onDragStart={props.onDragStart} onDrop={props.onDrop} sortChildren={props.sortChildren} onDragOver={props?.onDragOver} />
        </div>
  );
};

function getPathNodeSelected(rootNode: TreeNode, selectedId: string) {
  const pathNodeStatus: TreeNodeCollapsedStatus = {};

  function ensureOpenTreePathToActive(tree: TreeNode[]): boolean {
    for (let i = 0; i < tree.length; ++i) {
      const item = tree[i];
      if (item.id == selectedId) {
        return true;
      }
      if (item.children.length > 0) {
        const childIsActive = ensureOpenTreePathToActive(item.children);
        if (childIsActive) {
          pathNodeStatus[item.id] = false;
          return true;
        }
      }
    }
    
    return false;
  }

  // Ensure that the active worklist node is rendered as open
  ensureOpenTreePathToActive(rootNode.children);

  return pathNodeStatus;
}

function getNodeCollpasedInitStatus(tree: TreeNode, lvl: number) {
  const ret: TreeNodeCollapsedStatus = { [tree.id]: false };

  function Uncollapse(_tree: TreeNode[], depth: number) {
    if (depth >= lvl) return;
    for (let i = 0; i < _tree.length; ++i) {
      const item = _tree[i];
      ret[item.id] = false;
      if (item.children && item.children.length > 0) {
        Uncollapse(item.children, depth + 1);
      }
    }
  }

  Uncollapse(tree.children, 1);

  return ret;
}