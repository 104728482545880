/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { DebugSignin } from '../DebugContent/DebugSignin';
import { routeToPath } from '../Helpers/NavigationHelper';
import { IServerCommunication } from '../ServerCommunication';
import { FormsSvg } from './FormsSvg';
import { SectraLogoSvg } from './SectraLogoSvg';
import './login.scss';
import { useVersion } from '../CustomHooks/useVersion';

export interface LoginProps {
  server: IServerCommunication;
}

export const Login: React.FC<LoginProps> = (props) => {
  const [version, year, buildNr] = useVersion();
  const [showRequirements, setShowRequirements] = useState<boolean>(false);

  return (
    <Switch>
      {process.env.NODE_ENV !== 'production' ? 
        <Route exact path={routeToPath('/debugsignin')}>
          <DebugSignin server={props.server} />
        </Route>
        : null}
      <Route>
        <div className="login-page">
          <div className="login-header">
            <div className="login-badge">
              <div className="pattern">
                <svg className="login-svg" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000">
                  <defs>
                    <radialGradient id="gradient" cx="500" cy="500" r="400" gradientUnits="userSpaceOnUse">
                      <stop offset="0.05" style={{ stopColor: '#071326', stopOpacity: 0 }} />
                      <stop offset="0.20" style={{ stopColor: '#071326', stopOpacity: .3 }} />
                      <stop offset="0.50" style={{ stopColor: '#071326', stopOpacity: .7 }} />
                      <stop offset="0.75" style={{ stopColor: '#071326', stopOpacity: .9 }} />
                      <stop offset="1" style={{ stopColor: '#071326', stopOpacity: 1 }} />
                    </radialGradient>
                  </defs>
                  <rect fill="#112F5D" width="1000" height="1000" />
                  <rect fill="url(#gradient)" width="1000" height="1000" />
                </svg>
              </div>
              <a className="startbutton" href="/MicrosoftIdentity/Account/SignIn"><FormsSvg /></a>
            </div>
            <SectraLogoSvg />
          </div>

          <div className="login-main">
            <div className="login-content">
              <h1>Effortless creation of innovative reporting forms</h1>
              <p>Sectra Forms empowers your organization to design interactive reporting workflows, enabling seamless integration of structured data import, storage, and export capabilities.</p>
              <a className="startbutton" href="/MicrosoftIdentity/Account/SignIn">Start</a>
              {process.env.NODE_ENV !== 'production' ? 
                <a style={{ marginLeft: '5px' }} className="startbutton" href="/debugsignin">Debug signin</a> : null}
            </div>
          </div>
          <div className="login-footer">
            <dl>
              <dt>Sectra Forms</dt>
              <dd>Version {version}.{buildNr} <br /> ©{year} Sectra AB</dd>
              <dd></dd>
              <dd></dd>
            </dl>
            <dl>
              <dt>Sectra AB</dt>
              <dd>Teknikringen 20 <br /> SE-583-30 Linköping <br /> SWEDEN</dd>
              <dd><a href="https://medical.sectra.com/">medical.sectra.com</a></dd>
              <dd><a href="mailto:info@sectra.com">info@sectra.com</a></dd>
            </dl>
            <dl className="info">
              <dt onClick={() => setShowRequirements(!showRequirements)}>
                <span className="chevron" />
                System Requirements
              </dt>
              {showRequirements ?
              <dd>
                <h2>System requirements</h2>
                <p>Sectra Forms has been tested on the latest version of Google Chrome on Windows. Microsoft Internet Explorer is not supported.
                  Modern browsers fulfilling the following requirements should work:</p>
                <ul>
                  <li>HTML 5 support</li>
                  <li>ECMAScript 5 support</li>
                  <li>Cookies enabled</li>
                  <li>File API</li>
                </ul>
                <button className="no-btn closebutton" onClick={() => setShowRequirements(false)}>x</button>
              </dd> : null}
            </dl>
          </div>
        </div>
      </Route>
    </Switch>
  );
};