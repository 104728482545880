import React, { useState } from 'react';

interface FormStateViewerProps{
  ids7FormData: string;
  onNewStateLoaded: ()=>void;
}

export const FormStateViewer: React.FC<FormStateViewerProps> = (props) => {
  const [currentStoredState, setCurrentStoredState] = useState('');
  return (
        <div>
            <pre style={{ height: '300px' }}>{props.ids7FormData}</pre>
            <div style={{ margin: '0 0 10px' }}>
                <button className="btn btn-secondary" onClick={() => {
                  // simulate ids7 behaviour by calling on window
                  window.setCustomState(JSON.parse(currentStoredState));
                  props.onNewStateLoaded();
                }
                }>Load from state below</button> &nbsp;
                <button className="btn btn-secondary" onClick={() => 
                  setCurrentStoredState(props.ids7FormData)
                }>Copy current to state below</button>
            </div>
            <textarea className="form-control" rows={15} onChange={(e) => setCurrentStoredState(e.target.value)} value={currentStoredState} />
        </div>
  );
};
