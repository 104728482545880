const trimTrailingSlash = (s: string | undefined): string | undefined => {
  if (s == null || !s.endsWith('/')) {
    return s;
  }

  return s.slice(0, s.length - 1);
};

export const routeToPath = (r: string): string => {
  return trimTrailingSlash(process.env.PUBLIC_URL) + r;
};
