import { GetNumberAsTextFormatter, GetNumberAsTextFormatterFixed } from './NumberHelper';

export type AsciTableStyle = 'none' | 'simple' | 'border' | 'border2' | 'simpleb' | 'simplemb' | 'dashed' | 'rounded' 
/* Not very useful layouts, consider remove*/
| 'mysql' | 'separated' | 'rstGrid' | 'rstSimple' | 'jira' | 'bubbles' | 'girder';
export interface AsciTableOptions {
  firstRowHeader?: boolean;
  autoFormat?: boolean;
  style?: AsciTableStyle
  decimalPlaces?: number;
}

// translation/clean-up from https://github.com/ozh/ascii-tables/
export function createTable(rows: any[][], opts?: AsciTableOptions | AsciTableStyle, firstRowHeader?: boolean, langCode?: string) {
  opts = typeof opts === 'string' ? { style: opts as AsciTableStyle } : opts;
  const hasHeaders = firstRowHeader ?? opts?.firstRowHeader ?? true;
  const style = opts?.style ?? 'border';
  const decimalPlaces = opts?.decimalPlaces != null ? Number(opts.decimalPlaces) : undefined;
  let autoFormat = opts?.autoFormat ?? true;
    

  // calculate the max size of each column
  const colLengths: number[] = [];
  const isNumberCol: boolean[] = [];
  for (let i = 0; i < rows.length; i++) {
    let cols = rows[i];
    for (let j = 0; j < cols.length; j++) {
      const rawData = cols[j];
      const data = String(rawData);
      const isNewCol = colLengths[j] == undefined;
      if (isNewCol) {
        isNumberCol[j] = true;
      }
      // keep track of which columns are numbers only
      if (autoFormat) {
        if (hasHeaders && i == 0) {
          // a header is allowed to not be a number (exclude spreadsheet because the header hasn't been added yet
        } else if (isNumberCol[j] && (typeof rawData !== 'number' && !data.match(/^(\s*-?(\d|,| |[.])*\s*)$/))) { //number can be negative, comma/period-separated, or decimal
          isNumberCol[j] = false;
        }
      }
      if (isNewCol || colLengths[j] < data.length) {
        colLengths[j] = data.length;
      }
    }
  }

  let hasHeaderSeparators = true; // Defaults to including a separator line btwn header and data rows
  let hasLineSeparators = false; // Defaults to no separator lines btwn data rows
  let hasTopLine = true; // Defaults to including the topmost line
  let hasBottomLine = true; // Defaults to including the bottom-most line
  let hasLeftSide = true; // Defaults to including the left side line
  let hasRightSide = true; // Defaults to including the right side line
  let topLineUsesBodySeparators = false; // Defaults to top line uses the same separators as the line between header and body
  let align: 'r' | 'l' | 'c' = 'l'; // Default alignment: left-aligned

  // Map of variable locations in the output:
  //
  // [cTL]   [hdH]  [cTM]   [hdH]  [cTR]
  // [hdV] Header 1 [hdV] Header 2 [hdV]
  // [cML]   [hdH]  [cMM]   [hdH]  [cMR]
  // [spV] Value 1  [spV] Value 2  [spV]
  // [cML]   [spH]  [cMM]   [spH]  [cMR]
  // [spV] Value 1a [spV] Value 2a [spV]
  // [cBL]   [spH]  [cBM]   [spH]  [cBR]
  let cTL: string = '', cTM: string = '', cTR: string = '';
  let cML: string = '', cMM: string = '', cMR: string = '';
  let cBL: string = '', cBM: string = '', cBR: string = '';
  let hdV: string = '', hdH: string = '';
  let spV: string = '', spH: string = '';

  switch (style) {
    case 'mysql':
      // ascii mysql style
      cTL = '+'; cTM = '+'; cTR = '+';
      cML = '+'; cMM = '+'; cMR = '+';
      cBL = '+'; cBM = '+'; cBR = '+';

      hdV = '|'; hdH = '-';
      spV = '|'; spH = '-';
      break;
    case 'separated':
      // ascii 2
      hasLineSeparators = true;
      cTL = '+'; cTM = '+'; cTR = '+';
      cML = '+'; cMM = '+'; cMR = '+';
      cBL = '+'; cBM = '+'; cBR = '+';

      hdV = '|'; hdH = '=';
      spV = '|'; spH = '-';
      break;
    case 'simple':
      // ascii - compact
      hasTopLine = false;
      hasBottomLine = false;
      cML = ' '; cMM = ' '; cMR = ' ';
      hdV = ' '; hdH = '-';
      spV = ' '; spH = '-';
      break;
    case 'rounded':
      // ascii rounded style
      hasLineSeparators = true;
      cTL = '.'; cTM = '.'; cTR = '.';
      cML = ':'; cMM = '+'; cMR = ':';
      cBL = "'"; cBM = "'"; cBR = "'";

      hdV = '|'; hdH = '-';
      spV = '|'; spH = '-';
      break;
    case 'girder':
      // ascii rounded style
      cTL = '//'; cTM = '[]'; cTR = '\\\\';
      cML = '|]'; cMM = '[]'; cMR = '[|';
      cBL = '\\\\'; cBM = '[]'; cBR = '//';

      hdV = '||'; hdH = '=';
      spV = '||'; spH = '=';
      break;
    case 'bubbles':
      // ascii bubbled style
      cTL = ' o8'; cTM = '(_)'; cTR = '8o ';
      cML = '(88'; cMM = '(_)'; cMR = '88)';
      cBL = ' O8'; cBM = '(_)'; cBR = '8O ';

      hdV = '(_)'; hdH = '8';
      spV = '(_)'; spH = 'o';
      break;
    case 'dashed':
      // ascii dotted style
      cTL = '.'; cTM = '.'; cTR = '.';
      cML = ':'; cMM = ':'; cMR = ':';
      cBL = ':'; cBM = ':'; cBR = ':';

      hdV = ':'; hdH = '.';
      spV = ':'; spH = '.';
      break;
    case 'simpleb':
      // github markdown
      hasTopLine = false;
      hasBottomLine = false;
      cTL = '|'; cTM = '|'; cTR = '|';
      cML = '|'; cMM = '|'; cMR = '|';
      cBL = '|'; cBM = '|'; cBR = '|';

      hdV = '|'; hdH = '-';
      spV = '|'; spH = '-';
      break;
    case 'simplemb':
      // reddit markdown
      hasTopLine = false;
      hasBottomLine = false;
      hasLeftSide = false;
      hasRightSide = false;
      cTL = ' '; cTM = '|'; cTR = ' ';
      cML = ' '; cMM = '|'; cMR = ' ';
      cBL = ' '; cBM = '|'; cBR = ' ';

      hdV = '|'; hdH = '-';
      spV = '|'; spH = '-';
      break;
    case 'rstGrid':
      // reStructuredText Grid markup
      hasTopLine = true;
      topLineUsesBodySeparators = true;
      hasBottomLine = true;
      cTL = '+'; cTM = '+'; cTR = '+';
      cML = '+'; cMM = '+'; cMR = '+';
      cBL = '+'; cBM = '+'; cBR = '+';

      hdV = '|'; hdH = '=';
      spV = '|'; spH = '-';
      break;
    case 'rstSimple':
      // reStructuredText Simple markup
      hasTopLine = true;
      hasBottomLine = true;
      cTL = ' '; cTM = ' '; cTR = ' ';
      cML = ' '; cMM = ' '; cMR = ' ';
      cBL = ' '; cBM = ' '; cBR = ' ';

      hdV = ' '; hdH = '=';
      spV = ' '; spH = '=';
      break;
    case 'jira':
      // jira markdown
      hasTopLine = false;
      hasBottomLine = false;
      autoFormat = false;
      hasHeaderSeparators = false;

      cTL = ''; cTM = ''; cTR = '';
      cML = ''; cMM = ''; cMR = '';
      cBL = ''; cBM = ''; cBR = '';

      hdV = '||'; hdH = '';
      spV = '| '; spH = '';
      break;
    case 'border2':
      // unicode
      cTL = '\u2554'; cTM = '\u2566'; cTR = '\u2557';
      cML = '\u2560'; cMM = '\u256C'; cMR = '\u2563';
      cBL = '\u255A'; cBM = '\u2569'; cBR = '\u255D';

      hdV = '\u2551'; hdH = '\u2550';
      spV = '\u2551'; spH = '\u2550';
      break;
    case 'border':
      // unicode one line thick border
      cTL = '\u250C'; cTM = '\u252C'; cTR = '\u2510';
      cML = '\u251C'; cMM = '\u253C'; cMR = '\u2524';
      cBL = '\u2514'; cBM = '\u2534'; cBR = '\u2518';

      hdV = '\u2502'; hdH = '\u2500';
      spV = '\u2502'; spH = '\u2500';
      break;
    default:
      break;
  }

  // output the text
  let output = '\n';

  // output the top most row
  // Ex: +---+---+
  if (hasTopLine ) {
    let topLineHorizontal: string;
    if (topLineUsesBodySeparators || !hasHeaders) {
      topLineHorizontal = spH;
    } else {
      topLineHorizontal = hdH;
    }
    output += getSeparatorRow(colLengths, cTL, cTM, cTR, topLineHorizontal);
  }

  const numFormat = decimalPlaces != null 
    ? GetNumberAsTextFormatterFixed(decimalPlaces, langCode)
    : GetNumberAsTextFormatter(langCode);

  for (let i = 0; i < rows.length; i++) {
    // Separator Rows
    if (hasHeaders && hasHeaderSeparators && i == 1 ) {
      // output the header separator row
      output += getSeparatorRow(colLengths, cML, cMM, cMR, hdH);
    } else if ( hasLineSeparators && i < rows.length ) {
      // output line separators
      if ( ( !hasHeaders && i >= 1 ) || ( hasHeaders && i > 1 ) ) {
        output += getSeparatorRow(colLengths, cML, cMM, cMR, spH);
      }
    }

    for (let j = 0; j <= colLengths.length; j++) {
      // output the data
      let cols = rows[i];
      let data = cols[j] || '';
      if (autoFormat) {
        if (hasHeaders && i == 0) {
          align = 'c';
        } else if (isNumberCol[j]) {
          align = 'r';
        } else {
          align = 'l';
        }
      }
      let verticalBar: string;
      if (hasHeaders && i == 0 ) {
        verticalBar = hdV;
      } else {
        verticalBar = spV;
      }
      if ( j < colLengths.length ) {
        data = pad(data, colLengths[j], ' ', align, numFormat);
        if (j == 0 && !hasLeftSide) {
          output += '  ' + data + ' ';
        } else {
          output += verticalBar + ' ' + data + ' ';
        }
      } else if (hasRightSide) {
        output += verticalBar + '\n';
      } else {
        output += '\n';
      }

    }
  }

  // output the bottom line
  // Ex: +---+---+
  if (hasBottomLine ) {
    output += getSeparatorRow(colLengths, cBL, cBM, cBR, spH);
  }

  return output;
}

function getSeparatorRow(lengths: number[], left: string, middle: string, right: string, horizontal: string) {
  let rowOutput = '';
  for (let j = 0; j <= lengths.length; j++) {
    if ( j == 0 ) {
      rowOutput += left + repeat(horizontal, lengths[j] + 2);
    } else if ( j < lengths.length ) {
      rowOutput += middle + repeat(horizontal, lengths[j] + 2);
    } else {
      rowOutput += right + '\n';
    }
  }
  return rowOutput;
}

function pad(val: any, length: number, char: string | undefined, align: 'r' | 'l' | 'c' | undefined, numFormat: (n: number)=>string) {
  // align: r l or c
  char = char != null ? char : ' ';
  align = align != null ? align : 'l';
  const text = typeof val === 'number' 
    ? numFormat(val)
    : String(val);
  const additionalChars = length - text.length;

  let result = '';
  switch (align) {
    case 'r':
      result = repeat(char, additionalChars) + text;
      break;
    case 'l':
      result = text + repeat(char, additionalChars);
      break;
    case 'c': {
      let leftSpaces = Math.floor(additionalChars / 2);
      let rightSpaces = additionalChars - leftSpaces;
      result = repeat(char, leftSpaces) + text + repeat(char, rightSpaces);
      break;
    }
    default:
      throw new Error('invalid align');
  }
  return result;
}

function repeat(str: string, num: number) {
  return new Array(num + 1).join(str);
}
