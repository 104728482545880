import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ParseTemplateSpec } from './Components/SrtTemplateSpec';
import { ParseYaml } from './YamlToJson';
import { TemplateBaseContainer } from './Components/TemplateBaseContainer';
import { TemplateSpec } from './Components/BasicTypes';
import { initalizeTemplateContext } from './Components/TemplateContextHelper';
import { getReduxStoreForProvider } from './Components/ReduxStateStore';
import './WindowIf';

/*
When exporting a completed srt from Ids7Packager this is the main page that ids7 shows
*/
const Ids7App: React.FC<{ spec: string, isJson: boolean }> = ({ spec, isJson }) => {
  const reportSpec = isJson
    ? JSON.parse(spec) as TemplateSpec
    : ParseYaml(spec, false) as TemplateSpec;
  const parsedSpec = ParseTemplateSpec(reportSpec, false);
  const templateContext = initalizeTemplateContext(parsedSpec);

  window.clearState = () => {
    templateContext.componentStore.clearState(true);
  };

  return <TemplateBaseContainer templateContext={templateContext} />;
};

const loadIds7React = (spec: string, isJson: boolean, rootId: string) => {
  ReactDOM.render(
        <Provider store={getReduxStoreForProvider()}>
            <Ids7App spec={spec} isJson={isJson} />
        </Provider>,
        document.getElementById(rootId),
  );

  // clean-up loaders once template is loaded
  delete window._loadIds7React_;
  delete window._loadWebApp_;
};

window._loadIds7React_ = loadIds7React;