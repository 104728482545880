import React from 'react';
import { ExplicitSetterType, GetterType, SrComponent, SrComponentPropsBase, ComponentRenderContext } from '../BasicTypes';
import { CanvasAnnotationTransformer, CanvasImageAnnotation, MapItem, SectorData } from './SectraBaseComponent/SectraCanvasAnnotation';
import { SectorMap, SectraCanvas } from './SectraBaseComponent/SectraCanvasOverride';
import { getSiblingIds } from './SectraListSection';
import { ReactComponentContainerProps, shouldDisplay, getDefaultBgColor } from './SrtComponent';
import { schema, expressionPattern } from './Schema';
import { applogger } from '../../applogger';
import { IsInDirectRowContext } from './SectraRow';

/*
 * TODO / Improvment list:
   - Support for color selector toolbox (a special case would be just showing the current unchangable color)
   - Showing an annotation type (marker) toolbox plus changing annotation type settings such as size (brush-size, image-icon size?)
   - Support for sector aware fill tools (UK cardio use-case). Status: Started but needs to be extended.
 */

interface SvgColor {
  type: string;
  color: string;
  selector?: string;
}

interface SectorInfo {
  name: string;
  description?: string;
  polygon: string;
}

interface DropMarker {
  name: string;
  image: string | string[];
  darkImage?: string | string[];
  lightImage?: string | string[];
  toolboxWidth?: number;
  toolboxHeight?: number;
  svgColor?: SvgColor[];
  darkSvgColor?: SvgColor[];
  lightSvgColor?: SvgColor[];
}

interface Toolbox {
  position?: 'left' | 'right';
  vposition?: 'top' | 'middle' | 'bottom';
  markers?: DropMarker[];
  iconWidth?: number;
  iconHeight?: number;
  svgColor?: SvgColor[];
  darkSvgColor?: SvgColor[];
  lightSvgColor?: SvgColor[];
}

interface PainterProps extends SrComponentPropsBase {
  image?: string;
  imageScale?: number;
  marker?: string;
  moveAsDefault?: boolean;
  brushWidth?: number;
  sprayDensity?: number;
  sprayRadius?: number;
  toolbox?: Toolbox;
  darkImage?: string;
  lightImage?: string;
  svgColor?: SvgColor[];
  darkSvgColor?: SvgColor[];
  lightSvgColor?: SvgColor[];
  width?: number,
  height?: number,
  bgColor?: string;
  darkBgColor?: string;
  lightBgColor?: string;
  storeBgColor?: string;
  sectorMap?: SectorInfo[];
  sectorMapWidth?: number;
  sectorMapHeight?: number;
  sectorMapColor?: string;
  storeImageResult?: boolean;
  storeImageScale?: number;
  posAlignment?: string;
  posOffset?: string;
  colors?: string;
  colorIndex?: number;
  communicatingInListElements?: boolean;
  rightClickUndo?: string;

  value?: string;
  sectorArrayFull?: SectorData;
  backgroundAnnotationData?: string;
  disallowAnnotationMoving?: boolean;
}

const svgColorDef = {
  'type': 'object',
  'required': ['type', 'color'],
  'properties': {
    'type': { 'type': 'string', 'enum': ['fill', 'stroke'], 'enumDescription': ['Affect the _fill_ CSS property', 'Affect the _stroke_ CSS property'], 'description': 'Whether to set fill or stroke' },
    'color': { 'type': 'string', 'description': 'The color code (valid CSS color)' },
    'selector': { 'type': 'string', 'description': 'An optional CSS-selector for which nodes to replace (default: *)' },
  },
  'uniqueItems': false,
  'description': 'A SVG color replacement definition',
  'additionalProperties': false,
};

const dropMarkerImageDef = {
  'anyOf': [
    { 'type': 'string' },
    {
      'type': 'array', 
      'items': { 'type': 'string', 'description': 'The image data' },
      'minItems': 1,
    },
  ],
  'description': 'The image data',
};

const dropMarkerDef = {
  'type': 'object',
  'required': ['name', 'image'],
  'properties': {
    'name': { 'type': 'string', 'description': 'The display name of the marker annotation (also used as legend description unless specified)' },
    'image': dropMarkerImageDef,
    'darkImage': dropMarkerImageDef,
    'lightImage': dropMarkerImageDef,
    'toolboxWidth': { 'type': 'number', 'description': 'Width of toolbox icon in pixel (default: height or if not set 24)' },
    'toolboxHeight': { 'type': 'number', 'description': 'Height of toolbox icon in pixel (default: width or if not set 24)' },
    'legendDescription': { 'type': 'string', 'description': 'A legend description text (default: name)' },
    'svgColor': {
      'type': 'array',
      'items': svgColorDef,
      'minItems': 0,
      'description': 'A SVG color definition applicable for an SVG image.',
    },
    'darkSvgColor': {
      'type': 'array',
      'items': svgColorDef,
      'minItems': 0,
      'description': 'A SVG color definition applicable for an SVG image that is used in dark theme (i.e. CSS adjustments for dark theme).',
    },
    'lightSvgColor': {
      'type': 'array',
      'items': svgColorDef,
      'minItems': 0,
      'description': 'A SVG color definition applicable for an SVG image that is used in light theme (i.e. CSS adjustments for light theme).',
    },
  },
  'uniqueItems': false,
  'description': 'A SVG color replacement definition',
  'additionalProperties': false,
};

const toolboxDef = {
  'type': 'object',
  'properties': {
    'position': { 'type': 'string', 'enum': ['left', 'right'], 'enumDescription': ['Position left', 'Position right'], 'description': 'Position of the toolbox (default: left)' },
    'vposition': { 'type': 'string', 'enum': ['top', 'middle', 'bottom'], 'enumDescription': ['Positioned in the top corner', 'Possitioned in the middle', 'Positioned in the bottom corner'], 'description': 'The vertical position of the toolbox (default: middle)' },
    'iconWidth': { 'type': 'number', 'description': 'Width of icons in pixel (default: height or if not set 24)' },
    'iconHeight': { 'type': 'number', 'description': 'Height of icons in pixel (default: width or if not set 24)' },
    'markers': {
      'type': 'array',
      'items': dropMarkerDef,
      'minItems': 1,
      'description': 'An array of drop markers that can be used. Each drop-marker will be available through a drop-marker toolbox',
    },
    'svgColor': {
      'type': 'array',
      'items': svgColorDef,
      'minItems': 0,
      'description': 'A SVG color definition applicable for an SVG image.',
    },
    'darkSvgColor': {
      'type': 'array',
      'items': svgColorDef,
      'minItems': 0,
      'description': 'A SVG color definition applicable for an SVG image that is used in dark theme (i.e. CSS adjustments for dark theme).',
    },
    'lightSvgColor': {
      'type': 'array',
      'items': svgColorDef,
      'minItems': 0,
      'description': 'A SVG color definition applicable for an SVG image that is used in light theme (i.e. CSS adjustments for light theme).',
    },
  },
  'description': 'A toolbox definition',
  'additionalProperties': false,
};

const schemaProps = {
  'id': { 'type': 'string', 'description': 'The ID used to store image data.' },
  'display': schema.PropDefinition.display,
  'width': { 'type': 'number', 'description': 'The width of the displayed image, in pixels.' },
  'height': { 'type': 'number', 'description': 'The height of the displayed image, in pixels.' },
  'posAlignment': { 'type': 'string', 'enum': ['left', 'center', 'right'], 'enumDescription': ['Align left', 'Align center (middle)', 'Align right'], 'description': 'Alignment of the painter canvas (default: right)' },
  'posOffset': { 'type': ['string', 'number'], 'description': 'Fine-tuning possibilities for the canvas position on the form of _[top]_ _[left]_ (i.e. space separated, default: 0 0)' },
  'image': { 'type': 'string', 'description': 'The image data' },
  'imageScale': { 'type': 'number', 'description': 'Scaling of the image when drawn to the canvas (default: 1.0)' },
  'marker': {
    'anyOf': [
      { 'type': 'string', 'enum': ['brush', 'spray', 'line', '4pcurve', '3pcurve', 'sectorFill', 'fill', 'none'], 'enumDescription': ['Free-draw line', 'Spray painter (default)', 'Sector fill tool that will between two points bounded by a sector map (experimental)', 'No marker available (not possible to paint on canvas)'], 'description': 'The primary annotation tool (default: spray)' },
      { 'type': 'string',  'description': 'Annotation tool by expression', 'pattern': expressionPattern },
    ],
    'description': 'The primary annotation tool (default: spray)',
  },
  'moveAsDefault': { 'type': 'boolean', 'description': 'If set to true, moving annotations are prioritized and shift-press is needed to draw over existing annotations.' },
  'brushWidth': { 'type': 'number', 'description': 'The width of the brush when drawing (default: 2)' },
  'sprayDensity': { 'type': 'integer', 'description': 'The spray density of the spray tool when drawing (default: 10)' },
  'sprayRadius': { 'type': 'number', 'description': 'The spray radius of the spray tool when drawing (default: 8)' },
  'toolbox': toolboxDef,
  'darkImage': { 'type': 'string', 'description': 'The image data, used if theme is dark' },
  'lightImage': { 'type': 'string', 'description': 'The image data, used if theme is light' },
  'svgColor': {
    'type': 'array',
    'items': svgColorDef,
    'minItems': 0,
    'description': 'A SVG color definition applicable for an SVG image.',
  },
  'darkSvgColor': {
    'type': 'array',
    'items': svgColorDef,
    'minItems': 0,
    'description': 'A SVG color definition applicable for an SVG image that is used in dark theme (i.e. CSS adjustments for dark theme).',
  },
  'lightSvgColor': {
    'type': 'array',
    'items': svgColorDef,
    'minItems': 0,
    'description': 'A SVG color definition applicable for an SVG image that is used in light theme (i.e. CSS adjustments for light theme).',
  },
  'bgColor': { 'type': 'string', 'description': 'Default background color. Occasionally useful when using images with transparent background (default: [default theme color])' },
  'darkBgColor': { 'type': 'string', 'description': 'Default background color in dark theme (default: fallback to _bgColor_)' },
  'lightBgColor': { 'type': 'string', 'description': 'Default background color in light theme (default: fallback to _bgColor_)' },
  'sectorMap': {
    'type': 'array',
    'items': {
      'type': 'object',
      'required': ['name', 'polygon'],
      'properties': {
        'name': { 'type': 'string', 'description': 'Sector name' },
        'description': { 'type': 'string', 'description': 'Sector description' },
        'polygon': { 'type': 'string', 'description': 'A comma-separated list of polygon points (a point is two numbers separated by a space, tip: set the _sectorMapColor_ in order to see what is drawn)' },
      },
      'uniqueItems': false,
      'description': 'A sector map item',
      'additionalProperties': false,
    },
    'minItems': 0,
    'description': 'A sector map that assigns labels to various parts of the marker area',
  },
  'sectorMapWidth': { 'type': 'number', 'description': 'A scaling width for the sector map, i.e. the expected canvas width this map applies to' },
  'sectorMapHeight': { 'type': 'number', 'description': 'A scaling height for the sector map, i.e. the expected canvas height this map applies to' },
  'sectorMapColor': { 'type': 'string', 'description': 'Showing the sector map in this color, mostly for debug purposes (default: not displayed)' },
  'storeImageResult': { 'type': ['string', 'boolean'], 'enumDescription': ['Store image', 'Do not storage image'], 'description': 'Whether the resulting image is stored as part of the report or not (default: true)', 'pattern': expressionPattern },
  'storeImageScale': { 'type': 'number', 'description': 'Scale factor at which the resulting image is stored (default: 1.5)' },
  'storeBgColor': { 'type': 'string', 'description': 'Default background color. Occasionally useful when using images with transparent background (default: fallback to _bgColor_)' },
  'colors': { 'type': 'string', 'description': 'A space separated string with colors available. Colors are actualized render time and referred to by index. Default: A Sectra palette.' },
  'colorIndex':  { 'type': ['number', 'string'], 'description': 'The current color index. Ignored when running in a communicating mode where color is automatically set to the list index. Default: 0' },
  'communicatingInListElements': { 'type': ['boolean'], 'enumDescription': ['Enabled', 'Disabled'], 'description': 'Whether the component should be communicating with sibling components in a list, color index will be set to list index. Default: true' },
  'rightClickUndo': { 'type': 'string', 'enum': ['none', 'color', 'global'], 'enumDescription': ['Disabled', 'Within the current active color', "The last annotation made regardless of color (won't work in communicating mode where the local color list index is all that's available)"], 'description': 'Undo last annotation via right click on the canvas (default: none)' },
  'disallowAnnotationMoving': { 'type': 'boolean', 'description': 'set to true to disable annotation moving' },
};

/*
 *  The Painter component
 */

function clickElem(elem: any) {
  var eventMouse = document.createEvent('MouseEvents');
  eventMouse.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  elem.dispatchEvent(eventMouse);
}

function getBase64ImageFromFile(resolve: (image: string) => void, reject: (reason?: any) => void) {
  let fileInput : any = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = 'image/*';
  fileInput.style.display = 'none';
  fileInput.onchange = (e : any) => {
    let file = e.target.files[0];
    if (!file) {
      reject('no file');
      return;
    }
    var reader = new FileReader();
    reader.onload = (ev : any) => {
      let base64Image: string = ev.target.result;
      document.body.removeChild(fileInput);
            
      const maxSizeAllowd = (2 * 1024 * 1024 * (4.0 / 3.0)); // 2Mb represented as base64
      if (base64Image != null && base64Image.length > maxSizeAllowd) {
        alert('The provided image well exceeds recommended size. Please choose a smaller image (<2Mb), preferable an SVG that will scale nicely when zoomed.');
        reject('too big');
        return;
      }

      resolve(base64Image);
    };
    reader.readAsDataURL(file);
  };
  document.body.appendChild(fileInput);
  clickElem(fileInput);
}

const getTemplate = () => new Promise(getBase64ImageFromFile)
  .then(img => `- Painter:
    id: painter${schema.getNextIdNum()}
    width: 500
    height: 300
    image: ${img}`);


// The React-component
const SectraPainterComponent: React.FC<ReactComponentContainerProps<PainterProps>> = (container) => {
  const props = container.props;
  let imageResultId = props.id;
  let storeImageData = props.storeImageResult ?? true;
  let colorIndex = props.colorIndex ?? 0;
  let inListAndCommunicating = props.communicatingInListElements !== false 
        && container.context.listCount != null;

  // setup communication for when in a list context and communicating with siblings
  let sourceId = container.context.currentParsedComponent.id;
  if (inListAndCommunicating) {
    colorIndex = container.context.listIndex ?? 0;
    storeImageData = storeImageData && colorIndex === 0;
    imageResultId = sourceId; // since we're just storing one image, store it using source id
  }
    
  // if we're not displaying at all, lets bailout
  if (!shouldDisplay(props.display, container.context)) {
    return null;
  }

  // setup background color and image
  const theme = container.context.theme;
  const isDarkTheme = theme === 'dark';
  const canvasBgColor = (isDarkTheme ? props.darkBgColor : props.lightBgColor) ?? props.bgColor ?? getDefaultBgColor(theme, container.context.listCount != null);
  const storeBgColor = props.storeBgColor ?? canvasBgColor;
  let canvasBgImage = (isDarkTheme ? props.darkImage : props.lightImage) ?? props.image;
  if (canvasBgImage != null) {
    const svgColor = (isDarkTheme ? props.darkSvgColor : props.lightSvgColor) ?? props.svgColor;
    if (svgColor != null && svgColor.length > 0) {
      canvasBgImage = applySvgReplacements(canvasBgImage, svgColor);
    }
  }

  // setup some layouting, alignment and potential offsets
  const alignmentClass = props.posAlignment != null ? ' ' + props.posAlignment : '';
  const containerStyle: React.CSSProperties = {};
  if (props.posOffset != null) {
    let parts = String(props.posOffset).split(' ', 2);
    let top = Number(parts[0]);
    let left = parts.length > 1 ? Number(parts[1]) : NaN;
    containerStyle.marginTop = (!isNaN(top)) ? top : undefined;
    containerStyle.marginLeft = (!isNaN(left)) ? left : undefined;
  }

  let colors = props.colors != null ? String(props.colors).split(' ') : null;
  let getColorByIndex = (clrIndex: number): string | undefined => {
    if (colors == null) return undefined;
    return colors[clrIndex % colors.length];
  };

  let map = props.sectorMap != null ? parseSectorMap(props.sectorMap) : null;
  let sectorMap = map != null 
    ? {
      map: map,
      scaleWidth: props.sectorMapWidth,
      scaleHeight: props.sectorMapHeight,
      highlightColor: props.sectorMapColor,
    } as SectorMap : undefined;

  const toolboxData = getToolboxAndMarkers(props, isDarkTheme);
  if (props.toolbox != null && toolboxData.container != null) {
    switch (props.toolbox.vposition) {
      case 'top': 
        containerStyle.alignItems = 'flex-start';
        break;
      case 'bottom':
        containerStyle.alignItems = 'flex-end';
        break;
      case 'middle':
      default:
        containerStyle.alignItems = 'center';
        break;
    }
  }

  const inner = <>
        {props.toolbox?.position !== 'right' ? toolboxData.container : null}
        <SectraCanvas 
            id={props.id} 
            width={props.width} 
            height={props.height}
            defaultImage={canvasBgImage}
            defaultImageScale={props.imageScale}
            backgroundColor={canvasBgColor}
            annotationType={(props.marker ?? 'spray') as any}
            brushWidth={props.brushWidth}
            sprayDensity={props.sprayDensity}
            sprayRadius={props.sprayRadius}
            sectorMap={sectorMap}
            dropToAnnotation={(_, e) => {
              const imageId = e.dataTransfer.getData('img-id');
              const image = toolboxData.markers[imageId]?.image;
              return image != null && image.width > 0 ? new CanvasImageAnnotation(imageId, colorIndex, image) : null;
            }}
            imageAnnotationResolver={(imageId: string) => toolboxData.markers[imageId]?.image ?? null}
            allowDrop={Object.keys(toolboxData.markers).length > 0}
            onCanvasChange={(canvas)=>{
              let sectorArrayFull = canvas.getSectorsForAnnotations();
              let sector = sectorArrayFull != null ? (sectorArrayFull.length > 0 ? sectorArrayFull[0].label  : null) : undefined;
              let sectorArray = sectorArrayFull != null ? (sectorArrayFull.map(x => x.label)) : undefined;
                
              container.functions.compositeUpdate([
                { componentId: props.id, propName: 'value', value: canvas.getAnnotationData() },
                { componentId: props.id, propName: 'sector', value: sector },
                { componentId: props.id, propName: 'sectorArray', value: sectorArray },
                { componentId: props.id, propName: 'sectorArrayFull', value: sectorArrayFull },
              ]);
            }}
            storeImage={props.image ?? canvasBgImage}
            moveAsDefault={props.moveAsDefault ?? true}
            storeImageBgColor={storeBgColor}
            storeImageResult={storeImageData}
            storeImageScale={props.storeImageScale ?? 1.5}
            imageResultId={imageResultId}
            disallowAnnotationMoving={props.disallowAnnotationMoving}
            annotationData={props.value}
            getColorByIndex={getColorByIndex}
            backgroundAnnotationData={props.backgroundAnnotationData}
            backgroundAnnotationColor={'auto'} /* TODO: dictate behaviour by property? E.g. be able to setup so that background annotations are rendered with a lighter color scheme? */
            keepLoadedAnnotationsWithinColorIndex={inListAndCommunicating ? true : false} // needs to be true since we enforce that all anotations made are within current color index and may not be the case when a list index is deleted and consequently colorIndex is changed for existing annotations
            colorIndex={colorIndex}
            rightClickAsUndo={(props.rightClickUndo ?? 'none') as ('none' | 'color' | 'global')}
        />
        {props.toolbox?.position === 'right' ? toolboxData.container : null}
    </>;

  const ret = IsInDirectRowContext(container.context, container.templateContext)
    ? <div className={'painter'}>{inner}</div>
    : <div className={'container painter' + alignmentClass} style={containerStyle}>
            {inner}
        </div>;

  return ret;
};

const painterComponentKey = 'Painter';
export const SectraPainter : SrComponent<PainterProps> = {
  key: painterComponentKey,
  render: (props, context, templateContext, functions) => <SectraPainterComponent props={props} context={context} templateContext={templateContext} functions={functions} />,
  toolboxName: painterComponentKey,
  template: getTemplate,
  schema: schema.getSchema(painterComponentKey, schemaProps, ['id'], false),
  getInitValues: () => ({ 'value': null }),
  onStateChangeRunner: onStateChangeRunner,
};

interface MarkerData {
  id: string; 
  mainId: string;
  name: string;
  image: HTMLImageElement;
  imageData: string;
}

interface ToolboxData {
  container: JSX.Element | null;
  markers: { [id: string]: MarkerData };
}

function getToolboxAndMarkers(props: PainterProps, isDarkTheme: boolean): ToolboxData {
  const getIconSize = (w: number | undefined, h: number | undefined) => {
    if (w == null && h == null) {
      return { width: undefined, height: undefined };
    }
        
    return w != null 
      ? { width: w, height: h !== undefined ? h : w }
      : { width: w !== undefined ? w : h, height: h };
  };

  let toolbox: JSX.Element | null = null;
  let markers: { [id: string]: MarkerData } = {};
  if (props.toolbox != null) {
    const defIconSize = getIconSize(props.toolbox.iconWidth, props.toolbox.iconHeight);
    const toolboxItems: JSX.Element[] = []; 

    // adding markers
    if (props.toolbox.markers != null && Array.isArray(props.toolbox.markers)) {
      const toolBoxSvgColor = (isDarkTheme ? props.toolbox.darkSvgColor : props.toolbox.lightSvgColor) ?? props.toolbox.svgColor;

      toolboxItems.push(...props.toolbox.markers.map((dm, i) => {   
        const svgColor = (isDarkTheme ? dm.darkSvgColor : dm.lightSvgColor) ?? dm.svgColor ?? toolBoxSvgColor;
                
        const itemIconSize = getIconSize(dm.toolboxWidth, dm.toolboxHeight);
        const iconSize = itemIconSize.width != null 
          ? itemIconSize : defIconSize;
                
        const iconStyle: React.CSSProperties = { };
        if (iconSize.width != null) {
          iconStyle.width = iconSize.width + 'px';
          iconStyle.height = iconSize.height + 'px';
        }

        const toolboxStyle: React.CSSProperties = { };
        if (defIconSize.width != null) {
          toolboxStyle.minWidth = defIconSize.width + 'px';
          toolboxStyle.minHeight = defIconSize.height + 'px';
        }

        const onDragStart = (e: React.DragEvent<HTMLElement>, imageId: string, image: HTMLImageElement) => {
          e.dataTransfer.setDragImage(image, image.width / 2, image.height / 2);
          e.dataTransfer.setData('img-id', imageId);
        };

        const imageId = props.id + '-tool-' + i;
        const image = (isDarkTheme ? dm.darkImage : dm.lightImage) ?? dm.image;

        if (Array.isArray(image)) {
          return <label key={imageId}>
                        <div className={'toolbox-icon'} style={toolboxStyle}>
                            {image.map((imgData, imgIdx) => {
                              if (svgColor != null && svgColor.length > 0) {
                                imgData = applySvgReplacements(imgData, svgColor);
                              }

                              const htmlImage = new Image();
                              htmlImage.src = imgData;
                              const subImageId = imageId + '-' + imgIdx;
                              markers[subImageId] = { id: subImageId, mainId: imageId, name: dm.name, image: htmlImage, imageData: imgData };
                              return <img alt={dm.name} key={subImageId} src={imgData} id={subImageId} style={iconStyle} draggable={true} onDragStart={e => onDragStart(e, subImageId, htmlImage)} />;
                            })}
                        </div>
                        <span style={{ cursor: 'default' }}>{dm.name}</span>
                    </label>;
        } else {
          let imgData = image;
          if (svgColor != null && svgColor.length > 0) {
            imgData = applySvgReplacements(imgData, svgColor);
          }

          const htmlImage = new Image();
          htmlImage.src = imgData;
          markers[imageId] = { id: imageId, mainId: imageId, name: dm.name, image: htmlImage, imageData: imgData };
          return <label key={imageId} draggable={true} onDragStartCapture={e => onDragStart(e, imageId, htmlImage)}>
                        <div className={'toolbox-icon'} style={toolboxStyle}>
                            <img alt={dm.name} src={imgData} id={imageId} style={iconStyle} />
                        </div>
                        <span>{dm.name}</span>
                    </label>;
        }
      }));
    }

    // add a toolbox if we've got items in it
    if (toolboxItems.length > 0) {
      toolbox = <div className={'canvas-toolbox'}>
                <div className="canvas-toolbox-buttons">
                    {toolboxItems}
                </div>
            </div>;
    }
  }

  return {
    container: toolbox,
    markers: markers,
  };
}

function onStateChangeRunner(props: PainterProps, set: ExplicitSetterType, get: GetterType, context: ComponentRenderContext) {
  // setup background annotations (communicated from other canvases when in a list and is communicating between list items)

  let bgAnnotations: string | null = null;
  if (props.communicatingInListElements !== false) {       
    const sourceId = context.currentParsedComponent.id;
    if (sourceId != null) {
      const listCount = context.listCount;
      if (listCount != null) {
        const listIndex = context.listIndex;
        const sibValues = getSiblingIds(context.prefixBase ?? '', sourceId, listCount ?? 0)
          .filter((_, i) => i !== listIndex)
          .map(id =>  get(id, 'value', false));
    
        bgAnnotations = CanvasAnnotationTransformer.Merge(sibValues);
      }
    }
  }

  set(props.id, 'backgroundAnnotationData', bgAnnotations != null && bgAnnotations !== '' ? bgAnnotations : undefined, 'force');
}

function parseSectorMap(sectorMap?: SectorInfo[]) : MapItem[] | null {
  if (sectorMap == null) {
    return null;
  }

  let ret: MapItem[] = [];
  for (let i = 0; i < sectorMap.length; ++i) {
    let s = sectorMap[i];
    let polygon = s.polygon.match(/[\d.]+/g);
    if (polygon == null || polygon.length < 4) {
      continue;
    }

    let item: MapItem = {
      label: s.name,
      description: s.description ?? '',
      polygon: [],
    };

    for (let j = 0; j < s.polygon.length - 1; j += 2) {
      let x = Number(polygon[j]);
      let y = Number(polygon[j + 1]);
      if (!isNaN(x) && !isNaN(y)) {
        item.polygon.push({ x, y });
      }
    }
    ret.push(item);
  }

  return ret.length > 0 ? ret : null;
}

/*
 *  SVG functions
 */

function applySvgReplacements(imgData: string, colors: SvgColor[]): string {
  try {
    const svgXml = imgData != null && imgData.startsWith('data:image/svg+xml')
      ? (imgData.startsWith('data:image/svg+xml,')
        ? decodeURI(imgData.substr('data:image/svg+xml,'.length))
        : imgData.startsWith('data:image/svg+xml;base64,') ? window.atob(imgData.substr('data:image/svg+xml;base64,'.length)) : null)
      : null;

    if (svgXml == null) {
      return imgData;
    }

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svgXml, 'image/svg+xml');

    // parse style tags (i.e. so that we know what a class reference means in terms of CSS later on)
    const css: { selector: string, css: { [key: string]: string } }[] = [];
    const parseStyle = (styleStr: string) => {
      let m = styleStr.match(/([\w-]+):[^:]+(?=[;}]|$)/gm);
      let ret: { [key: string]: string } = {};
      if (m == null) {
        return ret;
      }
      m.forEach(x => {
        let p = x.split(':', 2);
        ret[p[0]] = p[1];
      });
      return ret;
    };

    const styleTags = xmlDoc.querySelectorAll('style');
    styleTags.forEach(styleTag => {
      const style = styleTag.textContent ?? '';
      const defs = style.match(/([^{}]+)({[^}]+})/gm);
      defs?.forEach(def => {
        const selectors = def.match(/[^{}]+/)?.[0]?.split(',');
        const stle = def.match(/(?<={)[^}]+/);
        if (stle != null && selectors != null) {
          const cssData = parseStyle(stle[0]);
          selectors.forEach(s => {
            css.push({ selector: s, css: cssData });
          });
        }
      });
    });

    // mark-up the DOM with its css based styling based on the css lookup
    const customStyleDataProp = '___StyleData';
    css
      .sort((a, b) => {
        if (a.selector.length > b.selector.length) {
          return 1;
        } else if (a.selector.length === b.selector.length) {
          return 0;
        } else {
          return -1;
        }
      })
      .forEach(c => {
        try {
          let nodes = xmlDoc.querySelectorAll(c.selector);
          nodes.forEach(n => {
            let styleData = (n as any)[customStyleDataProp];
            if (styleData == null) {
              (n as any)[customStyleDataProp] = c.css;
            } else {
              (n as any)[customStyleDataProp] = Object.assign(styleData, c.css);
            }
          });
        } catch (e) {
          applogger.warn('failure to interpret selector: ' + c.selector);
        }
      });

    // go through all our custom styling (i.e. input to this method)
    colors.forEach(c => {
      const nodes = xmlDoc.querySelectorAll(c.selector ?? '*');
      nodes.forEach(node => {
        const elementStyle = node.getAttribute('style');
        const parsedElementStyle = elementStyle != null ? parseStyle(elementStyle) : {};
        if (parsedElementStyle[c.type] != null && parsedElementStyle[c.type] === 'none') {
          // element style for type ([stroke, fill]) explicitly set to none, skipping
          return;
        }

        // check whether we have css affecting this element
        const cssStyle = (node as any)[customStyleDataProp]?.[c.type];
        const hasCssStyle = (cssStyle != null && cssStyle != 'none');

        const nodeAttr = node.getAttribute(c.type);
        const hasNodeAttrSet = nodeAttr != null && nodeAttr != 'none';

        // we will only explicitly set a style to this element if it's styled by CSS or property name ([stroke, fill]) 
        // unless we've got an explicit selector set
        const hasType = hasCssStyle || hasNodeAttrSet || c.selector != null;
        if (hasType) {
          // retain existing style property but clear it from any property of type already set
          let newElementStyle = elementStyle ?? '';
          if (parsedElementStyle[c.type] != null) {
            newElementStyle = newElementStyle.replace(new RegExp(c.type + ':[^;]+;'), '');
          }

          // add the styling from our color swap property (function input) to the style tag and update the node
          newElementStyle = newElementStyle + (newElementStyle.length > 0 ? ';' : '') + c.type + ':' + c.color;
          node.setAttribute('style', newElementStyle);
        }
      });
    });

    // reformat SVG DOM as image data
    const newSvgXml = new XMLSerializer().serializeToString(xmlDoc.documentElement);
    return 'data:image/svg+xml;base64,' + window.btoa(newSvgXml);
  } catch (e) {
    applogger.warn(e);
    return imgData;
  }
}