/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from 'react';
import { expressionPattern, schema, SchemaProperties } from './Schema';
import { ComponentBaseProps, componentBaseSchema } from './SectraInput';
import { ReactComponentContainerProps } from './SrtComponent';
import { CompositeActionItem, SrComponent } from '../BasicTypes';
import { SectraInput } from './SectraBaseComponent/SectraInput';
import { GetRowComponent } from './SectraRow';
import { FormDataOutput } from './FormDataOutput';
import { SvgRef } from '../Generic/SvgRef';
import SectraTooltip from './SectraTooltip';
import { GetDecimalSeparator, NumberAsString } from '../NumberHelper';
import { numberComponentKey } from '../Toolbox/ComponentNames';

// Number component props
interface ComponentNumberProps extends ComponentBaseProps {
  stepSize?: number;
  scale?: number;
  inputScale?: number;
  enforceValueRange?: 'none' | 'loose' | 'strict';
  minValue?: number | string;
  maxValue?: number | string;
  refMinValue?: number;
  refMaxValue?: number;
  refWarningText?: string | null;
  rangeWarningText?: string | null;

  userInputValue?: string;
  isFocused?: boolean;
}

const maxScale = 14;
const componentNumberSchema: SchemaProperties = schema.mergeSchemaProps(componentBaseSchema, {
  'value': { 'type': ['string', 'number', 'null'], 'description': 'An initial value for the number field (number or string expression)', 'pattern': expressionPattern },
  'minValue': { 'type': ['string', 'number'], 'description': 'Spinner input min-value (number or string expression).', 'pattern': expressionPattern }, 
  'maxValue': { 'type': ['string', 'number'], 'description': 'Spinner input max-value (number or string expression).', 'pattern': expressionPattern }, 
  'stepSize': { 'type': ['string', 'number'], 'description': 'Spinner input value step size (number or string expression).', 'pattern': expressionPattern }, 
  'scale': { 'type': ['string', 'number'], 'description': 'Number of decimals (digits allowed after the decimal point) allowed as a value between 0 and ' + maxScale + ' (default: ' + maxScale + ').' }, 
  'inputScale': { 'type': ['string', 'number'], 'description': 'Number of decimals (digits allowed after the decimal point) allowed in input mode (default: [scale]).' }, 
  'enforceValueRange': { 'type': 'string', 'enum': ['strict', 'loose', 'none'], 'enumDescription': ['The component value must be a valid number within range, i.e. min/max value', 'The component value must be a valid number within range or null', 'No validation is performed (the min/max property will only affect the up/down spinner buttons)'], 'description': 'Enforce that the component value is valid and within range (default: loose).' }, 
  'refMinValue': { 'type': ['string', 'number'], 'description': 'A lower bound for a reference interval, or recommended min value (default: none)' }, 
  'refMaxValue': { 'type': ['string', 'number'], 'description': 'A upper bound for a reference interval, or recommended max value (default: none)' },
  'refWarningText': { 'type': ['string', 'null'], 'description': 'A warning text that will be displayed if the value is outside the bounds of the reference interval (default: [generic]).' },
  'rangeWarningText': { 'type': ['string', 'null'], 'description': 'A warning text that will be displayed if the input value is outside the bounds of the allowed values (default: [generic]).' },
});

const InputNumberComponent: React.FC<ReactComponentContainerProps<ComponentNumberProps>> = (container) => {
  const props = container.props;
  const langCode = container.templateContext.langCode;
  const decimalSeparator = GetDecimalSeparator(langCode);
  const allowedDecimalChars = [',', '.', decimalSeparator];
  const renderDecimalsAllowed = getDecimalCountFromProp(props.scale);
  const inputDecimalsAllowed = getDecimalCountFromProp(props.inputScale, renderDecimalsAllowed);
  const valueDecimalsAllowed = Math.max(renderDecimalsAllowed, inputDecimalsAllowed);

  // Validate that the keys pressed are OK
  const onKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>): boolean => {
    const target = evt.target as HTMLInputElement;
    const existingValue = String(target.value);
    const selStart = target.selectionStart ?? 0;
    const selEnd = target.selectionEnd ?? 0;
    const preInsert = target.value.substr(0, selStart);
    const postInsert = target.value.substr(selEnd);

    let isAllowed = false;
    if (evt.key >= '0' && evt.key <= '9') {
      const decSepIndex = preInsert.indexOf(decimalSeparator);
      const currDecimalCount = decSepIndex !== -1 ? preInsert.length - decSepIndex - 1 + postInsert.length : -1;
      isAllowed = currDecimalCount < inputDecimalsAllowed;
    } else if (allowedDecimalChars.indexOf(evt.key) >= 0) {
      // allow if not already present
      isAllowed = preInsert.indexOf(decimalSeparator) === -1 && postInsert.indexOf(decimalSeparator) === -1 && inputDecimalsAllowed > 0;
      if (isAllowed && (evt.key !== decimalSeparator || target.selectionStart === 0)) {
        isAllowed = false;
        document.execCommand('insertText', false, (target.selectionStart === 0 ? '0' : '') + decimalSeparator);
      }
    } else if (evt.key === '-' || evt.key === '+') {
      // allow if we're at the begining and replacing the start or the start doesnt already contain a plus or minus
      isAllowed = selStart <= 0 && ((selEnd - selStart) > 0
                || !(existingValue.startsWith('+') || existingValue.startsWith('-')));
    }

    if (!isAllowed) {
      evt.preventDefault();
    }

    return isAllowed;
  };

  const stepValue = (increment: -1 | 1, currentValue?: number) => {
    if (props.stepSize != null && !isNaN(+props.stepSize)) {
      increment *= +props.stepSize;
    }

    const stepDecimals = increment.toString().split('.')[1]?.length ?? 0;
    const stepPow = Math.pow(10, stepDecimals);
    const round = (val: number) => Math.round(val * stepPow) / stepPow;

    const current = currentValue ?? getAsValue(props.value) ?? 0;
    let steppedValue = round(current);
    if ((increment > 0 && steppedValue <= current) || (increment < 0 && steppedValue >= current)) {
      steppedValue = round(steppedValue + increment);
    }

    const validatedNewValue = getValidNumberInputValue(steppedValue, props, 'force', valueDecimalsAllowed);

    container.functions.compositeUpdate([
      { componentId: props.id, propName: 'userInputValue', value: NumberAsString(validatedNewValue, langCode) },
      { componentId: props.id, propName: 'value', value: validatedNewValue },
    ], false);

    // delay script run to not slow down the step process
    container.functions.runScripts(100, [{ type: 'setUserInput', compId: props.id, propName: 'value' }]);
  };

  // Implement Step Up/down by arrows
  const onKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>): boolean => {
    if (evt.keyCode == 38 || evt.keyCode == 40) {
      stepValue(evt.keyCode == 38 ? 1 : -1);
      evt.preventDefault();
      return false;
    }

    return true;
  };

  // Handle paste of data
  const onPaste = (evt: React.ClipboardEvent<HTMLInputElement>): boolean => {
    const paste = evt.clipboardData?.getData('text/plain');
    if (paste == null || paste === '') {
      evt.preventDefault();
      return false;
    }

    const target = evt.target as HTMLInputElement;
    const preInsert = target.value.substr(0, target.selectionStart ?? 0);
    const postInsert = target.value.substr(target.selectionEnd ?? 0);

    let pasteValue = '';
    if (!postInsert.startsWith('+') && !postInsert.startsWith('-')) {
      let hasDecimalSeparator = allowedDecimalChars.some(c => preInsert.indexOf(c) !== -1 || postInsert.indexOf(c) !== -1);

      // start writing values into the given position
      for (let i = 0; i < paste.length; ++i) {
        const char = paste[i];
        if (char >= '0' && char <= '9') {
          pasteValue += char;
        } else if (allowedDecimalChars.indexOf(char) !== -1 && !hasDecimalSeparator) {
          pasteValue += decimalSeparator;
          hasDecimalSeparator = true;
        } else if ((char === '+' || char === '-') && preInsert === '' && pasteValue === '') {
          pasteValue += char;
        }
      }
    }

    if (pasteValue !== '') {
      // update form to allow for better UI with undo state and matching of react update with current input
      document.execCommand('insertText', false, pasteValue);

      const newValue = preInsert + pasteValue + postInsert;
      const validatedNewValue = getValidNumberInputValue(newValue, props, true, inputDecimalsAllowed);
      container.functions.compositeUpdate([
        { componentId: props.id, propName: 'userInputValue', value: NumberAsString(validatedNewValue, langCode) },
        { componentId: props.id, propName: 'value', value: getValidNumberInputValue(newValue, props, true, valueDecimalsAllowed) },
      ]);
    }

    evt.preventDefault();
    return false;
  };

  const componentRenderValue = props.isFocused === true 
    ? props.userInputValue ?? NumberAsString(getValidNumberInputValue(props.value, props, true, inputDecimalsAllowed), langCode)
    : NumberAsString(getValidNumberInputValue(props.value, props, true, renderDecimalsAllowed), langCode);

  const spinnerTimeout = useRef(null as NodeJS.Timeout | null);
  const [spinnerActive, setSpinnerActive] = useState<'up' | 'down' | ''>('');
  const deActiveSpinner = () => {
    if (spinnerTimeout.current) {
      clearInterval(spinnerTimeout.current);
      spinnerTimeout.current = null;
    }
    setSpinnerActive('');
  };

  const onSpinnerActivate = (evt: React.MouseEvent<HTMLSpanElement, MouseEvent>, inc: -1 | 1) => { 
    stepValue(inc); 
    deActiveSpinner();
    setSpinnerActive(inc > 0 ? 'up' : 'down');

    const target = (evt.target as HTMLElement)?.closest('.input-number-container')?.querySelector('input') as HTMLInputElement;
    if (target != null) {
      spinnerTimeout.current = setTimeout(() => {
        spinnerTimeout.current = setInterval(() => {
          stepValue(inc, getValidNumberInputValue(target.value, props, 'force', valueDecimalsAllowed) ?? 0); 
        }, 50);
      }, 250);
    }
  };

  const templateDefualts = container.templateContext.defaults;
  const componentValueError = getValueState(props);
  const tooltipText = componentValueError?.type === 'warning'
    ? (props.refWarningText !== undefined ? props.refWarningText : templateDefualts.inputNumberRefWarningText)
    : (componentValueError?.type === 'error'
      ? (props.rangeWarningText !== undefined ? props.rangeWarningText : templateDefualts.inputNumberRangeWarningText)
      : null);
  const showTooltip = props.isFocused && tooltipText != null && tooltipText !== '';
  return GetRowComponent(props, container.context, container.templateContext,
        <>
        <SectraTooltip tooltipMessage={tooltipText} forceShow={showTooltip} style={componentValueError?.type ?? 'warning'}>
        <FormDataOutput id={props.id} value={getValidNumberInputValue(props.value, props, true, valueDecimalsAllowed)} type="number"
            name={props.name ?? props.inherritedLabel ?? props.id} mandatory={props.mandatory}
            worklistAttribute={props.worklistAttribute} freeField={props.freeField} />
        <div className={'input-number-container' + (props.isFocused === true ? ' focused' : '')}>
            <div className={'spinner-container' + (spinnerActive !== '' ? ' active-' + spinnerActive : '')}>
                <div className="input-spinner-up" onMouseDown={(evt) => onSpinnerActivate(evt, 1)} onMouseUp={deActiveSpinner} onMouseLeave={deActiveSpinner}><SvgRef id="icon-ArrowUp" /></div>
                <div className="input-spinner-down" onMouseDown={(evt) => onSpinnerActivate(evt, -1)} onMouseUp={deActiveSpinner} onMouseLeave={deActiveSpinner}><SvgRef id="icon-ArrowDown" /></div>
            </div>
            <SectraInput
                id={props.id}
                type="text"
                data-type="number"
                autoComplete="off"
                onInputChange={(userInputValue: string) => {
                  container.functions.compositeUpdate([
                    { componentId: props.id, propName: 'userInputValue', value: userInputValue },
                    { componentId: props.id, propName: 'value', value: getValidNumberInputValue(userInputValue, props, true, valueDecimalsAllowed) },
                  ], false);

                  // delay script run to not slow down the input process
                  container.functions.runScripts(25, [{ type: 'setUserInput', compId: props.id, propName: 'value' }]);
                }}
                value={componentRenderValue}
                onKeyPress={onKeyPress}
                onKeyDown={onKeyDown}
                onPaste={onPaste}
                onFocus={()=>{
                  const actions: CompositeActionItem[] = [ { componentId: props.id, propName: 'isFocused', value: true } ];
                  let currentValue = getAsValue(props.value);
                  if (currentValue != null && (props.enforceValueRange === 'strict' || props.userInputValue != null)) {
                    // re-validate the value since value might been change by others as well as min/max props might have changed
                    const reValidatedValue = NumberAsString(getValidNumberInputValue(currentValue, props, true, inputDecimalsAllowed), langCode); 
                    actions.push({ componentId: props.id, propName: 'userInputValue', value: reValidatedValue });
                  }
                  container.functions.compositeUpdate(actions, false);
                }}
                onBlur={() => {
                  container.functions.setUserInput(props.id, 'isFocused', false, false);
                }}
                name={props.name ?? props.inherritedLabel ?? props.id}
                style={{ maxWidth: '100%' }}
                placeholder={props.placeholder}
                disabled={props.disabled === true}
                speechEnabled={false} />
        </div>
        </SectraTooltip>
        </>, 'sectra-input-number' + (componentValueError?.type != null ? ' component-' + componentValueError.type : ''));
};

//
// The number component
//

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Number : SrComponent<ComponentNumberProps> = {
  key: numberComponentKey,
  render: (props, context, templateContext, functions) => <InputNumberComponent props={{ ...props, size: props.size ?? 'xs' }} context={context} templateContext={templateContext} functions={functions} />,
  template: () => Promise.resolve(`- ${numberComponentKey}:
    id: number${schema.getNextIdNum()}
    size: xs`),
  getInitValues: () => ({ 'value': null }),
  toolboxName: numberComponentKey,
  schema: schema.getSchema(numberComponentKey, componentNumberSchema),
  onStateChangeRunner: (props, set) => {
    const renderDecimalsAllowed = getDecimalCountFromProp(props.scale);
    const inputDecimalsAllowed = getDecimalCountFromProp(props.inputScale, renderDecimalsAllowed);
    const valueDecimalsAllowed = Math.max(renderDecimalsAllowed, inputDecimalsAllowed); 

    set(props.id, 'value', getValidNumberInputValue(props.value, props, true, valueDecimalsAllowed), false);
  },
  normalizeDataSourceValue: (value) => {
    if (typeof value === 'number') {
      return value;
    }
    let numMatch = String(value).match(/-?\d*\.?\d*/);
    return numMatch != null && !isNaN(+numMatch[0]) ? +numMatch[0] : null;
  },
};


//
// Helper functions
//

function getAsValue(value: any): number | null {
  const val = value != null && value !== '' 
    ? (typeof value !== 'number' ? parseFloat(String(value).replace(',', '.')) : value) 
    : null;

  return val != null && !isNaN(val) && isFinite(val) 
    ? val 
    : null;
}

function getValidNumberInputValue(value: any, props: ComponentNumberProps, applyMinAndMax: true | false | 'force', truncateTo?: number): number | null {
  let val = getAsValue(value);
  if (val == null && props.enforceValueRange === 'strict') {
    val = 0;
  }

  if (val != null && truncateTo != null && truncateTo < maxScale) {
    const scale = Math.pow(10, truncateTo);
    val = Math.round(val * scale) / scale;
  }

  if (applyMinAndMax === 'force' || (val != null && applyMinAndMax !== false && props.enforceValueRange !== 'none')) {
    if (props.minValue != null && (val ?? 0) < +(props.minValue)) {
      val = +(props.minValue);
    } else if (props.maxValue != null && (val ?? 0) > +(props.maxValue)) {
      val = +(props.maxValue);
    }
  }

  return val;
}

function getDecimalCountFromProp(prop: any, def?: number): number {
  return prop != null && !isNaN(+prop) && isFinite(+prop)
    ?  Math.max(Math.min(+prop, maxScale), 0)
    : (def ?? maxScale);
}

function getValueState(props: ComponentNumberProps): null | { type: 'error' | 'warning', trigger: 'min' | 'max' | 'refMin' | 'refMax' } {
  const value = getAsValue(props.isFocused ? (props.userInputValue ?? props.value) : props.value);
  if (value === null) {
    // cannot be out of bounds if empty
    return null;
  }

  if (props.enforceValueRange !== 'none') {
    const minValue = getAsValue(props.minValue);
    if (minValue != null && value < minValue) {
      return { type: 'error', 'trigger': 'min' };
    }

    const maxValue = getAsValue(props.maxValue);
    if (maxValue != null && value > maxValue) {
      return { type: 'error', 'trigger': 'max' };
    }
  }

  const refMinValue = getAsValue(props.refMinValue);
  if (refMinValue != null && value < refMinValue) {
    return { type: 'warning', 'trigger': 'refMin' };
  }
  const refMaxValue = getAsValue(props.refMaxValue);
  if (refMaxValue != null && value > refMaxValue) {
    return { type: 'warning', 'trigger': 'refMax' };
  }

  return null;
}