/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import * as React from 'react';
import { applogger } from '../../../applogger';
import { ICanvasAnnotation, IPosition, CanvasSpayAnnotation, CanvasBrushAnnotation, CanvasAnnotationTransformer, MapItem, CanvasSectorFillAnnotation, annotationHelper, SectorData } from './SectraCanvasAnnotation';
import { Canvas3pCurveAnnotation, Canvas4pCurveAnnotation } from './SectraCanvasAnnotations/CanvasCurveAnnotation';
import { CanvasFillAnnotation } from './SectraCanvasAnnotations/CanvasFillAnnotation';
import { CanvasLineAnnotation } from './SectraCanvasAnnotations/CanvasLineAnnotation';

export interface ICanvasPadding {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export interface SectorMap {
  map: MapItem[];
  scaleWidth?: number;
  scaleHeight?: number;
  highlightColor: string;
}

export interface ISectraCanvasProps {
  id: string;
  moveAsDefault: boolean;
  imageResultId?: string;
  colorIndex?: number;
  width?: number;
  height?: number;
  backgroundColor?: string;
  disallowAnnotationMoving?: boolean;
  defaultImage?: string;
  defaultImageScale?: number;
  annotationType?: 'brush' | 'spray' | 'line' | '3pcurve' | '4pcurve' | 'sectorFill' | 'none' | 'fill';
  brushWidth?: number;
  sprayDensity?: number;
  sprayRadius?: number;
  rightClickAsUndo?: 'none' | 'color' | 'global';
  allowDrop?: boolean;
  sectorMap?: SectorMap | null;
  storeImage?: string;
  storeImageResult?: boolean;
  storeImageScale?: number;
  storeImageBgColor?: string;
  annotationData?: string | null;
  backgroundAnnotationData?: string | null;
  backgroundAnnotationColor?: string;
  keepLoadedAnnotationsWithinColorIndex?: boolean,
  getColorByIndex?: (colorIndex: number) => string | undefined;               // get the color for this color index, if returns undefined it will fallback to the Sectra palette
  getColorByIndexBgAnnotations?: (colorIndex: number) => string | undefined;  // get the color for this color index when rendering a background annotation, if returns undefined it will fallback to the Sectra palette

  onCanvasChange?: (canvas: SectraCanvas, annotation: ICanvasAnnotation | null) => void;
  postRepaint?: (canvas: SectraCanvas) => void;

  imageAnnotationResolver?: (name: string) => HTMLImageElement | null;
  dropToAnnotation?: (canvas: SectraCanvas, event: React.DragEvent) => ICanvasAnnotation | null;

  style?: React.CSSProperties;
}

export interface ISectraCanvasState {
  imageData: string;
  cursor: string;
}

export class SectraCanvas extends React.Component<ISectraCanvasProps, ISectraCanvasState> {
  private static defaultProps = {
    brushWidth: 2,
    sprayDensity: 10,
    sprayRadius: 8,
  };

  private static cursors = {
    default: 'default',
    action: 'pointer',
    move: 'move',
    painting: 'crosshair',
  };

  private canvasRef: React.RefObject<HTMLCanvasElement>;

  private canvas: HTMLCanvasElement | null;

  private ctx: CanvasRenderingContext2D | null;

  private defaultImage: HTMLImageElement | null;

  private storeImage: HTMLImageElement | null;

  private annotations: ICanvasAnnotation[];

  private backgroundAnnotations: ICanvasAnnotation[];

  private paintingAnnotation: ICanvasAnnotation | null;

  private moveAnnotation: ICanvasAnnotation | null;

  private hoveringAnnotation: ICanvasAnnotation | null;

  private moveAnnotationChange: boolean;

  private defaultImageWidth?: number;

  private defaultImageHeight?: number;

  private storeHandle: NodeJS.Timeout | null;

  private sectorMap: SectorMap | null;

  constructor(props: ISectraCanvasProps, context: any) {
    super(props, context);

    this.canvasRef = React.createRef();
    this.hoveringAnnotation = null;
    this.paintingAnnotation = null;
    this.moveAnnotation = null;
    this.moveAnnotationChange = false;
    this.canvas = null;
    this.ctx = null;
    this.defaultImage = null;
    this.storeImage = null;
    this.storeHandle = null;
    this.annotations = [];
    this.backgroundAnnotations = [];
    this.sectorMap = props.sectorMap != null ? props.sectorMap : null;
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.endCurrentMouseAction = this.endCurrentMouseAction.bind(this);
    this.loadDefaultImage = this.loadDefaultImage.bind(this);
    this.loadStoreImage = this.loadStoreImage.bind(this);
    this.validateAndUpdateCanvasSize = this.validateAndUpdateCanvasSize.bind(this);
    this.storeImageData = this.storeImageData.bind(this);
    this.signalOnChange = this.signalOnChange.bind(this);
    this.exportToCanvas = this.exportToCanvas.bind(this);
    this.redrawCanvas = this.redrawCanvas.bind(this);
    this.getActionAnnotation = this.getActionAnnotation.bind(this);
    this.loadAnnotationsFromData = this.loadAnnotationsFromData.bind(this);
    this.getSectorsForAnnotations = this.getSectorsForAnnotations.bind(this);
    this.state = { imageData: '', cursor: SectraCanvas.cursors.default };

    this.loadAnnotationsFromData(props);
  }

  private onMouseDown(event: React.MouseEvent) {
    if (this.ctx == null) {
      return;
    }

    if (this.paintingAnnotation != null) {
      this.endCurrentMouseAction(event);
      if (event.button === 2)  {
        this.setState({ cursor: SectraCanvas.cursors.action });
      }
    }

    const nativeEvent: any = event.nativeEvent;
    let pos : IPosition = { x: nativeEvent.offsetX, y: nativeEvent.offsetY };
    applogger.debug(nativeEvent.offsetX + ' ' + nativeEvent.offsetY + ', ');
    // Check if we're clicking on an existing annotation
    if (event.button === 0) {
      if (event.ctrlKey) {
        // treat control-click on an annotation as deleting
        var deleting = this.getActionAnnotation(pos);
        if (deleting != null) {
          this.annotations = this.annotations.filter(a => a !== deleting);
          this.redrawCanvas();
          this.storeImageData();
          this.signalOnChange();
          return;
        }
      } else {
        const hoveredAnnotation = this.getActionAnnotation(pos);
        if (hoveredAnnotation) {
          if (hoveredAnnotation.isIncomplete?.() || !this.props.disallowAnnotationMoving && 
            (!nativeEvent.shiftKey && this.props.moveAsDefault || nativeEvent.shiftKey && !this.props.moveAsDefault)) {
            this.moveAnnotation = hoveredAnnotation;
            const mRes = this.moveAnnotation.startMove?.(pos);
            if (mRes?.isValid === false) {
              this.moveAnnotation = null;
            } else if (mRes?.redraw === true) {
              this.redrawCanvas();
              this.storeImageData();
              this.signalOnChange();
            }
          }
        }
        this.moveAnnotationChange = false;
        if (this.moveAnnotation != null) {
          this.setState({ cursor: SectraCanvas.cursors.move });
          return;
        }
      }
    }

    // Starting new annotation?
    if (event.button === 0 && this.props.annotationType != null) {
      let cIndex = this.props.colorIndex ?? 0;
      this.setColorForIndex(cIndex);

      // setup some defaults
      this.ctx.lineWidth = 2;
      this.ctx.lineJoin = 'round';
      this.ctx.lineCap = 'round';

      if (this.props.annotationType === 'brush') {
        this.paintingAnnotation = new CanvasBrushAnnotation(cIndex, this.props.brushWidth ??  SectraCanvas.defaultProps.brushWidth);
      } else if (this.props.annotationType === 'spray') {
        this.paintingAnnotation = new CanvasSpayAnnotation(cIndex, this.props.sprayDensity ?? SectraCanvas.defaultProps.sprayDensity, this.props.sprayRadius ?? SectraCanvas.defaultProps.sprayRadius);
      } else if (this.props.annotationType === 'sectorFill') {
        this.paintingAnnotation = new CanvasSectorFillAnnotation(cIndex);
      } else if (this.props.annotationType === 'line') {
        this.paintingAnnotation = new CanvasLineAnnotation(cIndex, this.props.brushWidth ?? SectraCanvas.defaultProps.brushWidth);
      } else if (this.props.annotationType === '4pcurve') {
        this.paintingAnnotation = new Canvas4pCurveAnnotation(cIndex, this.props.brushWidth ?? SectraCanvas.defaultProps.brushWidth);
      } else if (this.props.annotationType === '3pcurve') {
        this.paintingAnnotation = new Canvas3pCurveAnnotation(cIndex, this.props.brushWidth ?? SectraCanvas.defaultProps.brushWidth);
      } else if (this.props.annotationType === 'fill') {
        this.paintingAnnotation = new CanvasFillAnnotation(cIndex);
      }

      if (this.paintingAnnotation != null) {
        let success = this.paintingAnnotation.startDraw(this.ctx, pos, this.sectorMap?.map);
        if (success) {
          this.annotations.push(this.paintingAnnotation);
          if (this.paintingAnnotation.canFocus?.()) {
            this.hoveringAnnotation = this.paintingAnnotation;
          }

          let cursor = SectraCanvas.cursors.painting;
          if (this.state.cursor !== cursor) {
            this.setState({ cursor: cursor });
          }
          applogger.log('added new annotation');
        } else {
          // TODO: in an optimal world, this should probably in some way be relayed to user
          applogger.info('invalid annotation placement, cannot draw');
          this.paintingAnnotation = null;
        }
      }

      return;
    }

    // Delete annotation?
    if (event.button === 2) {
      const clickedAnnotation = this.getActionAnnotation(pos);
      if (clickedAnnotation != null) {
        this.annotations = this.annotations.filter(a => a !== clickedAnnotation);

        if (clickedAnnotation == this.hoveringAnnotation) {
          this.hoveringAnnotation = null;
        }

        this.redrawCanvas();
        this.storeImageData();
        this.signalOnChange();
      } else {
        if (this.props.rightClickAsUndo === 'color') {
          this.undoLastAnnotationWithinColor();
        } else if (this.props.rightClickAsUndo === 'global') {
          this.undoLastAnnotation();
        }
      }

      // don't display right click menu
      event.stopPropagation();
    }
  }

  private onMouseMove(event: React.MouseEvent) {
    const nativeEvent = event.nativeEvent;
    let pos: IPosition = { x: nativeEvent.offsetX, y: nativeEvent.offsetY };
    if (this.paintingAnnotation != null) {
      let redraw = this.paintingAnnotation.setPoint(pos);
      if (redraw === true) {
        this.redrawCanvas();
      }
      return;
    }

    if (this.moveAnnotation != null) {
      if (this.moveAnnotation.setNewBasePoint(pos)) {
        this.moveAnnotationChange = true;
        this.redrawCanvas();
      }
      return;
    }

    // Check if hovering an existing annotation
    const annotationHovered = this.getActionAnnotation(pos);
    if (annotationHovered?.isIncomplete?.() || !this.props.disallowAnnotationMoving && 
      (this.props.moveAsDefault && !nativeEvent.shiftKey || !this.props.moveAsDefault && nativeEvent.shiftKey || nativeEvent.ctrlKey)) {
      let cursor = annotationHovered != null
        ? SectraCanvas.cursors.action
        : SectraCanvas.cursors.default;
                

      if (this.state.cursor !== cursor) {
        this.setState({ cursor: cursor });
      }
    } else if (this.state.cursor === SectraCanvas.cursors.action) {
      this.setState({ cursor: SectraCanvas.cursors.default });
    }

    if (annotationHovered?.canFocus?.() && this.hoveringAnnotation == null) {
      this.hoveringAnnotation = annotationHovered;
      this.redrawCanvas();
    } else if (annotationHovered == null && this.hoveringAnnotation != null) {
      this.hoveringAnnotation = null;
      this.redrawCanvas();
    }
  }

  private getActionAnnotation(pos: IPosition) : ICanvasAnnotation | null {
    for (let i = this.annotations.length - 1; i >= 0; --i) {
      const a = this.annotations[i];
      if (a.getWithinActionBox(pos)) return a;
    }

    return null;
  }

  private onDragOver(event: React.DragEvent) {
    if (this.props.allowDrop) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';
    }
  }

  private onDrop(event: React.DragEvent) {
    if (this.props.dropToAnnotation != null && this.ctx != null) {
      const nativeEvent: any = event.nativeEvent;
      const a = this.props.dropToAnnotation(this, event);
      if (a != null && a.startDraw(this.ctx, { x: nativeEvent.offsetX, y: nativeEvent.offsetY }, this.sectorMap?.map)) {
        this.annotations.push(a);
        this.storeImageData();
        this.signalOnChange();
      }
    }
  }

  private endCurrentMouseAction(event: React.MouseEvent) {
    if (this.paintingAnnotation != null) {
      const res = this.paintingAnnotation.stopDraw?.();
      if (res?.isValid === false) {
        // this annotation isn't valid, thus we'll drop it
        this.annotations = this.annotations.filter(x => x !== this.paintingAnnotation);
        this.redrawCanvas();
      } else if (res?.redraw === true) {
        this.redrawCanvas();
      }

      this.storeImageData();
      this.signalOnChange();
      this.paintingAnnotation = null;
      this.setState({ cursor: SectraCanvas.cursors.action });
    }

    if (this.moveAnnotation != null) {
      const res = this.moveAnnotation.stopMove?.();
      if (res?.isValid === false) {
        // this annotation isn't valid, thus we'll drop it
        this.annotations = this.annotations.filter(x => x !== this.moveAnnotation);
        this.moveAnnotationChange = true;
        this.redrawCanvas();
      } else if (res?.redraw === true) {
        this.moveAnnotationChange = true;
        this.redrawCanvas();
      }

      if (this.moveAnnotationChange) {
        this.storeImageData();
        this.signalOnChange();
      }

      this.moveAnnotation = null;
      this.moveAnnotationChange = false;
      this.setState({ cursor: SectraCanvas.cursors.action });
    }

    if (event.type === 'mouseleave' || event.type === 'mouseout' && this.hoveringAnnotation !== null) {
      this.hoveringAnnotation = null;
    }
  }

  private signalOnChange() {
    if (this.props.onCanvasChange != null) { 
      //applogger.debug("signal update");
      this.props.onCanvasChange(this, this.paintingAnnotation);
    }
  }

  private storeImageData() {
    let canvas = this.canvas;
    if (canvas == null || this.props.storeImageResult === false) {
      return;
    }

    if (this.storeHandle) {
      clearTimeout(this.storeHandle);
      this.storeHandle = null;
    }
        
    this.storeHandle = setTimeout(() => {
      if (canvas != null) {
        let tmpCanvas = this.exportToCanvas(
          this.props.storeImageScale ?? 2.0, 
          this.storeImage ?? undefined,
          this.props.storeImageBgColor);

        //applogger.debug("store image");
        if (tmpCanvas != null) {
          this.setState({ imageData: tmpCanvas.toDataURL('image/png') });
        }
      }
    }, 200);
  }

  private scaleSectorMap(sectorMap: SectorMap | null | undefined, width: number, height: number): SectorMap | null {
    if (sectorMap == null) {
      return null;
    }

    let scaleH = sectorMap.scaleHeight != null && sectorMap.scaleHeight > 0 ? height / sectorMap.scaleHeight : undefined;
    let scaleW = sectorMap.scaleWidth != null && sectorMap.scaleWidth > 0 ? width / sectorMap.scaleWidth : undefined;
    scaleW = scaleW ?? scaleH;
    scaleH = scaleH ?? scaleW;
    if (scaleW == null || scaleH == null) {
      return sectorMap;
    }
        
    return {
      map: sectorMap.map.map(item => {
        return {
          label: item.label,
          description: item.description,
          polygon: item.polygon.map(point => ({ x: point.x * (scaleW ?? 1.0), y: point.y * (scaleH ?? 1.0) })),
        };
      }),
      scaleHeight: height,
      scaleWidth: width,
      highlightColor: sectorMap.highlightColor,
    };
  }

  private getBackgroundImageDrawScale() {
    if (this.props.defaultImageScale != null && this.props.defaultImageScale > 0) {
      return this.props.defaultImageScale;
    }

    if (this.defaultImageWidth != null && this.defaultImageHeight != null) {
      if (this.props.width != null && this.props.height != null) {
        return Math.min(this.props.width / this.defaultImageWidth, this.props.height / this.defaultImageHeight);
      }

      if (this.props.width != null && this.props.height == null) {
        return this.props.width / this.defaultImageWidth;
      }
            
      if (this.props.height != null && this.props.width == null) {
        return this.props.height / this.defaultImageHeight;
      }
    }

    return 1.0;
  }

  private validateAndUpdateCanvasSize() {
    if (this.canvas == null) {
      return;
    }
    //applogger.debug("validate");
    let width: number | null = null;
    let height: number | null = null;

    if (this.props.width != null && this.props.height != null) {
      // booth set
      width = this.props.width;
      height = this.props.height;
    } else if (this.defaultImageWidth != null && this.defaultImageHeight != null) {
      if (this.props.width == null && this.props.height == null) {
        // none set, setup as image
        const scale = this.getBackgroundImageDrawScale();
        width = this.defaultImageWidth * scale;
        height = this.defaultImageHeight * scale;
      } else {
        // one set, auto set the other one based in image aspect ratio
        let aspectRatio = this.defaultImageWidth / this.defaultImageHeight;
        if (this.props.height != null) {
          height = this.props.height;
          width = height * aspectRatio;
        } else if (this.props.width != null) {
          width = this.props.width;
          height = width / aspectRatio;
        }
      }
    }

    if (width != null && height != null && (width != this.canvas.width || height != this.canvas.height)) {
      this.canvas.width = width;
      this.canvas.height = height;
    }

    if (width != null && height != null) {
      this.sectorMap = this.scaleSectorMap(this.props.sectorMap, width, height);
    }

    //applogger.debug({width: this.canvas.width, height: this.canvas.height});
  }

  private loadStoreImage(event: React.SyntheticEvent<HTMLImageElement>) {
    this.storeImage = event.currentTarget;
  }

  private loadDefaultImage(event: React.SyntheticEvent<HTMLImageElement>) {
    // Note that this happends after componentDidMount
    this.defaultImage = event.currentTarget;
    this.defaultImageWidth = this.defaultImage.width;
    this.defaultImageHeight = this.defaultImage.height;
    this.validateAndUpdateCanvasSize();

    if (this.ctx == null || this.canvas == null) {
      return;
    }

    if (this.props.backgroundColor != null) {
      this.ctx.fillStyle = this.props.backgroundColor;
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    } else {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    const scale = this.getBackgroundImageDrawScale();
    this.ctx.drawImage(this.defaultImage, 0, 0, this.defaultImage.width * scale, this.defaultImage.height * scale);

    if (this.props.postRepaint != null) {
      this.props.postRepaint(this);
    }

    this.redrawAnnotations();
    this.storeImageData();
    this.signalOnChange();
  }

  private setColorForIndex(idx: number) {
    if (this.ctx != null) {
      let color = this.getColorByIndex(idx);
      this.ctx.strokeStyle = color;
      this.ctx.fillStyle = color;
    }
  }

  private getColorByIndex(idx: number) {
    if (this.props.getColorByIndex) {
      let color = this.props.getColorByIndex(idx);
      if (color !== undefined) {
        return color;
      }
    }

    // default colors (Sectra SPX): Red 400,  Green 500,  Blue 300, Yellow 500, Orange 500, Purple 500,  Cyan 500,  Pink 500,  Lime 500, Beige 500, Silver 500, Black 500
    const colors: Array<string> = ['#f95041', '#51c260', '#74a4e3',  '#FCDB44',  '#ff7b2e',  '#bb5ad8', '#2fbcc6', '#ef5da6', '#b5d161', '#c39c6f',  '#f7f9fc', '#000000'];
    return colors[idx % colors.length];
  }

  private redrawAnnotations() {
    if (this.ctx == null) {
      return;
    }

    // Redraw any sector map 
    if (this.sectorMap != null && this.sectorMap.map != null && this.sectorMap.highlightColor != null) {
      let ctx = this.ctx;
      let map = this.sectorMap.map;
      ctx.lineWidth = 1;
      ctx.strokeStyle = this.sectorMap.highlightColor;
      for (let i = 0; i < map.length; ++i) {
        let item = map[i];
        annotationHelper.drawPolyLine(ctx, item.polygon, true);
      }
    }
        
    this.ctx.lineWidth = this.props.brushWidth ? this.props.brushWidth : SectraCanvas.defaultProps.brushWidth;

    // Redraw any background annotations
    let bgColor = this.props.backgroundAnnotationColor ?? 'auto'; 
    for (let i = 0; i < this.backgroundAnnotations.length; ++i) {
      let a = this.backgroundAnnotations[i];
      if (this.props.getColorByIndexBgAnnotations != null) {
        let color = this.props.getColorByIndexBgAnnotations(a.getColorIndex());
        if (color !== undefined) {
          this.ctx.fillStyle = this.ctx.strokeStyle = color;
        } else {
          this.setColorForIndex(a.getColorIndex());
        }
      } else if (bgColor === 'auto') {
        this.setColorForIndex(a.getColorIndex());
      } else {
        this.ctx.fillStyle = this.ctx.strokeStyle = bgColor;
      }
      a.redrawToCanvas(this.ctx, false, this.sectorMap?.map);
    }

    // Redraw annotations
    for (let i = 0; i < this.annotations.length; ++i) {
      let a = this.annotations[i];
      this.setColorForIndex(a.getColorIndex());
      a.redrawToCanvas(this.ctx, a === this.paintingAnnotation || a === this.moveAnnotation || a === this.hoveringAnnotation, this.sectorMap?.map);
    }
  }

  public getCurrentLayerColor() {
    return this.getColorByIndex(this.props.colorIndex ?? 0);
  }

  public exportToCanvas(scale: number, backgroundImage?: HTMLImageElement, backgroundColor?: string, padding?: ICanvasPadding, canvas?: HTMLCanvasElement): HTMLCanvasElement | null {
    if (this.canvas == null || this.ctx == null) {
      return null;
    }

    let lP = (padding?.left ?? 0);
    let tP = (padding?.top ?? 0);
    let wP = lP + (padding?.right ?? 0);
    let hP = tP + (padding?.bottom ?? 0);

    let cw = this.canvas.width;
    let ch = this.canvas.height;
    let tCanvas = canvas != null ? canvas : document.createElement('canvas');
    tCanvas.width = wP + cw * scale;
    tCanvas.height = hP + ch * scale;

    let tCtx = tCanvas.getContext('2d');
    if (tCtx == null) {
      return null;
    }

    // setup some default props
    tCtx.lineWidth = this.props.brushWidth ? this.props.brushWidth : SectraCanvas.defaultProps.brushWidth;
    tCtx.lineJoin = 'round';
    tCtx.lineCap = 'round';

    let bgC = backgroundColor ?? this.props.backgroundColor;
    if (bgC != null) {
      tCtx.fillStyle = bgC;
      tCtx.fillRect(0, 0, tCanvas.width, tCanvas.height);
    }

    // setup transform
    tCtx.setTransform(scale, 0, 0, scale, lP, tP);

    let image = backgroundImage ?? this.defaultImage;
    if (image != null) {
      const imgDrawScale = this.getBackgroundImageDrawScale();
      tCtx.drawImage(image, 0, 0, image.width * imgDrawScale, image.height * imgDrawScale);
    }

    // Redraw any sector map 
    if (this.sectorMap != null && this.sectorMap.map != null && this.sectorMap.highlightColor != null) {
      // setup line width and stoke style
      const lw = tCtx.lineWidth;
      const ss = tCtx.strokeStyle;
      tCtx.lineWidth = 1;
      tCtx.strokeStyle = this.sectorMap.highlightColor;

      const map = this.sectorMap.map;
      for (let i = 0; i < map.length; ++i) {
        let item = map[i];
        annotationHelper.drawPolyLine(tCtx, item.polygon, true);
      }
            
      // restore line width and stoke style
      tCtx.lineWidth = lw;
      tCtx.strokeStyle = ss;
    }

    // Redraw any background annotations
    let bgColor = this.props.backgroundAnnotationColor ?? 'auto'; 
    for (let i = 0; i < this.backgroundAnnotations.length; ++i) {
      let a = this.backgroundAnnotations[i];
      let color: string | undefined = undefined;

      if (this.props.getColorByIndexBgAnnotations != null) {
        color = this.props.getColorByIndexBgAnnotations(a.getColorIndex()) 
                    ?? this.getColorByIndex(a.getColorIndex());
      } else if (bgColor === 'auto') {
        color = this.getColorByIndex(a.getColorIndex());
      } else {
        color = bgColor;
      }
      tCtx.fillStyle = tCtx.strokeStyle = color;
      a.redrawToCanvas(tCtx, false, this.sectorMap?.map);
    }
        
    // Draw annotations
    for (let i = 0; i < this.annotations.length; ++i) {
      let a = this.annotations[i];
      let color = this.getColorByIndex(a.getColorIndex());
      tCtx.strokeStyle = tCtx.fillStyle = color;
      a.redrawToCanvas(tCtx, false, this.sectorMap?.map);
    }

    // Reset transform 
    tCtx.setTransform(1, 0, 0, 1, 0, 0);

    return tCanvas;
  }

  public componentDidMount() {
    if (this.canvasRef == null || this.canvasRef.current == null) {
      return;
    }

    this.canvas = this.canvasRef.current;
    this.ctx = this.canvas.getContext('2d');

    // Here we set up the properties of the canvas element. 
    this.canvas.width = this.props.width ?? this.defaultImageWidth ?? 500;
    this.canvas.height = this.props.height ?? this.defaultImageHeight ?? 400;
    if (this.ctx != null && this.props.backgroundColor != null) {
      this.ctx.fillStyle = this.props.backgroundColor;
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }
    this.canvas.oncontextmenu = e => { e.preventDefault(); };
    if (this.props.defaultImage == null) {
      this.validateAndUpdateCanvasSize();
      if (this.props.postRepaint != null) {
        this.props.postRepaint(this);
      }
      this.redrawAnnotations();
      this.storeImageData();
    }
  }

  // Returns true if a property that should trigger an annotation load have been changed
  private shouldTriggerAnnotationLoad(prevProps: ISectraCanvasProps) {
    return prevProps.annotationData != this.props.annotationData && this.props.annotationData != this.getAnnotationData() ||
      prevProps.backgroundAnnotationData != this.props.backgroundAnnotationData ||
      prevProps.storeImageResult !== this.props.storeImageResult ||
      prevProps.storeImageScale !== this.props.storeImageScale ||
      prevProps.storeImageBgColor !== this.props.storeImageBgColor;

  }

  public componentDidUpdate(prevProps: ISectraCanvasProps) {
    let redraw = false;

    // Handle updating annotations
    if (this.shouldTriggerAnnotationLoad(prevProps)) {
      this.loadAnnotationsFromData(this.props);
      redraw = true;
    } 

    // Check for canvas size changes
    if (   prevProps.width != this.props.width 
            || prevProps.height != this.props.height 
            || prevProps.defaultImage != this.props.defaultImage
            || prevProps.defaultImageScale != this.props.defaultImageScale
            || prevProps.backgroundColor != this.props.backgroundColor) {
      this.validateAndUpdateCanvasSize();
      redraw = true;
    }

    if (this.canvas != null && JSON.stringify(prevProps.sectorMap) != JSON.stringify(this.props.sectorMap)) {
      this.sectorMap = this.scaleSectorMap(this.props.sectorMap, this.canvas.width, this.canvas.height);
      redraw = true;
    }

    if (redraw) {
      this.redrawCanvas();
      this.storeImageData();
      this.signalOnChange();
    }
  }
    
  private loadAnnotationsFromData(props: ISectraCanvasProps) {
    this.annotations = CanvasAnnotationTransformer.Deserialize(props.annotationData, props.imageAnnotationResolver);
    this.backgroundAnnotations = CanvasAnnotationTransformer.Deserialize(props.backgroundAnnotationData, props.imageAnnotationResolver);
        
    if (this.props.keepLoadedAnnotationsWithinColorIndex === true) {
      let update = false;
      let cIndex = props.colorIndex ?? 0;
      this.annotations.forEach(a => {
        if (a.getColorIndex() != cIndex) {
          a.setColorIndex(cIndex);
          update = true;
        }
      });

      if (update) {
        this.storeImageData();
        this.signalOnChange();
      }
    }
  }

  public getAnnotations(): ICanvasAnnotation[] {
    return this.annotations;
  }

  public getAnnotationData(colorIndex?: number): string | null {
    let annotations = colorIndex == null 
      ? this.getAnnotations()
      : this.getAnnotations().filter(a => a.getColorIndex() === colorIndex);

    return CanvasAnnotationTransformer.Serialize(annotations);
  }

  public getCtx(): CanvasRenderingContext2D | null {
    return this.ctx;
  }

  public getWidth(): number | null {
    return this.canvas != null ? this.canvas.width : null;
  }

  public getHeight(): number | null {
    return this.canvas != null ? this.canvas.height : null;
  }

  public getImageData(): ImageData | null {
    if (this.ctx != null && this.canvas != null) {
      return this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
    }
    return null;
  }

  public getSectorMap(): SectorMap | null {
    return this.sectorMap;
  }

  public clearMap(storeAndUpdate?: boolean) {
    if (this.ctx == null || this.canvas == null) {
      return;
    }

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    if (this.props.backgroundColor != null) {
      this.ctx.fillStyle = this.props.backgroundColor;
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }

    if (this.defaultImage) {
      const scale = this.getBackgroundImageDrawScale();
      this.ctx.drawImage(this.defaultImage, 0, 0, this.defaultImage.width * scale, this.defaultImage.height * scale);
    }

    if (storeAndUpdate) {
      this.storeImageData();
      this.signalOnChange();
    }
  }

  public undoLastAnnotation() {
    if (this.annotations.length === 0) {
      return;
    }

    // Remote last annotation and clear the map
    const removed = this.annotations.pop();
    if (removed == this.hoveringAnnotation) {
      this.hoveringAnnotation = null;
    }

    this.redrawCanvas();
    this.storeImageData();
    this.signalOnChange();
  }

  public redrawCanvas() {
    this.clearMap(false);
    if (this.props.postRepaint != null) {
      this.props.postRepaint(this);
    }
    this.redrawAnnotations();
  }

  public undoLastAnnotationWithinColor(colorIndex?: number) {
    colorIndex = colorIndex != null ? colorIndex : this.props.colorIndex;
    let removed: ICanvasAnnotation | null = null;
    for (let i = this.annotations.length - 1; i >= 0; --i) {
      let a = this.annotations[i];
      if (a.getColorIndex() === colorIndex) {
        this.annotations.splice(i, 1);
        removed = a;
        break;
      }
    }

    if (removed != null) {
      if (removed == this.hoveringAnnotation) {
        this.hoveringAnnotation = null;
      }

      this.redrawCanvas();
      this.storeImageData();
      this.signalOnChange();
    }
  }

  public clearByColorIndex(colorIndex?: number) {
    colorIndex = colorIndex != null ? colorIndex : this.props.colorIndex;
    let removed = false;
    for (let i = this.annotations.length - 1; i >= 0; --i) {
      let a = this.annotations[i];
      if (a.getColorIndex() === colorIndex) {
        this.annotations.splice(i, 1);
        removed = true;
      }
    }

    if (removed) {
      this.redrawCanvas();
      this.storeImageData();
      this.signalOnChange();
    }
  }

  public removeByColorIndex(colorIndex?: number): void {
    colorIndex = colorIndex != null ? colorIndex : this.props.colorIndex;

    for (let i = this.annotations.length - 1; i >= 0; --i) {
      let a = this.annotations[i];
      if (a.getColorIndex() === colorIndex) {
        this.annotations.splice(i, 1);
      } else if (colorIndex !== undefined && a.getColorIndex() > colorIndex) {
        a.setColorIndex(a.getColorIndex() - 1);
      }
    }

    this.redrawCanvas();
    this.storeImageData();
    this.signalOnChange();
  }

  public getSectorsForAnnotations(map?: MapItem[], annotations?: ICanvasAnnotation[]): SectorData {
    map = map ?? this.sectorMap?.map;
    annotations = annotations ?? this.getAnnotations();
    if (map == null || map.length === 0 || annotations.length === 0) {
      return [];
    }

    return annotationHelper.getSectorsForAnnotations(annotations, map);
  }

  public render() {
    const {
      id, 
      imageResultId,
      defaultImage,
      storeImage,
      backgroundColor,
      style: parentStyle,
      storeImageResult,
    } = this.props;

    // default to transparent background color (otherwise he canvas default i white)
    let bgColor = backgroundColor ? backgroundColor : '#0000';

    let style = parentStyle != null ? parentStyle : {};
    style.background = bgColor;
    style.cursor = this.state.cursor;

    return (
            <div>
                <canvas id={id} ref={this.canvasRef}
                    style={style}
                    onMouseDown={this.onMouseDown}
                    onMouseLeave={this.endCurrentMouseAction}
                    onMouseUp={this.endCurrentMouseAction}
                    onMouseOut={this.endCurrentMouseAction}
                    onMouseMove={this.onMouseMove}
                    onDragOver={this.onDragOver}
                    onDrop={this.onDrop}
                />

                {storeImageResult !== false ? <input type="hidden" name={imageResultId ?? id} data-canvas-id={imageResultId ?? id} data-field-type="image/png" data-custom-field-type="canvas" value={this.state.imageData} readOnly={true}></input> : null }
                {defaultImage ? <img id={id + '-default-image'} alt="" src={defaultImage} onLoad={this.loadDefaultImage} hidden></img> : null}
                {storeImage != null && storeImage !== defaultImage ? <img id={id + '-store-image'} alt="" src={storeImage} onLoad={this.loadStoreImage} hidden></img> : null}
            </div>
    );
  }
}