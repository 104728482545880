import React, { useEffect, useRef, useState } from 'react';
import './Editor.scss';
import { Controlled as CodeMirror } from 'react-codemirror2-react-17';
import 'codemirror/mode/yaml/yaml';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/lucario.css';
import 'codemirror/theme/eclipse.css';
import 'codemirror/keymap/sublime';
import 'codemirror/keymap/vim';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';
import { getParsedLines, setupCodeMirrorAutocomplete } from './EditorCodeCompletion';
import { useCookieState } from '../ReactExt';
import { AppColorMode } from '../../NavigatonBar';
import { IValidationContext } from '../Validator';

const codeMirror =  require('codemirror');

interface EditorProps {
  content: string;
  appColorMode: AppColorMode;
  getValidationContext: ()=>IValidationContext;
  onChange: (s: string) => void;
}

// Maybe todo - if this keeps the yaml state and App can send in clear, add etc, then it will be faster since this is not rerendered on keystroke
export const Editor: React.FC<EditorProps> = (props) => {
  const [keybinding, setKeyBinding] = useCookieState('sublime', 'e-kb');
  const [lineWrapping, setLineWrapping] = useCookieState(true, 'e-lw');
  const [content, setContent] = useState(props.content);
  const signalChangeTimeout = useRef(null as NodeJS.Timeout | null);

  useEffect(() => { setContent(props.content); }, [props.content]);

  return <div className="Editor">
        <div className="EditorHeader">
            <span>Use ctrl+space to trigger auto-complete</span>
            <div>
                <button className="btn btn-sm btn-secondary" style={{ marginRight: '.4rem' }} onClick={()=>setLineWrapping(!lineWrapping)}>{lineWrapping ? 'Line wrapping' : 'No line break'}</button>
                <button className="btn btn-sm btn-secondary" onClick={()=>setKeyBinding(keybinding === 'sublime' ? 'vim' : 'sublime')}>{keybinding === 'vim' ? 'Disable' : 'Enable'} Vim mode</button>
            </div>
        </div>
        <CodeMirror
            editorDidMount={(e) => onEditorMount(e, props.getValidationContext)}
            onBeforeChange={(editor, data, value) => {
              if (value !== content) {
                setContent(value);
                if (signalChangeTimeout.current != null) {
                  clearTimeout(signalChangeTimeout.current);
                  signalChangeTimeout.current = null;
                }
    
                // delay update until stack is empty (timeout call with 0 delay) as indentSelection causes multiple updates
                signalChangeTimeout.current = setTimeout(() => {
                  signalChangeTimeout.current = null;
                  props.onChange(value);
                }, 0);
              }
            }}
            value={content}
            options={{
              indentUnit: 2,
              tabSize: 2,
              lineNumbers: true,
              lineWrapping: lineWrapping,
              mode: 'yaml',
              theme: props.appColorMode == 'theme-dark' ? 'lucario' : 'eclipse',
              keyMap: keybinding,
              viewportMargin: Infinity,
              extraKeys: {
                'Ctrl-Space': (cm: any) => { codeMirror.showHint(cm, codeMirror.hint.yaml); },
                'Tab': (cm: any) => { cm.execCommand(cm.somethingSelected() ? 'indentMore' : 'insertSoftTab'); },
                'Shift-Tab': (cm: any) => { cm.execCommand('indentLess'); },
              },
            }}
        />
    </div>;
};

interface EditorInteface {
  highlightLine: (line: number) => void;
  insertCodeAtCursor: (s: string) => boolean;
  getLineForPath: (path: string) => number | null;
}

let editorInstance: any = null;
export const editorManipulation: EditorInteface = {
  insertCodeAtCursor: (s: string) => {
    if (editorInstance == null) return false;
    // insert
    const doc = editorInstance.getDoc();
    const cursor = doc.getCursor(true);
    const indentSize = editorInstance.getOption('indentUnit');
    const offIndentSize = (cursor.ch % indentSize);
    const indent = cursor.ch + offIndentSize; // ensure valid indent
    if (indent > 0) {
      const rows = s.split('\n');

      // First row inserted at cursor, thus leading indent only needed if cursor isn't at an even indent position
      if (offIndentSize > 0) {
        rows[0] = Array(offIndentSize + 1).join(' ') + rows[0];
      }

      // Prepend indent space to any consequent rows
      const space = Array(indent + 1).join(' ');
      for (let i = 1; i < rows.length; ++i) {
        rows[i] = space + rows[i];
      }
            
      s = rows.join('\n');
    }

    if (editorInstance.getSelection().length > 0) {
      doc.replaceSelection(s);
    } else {
      doc.replaceRange(s, cursor);
    }
        
    editorInstance.focus();
    return true;
  },
  highlightLine: (line: number) => {
    if (editorInstance == null) return;
    --line;// we're zero index

    let start = { line: line, ch: 0 };
    let end = { line: line, ch: 10000 };

    let t = editorInstance.charCoords(start, 'local').top; 
    let middleHeight = editorInstance.getScrollerElement().offsetHeight / 2; 
    editorInstance.scrollTo(null, t - middleHeight - 5);
    let marker = editorInstance.markText(start, end, { className: 'codemirror-highlighted' });
    setTimeout(() => { marker.clear();}, 1000);
  },
  getLineForPath: (path: string) => {
    if (editorInstance == null) return null;

    // read all the lines from editor
    let sLines: string[] = [];
    for (let i = 0; i < editorInstance.lineCount(); ++i) {
      sLines.push(editorInstance.getLine(i));
    }

    //applogger.debug(`Looking for '${path}'`);
    const indentSize = editorInstance.getOption('indentUnit');
    const lines = getParsedLines(sLines, indentSize);
    let aCounts: { [path: string]: number } = {};
    for (let i = 0; i < lines.length; ++i) {
      const line = lines[i];
      if (line.key != null && line.key.length > 0 && line.parent != null) {
        let parentPath = line.parent.indent >= 0 ? line.parent.path ?? '' : '';
        if (line.parent.indent >= 0 && line.parent.isList) {
          let currentCount = line.isListItemHead ? (aCounts[parentPath] ?? -1) + 1 : (aCounts[parentPath] ?? 0);
          aCounts[parentPath] = currentCount;
          parentPath += '[' + currentCount + ']';
        }

        line.path = parentPath + '.' + line.key;
        if (line.path === path) {
          return i + 1;
        }
      }
    }

    // search for longest prefix
    let longest = 0, lIdx = 0;
    for (let i = 0; i < lines.length; ++i) {
      const line = lines[i];
      if (line.path != null) {
        const l = Math.min(line.path.length, path.length);
        for (let j = 0; j < l; ++j) {
          if (line.path[j] !== path[j]) break;
          if (j >= longest) {
            lIdx = i;
            longest = j + 1;
          }
        }
      }
    }

    //applogger.debug(`Not a perfect match for '${path}': `, lines.map(x => x.path));
    return longest > 0 ? lIdx + 1 : null;
  },
};

function onEditorMount(editor: any, getValidationContext: ()=>IValidationContext) {
  // store instance to be able to perform editor manipulation, see above
  editorInstance = editor;

  // setup auto-complete for YAML
  setupCodeMirrorAutocomplete(codeMirror, getValidationContext);

  // handle paste of tabs as indent since these genereate YAML parse errors
  editor.on('change', function (cm: any, change: any) {
    if (change.origin != 'paste' || change.text.length < 2) return;
    cm.operation(function () {
      for (var i = change.from.line, end = codeMirror.changeEnd(change).line; i < end; ++i) {
        const line = cm.getLine(i);
        const newLine = line.replace(/^\s*\t\s*(?=[^:]+:|$)/, (m: string) => m.replace(/\t/g, '  '));
        if (newLine !== line) {
          cm.replaceRange(newLine, codeMirror.Pos(i, 0), codeMirror.Pos(i, line.length));
        }
      }
    });
  });

  // expose a highlight method in the global scope (used by rendered form to fire a highlight when in editor mode, however code is present in IDS7 mode too and thus not hard coupled to the editor here)
  window._yamlHighlightComponentByPath_ = (compPath: string | null | undefined) => {
    if (compPath != null && compPath.length > 0) {
      let line = editorManipulation.getLineForPath(compPath);
      if (line != null) {
        editorManipulation.highlightLine(line);
        return true;
      }
    }
    return false;
  };
}
