/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React  from 'react';
import { applogger } from '../../applogger';
interface FormDataViewerProps { }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FormDataViewer: React.FC<FormDataViewerProps> = (props) => {
  let fields = document.querySelectorAll('.srt-container [data-field-type]');
  let data: any = {};
  let images: HTMLInputElement[] = [];
  for (let i = 0; i < fields.length; ++i) {
    let field = fields[i] as HTMLInputElement;
    let componentId = field.name;
    if (componentId == null || componentId === '') {
      applogger.error('missing id for', field);
      continue;
    }
    if (field.dataset.isEmpty === 'true' && data[componentId] === undefined) {
      // placeholder field for mandatory fields, no point in showing here
      continue;
    }

    let value: any = field.value;
    let type = field.dataset.fieldType;
    switch (type) {
      case 'text':
      case 'date':
        break;
      case 'number':
        if (value != null && value !== '') {
          value = Number(value);
        }
        break;
      case 'checkbox':
        value = Boolean(field.checked);
        break;
      case 'image/png':
        images.push(field);
        continue;
      default:
        applogger.error(`unhandled input type: ${type}, assuming text...`);
        break;
    }
        
    if (value != null && value !== '') {
      data[componentId] = value;
    }
  }

  if (data["OEhrOutput"] != null) {
    try {
      data["OEhrOutput"] = JSON.parse(data["OEhrOutput"]);
    }
    catch (e) {
      console.log(e);
    }
  }

  return (
        <div>
            <h3>Form data</h3>
            <pre>{JSON.stringify(data, null, 2)}</pre>
            {images.length ? <div>
                <h3>Form {images.length > 1 ? `images (${images.length})` : 'image'}</h3>
                {images.map((e, i) => <img key={i} src={e.value} />)}
            </div> : null}
        </div>
  );
};
