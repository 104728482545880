import React from 'react';

// The React options component
export const SvgSymbol: React.FC<{}> = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol id="icon-ListCollapse" viewBox="0 0 32 32">
            <path d="M16 25.384l-10.694-10.692q-0.879-0.877-0.879-2.12t0.879-2.122q0.877-0.879 2.12-0.879t2.122 0.879l6.452 6.45 6.452-6.45q0.879-0.879 2.121-0.879t2.121 0.879 0.879 2.122-0.879 2.12z"></path>
        </symbol>
        <symbol id="icon-ListExpand" viewBox="0 0 32 32">
            <path d="M10.452 26.692q-0.879-0.879-0.879-2.121t0.877-2.123l6.45-6.448-6.448-6.45q-0.879-0.879-0.879-2.122t0.879-2.12q0.879-0.879 2.122-0.879t2.12 0.879l10.69 10.692-10.688 10.692q-0.878 0.878-2.122 0.878t-2.122-0.878z"></path>
        </symbol>
        <symbol id="icon-ListDelete" viewBox="0 0 32 32">
            <path d="M24.547 11.213l-4.773 4.787 4.773 4.787-3.76 3.76-4.787-4.773-4.787 4.773-3.76-3.76 4.773-4.787-4.773-4.787 3.76-3.76 4.787 4.773 4.787-4.773z"></path>
        </symbol>
        <symbol id="icon-CheckboxChecked" viewBox="0 0 32 32">
            <path d="M15.8 16.22l14.2-14.2v7.060l-14.2 14.2-8.18-8.18 3.54-3.54zM26 26v-7.96l4-4v11.96q0 1.656-1.172 2.828t-2.828 1.172h-20q-1.656 0-2.828-1.172t-1.172-2.828v-20q0-1.656 1.172-2.828t2.828-1.172h19.060l-4 4h-15.060v20h20z"></path>
        </symbol>
        <symbol id="icon-CheckboxUnchecked" viewBox="0 0 32 32">
            <path d="M26 2q1.656 0 2.828 1.172t1.172 2.828v20q0 1.656-1.172 2.828t-2.828 1.172h-20q-1.656 0-2.828-1.172t-1.172-2.828v-20q0-1.656 1.172-2.828t2.828-1.172h20zM26 26v-20h-20v20h20z"></path>
        </symbol>
        <symbol id="icon-RadiobuttonChecked" viewBox="0 0 32 32">
            <path d="M9 16q0-2.906 2.047-4.953t4.953-2.047 4.953 2.047 2.047 4.953-2.047 4.953-4.953 2.047-4.953-2.047-2.047-4.953zM16 1q4.063 0 7.516 2.016t5.469 5.469 2.016 7.516-2.016 7.516-5.469 5.469-7.516 2.016-7.516-2.016-5.469-5.469-2.016-7.516 2.016-7.516 5.469-5.469 7.516-2.016zM16 27q4.563 0 7.781-3.219t3.219-7.781-3.219-7.781-7.781-3.219-7.781 3.219-3.219 7.781 3.219 7.781 7.781 3.219z"></path>
        </symbol>
        <symbol id="icon-RadiobuttonUnchecked" viewBox="0 0 32 32">
            <path d="M16 1q4.063 0 7.516 2.016t5.469 5.469 2.016 7.516-2.016 7.516-5.469 5.469-7.516 2.016-7.516-2.016-5.469-5.469-2.016-7.516 2.016-7.516 5.469-5.469 7.516-2.016zM16 27q4.563 0 7.781-3.219t3.219-7.781-3.219-7.781-7.781-3.219-7.781 3.219-3.219 7.781 3.219 7.781 7.781 3.219z"></path>
        </symbol>
        <symbol id="icon-ArrowUp" viewBox="0 0 16 16">
            <path d="M8,3.525L1.763,9.763c-0.684,0.684-0.684,1.791,0,2.475s1.791,0.684,2.475,0L8,8.475l3.763,3.762  c0.342,0.342,0.789,0.513,1.237,0.513s0.896-0.171,1.237-0.513c0.684-0.684,0.684-1.791,0-2.475L8,3.525z"></path>
        </symbol>
        <symbol id="icon-ArrowDown" viewBox="0 0 16 16">
            <path d="M11.763,5.763L8,9.525L4.237,5.763c-0.684-0.684-1.791-0.684-2.475,0  c-0.684,0.683-0.684,1.792,0,2.475L8,14.475l6.237-6.237c0.684-0.683,0.684-1.792,0-2.475C13.554,5.079,12.446,5.079,11.763,5.763z"></path>
        </symbol>
        <symbol id="icon-Question" viewBox="0 0 1024 1024">
            <path d="M512 32q129 0 239.5 65t175.5 175.5 65 239.5-65 239.5-175.5 175.5-239.5 65-239.5-65-175.5-175.5-65-239.5 65-239.5 175.5-175.5 239.5-65zM614.4 761.6q0-33.527-28.758-58.363t-67.242-24.836-67.242 24.836-28.758 58.363 28.758 58.364 67.242 24.836q38.484 0 67.242-24.836t28.758-58.364zM729.6 454.4q19.2-38.399 19.2-89.601 0-72.534-51.2-115.2-53.759-44.8-147.199-44.8-115.201 0-211.2 57.601l44.8 102.399q80.639-44.8 153.6-44.8 25.599 0 64 12.8 19.2 19.199 19.2 44.8 0 32-12.8 44.801-3.715 5.572-8.069 10.557t-11.203 10.988-11.632 9.902-15.811 12.524-17.285 13.629q-36.572 29.257-64 70.4-19.2 19.2-19.2 57.6 38.397-12.8 57.601-12.8 19.202 0 57.6 12.8 3.2-3.2 6.4-12.8t6.4-12.799q25.601-25.601 57.601-44.801 2.43-2.025 7.211-6.001 22.739-18.909 33.816-28.6t23.805-23.505 18.367-25.093z"></path>
        </symbol>
        <symbol id="icon-Checkmark" viewBox="0 0 32 32">
            <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z"></path>
        </symbol>
        <symbol id="icon-Plus" viewBox="0 0 32 32">
            <path d="M31 12h-11v-11c0-0.552-0.448-1-1-1h-6c-0.552 0-1 0.448-1 1v11h-11c-0.552 0-1 0.448-1 1v6c0 0.552 0.448 1 1 1h11v11c0 0.552 0.448 1 1 1h6c0.552 0 1-0.448 1-1v-11h11c0.552 0 1-0.448 1-1v-6c0-0.552-0.448-1-1-1z"></path>
        </symbol>

        <symbol id="icon-Folder" viewBox="0 0 512 512">
            <path d="M224 64l64 64h224v352h-512v-416z"></path>
        </symbol>
        <symbol id="icon-FolderOpen" viewBox="0 0 512 512">
            <path d="M416 480l96-256h-416l-96 256zM64 192l-64 288v-416h144l64 64h208v64z"></path>
        </symbol>
        <symbol id="icon-FolderPlus" viewBox="0 0 512 512">
            <path d="M288 128l-64-64h-224v416h512v-352h-224zM352 352h-64v64h-64v-64h-64v-64h64v-64h64v64h64v64z"></path>
        </symbol>
        <symbol id="icon-Versions" viewBox="0 0 32 32">
            <path d="M32 10l-16-8-16 8 16 8 16-8zM16 4.655l10.689 5.345-10.689 5.345-10.689-5.345 10.689-5.345zM28.795 14.398l3.205 1.602-16 8-16-8 3.205-1.602 12.795 6.398zM28.795 20.398l3.205 1.602-16 8-16-8 3.205-1.602 12.795 6.398z"></path>
        </symbol>
        <symbol id="icon-Undo" viewBox="0 0 32 32">
            <path d="M16 2c-4.418 0-8.418 1.791-11.313 4.687l-4.686-4.687v12h12l-4.485-4.485c2.172-2.172 5.172-3.515 8.485-3.515 6.627 0 12 5.373 12 12 0 3.584-1.572 6.801-4.063 9l2.646 3c3.322-2.932 5.417-7.221 5.417-12 0-8.837-7.163-16-16-16z"></path>
        </symbol>
    </svg>;
};