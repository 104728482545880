/* eslint-disable no-unused-vars */
import * as React from 'react';
import { asControllableElement, InputContentController } from './ContentController';

export interface SectraInputProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
  type: string;
  bsSize?: string;
  speechEnabled?: boolean;
  onInputChange?: (val: string) => void;
}

export class SectraInput extends React.Component<SectraInputProps, {}> {
  el = React.createRef<HTMLInputElement>();

  constructor(props: any, context: any) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.onInputChange?.(event.target.value);
    this.props.onChange?.(event);
  }

  componentDidMount() {
    if (this.el.current != null) {
      asControllableElement(this.el.current).contentController = new InputContentController(this.el.current, val => this.props.onInputChange?.(val));
    }
  }

  componentWillUnmount() {
    if (this.el.current != null) delete asControllableElement(this.el.current).contentController;
  }

  render() {
    const {
      id,
      name,
      type,
      bsSize,
      speechEnabled,
      onChange,
      onInputChange,
      ...htmlProps
    } = this.props;

    const className = 'form-control input-xs' + (!bsSize || bsSize == 'xl' ? '' : ' inline-input-' + bsSize);
    return (
            <input ref={this.el} type={type} id={id}
                    onChange={this.handleChange} 
                    name={name}
                    className={className}
                    data-speech-id={speechEnabled !== false ? id : undefined}
                    data-speech-enabled={speechEnabled !== false ? 'true' : undefined}
                    {...htmlProps} />
    );
  }
}