/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReportTextPart } from '../Components/BasicTypes';

export function convertReportToRTF(reportParts: ReportTextPart[]) {
  const header = '{\\rtf1\\ansi\\deff0\\nouicompat{\\fonttbl{\\f0\\fnil\\fcharset0 Segoe UI;}{\\f1\\fnil\\fcharset0 Consolas;}}{\\colortbl ;\\red0\\green0\\blue0;}';
  let result = '' + header;
  for (let i = 0; i < reportParts.length; i++) {
    let part = reportParts[i];
    part.text = escapeChars(part.text);
    let styling = '\\pard\\f';
    // eslint-disable-next-line no-unused-vars
    styling += part.styling.preformat ? '1' : '0';
    if (part.styling.tableContext === 'table') {
      const innerHtml = part.tableHtml ?? '';
      const totalTableSize = getTwipsWidth(part.styling.tableWidth ?? 'md');
      const totalTablePx = getWidth(part.styling.tableWidth ?? 'md');
      const minColumnWidth = part.styling.minColumnWidth ?? 20;
      const columnWidths = part.styling.columnWidths;
      const ttextresult = generateTableMatrix(i, reportParts);
      const tableTexts: ReportTextPart[][] = ttextresult.tableParts;
      if (!tableTexts.length) {
        i = ttextresult.continueIndex;
        continue;
      }
      const percentages = getColumnWidthPercentages(innerHtml, parseInt(totalTablePx), minColumnWidth, columnWidths);
      for (let y = 0; y < tableTexts.length; y++) {
        let prevPos = 0;
        if (y === 0) {
          result += '{';
        }
        result += '{\\trowd\\trgaph50';
        for (let x = 0; x < tableTexts[y].length; x++) {
          const currentPos = Math.round(percentages[x] * totalTableSize);
          result += `\\clbrdrt\\brdrw15\\brdrs\\clbrdrl\\brdrw15\\brdrs\\clbrdrb\\brdrw15\\brdrs\\clbrdrr\\brdrw15\\brdrs\\cellx${prevPos + currentPos}`;
          prevPos += currentPos;
        }
        for (let x = 0; x < tableTexts[y].length; x++) {
          const rtfStyling = getRtfStyling(tableTexts[y][x]);
          result += `{\\pard\\intbl\\qc\\sb20\\sa40${rtfStyling} ` + tableTexts[y][x].text + ' \\cell}';
        }
        result += '\\row}';
        if (y === tableTexts.length - 1) {
          result += '}';
        }
      }
      i = ttextresult.continueIndex;
      continue;
    }
    result += '{' + getRtfStyling(part) + ' ' + part.text + '}';
  }
  result += '}';
  return result;
}

function getRtfStyling(part: ReportTextPart) {
  let styling = '';
  if (part.styling.h1) {
    return '\\fs30\\b';
  } 
  if (part.styling.h2) {
    return '\\fs25\\b';
  } 
  if (part.styling.h3) {
    return '\\fs23\\b';
  } 

  if (part.styling.small) {
    styling += '\\fs16';
  } else {
    styling += '\\fs20';
  }

  if (part.styling.strong) {
    styling += '\\b';
  }
  if (part.styling.emphasis) {
    styling += '\\i';
  }
  return styling;
}

export function getTwipsWidth(width: 'xs' | 'sm' | 'md' | 'lg') {
  const widths = [3000, 5000, 9000, 10400];
  let retval = 4500;
  switch (width) {
    case 'xs':
      retval = widths[0];
      break;
    case 'sm':
      retval = widths[1];
      break;
    case 'lg':
      retval = widths[3];
      break;
    default:
      retval = widths[2];
  }
  return retval;
}

export function getWidth(width: 'xs' | 'sm' | 'md' | 'lg' | undefined) {
  const widths = ['200px', '330px', '600px', '700px'];
  let retval = '';
  switch (width) {
    case 'xs':
      retval = widths[0];
      break;
    case 'sm':
      retval = widths[1];
      break;
    case 'lg':
      retval = widths[3];
      break;
    default:
      retval = widths[2];
  }
  return retval;
}

interface TableTextResult {
  continueIndex: number;
  tableParts: ReportTextPart[][];
}

function generateTableMatrix(currentIndex: number, reportParts: ReportTextPart[]) : TableTextResult {
  const tableParts: ReportTextPart[][] = [];
  let currentTableRow = -1;
  let i;
  for (i = currentIndex; i < reportParts.length; i++) {
    if (reportParts[i].styling.tableContext === 'none') break;
    if (reportParts[i].styling.tableContext === 'td') {
      tableParts[currentTableRow].push(reportParts[i]);
    }
    if (reportParts[i].styling.tableContext === 'tr') {
      tableParts.push([]);
      currentTableRow++;
    }
  }
  return { continueIndex: i - 1, tableParts: tableParts };
}

function getColumnWidthPercentages(innerHtml: string, totalWidth: number, minColumnWidth: number, columnWidths: (number | null | undefined)[] | undefined): number[] {
  let elem = document.createElement('table');
  elem.innerHTML = innerHtml;
  elem.style.width = totalWidth + 'px';
  elem.style.position = 'absolute';
  elem.style.visibility = 'hidden';
  document.querySelector('body')?.append(elem);
  let columns = elem.querySelector('tr')?.querySelectorAll('td');
  let ret: number[] = [];
  if (!columns) return [];
  let keepLooping = true;
  for (let i = 0; i < 10 && keepLooping; i++) {
    keepLooping = false;
    columns.forEach((x, j) => {
      if (columnWidths != null && columnWidths[j] != null) {
        x.style.width = columnWidths[j] + 'px';
      } else if (x.clientWidth < minColumnWidth) {
        x.style.width = minColumnWidth + 'px';
        keepLooping = true;
      }
    });
  }
  for (let i = 0; i < columns.length; i++) {
    ret.push(columns[i].clientWidth);
  }
  elem.remove();
  return ret.map(x => x / totalWidth);
}

function escapeChars(text: string) {
  let retval = '';
  for (let i = 0; i < text.length; i++) {
    if (text.charCodeAt(i) === 123) {
      retval += '\\{';
    } else if (text.charCodeAt(i) === 125) {
      retval += '\\}';
    } else if (text.charCodeAt(i) > 125) {
      retval += '\\u' + text.charCodeAt(i) + '?';
    } else if (text[i] === '\n') {
      retval += '\\par';
      if (text[i + 1] != undefined && text[i + 1] !== '\n') {
        retval += ' ';
      }
    } else {
      retval += text[i];
    }
  }
  return retval;
}