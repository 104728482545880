import React from 'react';

export const SectraLogoSvg = () => (
<svg className="logo image" version="1.1" id="sectra-logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 160 32" enableBackground="new 0 0 160 32" xmlSpace="preserve">
<g>
	<g>
		<path id="sectra-logo-path" fill="#CED1D6;" d="M25.53,2.24c-1.24-0.95-3.05-1.42-5.45-1.42H7.3c-2.39,0-4.21,0.48-5.46,1.42
			C0.6,3.19-0.02,4.59-0.02,6.43v3.21c0,1.53,0.33,2.73,0.99,3.61c0.66,0.88,1.92,1.7,3.79,2.45l8.12,3.28
			c0.89,0.36,1.45,0.73,1.67,1.1c0.22,0.37,0.33,1.03,0.33,1.97c0,0.83-0.12,1.42-0.35,1.79c-0.23,0.36-0.61,0.54-1.13,0.54
			c-0.51,0-0.87-0.17-1.1-0.51c-0.23-0.34-0.34-0.88-0.34-1.62v-1.25H-0.02v4.57c0,1.85,0.62,3.26,1.87,4.21
			c1.24,0.96,3.06,1.43,5.46,1.43h12.79c2.5,0,4.34-0.52,5.53-1.56c1.18-1.04,1.78-2.66,1.78-4.86v-3.32c0-1.89-0.24-3.3-0.72-4.23
			c-0.48-0.93-1.32-1.66-2.52-2.17l-10.15-3.99c-0.94-0.38-1.52-0.71-1.76-0.98C12,9.83,11.88,9.48,11.88,9.04
			c0-0.44,0.13-0.8,0.39-1.06c0.26-0.26,0.6-0.4,1.02-0.4c0.49,0,0.87,0.17,1.13,0.51c0.26,0.34,0.39,0.84,0.39,1.51v0.14h12.59
			V6.43C27.39,4.59,26.77,3.19,25.53,2.24z M30,30.99h19v-7.03h-8.02v-5.88H49v-6.12h-8.02V8.04H49V1H30V30.99z M69.58,0.82h-10.5
			c-2.62,0-4.46,0.54-5.53,1.61c-1.06,1.08-1.6,2.92-1.6,5.55v16.77c0,2.22-0.05,3.86,1.09,4.9c1.14,1.04,3.57,1.56,6.04,1.56h10.5
			c2.64,0,4.54-0.5,5.69-1.51c1.15-1,1.72-2.65,1.72-4.95v-5.8H65.7v3.29c0,0.75-0.12,1.29-0.37,1.64c-0.25,0.35-0.8,0.52-1.31,0.52
			c-0.52,0-1.4-0.17-1.63-0.51c-0.23-0.34-0.35-0.89-0.35-1.65V9.75c0-0.76,0.12-1.31,0.35-1.65c0.23-0.34,1.12-0.51,1.63-0.51
			c0.54,0,1.1,0.17,1.33,0.5c0.23,0.33,0.35,0.89,0.35,1.67v1.24h11.29V7.98c0-2.66-0.54-4.51-1.61-5.57
			C74.3,1.35,72.36,0.82,69.58,0.82z M78.92,8.97h6.03v22.02h11.12V8.97h5.94V1H78.92V8.97z M125.27,14.96
			c1.73-0.43,2.97-1.18,3.71-2.23c0.75-1.05,1.12-2.59,1.12-4.61c0-2.54-0.58-4.36-1.75-5.46C127.18,1.55,125.24,1,122.52,1h-18.51
			v29.99h11V18.97h2.25c0.63,0,1.06,0.16,1.32,0.48c0.25,0.32,0.38,0.87,0.38,1.67v9.88h11.17v-7.72c0-2.56-0.38-4.48-1.14-5.77
			C128.24,16.21,127,15.37,125.27,14.96z M118.57,11.53c-0.26,0.31-0.69,0.47-1.31,0.47h-2.25V7.97h2.25c0.63,0,1.06,0.16,1.32,0.47
			c0.25,0.31,0.38,0.66,0.38,1.47C118.96,10.68,118.83,11.22,118.57,11.53z M154.81,1h-18.32l-5.28,29.99h12.08l0.67-6h3.35l0.67,6
			h12.12L154.81,1z M144.39,17.95l1.26-10.26l1.21,10.26H144.39z"/>
	</g>
</g>
</svg>
);