/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { ISectraOptionsValue, SectraOptionsHelper } from '../SectraOptionsBase';
import { ISingleOptionValue } from './SectraOptionBase';
import { SvgRef } from '../../Generic/SvgRef';

export interface SectraAutocompleteBaseProps {
  options: ISectraOptionsValue[];
  onInputChange: (values: ISingleOptionValue[]) => void;
  maxSearchResults: number;
  value: ISingleOptionValue[];
  id: string;
  name: string;
  allowMultiple: boolean;
  minSearch: number;
  placeholder?: string;
  hidden?: boolean;
  disabled?: boolean;
}

const autocompleteMatch = (input: string, options: ISectraOptionsValue[], selectedIndexes: number[]) => {
  if (input === '') {
    return [];
  }
  let regExps = input.split(' ').map(x => new RegExp(x.toLocaleLowerCase()));
  const optionTexts = SectraOptionsHelper.getTexts(options).map(x => x.toLocaleLowerCase());
  const retval: number[] = [];
  for (let i = 0; i < optionTexts.length; i++) {
    let matches = regExps.map(x => optionTexts[i].match(x));
    if (matches.every(x => x != null) && selectedIndexes.indexOf(i) === -1) {
      retval.push(i);
    }
  }
  return retval;
};

export const SectraAutocompleteBase: React.FC<SectraAutocompleteBaseProps> = (props) => {
  const [searchResultIndexes, setSearchResultIndexes] = useState<number[]>([]);
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [controlOpened, setControlOpened] = useState<boolean>(false);
  const [draggedIndex, setDraggedIndex] = useState<number>(-1);
  const [singlePlaceHolder, setSinglePlaceholder] = useState<string>();
  const [prevNrOptions, setPrevNrOptions] = useState<number>(props.options.length);
    

  const getSelectedValues = (selIndexes: number[]) => {
    return SectraOptionsHelper.getValues(selIndexes.map(index => props.options[index]), true);
  };
    
  const showSearchResults = (inputValue: string) => {
    setSearchInput(inputValue);
    if (inputValue.length > 0) {
      setControlOpened(true);
    }
  };

  const addSelectedElement = (index: number) => {
    const newSelectedIndexes = props.allowMultiple ? [...selectedIndexes, index] : [index];
    props.onInputChange(getSelectedValues(newSelectedIndexes));
    setSearchInput('');
    setControlOpened(false);
  };

  const removeSelectedElement = (index: number) => {
    const newSelectedIndexes = selectedIndexes.filter(i => i !== index);
    props.onInputChange(getSelectedValues(newSelectedIndexes));
  };

  const handleDropdownBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setSearchInput('');
      setControlOpened(false);
    }
  };

  const handleEmptySearch = () => {
    if (props.minSearch === 0) {
      const newSearchResultIndexes = [];
      for (let i = 0; i < props.options.length; i++) {
        if (selectedIndexes.indexOf(i) === -1) {
          newSearchResultIndexes.push(i);
        }
      }
      setSearchResultIndexes(newSearchResultIndexes);
    }
  };
    
  const inputClick = () => {
    if (!props.disabled) {
      setControlOpened(!controlOpened);
    }
  };

  useEffect(() => {
    let allVals = SectraOptionsHelper.getValues(props.options, true);
    setSelectedIndexes(props.value.map(x => allVals.indexOf(x)).filter(x => x != -1));
  }, [props.value]);

  useEffect(() => {
    if (prevNrOptions != props.options.length) {
      props.onInputChange([]);
      setPrevNrOptions(props.options.length);
    }
  }, [props.options.length]);

  useEffect(() => {
    if (searchInput.length < props.minSearch) {
      return;
    }

    if (searchInput.length > 0) {
      setSearchResultIndexes(autocompleteMatch(searchInput, props.options, selectedIndexes));
    } else {
      handleEmptySearch();
    }
  }, [selectedIndexes, searchInput, props.minSearch, props.options]);

  useEffect(() => {
    setSinglePlaceholder(undefined);
    if (!props.allowMultiple) {
      props.onInputChange([]);
    }
  }, [props.allowMultiple]);

  const handleDrop = (dropIndex: number) => {
    if (draggedIndex == -1) {
      return;
    }

    let arr = [...selectedIndexes];
    arr.splice(dropIndex, 0, arr.splice(draggedIndex, 1)[0]);
    props.onInputChange(getSelectedValues(arr));
  };

  const handleSingleClick = () => {
    if (props.disabled) {
      return;
    }
    setControlOpened(true);
    if (props.value != null && props.value.length === 1) {
      setSinglePlaceholder('' + props.value[0]);
    } else {
      setSinglePlaceholder('');
    }
  };

  const handleSingleRemove = () => {
    setSinglePlaceholder(undefined);
    props.onInputChange([]);
  };

  return (
        <div className="autocomplete" hidden={props.hidden}>
            {props.allowMultiple ? 
                <div className="autocomplete-values" onDragOver={ev => { ev.preventDefault(); ev.dataTransfer.dropEffect = 'move'; }}>
                    {selectedIndexes.map((selectedIndex, i) =>
                        <div className="autocomplete-value-row selected-item" draggable={true} onDragStart={() => setDraggedIndex(i)} onDrop={() => handleDrop(i)} key={selectedIndex}>
                            <span className='selected-item-text'>{SectraOptionsHelper.getTexts([props.options[selectedIndex]])[0]}</span>
                            <button disabled={props.disabled} className="btn btn-link chevron delete" onClick={() => removeSelectedElement(selectedIndex)}>
                                <SvgRef id='icon-ListDelete' className="svg-icon delete no-border" />
                            </button>
                        </div>,
                    )}
                </div>
              : undefined}
            <div onBlur={handleDropdownBlur}>
                {!props.allowMultiple && props.value.length === 1 && !controlOpened ?
                    <div className="input-container">
                        <input type="text" autoComplete="off" className="form-control autocomplete-single-input" onKeyDown={handleSingleClick} onClick={() => handleSingleClick()}
                        disabled={props.disabled} value={!props.allowMultiple && props.value.length === 1 ? '' + props.value[0] : undefined} />
                        <span onClick={!props.disabled ? handleSingleRemove : undefined}><SvgRef id="icon-ListDelete" className="svg-icon clear no-border" /></span>
                    </div> 
                  :
                    <div className="input-container" onKeyDown={e => e.key === 'Enter' ? inputClick() : undefined} onClick={inputClick}>
                        <input type="text" autoComplete="off" disabled={props.disabled}
                            placeholder={singlePlaceHolder ?? props.placeholder ?? 'Select an option...'} className="form-control"
                            onChange={e => showSearchResults(e.currentTarget.value)} value={searchInput} />
                        <SvgRef id="icon-ListCollapse" className="svg-icon no-border" />
                    </div>
                }
                <div className="dropdown-items-container">
                    {controlOpened ?
                    <>
                       {props.minSearch > searchInput.length ? undefined : searchResultIndexes.slice(0, props.maxSearchResults).map(i =>
                            <button className="dropdown-item" key={i} onClick={() => addSelectedElement(i)}>
                                {SectraOptionsHelper.getTexts([props.options[i]])[0]}
                            </button>)}
                        {props.minSearch > searchInput.length ?
                          <span className="dropdown-item info">Type {props.minSearch - searchInput.length} more letters to find results...</span>
                          : searchResultIndexes.length === 0 ? 
                          <span className="dropdown-item info">No results found...</span> :
                            searchResultIndexes.length > props.maxSearchResults ?
                          <span className ="dropdown-item info">More results found. Keep narrowing down the search...</span> : undefined}
                    </>
                      : undefined}
                </div>
            </div>
        </div>
  );
};
