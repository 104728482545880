import React, { useRef, useState } from 'react';


export const ExpandableTextarea: React.FC<React.HTMLProps<HTMLTextAreaElement>> = (props) => {
  const taRef = useRef<HTMLTextAreaElement | null>(null);
  const [initialHeight, setInitialHeight] = useState<number>(0);

  const onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (props.onChange) {
      props.onChange(event);
    }
    if (taRef?.current) {
      if (initialHeight === 0) {
        setInitialHeight(taRef.current.scrollHeight + 5);
      } else {
        taRef.current.style.height = '0px';
        if (taRef.current.scrollHeight > initialHeight) {
          taRef.current.style.height = (taRef.current.scrollHeight + 5) + 'px';
        } else {
          taRef.current.style.height = initialHeight + 'px';
        }
      }
    }
  };

  return (
    <textarea ref={taRef} onChange={onTextChange} {...props} />
  );
};