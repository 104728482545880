/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react';
import { applogger } from '../applogger';
import { ColorTheme, CompositeActionItem, IComponentStateStore, SimpleComponentInfo, SrtComponentFunctions, StateChangeReasonAllType, TemplateContext } from './BasicTypes';
import { Ids7Interface } from '../Ids7Interface';
import { getCurrentTheme } from './SrtComponent/SrtComponent';
import { SrtContainer } from './SrtComponent/SrtContainer';
import { SvgSymbol } from './SvgSymbol';
import { ErrorBoundary } from '../ErrorBoundary';

export interface TemplateBaseContainerProps {
  templateContext: TemplateContext;
  onThemeUpdate?: (theme: ColorTheme) => void;
  focusSpec?: () => void;
}

export const TemplateBaseContainer: React.FC<TemplateBaseContainerProps> = (props) => {
  const templateContext = props.templateContext;
  const ids7IfRef = useRef(new Ids7Interface());
  ids7IfRef.current.setTemplateContext(templateContext);
  const [theme, setTheme] = useState<ColorTheme>('dark');

  const delayedScriptRunner = getDelayedScriptRunner(templateContext.componentStore);

  /**
     * Setup component functions
     */

  const store = templateContext.componentStore;
  const functions: SrtComponentFunctions = {
    copyComponents: (items) => store.copyComponentValues(items),
    deleteComponentValues: (ids) => store.deleteComponentValues(ids.map(id => ({ compId: id }))),
    setUserInput: (componentId, propName, value, runScripts) => store.setStoreUserInputValue(componentId, propName, value, runScripts ?? true),
    runScripts: delayedScriptRunner,
    getAllIds: () => templateContext.runtime.getAllRuntimeIds(),
    compositeUpdate: (operations: CompositeActionItem[], runScripts?: boolean) => store.compositeUpdate(operations, runScripts ?? true),
  };

  // Run post processor once after first rendering is done
  const hasInit = useRef(false as boolean);
  useEffect(() => {
    if (!hasInit.current) {
      hasInit.current = true;
      delayedScriptRunner(0, [], 'init');
    }
  });
  useEffect(() => { hasInit.current = false; }, [props.templateContext.templateSpec.Root]);
  useEffect(() => {
    const timer = setInterval(() => {
      setTheme(getCurrentTheme());
    }, 100);
    
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    applogger.debug(`setting new theme: ${theme}`);
    props.onThemeUpdate?.(theme);
  }, [theme]);

  const langCode = props.templateContext.langCode;
  const rootComponent = props.templateContext.templateSpec.Root;

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  return <div className="srt-container" data-custom-state lang={langCode} onClick={templateContext.inBuildMode ? e => yamlSyncContainerClickHandler(e, props.templateContext, props.focusSpec) : undefined}>
        <SvgSymbol />
        <ErrorBoundary>
            <SrtContainer parentId={rootComponent?.id} functions={functions} renderContext={{ currentParsedComponent: rootComponent, theme: theme }} templateContext={props.templateContext} />
        </ErrorBoundary>
    </div>;
};

function getDelayedScriptRunner(store: IComponentStateStore) {
  const scriptsRunnerTimeout = useRef(null as NodeJS.Timeout | null);
  const queuedOp = useRef(undefined as undefined | StateChangeReasonAllType);
  const queuedRun = useRef(null as SimpleComponentInfo[] | null);
  function getRunData(): SimpleComponentInfo[] {
    const components = (queuedRun.current ?? [])
      .reduce((a, c) => {
        if (!a.find(i => i.compId == c.compId && i.propName == c.propName)) {
          a.push(c);
        }
        return a;
      }, [] as SimpleComponentInfo[]);


    queuedRun.current = null;
    return components;
  }

  function pushToQueue(components: SimpleComponentInfo[] | undefined) {
    if (components == null) {
      return;
    }

    if (queuedRun.current == null) {
      queuedRun.current = components;
    } else {
      queuedRun.current = queuedRun.current.concat(components);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return function runPostProcessor(timeoutMs: number, comps: SimpleComponentInfo[], op?: StateChangeReasonAllType) {
    //applogger.debug(queuedOp.current, comps);
    pushToQueue(comps);

    if (scriptsRunnerTimeout.current != null) {
      clearTimeout(scriptsRunnerTimeout.current);
      scriptsRunnerTimeout.current = null;
    }

    if (timeoutMs != null && timeoutMs < 0) {
      const components = getRunData();
      const operation = queuedOp.current;
      queuedOp.current = undefined;
      store.runPostProcessingScripts(components, operation);
      return;
    }

    scriptsRunnerTimeout.current = setTimeout(() => {
      const components = getRunData();
      const operation = queuedOp.current;
      queuedOp.current = undefined;
      store.runPostProcessingScripts(components, operation);
    }, timeoutMs ?? 50);
  };
}

function yamlSyncContainerClickHandler(e: React.MouseEvent, templateContext: TemplateContext, focusSpec: (() => void) | undefined) {
  // React to clicks on a target with alt+ctrl key down
  const yamlHighlightComponentByPath = window._yamlHighlightComponentByPath_;
  if (yamlHighlightComponentByPath == null || !e.target || !e.altKey || !e.ctrlKey) return;

  const yamlHighlightComponentById = (compId: string) => {
    const component = templateContext.runtime.getComponentById(compId);
    if (component != null && component.id != null) {
      yamlHighlightComponentByPath(component.componentPath);
      return true;
    }
    return false;
  };

  focusSpec?.();

  const target = e.target as HTMLElement;
  if (target.id != null && target.id.length > 0 && yamlHighlightComponentById(target.id)) {
    return;
  }

  // try navigate back to see if we find a container attribute
  let p: HTMLElement | null = target;
  while (p != null) {
    if (p.dataset.componentPath) {
      yamlHighlightComponentByPath(p.dataset.componentPath);
      break;
    }
    if (p.dataset.componentId) {
      yamlHighlightComponentById(p.dataset.componentId);
      break;
    }
    p = p.parentElement;
  }
}