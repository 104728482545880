import { applogger } from '../applogger';
import { ScriptRunnerExtesionMethods, GetterType, StateChangeRunReason, StateChangeReasonType, SetterType, OnStateChangeRunner, ParsedComponent, ScriptRunnerContext, TemplateContext, CustomerEvaluateMethod, Ids7ProviderDataResult, ScriptRunnerGetter, CalculationInfo, StateChangeSimpleComponentInfo } from './BasicTypes';
import { addRuntimeError } from './SrtComponent/ScriptRunnerHelper';
import { methodsDict } from './SrtComponent/ScriptHelperMethods';

import { Button, buttonClickEventPropName } from './SrtComponent/SectraButton';

export interface PostProcessorInfo {
  id: string;
  name: string;
  propToSet: string;
  propRead?: string;
  setAsUserInput: boolean;
  customEvaluate?: CustomerEvaluateMethod<any>;
}

// Script execution
export interface IScriptExecutor {
  // Executes all code, inline functions and script
  executeOnStateChangeCode: (templateContext: TemplateContext, getter: GetterType, setter: SetterType, reason: StateChangeRunReason, currentProviderData: Ids7ProviderDataResult[]) => void;

  executeOnDataRetrieve: (templateContext: TemplateContext, data: Ids7ProviderDataResult[]) => void;

  executeOnBeforeGetState: (templateContext: TemplateContext, currentProviderData: Ids7ProviderDataResult[])=>void;

  registerComponent: (id: string, componentInfo: ParsedComponent<any>) => void;

  unregisterComponent: (id: string) => void;

  clear(): void;

  setNewScriptRunnerGetter: (scriptRunnerGetter: ScriptRunnerGetter | null) => void;
}

export class ScriptExecutor implements IScriptExecutor {
  private scriptRunnerGetter: ScriptRunnerGetter | null;

  private postProcessors: { ownerId: string; prefix: string; calculators: PostProcessorInfo[] }[];

  private renderTimeProcessors: { ownerId: string; processor: OnStateChangeRunner<any> }[]; // the once to run in main loop besides the codeExecutor

  constructor(scriptRunnerGetter: ScriptRunnerGetter | null) {   
    this.scriptRunnerGetter = scriptRunnerGetter;
    this.postProcessors = [];
    this.renderTimeProcessors = [];
  }

  setNewScriptRunnerGetter(scriptRunnerGetter: ScriptRunnerGetter | null): void {
    this.scriptRunnerGetter = scriptRunnerGetter;
  }

  executeOnStateChangeCode(templateContext: TemplateContext, getter: GetterType, setter: SetterType, changeReason: StateChangeRunReason, currentProviderData: Ids7ProviderDataResult[]) {
    const calculators = this.getRegisteredCalculators(templateContext, getter, setter);
    // object with functions available in the scripts tabs OnStateChange method. Use object to easier extend later
    const extensionMethods: ScriptRunnerExtesionMethods = {
      getReasonName: () => changeReason.components.map(x => x.name),
      getReason: () => changeReason,
      isReason: (testReason: string, type?: StateChangeReasonType) => {
        if (testReason == null) {
          return false; 
        }

        type = type === undefined ? 'setUserInput' : type;

        const parts = String(testReason).split('.');
        const componentId =  parts[0];
        const propName = parts.length > 1 ? parts.slice(1).join('.') : undefined;
        return propName != null 
          ? changeReason.components.some(c => (type === null || c.type === type) && ((c.componentId === componentId && c.propName === propName) || c.name == testReason))
          : changeReason.components.some(c => (type === null || c.type === type) && (c.componentId === componentId || c.name.startsWith(testReason + '.')));
      },
      getAllIds: ()=> templateContext.runtime.getAllRuntimeIds(),
      getOEhrInfos: () => templateContext.runtime.getOEhrInfos(),
      getProviderData: () => [...currentProviderData],
    };

    const runnerContext: ScriptRunnerContext = {
      get: getter,
      set: setter,
      extensions: extensionMethods,
      templateDefaults: templateContext.defaults,
      langCode: templateContext.langCode,
      componentExists: (compId) => templateContext.runtime?.getComponentById(compId) != null,
      _scriptHelperMethods_: methodsDict,
    };
        
    // Run all user defined scripts and genereated script from component expressions
    this.executeScriptTabCode(templateContext, runnerContext, getter, setter, calculators, extensionMethods);

    // Run all "in-component defined" state change processors
    this.renderTimeProcessors.forEach(x => {
      try {
        const props = getter(x.ownerId, null, true);
        const context = templateContext.runtime.getRenderContextByComponentId(x.ownerId);
        if (context != null) {
          x.processor(props, setter, getter, context, templateContext, runnerContext);
        }
      } catch (e) {
        if (!templateContext.inBuildMode) {
          applogger.error(`error while running scripts (${x.ownerId}): ${e.message}\nContact your form designer for further advice.`);
        } else {
          addRuntimeError({
            message: String(e.message),
            componentId: x.ownerId,
            propName: 'on-state-change-script',
          });
          applogger.error(e);
        }
      }
    });
  }

  executeOnBeforeGetState(templateContext: TemplateContext, currentProviderData: Ids7ProviderDataResult[]) {
    const extensionMethods: ScriptRunnerExtesionMethods = {
      getReasonName: () => [],
      getReason: () => ({
        components: [],
        changeNumber: -1,
        iteration: 0,
      }),
      isReason: () => false,
      getAllIds: ()=> templateContext.runtime.getAllRuntimeIds(),
      getOEhrInfos: () => templateContext.runtime.getOEhrInfos(),
      getProviderData: () => [...currentProviderData],
    };

    const context: ScriptRunnerContext = {
      get: templateContext.componentStore.getStoreValue,
      set: (id, propName, value) => {
        if (id != null && propName != null) {
          templateContext.componentStore.setStoreUserInputValue(id, propName, value, true);
        }
      },
      extensions: extensionMethods,
      templateDefaults: templateContext.defaults,
      langCode: templateContext.langCode,
      componentExists: (compId) => templateContext.runtime?.getComponentById(compId) != null,
      _scriptHelperMethods_: methodsDict,
    };

    const eventHandler = this.scriptRunnerGetter?.(context);
    if (eventHandler && eventHandler.OnBeforeGetState) {
      try {
        eventHandler.OnBeforeGetState(context.get, context.set, extensionMethods);
      } catch (e) {
        if (!templateContext.inBuildMode) {
          applogger.error(`error while running scripts: ${e.message}\nContact your form designer for further advice.`);
        } else {
          addRuntimeError({
            message: String(e.message),
            propName: 'data-retrieve-script',
          });
          applogger.error(e);
        }
      }
    } else {
      applogger.warn('No OnDataRetrieve found in event handler', eventHandler);
    }
  }

  executeOnDataRetrieve(templateContext: TemplateContext, data: Ids7ProviderDataResult[]) {
    const affectedComponents: StateChangeSimpleComponentInfo[] = [];
    const context: ScriptRunnerContext = {
      get: templateContext.componentStore.getStoreValue,
      set: (id, propName, value) => {
        if (id != null && propName != null) {
          affectedComponents.push({
            compId: id, 
            propName: propName,
            type: 'setUserInput',
          });

          templateContext.componentStore.setStoreUserInputValue(id, propName, value, false);
        }
      },
      extensions: {
        getReasonName: () => [],
        getReason: () => ({
          components: [],
          changeNumber: -1,
          iteration: 0,
        }),
        isReason: () => false,
        getAllIds: ()=> templateContext.runtime.getAllRuntimeIds(),
        getOEhrInfos: () => templateContext.runtime.getOEhrInfos(),
        getProviderData: () => [...data],
      },
      templateDefaults: templateContext.defaults,
      langCode: templateContext.langCode,
      componentExists: (compId) => templateContext.runtime?.getComponentById(compId) != null,
      _scriptHelperMethods_: methodsDict,
    };

    const eventHandler = this.scriptRunnerGetter?.(context);
    if (eventHandler && eventHandler.OnDataProviderRetrieve) {
      try {
        eventHandler.OnDataProviderRetrieve(context.get, context.set, data);

        // Run scripts
        if (affectedComponents.length > 0) {
          templateContext.componentStore.runPostProcessingScripts(affectedComponents);
        }
      } catch (e) {
        if (!templateContext.inBuildMode) {
          applogger.error(`error while running scripts: ${e.message}\nContact your form designer for further advice.`);
        } else {
          addRuntimeError({
            message: String(e.message),
            propName: 'data-retrieve-script',
          });
          applogger.error(e);
        }
      }
    } else {
      applogger.warn('No OnDataRetrieve found in event handler', eventHandler);
    }
  }

  registerComponent(renderId: string, ci: ParsedComponent<any>) {
    const prefix = renderId.substr(0, renderId.length - ci.id.length);
    const pIdx = this.postProcessors.findIndex(c => c.ownerId === renderId);
    const calculators: PostProcessorInfo[] = ci.expressions.map(e => ({
      id: e.componentId,
      name: e.expressionName,
      propToSet: e.componentPropName,
      propRead: e.componentPropNameRead,
      setAsUserInput: e.setAsUserInput,
      customEvaluate: e.customEvaluate,
    }));

    if (pIdx >= 0) {
      //applogger.debug("postProcessor: no new owner id, just update");
      if (calculators.length > 0) {
        this.postProcessors[pIdx].prefix = prefix ?? '';
        this.postProcessors[pIdx].calculators = calculators;
      } else {
        this.postProcessors.splice(pIdx, 1);
      }
    } else if (calculators.length > 0) {
      //applogger.debug("postProcessor: new owner id for " + renderId);
      this.postProcessors.push({ ownerId: renderId, prefix: prefix, calculators: calculators });
    }

    const rIdx = this.renderTimeProcessors.findIndex(c => c.ownerId === renderId);
    const runtimeProcessor = ci.component.onStateChangeRunner;
    if (rIdx >= 0) {
      //applogger.debug("runtimeProcessor: no new owner id, just update");
      if (runtimeProcessor != null) {
        this.renderTimeProcessors[rIdx].processor = runtimeProcessor;
      } else {
        this.renderTimeProcessors.splice(rIdx, 1);
      }
    } else if (runtimeProcessor != null) {
      //applogger.debug("runtimeProcessor: new owner id for " + renderId);
      this.renderTimeProcessors.push({ ownerId: renderId, processor: runtimeProcessor });
    }
  }

  unregisterComponent(id: string) {
    const pIdx = this.postProcessors.findIndex(c => c.ownerId === id);
    if (pIdx >= 0) this.postProcessors.splice(pIdx, 1);
    const rIdx = this.renderTimeProcessors.findIndex(c => c.ownerId === id);
    if (rIdx >= 0) this.renderTimeProcessors.splice(rIdx, 1);
  }

  clear(): void {
    this.postProcessors = [];
    this.renderTimeProcessors = [];
  }

  // I.e. this method runs all user defined scripts as well as the automatically genereated user defined script from component expressions
  private executeScriptTabCode(templateContext: TemplateContext, runnerContext: ScriptRunnerContext, getter: GetterType, setter: SetterType, calculators: CalculationInfo[], extensionMethods: ScriptRunnerExtesionMethods) {
    try {

      const eventHandler = this.scriptRunnerGetter?.(runnerContext);
      if (!eventHandler) {
        applogger.error('no event handler found, not executing');
        return;
      }
            
      // Run OnStateChange first so that user can set up things for InlineCode run
      eventHandler.OnStateChange?.(getter, setter, extensionMethods);
      eventHandler.OnStateChangeInlineCode?.(getter, setter, extensionMethods, extensionMethods.isReason, calculators);

    } catch (e) {
      if (!templateContext.inBuildMode) {
        applogger.error(`error while running scripts: ${e.message}\nContact your form designer for further advice.`);
      } else {
        const message = `Exception during script run with error: "${e.message}". Review the 'Script' and 'Generated code' tab for further advice.`;
        addRuntimeError({ message });
        applogger.warn(message);
      }
    }
  }

  private getRegisteredCalculators(templateContext: TemplateContext, getter: GetterType, setter: SetterType) {
    const calculators: CalculationInfo[] = this.postProcessors.flatMap(ci => ci.calculators.map(c => {
      const prefix = ci.prefix ?? '';
      const fullId = prefix + c.id;
      const customEvaluateFn = c.customEvaluate;
      const component = templateContext.runtime.getComponentBySourceId(c.id);
      return {
        id: fullId,
        name: c.name,
        propToSet: c.propToSet,
        propRead: c.propRead ?? c.propToSet,
        prefix: prefix,
        setAsUserInput: c.setAsUserInput,
        customEvaluate: customEvaluateFn
        // the registered custom evaluate needs a prop state as well, so wrap the call from generatedCode so we can add it
          ? (prfx, expressionRunner) => {
            let compProp = getter(fullId, null, true);
            // applogger.debug("using custom evaluate function. Passing prop ", compProp, " from getter with id ", fullId);
            return customEvaluateFn(compProp, prfx, expressionRunner, getter, setter, templateContext);
          }
          : undefined,
        // Sort calculators by their component path and then by prefix
        // component path typically looks like this .Components[i].ComponentName
        sortBy: 
        /* place button onclick last by prefixing with x */ (component?.component === Button && c.propToSet === buttonClickEventPropName ? 'x' : '') 
                        + (component?.componentPath ?? 'unk')
                        + /* place children before parent */ 'x'
                        + prefix,
      };
    }),
    );

    // perform in-place ordering (i.e. sort mutates the array and return a ref to the same array) and return
    return calculators.sort((a : any, b: any) => (a.sortBy > b.sortBy) ? 1 : ((b.sortBy > a.sortBy) ? -1 : 0));
  }
}