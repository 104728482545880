import React from 'react';
import { ReactComponentContainerProps, shouldDisplay } from './SrtComponent';
import { SrComponentPropsBase, SrComponent } from '../BasicTypes';
import { schema } from './Schema';

import { SrtContainer } from './SrtContainer';
import { HrReactComponent } from './SectraStructure';

interface SectionProps extends SrComponentPropsBase {
  display?: boolean;
  hidden?: boolean;
  borderTop?: boolean,
  borderBottom?: boolean,
}

const sectionSchema = {
  'id': { 'type': 'string', 'description': 'The id of the element' },
  'borderTop': { 'type': 'boolean', 'description': 'Display a border above the section content (default: false)', 'enumDescription': ['Display border', 'No border (default)'] },
  'borderBottom': { 'type': 'boolean', 'description': 'Display a border below the section content (default: false)', 'enumDescription': ['Display border', 'No border (default)'] },
  'hidden': schema.PropDefinition.hidden,
  'display': schema.PropDefinition.display,
};

export const SectraSectionReactComponent: React.FC<ReactComponentContainerProps<SectionProps>> = (container) => {
  const props = container.props;
  if (!shouldDisplay(props.display, container.context)) {
    return null;
  }

  return (
    <div className={'SectraSection'} hidden={props.hidden === true}>
        {props.borderTop === true ? <HrReactComponent props={props} context={container.context} templateContext={container.templateContext} functions={container.functions} /> : null}
        <SrtContainer parentId={props.id} renderContext={container.context}  templateContext={container.templateContext} functions={container.functions} />
        {props.borderBottom === true ? <HrReactComponent props={props} context={container.context}  templateContext={container.templateContext} functions={container.functions} /> : null}
    </div>);
};

const key = 'SectraSection';
export const SectraSection : SrComponent<SectionProps> = {
  key,
  render: (props, context, templateContext, functions) => <SectraSectionReactComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: ()=>Promise.resolve(`- ${key}:
    id: section${schema.getNextIdNum()}
    borderBottom: true
    components:
    - Header:
        header: MyHeader
    `),
  toolboxName: 'Section',
  schema: schema.getSchema(key, schema.mergeSchemaProps(schema.DefaultComponentSchemaPart, sectionSchema), ['id'], false),
};
