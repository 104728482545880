import React from 'react';
import './Toolbox.scss';
import { SrComponent } from '../BasicTypes';
import { applogger } from '../../applogger';
import { Button } from 'react-bootstrap';
import { Components } from '../SrtComponent/Components';
import { AppColorMode } from '../../NavigatonBar';

interface ToolboxProps {
  onClear: () => void;
  onInsert: (x: string) => void;
  onUndo: () => void;
  appColorMode: AppColorMode;
  disabled: boolean;
}

// Toolbox is the buttons menu to the left, 
export const Toolbox: React.FC<ToolboxProps> = ({ onInsert, onClear, onUndo, disabled, appColorMode }) => {

  // execute yaml code = insert it to the state in App.tsx
  const executeTemplate = (template: SrComponent<any>['template']) => {
    if (typeof template === 'string') {
      onInsert(template);
    } else {
      template().then(onInsert).catch(applogger.log);
    }
  };

  const getAllComponentsSortedByName = () => Object.keys(Components).map(x => Components[x])
    .sort((a, b) => a.toolboxName.localeCompare(b.toolboxName));

  // From each component create a button that when clicked 
  const components =  getAllComponentsSortedByName()
    .map(x => <Button key={x.key}
                          className="btn-secondary"
                          disabled={disabled}
                          onClick={() => executeTemplate(x.template)}>{x.toolboxName}</Button>);

  // return a clear button + all the tools we have implemented in SrtComponent
  return <div className={'Toolbox ' + appColorMode}>

        <Button disabled={disabled} className="btn-secondary undo-spec-button"
            onClick={() => {
              onUndo();
            }}>
            Undo
        </Button>
        <Button disabled={disabled} className="btn-secondary clear-spec-button"
            onClick={() => {
              onClear();
            }}>
            Clear
        </Button>
        <br/><br />
        {components}
    </div>;
};