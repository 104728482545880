/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Col, Grid, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { GenericTable, GenericTableRowData } from '../Generic/GenericTable';
import { SvgSymbol } from '../SvgSymbol';
import { IServerCommunication, SrtSite, UserRole } from '../../ServerCommunication';

interface SectraAdminProps {
  server: IServerCommunication;
}

export const SectraAdmin: React.FC<SectraAdminProps> = (props) => {
  const [sites, setSites] = useState<SrtSite[]>([]);
  const [siteName, setSiteName] = useState<string>('');
  const [siteDesc, setSiteDesc] = useState<string>('');
  const [siteEmailDomain, setSiteEmailDomain] = useState<string>('');
  const [changeSiteEmailDomain, setChangeSiteEmailDomain] = useState<string | null>(null);
  const [userId, setUserId] = useState<string>('');
  const [userSite, setUserSite] = useState<number>(1);
  const [userRole, setUserRole] = useState<UserRole>(UserRole.None);
  const [selectedSiteId, setSelectedSiteId] = useState<number | null>(null);

  useEffect(() => {
    async function loadSites() {
      let newSites = await props.server.getAllSites();
      setSites(newSites);
    }
    loadSites();
  }, []);

  const siteData: GenericTableRowData[] = sites.map(x => {
    return {
      values: ['' + x.Id, x.Name, x.Description, x.EmailDomain ?? ' - '],
      className: x.Id === selectedSiteId ? 'selected' : '',
      iconId: x.Id === selectedSiteId ? 'icon-Checkmark' : undefined,
    };
  });

  async function createSite() {
    var newSite = await props.server.createSite(siteName, siteDesc, siteEmailDomain);
    const newSites = [...sites];
    let siteIndex = sites.findIndex(x => x.Id === newSite.Id);
    if (siteIndex !== -1) {
      newSites[siteIndex] = newSite;
    } else {
      newSites.push(newSite);
    }
    setSites(newSites);
  }

  async function updateUser() {
    try {
      await props.server.setUser({ Guid: userId, Role: userRole }, userSite);
      alert('User updated successfully!');
      setUserSite(1);
      setUserId('');
      setUserRole(UserRole.None);
    } catch (ex) {
      alert(ex);
    }
  }

  async function updateSiteEmailDomain() {
    if (selectedSiteId != null) {
      const updatedSite = await props.server.setSiteEmailDomain(selectedSiteId, changeSiteEmailDomain);
      setSites(sites.map(x => x.Id === updatedSite.Id ? updatedSite : x));
      setChangeSiteEmailDomain(null);
    }
  }

  return (
    <>
      <SvgSymbol />
        <Grid>
          <h2 >
            Sectra admin
          </h2>
          <p>Any changes made to users will take effect the next time the user logs in.</p>
          <Row className="show-grid">
            <Col xs={2}>
              <label htmlFor="site-name"><p>Site name</p></label>
            </Col>
            <Col xs={8} sm={6}>
              <input id="site-name" className="form-control input-sm" type="text" value={siteName} onChange={e => setSiteName(e.currentTarget.value)} />
            </Col>
          </Row>
          <Row className="show-grid" style={{ marginTop: '10px' }}>
            <Col xs={2}>
              <label htmlFor="site-description"><p>Site description</p></label>
            </Col>
            <Col xs={8} sm={6}>
              <input id="site-description" className="form-control input-sm" type="text" value={siteDesc} onChange={e => setSiteDesc(e.currentTarget.value)} />
            </Col>
          </Row>
          <Row className="show-grid" style={{ marginTop: '10px' }}>
            <Col xs={2}>
              <label htmlFor="site-email" title="Email domain of the site (e.g. sectra.com). Users within this email domain can be added to the site">
                <p>Site email domain</p>
              </label>
            </Col>
            <Col xs={8} sm={6}>
              <input id="site-email" className="form-control input-sm" type="text" value={siteEmailDomain} onChange={e => setSiteEmailDomain(e.currentTarget.value)} />
            </Col>
          </Row>
          <Row className="show-grid">
            <Col xs={2}></Col>
            <Col xs={8} sm={6}>
              <button className="btn btn-primary pull-right" onClick={createSite}>Create site</button>
            </Col>
          </Row>
          <Row className="show-grid" style={{ marginTop: '20px' }}>
            <Col xs={2}>
              <label htmlFor="suser-id"><p>User id</p></label>
            </Col>
            <Col xs={8} sm={6}>
              <input type="text" id="suser-id" className="form-control input-sm" value={userId} onChange={e => setUserId(e.currentTarget.value)} />
            </Col>
            <Col xs={2}>
            </Col>
          </Row>
          <Row className="show-grid" style={{ marginTop: '10px' }}>
            <Col xs={2}>
              <label htmlFor="suser-role"><p>Account Id</p></label>
            </Col>
            <Col xs={2}>
              <input type="number" className="form-control input-sm" value={userSite} onChange={e => setUserSite(parseInt(e.currentTarget.value))} />
            </Col>
          </Row>
          <Row className="show-grid" style={{ marginTop: '10px' }}>
            <Col xs={2}>
              <label htmlFor="suser-role"><p>User role</p></label>
            </Col>
            <Col xs={6} sm={4}>
              <ToggleButtonGroup name="all group toggle" className="options" type="radio" value={userRole} onChange={(x: UserRole) => setUserRole(x)} >
                <ToggleButton value={UserRole.None} className="btn btn-secondary">{UserRole[UserRole.None]}</ToggleButton>
                <ToggleButton value={UserRole.User} className="btn btn-secondary">{UserRole[UserRole.User]}</ToggleButton>
                <ToggleButton value={UserRole.Admin} className="btn btn-secondary">{UserRole[UserRole.Admin]}</ToggleButton>
                <ToggleButton value={UserRole.SectraAdmin} className="btn btn-secondary">{UserRole[UserRole.SectraAdmin]}</ToggleButton>
              </ToggleButtonGroup>
            </Col>
            <Col xs={2}>
              <button className="btn btn-primary pull-right" onClick={updateUser}>Update user</button>
            </Col>
          </Row>
          <Row className="show-grid" style={{ marginTop: '20px' }}>
            <Col xs={12} sm={10}>
              <GenericTable headers={['Site Id', 'Site Name', 'Site Description', 'Email Domain']} tableRows={siteData} 
                onClick={i => selectedSiteId === sites[i].Id ? setSelectedSiteId(null) : setSelectedSiteId(sites[i].Id)} />
            </Col>
          </Row>
          <Row className="show-grid" style={{ marginTop: '10px' }}>
            <Col xs={2}>
              <label htmlFor="change-site-email"><p>New site email domain</p></label>
            </Col>
            <Col xs={6} sm={4}>
              <input id="change-site-email" className="form-control input-sm" type="text" value={changeSiteEmailDomain ?? ''}
                onChange={e => setChangeSiteEmailDomain(e.currentTarget.value === '' ? null : e.currentTarget.value)} />
            </Col>
            <Col xs={4}>
              <button className="btn btn-primary"
                onClick={() => updateSiteEmailDomain()}>
                  Update selected email domain
              </button>
            </Col>
          </Row>
        </Grid>
    </>
  );
};