import { expressionPattern } from './Schema';
import { IOptionValues, ISingleOptionValue } from './SectraBaseComponent/SectraOptionBase';

export const SchemaOptionsProperty = {
  'anyOf': [
    {
      'type': 'array',
      'items': { 
        'anyOf': [
          {
            'type': 'object',
            'additionalProperties': false,
            'description': 'Detailed option with potentially different text/value, description and more.',
            'properties': {
              'value': { 'type': ['string', 'number', 'boolean'], 'description': 'The option value.' },
              'text': { 'type': 'string', 'description': 'The option display text.' },
              'description': { 'type':'string', 'description': 'The option description.' },
              'disabled': { 'type': 'boolean', 'description': 'Whether the option is disabled or not.' },
              'exclusive': { 'type': 'boolean', 'description': 'Not allowed in combination with other values in a multi-select scenario.' },
            }, 
            'anyOf': [{ 'required': ['value'] }, { 'required': ['text'] }],
          },
          { 'type': ['string', 'number', 'boolean'], 'description': 'Simple option value/text without further description' },
        ],
      },
      'minItems': 1,
      'uniqueItems': true,
      'description': 'The values to select among as a list.',
    },
    { 'type': 'string', 'description': 'The values to select among, as an expression', 'pattern': expressionPattern },
  ],
  'description': 'The values to select among, either as a predefined list from specification or as an expression string.',
};

export interface ISectraOptionsComplexValue {
  value:  ISingleOptionValue | undefined;
  text: string | undefined;
  description?: string;
  exclusive?: boolean;
  disabled?: boolean;
}

export type ISectraOptionsValue = ISingleOptionValue | ISectraOptionsComplexValue;

export const SectraOptionsHelper = {
  getValues: (options: ISectraOptionsValue[], includeDisabled: boolean): IOptionValues => {
    if (options == null || !Array.isArray(options)) return [];
    if (!includeDisabled) {
      options = options.filter(o => typeof o !== 'object' || o.disabled !== true);
    }

    return options.map(opt => {
      if (opt == null) return '';
      if (typeof opt === 'object') {
        if (opt.value != null) {
          opt = opt.value;
        } else if (opt.text != null) {
          opt = opt.text;
        }
      }

      return (typeof opt === 'string' || typeof opt === 'boolean' || typeof opt === 'number') ? opt : '';
    });
  },
  getTexts: (options: ISectraOptionsValue[]): string[] => {
    if (options == null || !Array.isArray(options)) return [];
    return options.map(opt => {
      if (opt == null) return '';
      if (typeof opt === 'object') {
        if (opt.text != null) {
          opt = opt.text;
        } else if (opt.value != null) {
          opt = opt.value;
        }
      }

      return String(opt);
    });
  },
  getDescriptions: (options: ISectraOptionsValue[]): (string | undefined)[] => {
    if (options == null || !Array.isArray(options)) return [];
    return options.map(opt => {
      if (opt == null) return undefined;
      return typeof opt === 'object' ? (opt.description != null ? String(opt.description) : undefined) : undefined;
    });
  },
  getDisabled: (options: ISectraOptionsValue[]): boolean[] => {
    if (options == null || !Array.isArray(options)) return [];
    return options.map(opt => (opt as ISectraOptionsComplexValue)?.disabled === true);
  },
  getOptionsWithDisabledFilter: (options: ISectraOptionsValue[], disabledFilter: any) => {
    if (options == null || !Array.isArray(options)) {
      return [];
    }
        
    const filterExp = getAsRegExp(disabledFilter);
    if (filterExp == null) {
      return options;
    }

    return options.map(opt => {
      let option: ISectraOptionsComplexValue = typeof opt === 'object' 
        ? { ...opt } 
        : { value: opt, text: undefined, disabled: false };
      option.disabled = filterExp.test(option.value != null ? String(option.value) : '');
      return option;
    });
  },
    
};

function getAsRegExp(s: any): RegExp | null {
  if (s == null) return null;
  if (s instanceof RegExp) {
    return s;
  }
  if (typeof s === 'string') {
    let p = s.match(/^m?\/(.+)\/([igm]*)$/);
    return p != null 
      ? new RegExp(p[1], p[2])
      : new RegExp(s);
  }
  return null;
}