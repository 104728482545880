import { IAnnotationInfo, ICanvasAnnotation, IPosition, MapItem, ModifyResult } from '../SectraCanvasAnnotation';
import { CanvasLineAnnotation } from './CanvasLineAnnotation';

export enum CurveState {
  Line,
  Cp1,
  Cp2,
  Ended,
  Move,
}

function getBoundingBox(start: IPosition, cp1: IPosition, cp2: IPosition, end: IPosition) {
  function bezier(p0: number, p1: number, p2: number, p3: number, t: number) {
    return p0 * Math.pow(1 - t, 3) + 3 * p1 * t * (1 - t) * (1 - t) + 3 * p2 * t * t * (1 - t) + p3 * Math.pow(t, 3);
  }

  function calcAxis(cstart: number, ccp1: number, ccp2: number, cend: number) : IPosition {
    let a = 3 * cend - 9 * ccp2 + 9 * ccp1 - 3 * cstart;
    let b = 6 * cstart - 12 * ccp1 + 6 * ccp2;
    let c = 3 * ccp1 - 3 * cstart;
    let disc = b * b - 4 * a * c;
    let p1 = Math.min(cstart, cend);
    let p2 = Math.max(cstart, cend);
    if (disc >= 0 && a !== 0) {
      let t1 = (-b + Math.sqrt(disc)) / (2 * a);
      if (t1 > 0 && t1 < 1) {
        let x = bezier(cstart, ccp1, ccp2, cend, t1);
        p1 = Math.min(p1, x);
        p2 = Math.max(p2, x);
      }
      let t2 = (-b - Math.sqrt(disc)) / (2 * a);
      if (t2 > 0 && t2 < 1) {
        let x = bezier(cstart, ccp1, ccp2, cend, t2);
        p1 = Math.min(p1, x);
        p2 = Math.max(p2, x);
      }
    }
    return { x: p1, y: p2 };
  }

  let xPoints = calcAxis(start.x, cp1.x, cp2.x, end.x);
  let yPoints = calcAxis(start.y, cp1.y, cp2.y, end.y);
  return [{ x: xPoints.x, y: yPoints.x }, { x: xPoints.y, y: yPoints.y }];
}

export class Canvas4pCurveAnnotation extends CanvasLineAnnotation implements ICanvasAnnotation {
  protected cp1Pos: IPosition | null = null;

  protected cp2Pos: IPosition | null = null;

  protected curveState: CurveState = CurveState.Line;

  getType(): string {
    return '4pCurve';
  }

  stopDraw(): ModifyResult {
    return { isValid: this.endPos != null, redraw: true };
  }

  redrawToCanvas(ctx: CanvasRenderingContext2D, inFocus: boolean, map: MapItem[] | null | undefined): void {
    if (this.endPos == null) {
      return;
    }
    if (this.cp1Pos == null) {
      super.redrawToCanvas(ctx, inFocus, map);
      return;
    }

    const cp2 = this.cp2Pos ?? this.cp1Pos;
    const oldLineWidth = ctx.lineWidth; ctx.lineWidth = this.brushWidth;
    const oldLineJoin = ctx.lineJoin; ctx.lineJoin = 'round';
    const oldLineCap = ctx.lineCap; ctx.lineCap = 'round';
    const path = new Path2D();
    path.moveTo(this.startPos.x, this.startPos.y);
    path.bezierCurveTo(this.cp1Pos.x, this.cp1Pos.y, cp2.x, cp2.y, this.endPos.x, this.endPos.y);
    ctx.stroke(path);
    if (inFocus) {
      this.addGrabPoint(ctx, this.startPos);
      this.addGrabPoint(ctx, this.endPos);
      this.addGrabPoint(ctx, this.cp1Pos);
      if (this.cp2Pos != null) {
        this.addGrabPoint(ctx, this.cp2Pos);
      }
    }

    ctx.lineWidth = oldLineWidth;
    ctx.lineJoin = oldLineJoin;
    ctx.lineCap = oldLineCap;
  }

  startMove(pos: IPosition) {
    super.startMove(pos);
    if (this.cp1Pos == null) {
      this.curveState = CurveState.Cp1;
    } else if (this.positionDistance(pos, this.cp1Pos) <= this.maxDistToAcceptAsSamePoint) {
      this.curveState = CurveState.Cp1;
    } else if (this.cp2Pos == null || this.positionDistance(pos, this.cp2Pos) <= this.maxDistToAcceptAsSamePoint) {
      this.curveState = CurveState.Cp2;
    } else {
      this.curveState = CurveState.Move;
    }
    return { isValid: true };
  }

  getWithinActionBox(pos: IPosition): boolean {
    if (this.endPos == null) {
      return false;
    }
    let posOnCp1 = this.cp1Pos == null ? false : this.positionDistance(pos, this.cp1Pos) <= this.maxDistToAcceptAsSamePoint;
    let posOnCp2 = this.cp2Pos == null ? false : this.positionDistance(pos, this.cp2Pos) <= this.maxDistToAcceptAsSamePoint;
    if (this.cp1Pos == null) {
      return super.getWithinActionBox(pos);
    } else {
      const cp2 = this.cp2Pos ?? this.cp1Pos;
      let bb = getBoundingBox(this.startPos, this.cp1Pos, cp2, this.endPos);
      return posOnCp1 || posOnCp2 || pos.x >= bb[0].x && pos.x <= bb[1].x && pos.y >= bb[0].y && pos.y <= bb[1].y;
    }
  }

  setNewBasePoint(pos: IPosition): boolean {
    if (this.endPos == null) {
      return false;
    }
    if (this.lineMoveState !== 'line') {
      return super.setNewBasePoint(pos);
    }
    if (this.curveState === CurveState.Cp1) {
      this.cp1Pos = pos;
      return true;
    }
    if (this.curveState === CurveState.Cp2) {
      this.cp2Pos = pos;
      return true;
    }
    let centerPoint: IPosition = { x: (this.startPos.x + this.endPos.x) / 2, y: (this.startPos.y + this.endPos.y) / 2 };
    let offsetX = centerPoint.x - pos.x;
    let offsetY = centerPoint.y - pos.y;
    if (offsetX !== 0 || offsetY !== 0) {
      this.startPos.x -= offsetX;
      this.startPos.y -= offsetY;
      this.endPos.x -= offsetX;
      this.endPos.y -= offsetY;
      if (this.cp1Pos != null) {
        this.cp1Pos.x -= offsetX;
        this.cp1Pos.y -= offsetY;
      }
      if (this.cp2Pos != null) {
        this.cp2Pos.x -= offsetX;
        this.cp2Pos.y -= offsetY;
      }
      return true;
    }
    return true;
  }

  canFocus() {
    return true;
  }

  getPoints(): IPosition[] {
    if (this.endPos == null) {
      return [];
    }
    let retval = [this.startPos, this.endPos];
    if (this.cp1Pos != null) {
      retval.push(this.cp1Pos);
      if (this.cp2Pos != null) {
        retval.push(this.cp2Pos);
      }
    }
    return retval;
  }

  setPoints(points: IPosition[]) {
    super.setPoints(points);
    if (points.length >= 3) {
      this.cp1Pos = points[2];
    }
    if (points.length >= 4) {
      this.cp2Pos = points[3];
    }
  }

  getAnnotationInfo(): IAnnotationInfo {
    return {
      type: this.getType(),
      colorIndex: this.getColorIndex(),
      points: this.getPoints(),
      metaData: this.brushWidth,
    };
  }

  isIncomplete(): boolean {
    return this.cp2Pos == null;
  }
}

export class Canvas3pCurveAnnotation extends Canvas4pCurveAnnotation implements ICanvasAnnotation {
  getType(): string {
    return '3pCurve';
  }

  startMove(pos: IPosition) {
    super.startMove(pos);
    if (this.cp1Pos == null) {
      this.curveState = CurveState.Cp1;
    } else if (this.positionDistance(pos, this.cp1Pos) <= this.maxDistToAcceptAsSamePoint) {
      this.curveState = CurveState.Cp1;
    } else {
      this.curveState = CurveState.Move;
    }
    return { isValid: true };
  }

  isIncomplete(): boolean {
    return this.cp1Pos == null;
  }
}