import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { SrComponent, SrValueComponentPropsBase } from '../BasicTypes';
import { ReactComponentContainerProps, shouldDisplay } from './SrtComponent';
import { schema } from './Schema';

import { FormDataOutput } from './FormDataOutput';
import { SectraTextArea } from './SectraBaseComponent/SectraTextAreaContentEditable';
import { NumberAsString } from '../NumberHelper';
import { IsInDirectRowContext } from './SectraRow';

interface TextAreaProps extends SrValueComponentPropsBase {
  size?: string;
  placeholder?: string;
  rows: number | 'auto';
  minRows?: number;
  maxRows?: number;
  lineWrap?: boolean;
  breakRow?: boolean;
  speechEnabled?: boolean;
}

const textAreaSchema = schema.mergeSchemaProps(schema.DefaultSizeSchemaPart, {
  'label': { 'type': ['string', 'null'], 'description': 'The text area label. If blank - no label' }, 
  'placeholder': { 'type': ['string', 'null'], 'description': 'Text area placeholder value.' },
  'rows': {
    'anyOf': [
      { 'type': 'string', 'enum': ['auto'], 'enumDescription': ['Automatically set the number of rows based in input (default)'], 'description': '' },
      { 'type': 'integer', 'description': 'A fixed height (number of rows)' },
    ],
    'description': 'Number of rows in text area (height). Default: (auto).',
  },
  'minRows': { 'type': 'integer', 'description': 'The minimum number of rows allowed (applicable for auto, default: 2).' },
  'maxRows': { 'type': 'integer', 'description': 'The maximum number of rows allowed (applicable for auto, default: 20).' },
  'lineWrap': { 'type': ['string', 'boolean'], 'enumDescription': ['Break text into lines so that it will fit into the available width of the component (default)', 'Do not break text into lines'], 'description': 'Whether to break text into lines so that it will fit into the available width of the component (default true)' },
  'value': { 'type': ['number', 'string'], 'description': schema.ValueDescription },
  'breakRow': { 'type': ['string', 'boolean'], 'enumDescription': ['Text area on a new row in a row context (default)', 'Text area uses remaining space on the row just as a single row input component'], 'description': 'Whether text area is forcibly put on a new row in a row context (default true)' },
  'speechEnabled': { 'type': 'boolean', 'description': 'Whether to allow speech into this field or not (from IDS7 24.1, default: true)' },
});

export const TextAreaReactComponent: React.FC<ReactComponentContainerProps<TextAreaProps>> = (container) => {
  const props = container.props;
  if (!shouldDisplay(props.display, container.context)) {
    return null;
  }

  const containerStyle: React.CSSProperties = { };
  if (props.hidden === true) {
    containerStyle.visibility = 'hidden';
    containerStyle.height = 0;
    containerStyle.flexGrow = 0;
    containerStyle.width = 0;
  }

  const minRows = props.minRows != null && !isNaN(Number(props.minRows))
    ? Math.max(2, Number(props.minRows))
    : 2;
  const maxRows = props.maxRows != null && !isNaN(Number(props.maxRows))
    ? Math.min(100, Number(props.maxRows))
    : 20;

  const isRowContext = IsInDirectRowContext(container.context, container.templateContext);
  const breakRow = isRowContext && (props.breakRow !== false);
  const size = (props as any)?.size ?? 'fill';

  let textValue = props.value;
  if (typeof textValue === 'number') {
    textValue = NumberAsString(textValue, container.templateContext.langCode);
  } else if (textValue == null) {
    textValue = null;
  } else if (typeof textValue !== 'string') {
    textValue = String(textValue);
  }

  const onChange = (value: string | null) => {
    if (value !== textValue) {
      // since writing usually is a stream of updates let us set user input without running scripts (false)
      // and instead issue a delayed script runner so that we can consolidate update events
      container.functions.setUserInput(props.id, 'value', value, false);
      container.functions.runScripts(200, [{ type: 'setUserInput', compId: props.id, propName: 'value' }]);
    }
  };

  const inner = 
    <div style={containerStyle} className={size !== 'fill' ? 'input-size-' + size : undefined} data-component-id={props.id}>
        {props.label && props.hidden !== true
          ? <label htmlFor={props.id} style={{ margin: '0 0 4px 2px' }}>{props.label}</label>
          : null}
        <FormDataOutput id={props.id} name={props.name ?? props.inherritedLabel ?? props.id} value={textValue} mandatory={props.mandatory}
            worklistAttribute={props.worklistAttribute} freeField={props.freeField} />
        {props.hidden !== true
        /* No need to render this input element if we're hidden */ 
          ? <SectraTextArea 
                id={props.id} 
                className={'form-control textarea'} 
                rows={props.rows ?? 'auto'}
                minRows={minRows}
                maxRows={maxRows}
                lineWrap={props.lineWrap}
                placeholder={props.placeholder} 
                value={textValue} 
                onUpdate={onChange}
                markupCustomStylingOnPaste={false}
                renderOpts={{ pre: container.templateContext.defaults.defaultPreFont }}
                speechEnabled={props.speechEnabled !== false}
                disabled={props.disabled === true} />
          : null }
    </div>;
    
  return isRowContext || props.hidden === true
    ? <>{ breakRow ? <div className={'row-break'}></div> : null } { inner }</>
    : <Grid data-component-id={props.id}><Row className={'show-grid fullwidth sectra-textarea'}><Col xs={12}>{ inner }</Col></Row></Grid>;
};

//
// The textarea component
//

const textAreaComponentKey = 'TextArea';
export const TextArea : SrComponent<TextAreaProps> = {
  key: textAreaComponentKey,
  render: (props, context, templateContext, functions) => <TextAreaReactComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: () => Promise.resolve(`- ${textAreaComponentKey}:
    id: textarea${schema.getNextIdNum()}
    `),
  toolboxName: textAreaComponentKey,
  schema: schema.getSchema(textAreaComponentKey, textAreaSchema),
  getInitValues: () => ({ 'value': null }),
};
