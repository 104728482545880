import { applogger } from '../applogger';
import { CompositeActionItem, Ids7ProviderData, Ids7ProviderDataResult, ITemplateRuntime, TemplateContext } from './BasicTypes';

export const DataSourceHelper = {
  processProviderData(templateContext: TemplateContext, data: Ids7ProviderData): boolean {
    applogger.debug('retrieved provider data', data);
    if (!DataSourceHelper.validateProviderData(data)) {
      return false;
    }

    // reformat source items to a structure better suited for providers
    const fieldsLookup = getFieldsLookup(getDataRecievingComponentLookup(templateContext.runtime));

    // iterate all provider results and process
    const actions: CompositeActionItem[] = [];
    data.results.forEach(item => processSingleProviderData(item, fieldsLookup, templateContext.runtime, actions));

    // batch update data source values
    if (actions.length > 0) {
      templateContext.componentStore.compositeUpdate(actions, true);
    }

    return true;
  },

  validateProviderData: (data: Ids7ProviderData) => {
    if (data == null) {
      return false;
    }
    if (!data.results || !Array.isArray(data.results)) {
      applogger.warn('Malformed provider data object (ignored)', data);
      return false;
    }
    if (data.results.length <= 0) {
      applogger.info('Empty provider data sent!', data);
      return false;
    }
    return true;
  },
};

function getDataRecievingComponentLookup(runtime: ITemplateRuntime) {
  const ret: { [compId: string]: IDataSourceStoreRequesterItem } = {};
  runtime.getAllRuntimeIds().forEach(compId => {
    const component = runtime.getComponentById(compId);
    const propName = 'value';
    const dataarg = component?.args.dataarg;
    if (dataarg != null) {
      ret[`${compId}.${propName}`] = { compId, dataarg, propName };
    }
  });

  return ret;
}

interface IDataSourceStoreRequesterItem {
  compId: string;
  dataarg: string;
  propName: string;
}

function processSingleProviderData(item: Ids7ProviderDataResult, fieldsLookup: FieldsLookup, runtime: ITemplateRuntime, actions: CompositeActionItem[]){
  if (item.providerData == null || item.providerName == null) {
    applogger.warn('Malformed provider data item (no results child, ignored)', item);
    return;
  }

  const receivingFields = fieldsLookup[item.providerName];
  if (receivingFields == null) {
    applogger.debug(`no fields setup for receiving data from provider ${item.providerName} (nothing to do)`, item.providerData);
    return;
  }

  applogger.debug(`new data from ${item.providerName}`, item.providerData);
  applogger.debug('receiving fields are ', receivingFields);
  const providerData = Array.isArray(item.providerData) ? item.providerData : [item.providerData];
  receivingFields.forEach(f => {
    if (providerData[0]?.properties) {
      const value = getByPath(providerData[0].properties, f.dataarg);
      applogger.debug(`new data from provider (${item.providerName}) set into ${f.compId}, value: ${value}`);
      addSetValueAction(f, value, runtime, actions);
    }
  });
}

function addSetValueAction(f: Field, value: any, runtime: ITemplateRuntime, actions: CompositeActionItem[]){
  if (value != null) {
    const parsedComponent = runtime.getComponentById(f.compId);
    const normalizer = parsedComponent?.component.normalizeDataSourceValue;
    const normalizedValue = normalizer ? normalizer(value, f.propName) : (f.propName === 'value' && typeof (value) === 'object' ? JSON.stringify(value) : value);
    if (normalizedValue !== value) {
      applogger.debug('after normalization:', normalizedValue);
    }

    actions.push({ componentId: f.compId, propName: f.propName, value: normalizedValue });
  } else {
    actions.push({ componentId: f.compId, propName: f.propName, value: null });
  }
}


// navigate into the obj with the path
function getByPath(obj: any, path: string) {
  applogger.debug('getByPath ', obj, path);
  if (path == null || path.length === 0) return obj;
    
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, '');           // strip a leading dot
  path = path.replaceAll('freefield', 'free-field');

  // Check if complete path exists in provider (needed for free-fields)
  if (obj[path]) {
    return obj[path];
  } else {
    let k = Object.keys(obj).filter(key => key.toLowerCase() === path.toLowerCase());
    if (k.length === 1) {
      return obj[k[0]];
    }
  }

  const parts = path.split('.');
  parts.forEach(p => {
    if (typeof obj === 'object') {
      if (obj[p]) {
        obj = obj[p];
      } else {
        // case insensitive search (allowed if a single unique match was found)
        let k = Object.keys(obj).filter(key => key.toLowerCase() === p.toLowerCase());
        obj = k.length === 1 ? obj[k[0]] : undefined;
      }
    } else {
      obj = obj?.[p];
    }
    if (obj === undefined) {
      return obj;
    }
  });

  return obj;
}

type Field = { dataarg: string, compId: string, propName: string };
type FieldsLookup = { [providerName: string]: Field[] };

function getFieldsLookup(dataSourceRequesterItems: { [p: string]: IDataSourceStoreRequesterItem }):  FieldsLookup {
  const fieldsLookup: { [providerName: string]: { dataarg: string, compId: string, propName: string }[] } = {};
  Object.keys(dataSourceRequesterItems).forEach(k => {
    const item = dataSourceRequesterItems[k];
    const argParts = item.dataarg.split('.');
    const providerName =  argParts[0];
    const dataarg = argParts.slice(1).join('.');

    if (fieldsLookup[providerName] == null) {
      fieldsLookup[providerName] = [];
    }
    fieldsLookup[providerName].push({
      dataarg: dataarg,
      compId: item.compId,
      propName: item.propName,
    });
  });
  return fieldsLookup;
}