import React, { createContext, useEffect, useMemo } from 'react';
import { applogger } from './applogger';
import { baseUrl } from './ServerCommunication';
import './Auth.scss';

export interface AuthContext {
  getAuthorizationHeaders: () => Record<string, string>;
  username?: string;
}

interface CookieKeepAliveAuthProviderProps {
  username: string;
  doKeepAlive?: boolean;
  intervalMs?: number;
  timeoutWarningMs?: number;
  onSessionTimeoutWarning?: (millisecondsLeft: number) => void;
  onExpiredSession?: () => void
}

/**
 * AuthContext for authentication scenarios where no special action
 * needs to be taken for authentication in API calls.
 */
const nopAuthContext = Object.freeze({
  getAuthorizationHeaders: () => ({}),
});
export const Context = createContext<AuthContext>(nopAuthContext);

export const CookieKeepAliveAuthProvider: React.FC<CookieKeepAliveAuthProviderProps> = (props) => {
  if (!props.timeoutWarningMs && props.doKeepAlive !== false) {
    applogger.info('Timeout warning threshold not defined');
  }

  // setup the keep alive session callback loop
  useEffect(() => {
    if (props.doKeepAlive === false) {
      return;
    }

    // setup keep alive interval
    const intervalMs = props.intervalMs && !isNaN(+props.intervalMs) ? +props.intervalMs : 20 * 1000;
    const interval = setInterval(async () => {
      const millisecondsLeft = await shsWebApiLoginKeepAlive();
      //applogger.debug("Session time left: " + millisecondsLeft);

      if (millisecondsLeft <= 0) {
        applogger.info('Session has expired!');
        props.onExpiredSession?.();
      } else if (props.timeoutWarningMs != null && millisecondsLeft < props.timeoutWarningMs) {
        applogger.debug('Less time than threshold left on active session (session seconds, threshold seconds)', millisecondsLeft / 1000, props.timeoutWarningMs / 1000);
        props.onSessionTimeoutWarning?.(millisecondsLeft);
      } 
    }, intervalMs);

    return () => clearInterval(interval);
  }, [props.doKeepAlive ?? true]);

  const authState = useMemo<AuthContext>(
    () => ({
      username: props.username,
      getAuthorizationHeaders: (): Record<string, string> => {
        return {};
      },
    }),
    [props.username],
  );

  return <Context.Provider value={authState}>{props.children}</Context.Provider>;
};

/**
 * Keeps alive the SHS session, and gets the time left in the web session.
 *
 * @returns the seconds that are left in the current session.
 */
async function shsWebApiLoginKeepAlive(): Promise<number> {
  try {
    const url = baseUrl() + '/login/GetAuthExpiration?doSessionKeepAlive=true';

    const response = await fetch(url, {
      method: 'POST',
      body: null,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest', // needed for LoginHandler redirect suppression
      },
      redirect: 'error',
    });

    if (!response.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw response;
    }

    const result: { Expiration: string } = await response.json();
    const expirationTime = new Date(result.Expiration);
    const currentTime = new Date();

    return expirationTime.getTime() - currentTime.getTime();
  } catch (err) {
    applogger.error(`Failed to keep alive session: ${err}. Logging out...`);
    try { 
      await fetch(baseUrl() + '/login/signout', { redirect: 'error' });
    } catch {
      applogger.info('Also failed to logout');
    }

    return -1;
  }
}


export default {
  Context,
  CookieKeepAliveAuthProvider,
};
