import React from 'react';

import { ISectraOptionsValue } from './SectraOptionsBase';
import { ReactComponentContainerProps } from './SrtComponent';
import { ComponentBaseProps } from './SectraInput';
import { FormDataOutput } from './FormDataOutput';
import { SectraAutocompleteBase } from './SectraBaseComponent/SectraAutocomplete';
import { SrComponent } from '../BasicTypes';
import { SchemaProperties, schema } from './Schema';
import './SectraAutocomplete.scss';
import { ISingleOptionValue } from './SectraBaseComponent/SectraOptionBase';
import { GetRowComponent } from './SectraRow';

interface AutocompleteProps extends ComponentBaseProps {
  options: ISectraOptionsValue[];
  maxSearchResults?: number;
  value: ISingleOptionValue[];
  minSearch?: number;
  multichoice?: boolean;
}

let componentAutoCompleteSchema: SchemaProperties = schema.mergeSchemaProps(schema.DefaultInputComponentSchema, {
  'value': { 'type': 'array', 'items': { type: 'string' }, 'description': 'Initial values for the autocomplete field' },
  'options': { 'type': 'array', 'items': { type: 'string' }, 'description': 'Available options to select' },
  'maxSearchResults': { 'type': 'integer', 'minimum': 1, 'description': 'Max number of options to select on (Default: 10)' },
  'minSearch': { 'type': 'integer', 'minimum': 0, 'description': 'Minimum number of letters required in search to show results (Default: 0)' },
  'multichoice': { 'type': 'boolean', 'description': 'Whether multiple values should be accepted (Default: false)' },
});
componentAutoCompleteSchema = schema.mergeSchemaProps(schema.DefaultSizeSchemaPart, componentAutoCompleteSchema);

const AutocompleteComponent: React.FC<ReactComponentContainerProps<AutocompleteProps>> = (container) => {
  const props = container.props;
  const value = props.value;
  const defaultMaxSearchResults = 10;
  return (
    GetRowComponent(props, container.context, container.templateContext, <>
            <FormDataOutput id={props.id} name={props.name ?? props.inherritedLabel ?? props.id} value={Array.isArray(value) ? value.join() : ''} type="text" mandatory={props.mandatory}
                worklistAttribute={props.worklistAttribute} freeField={props.freeField} />
            <SectraAutocompleteBase options={props.options ?? []} id={props.id} onInputChange={vals => {
              container.functions.setUserInput(props.id, 'value', vals);
            }}
            value={value ?? []}
            name={props.name ?? props.inherritedLabel ?? props.id}
            allowMultiple={props.multichoice ?? false}
            placeholder={props.placeholder}
            disabled={props.disabled === true}
            minSearch={props.minSearch ?? 0}
            hidden={props.hidden}
            maxSearchResults={props.maxSearchResults ?? defaultMaxSearchResults}
            />
        </>)
  );
};

const autoCompleteComponentKey = 'AutoComplete';
export const AutoComplete : SrComponent<AutocompleteProps> = {
  key: autoCompleteComponentKey,
  render: (props, context, templateContext, functions) => <AutocompleteComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: () => Promise.resolve(`- ${autoCompleteComponentKey}:
    id: autoComplete${schema.getNextIdNum()}
    options:
        - Malignant
        - Benign`),
  getInitValues: () => ({ 'value': null }),
  toolboxName: autoCompleteComponentKey,
  schema: schema.getSchema(autoCompleteComponentKey, componentAutoCompleteSchema),
  normalizeDataSourceValue: (value) => {
    if (Array.isArray(value)) {
      return value;
    } else {
      return ['' + value];
    }
  },
};