import { GetterType, ColorTheme, SrtComponentFunctions, ComponentRenderContext, ParsedComponent, TemplateContext, ITemplateRuntime } from '../BasicTypes';

export interface ReactComponentContainerProps<TProps> {
  props: TProps;
  context: ComponentRenderContext;
  templateContext: TemplateContext;
  functions: SrtComponentFunctions;
}

//
// Schema interfaces and helpers
//

const lightTheme = 'theme-light';
const darkTheme = 'theme-dark';
export function getCurrentTheme(): ColorTheme {
  function hasSomeParentTheClass(element: HTMLElement, classname: string): boolean {
    if (element.className != null && element.className.split(' ').indexOf(classname) >= 0) return true;
    return element.parentNode != null 
      ? hasSomeParentTheClass(element.parentNode as HTMLElement, classname)
      : false;
  }

  let htmlClass = document.documentElement.className;
  if (htmlClass != null) {
    if (htmlClass === lightTheme) return 'light';
    if (htmlClass === darkTheme) return 'dark';
    if (htmlClass.split(' ').indexOf(lightTheme) >= 0) return 'light';
    if (htmlClass.split(' ').indexOf(darkTheme) >= 0) return 'dark';
  }

  let srtContainer = document.querySelector('.srt-container');
  if (srtContainer != null) {
    return hasSomeParentTheClass(srtContainer as HTMLElement, darkTheme) ? 'dark' : 'light';
  }

  let appContainer = document.getElementById('app-container');
  if (appContainer == null) {
    return 'dark';
  }

  return hasSomeParentTheClass(appContainer, darkTheme) ? 'dark' : 'light';
}

// Helper method to calculated whether we should display or not
export function shouldDisplay(display: boolean | undefined, context: ComponentRenderContext | null): boolean {
  return shouldDisplayInternal(display, context?.currentParsedComponent);
}

function shouldDisplayInternal(display: boolean | undefined, component: ParsedComponent<any> | null | undefined) {
  let hasExpression = component?.expresionByPropName.display != null;
  if (hasExpression) {
    return display === true;
  }

  return display === undefined || display;
}

export function isDisplayingComponent(componentId: string, getter: GetterType, runtime: ITemplateRuntime) {
  // we need to check the component and then walk up the component tree (max-depth 100)
  let compId: string | undefined = componentId;
  for (var i = 0; i < 100 && compId != null; ++i) {
    const runtimeInfo = runtime.getRuntimeInfoById(compId);
    const display = getter(compId, 'display', true);
    if (!shouldDisplayInternal(display, runtimeInfo?.component)) {
      return false;
    }
    
    compId = runtimeInfo?.context?.parentComponentId;
    if (compId == null) {
      // no context to allow walk, we'll have to assume it's displaying
      return true;
    }
  }

  return true;
}

export function getDefaultBgColor(theme: ColorTheme, inListContext: boolean) {
  if (theme === 'dark') {
    return !inListContext ? '#1d2b43' : '#293d5c';
  } else if (theme === 'light') {
    return !inListContext ? '#f7f9fc' : '#ebedf0';
  } else {
    return '#0000'; // transparent (works well for outputing but less so for the stored image)
  }
}