/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { asControllableElement, OptionsContentController } from './ContentController';
import { IOptionValues, ISingleOptionValue, SectraOptionHelper } from './SectraOptionBase';

export interface SectraSelectProps extends React.HTMLProps<HTMLSelectElement> {
  name: string;
  optionValues: IOptionValues;
  optionTexts?: string[];
  bsSize?: string;
  defaultOptionText?: string;
  disabledOptions?: boolean[];
  descriptions?: (string | undefined | null)[];
  onStateChange?: (x: ISingleOptionValue | null) => void;
  preventOutput?: boolean;
}

interface SectraSelectState {
  value: ISingleOptionValue | null;
}

export class SectraSelect extends React.Component<SectraSelectProps, SectraSelectState> {
  el = React.createRef<HTMLSelectElement>();

  constructor(props: any, context: any) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    let item: ISingleOptionValue = event.target.value;
    const selOption = event.target.options[event.target.selectedIndex];
    if (selOption != null) {
      let vindex = Number(selOption.dataset.vindex);
      let vitem = this.props.optionValues[vindex];
      if (vitem != null) {
        item = vitem;
      }
    }
        
    this.props.onStateChange?.(item);
    this.props.onChange?.(event);
  }

  componentDidMount() {
    if (this.el.current != null) {
      asControllableElement(this.el.current).contentController = new OptionsContentController(
        () => false,
        () => this.props.optionValues
          .map((v, i) => ({
            id: v?.toString() ?? '', 
            names: [this.props.optionTexts?.[i] ?? v?.toString() ?? ''],
          })),
        () => this.props.optionValues
          .filter(id => SectraOptionHelper.matchSingle(id, this.state.value, false))
          .map(v => v?.toString() ?? ''),
        (id: string | null | undefined, selected: boolean) => {
          if (id == null && selected !== false && this.state.value != null) {
            // we're clearing everything (unless we're trying to deselect null, which doesn't make sense)
            this.setState({ value: null });
            this.props.onStateChange?.(null);
            return;
          }

          const item = this.props.optionValues.find(v => v?.toString() === id);
          if (item != null) {
            if (selected !== false) {
              // we're selecting this item
              this.setState({ value: item });
              this.props.onStateChange?.(item);
            } else if (SectraOptionHelper.matchSingle(item, this.state.value, false)) {
              // this item is selected and we're deselecting it
              this.setState({ value: null });
              this.props.onStateChange?.(null);
            }
          }
        },
      );
    }
  }

  componentWillUnmount() {
    if (this.el.current != null) delete asControllableElement(this.el.current).contentController;
  }


  render() {
    const {
      name,
      optionValues,
      optionTexts,
      defaultOptionText,
      onChange,
      onStateChange,
      bsSize,
      preventOutput,
      descriptions,
      disabledOptions,
      ...htmlProps
    } = this.props;

    let dataFieldType = preventOutput ? null : 'selection_list';
    const className = 'form-control input-xs' + (!bsSize || bsSize == 'xl' ? '' : ' inline-input-' + bsSize);
    return (
            <select id={this.props.id} ref={this.el}  data-field-type={dataFieldType} name={name} className={className} onChange={this.handleChange} {...htmlProps}>
				{defaultOptionText ? <option hidden>{defaultOptionText}</option> : null}
                {this.props.optionValues.map((optionValue, index) => 
                    <option data-vindex={index} value={String(optionValue)} key={index} title={descriptions?.[index] ?? undefined} disabled={disabledOptions?.[index] === true}>
                        {optionTexts ? optionTexts[index] : optionValue}
                    </option>)}
            </select>
    );
  }
}