/* eslint-disable @typescript-eslint/no-redeclare */
import { requestDataProviderUpdate } from '../../Ids7Interface';
import { createTable } from '../AsciTable';
import { isSpecialVariable } from '../SrtTemplateSpec';
import { ScriptRunnerContext } from '../BasicTypes';
import { addRuntimeError, scriptHelperMethods, setScriptHelperMethods } from './ScriptRunnerHelper';

// Object declaring all the helpers
setScriptHelperMethods([
  /* Empty helpers*/
  {
    name: 'IsEmptySingle',
    description: 'Returns if argument is an empty scalar (empty array is treated as non-empty). The following scalar values are treated as empty: null, undefined, empty string as well as numbers of type NaN and Inf.',
    parameters: [
      { name: 'value', description: 'The scalar value to test' },
    ],
    method: IsEmptySingle,
  },
  {
    name: 'IsEmpty',
    description: 'Returns if argument is an empty (extends the concept of empty single (scalar) to include empty array as well as an array of all empty items. NOTE: no support for array of array).',
    parameters: [
      { name: 'value', description: 'The value to test' },
    ],
    method: IsEmpty,
  },
  {
    name:  'IsEmptyExt',
    description: 'Returns if argument is an empty or false (extends the concept of empty to include false).',
    parameters: [
      { name: 'value', description: 'The value to test' },
    ],
    method: IsEmptyExt,
  },
  {
    name:  'IsNotEmpty',
    description: 'Returns if argument is not empty (see empty for definition of empty).',
    parameters: [
      { name: 'value', description: 'The value to test' },
    ],
    method: IsNotEmpty,
  },
  {
    name:  'IsNotEmptyExt',
    description: 'Returns if argument is not empty or false (see empty for definition of empty).',
    parameters: [
      { name: 'value', description: 'The value to test' },
    ],
    method: IsNotEmptyExt,
  },
  {
    name: 'StateChangeReasonIs',
    description: 'Returns true if given component is the reason for the state change.',
    parameters: [
      { name: 'componentId', description: 'The component id of the component to check if it is the reason for the state change' },
    ],
    method: () => null,
  },

  /* Boolean logic */
  {
    name:  'Not',
    alias: ['not'],
    description: 'Returns boolean not, i.e the ! operator.',
    parameters: [
      { name: 'value', description: 'The boolean value negated' },
    ],
    method: Not,
  },
  {
    name:  'And',
    alias: ['and'],
    description: "Returns boolean and, i.e the && operator. This method takes an arbitary number of paramters. For 0 parameters it return true. NOTE: All parameters are evaluated which may trigger null ref exceptions. If that's the case consider using JavaScript && operator instead.",
    method: And,
  },
  {
    name:  'Or',
    alias: ['or'],
    description: "Returns boolean or, i.e the || operator. This method takes an arbitary number of paramters. For 0 parameters it return false. NOTE: All parameters are evaluated which may trigger null ref exceptions. If that's the case consider using JavaScript || operator instead.",
    method: Or,
  },
  {
    name:  'IsTrue',
    description: 'Test if value is true or "true"',
    parameters: [
      { name: 'value', description: 'The value to test' },
    ],
    method: IsTrue,
  },
  {
    name:  'IsFalse',
    description: 'Test if value is false or "false"',
    parameters: [
      { name: 'value', description: 'The value to test' },
    ],
    method: IsFalse,
  },

  /* Math */
  {
    name:  'Round',
    alias: ['round'],
    description: 'Returns val to the desiered number of decimals. If val is null, null is returned. If dec is missing or below 0, val be rounded to 0 decimals.',
    parameters: [
      { name: 'value', description: 'The numerical value to round' },
      { name: 'decimals', description: 'The number of decimals (default 0)' },
    ],
    method: Round,
  },
  {
    name:  'Min',
    alias: ['min'],
    description: 'Returns the minimum numerical value. This method takes an arbitary number of paramters. For 0 parameters it return null.',
    method: Min,
  },
  {
    name:  'Max',
    alias: ['max'],
    description: 'Returns the maximum numerical value. This method takes an arbitary number of paramters. For 0 parameters it return null.',
    method: Max,
  },

  /* Equality methods */
  {
    name:  'InArray',
    description: 'Returns whether a value is found in an array.',
    parameters: [
      { name: 'array', description: 'The array to search in' },
      { name: 'value', description: 'The value looked for' },
    ],
    method: InArray,
  },
  {
    name:  'ArrayEq',
    description: 'Returns whether two arrays are identical by comparing array items. Note: returns true if a == b, e.g. when both are null.',
    parameters: [
      { name: 'a', description: 'The first array to compare' },
      { name: 'b', description: 'The second array to compare' },
    ],
    method: ArrayEq,
  },
  {
    name:  'Eq',
    alias: ['eq'],
    description: 'Returns whether two values are identical (== operator). A value a is also deemd equal to b if b is an array and a is a value in the array and vice versa. Futhermore, if a and b both are arrays and an equal according to ArrayEq they are also Eq.',
    parameters: [
      { name: 'a', description: 'The first value to compare' },
      { name: 'b', description: 'The second value to compare' },
    ],
    method: Eq,
  },
  {
    name:  'EqS',
    description: 'Returns whether two values are identical (== operator).',
    parameters: [
      { name: 'a', description: 'The first value to compare' },
      { name: 'b', description: 'The second value to compare' },
    ],
    method: EqS,
  },
  {
    name:  'NotEq',
    alias: ['notEq'],
    description: 'Returns whether two values are not identical. For definition of identical see Eq.',
    parameters: [
      { name: 'a', description: 'The first value to compare' },
      { name: 'b', description: 'The second value to compare' },
    ],
    method: NotEq,
  },
    
  /* Conditional logic */
  {
    name:  'If',
    alias: ['IfThen'],
    description: 'Conditional if logic. Test first argument as a boolean argument and returns either the second or third argument.',
    parameters: [
      { name: 'condition', description: 'The switch condition, evaluated as a boolean' },
      { name: 'a', description: 'The value returned if true' },
      { name: 'b', description: "The value returned if false. Not that this can be left out. If will then return undefined which if used in an expression won't trigger a set, i.e. results in doing nothing (a so called noop)." },
    ],
    method: If,
  },
  {
    name:  'IfEmpty',
    description: 'Conditional if empty logic. Test first argument if empty and returns either the second (if empty) or third argument (if not empty)',
    parameters: [
      { name: 'a', description: 'The value to test if empty' },
      { name: 'b', description: 'The value returned when the first is empty. If not supplied the first argument will be returned instead.' },
      { name: 'c', description: "The value returned when not empty. Not that this can be left out. If will then return undefined which if used in an expression won't trigger a set, i.e. results in doing nothing (a so called noop)." },
    ],
    method: IfEmpty,
  },
  {
    name:  'IfEmptyExt',
    description: 'Conditional if empty logic using the IsEmptyExt method. Test first argument if empty and returns either the second (if empty) or third argument (if not empty)',
    parameters: [
      { name: 'a', description: 'The value to test if empty' },
      { name: 'b', description: 'The value returned when the first is empty. If not supplied the first argument will be returned instead.' },
      { name: 'c', description: "The value returned when not empty. Not that this can be left out. If will then return undefined which if used in an expression won't trigger a set, i.e. results in doing nothing (a so called noop)." },
    ],
    method: IfEmptyExt,
  },
  {
    name:  'IfNotEmpty',
    description: 'Conditional if not empty logic. Test first argument if not empty and returns either the second (if not empty) or third argument (if not empty)',
    parameters: [
      { name: 'a', description: 'The value to test if not empty' },
      { name: 'b', description: 'The value returned when the first is empty. If not supplied the first argument will be returned instead.' },
      { name: 'c', description: "The value returned when not empty. Not that this can be left out. If will then return undefined which if used in an expression won't trigger a set, i.e. results in doing nothing (a so called noop)." },
    ],
    method: IfNotEmpty,
  },
  {
    name:  'IfNotEmptyExt',
    description: 'Conditional if not empty logic using the IsNotEmptyExt method. Test first argument if not empty and returns either the second (if not empty) or third argument (if not empty)',
    parameters: [
      { name: 'a', description: 'The value to test if not empty' },
      { name: 'b', description: 'The value returned when the first is empty. If not supplied the first argument will be returned instead.' },
      { name: 'c', description: "The value returned when not empty. Not that this can be left out. If will then return undefined which if used in an expression won't trigger a set, i.e. results in doing nothing (a so called noop)." },
    ],
    method: IfNotEmptyExt,
  },

  /* Array methods */
  {
    name:  'Concat',
    alias: ['StringConcat'],
    description: 'Concatenate array of string values into a single string. This method also supports getting multiple arguments as the arguments to concat.',
    parameters: [
      { name: 'array', description: 'The array of values' },
    ],
    method: Concat,
  },
  {
    name:  'Length',
    description: 'Get array length. If not an array 0 is returned.',
    parameters: [
      { name: 'array', description: 'The array' },
    ],
    method: Length,
  },
  {
    name:  'ToArray',
    description: 'Convert scalar value to array of one item. If the scalar value is an empty scalar value, an empty array is returned. If value is already array the same array is returned.',
    parameters: [
      { name: 'value', description: 'The scalar value' },
    ],
    method: ToArray,
  },
  {
    name:  'Sum',
    description: 'Calculate the numerical sum of all the values in an array.',
    parameters: [
      { name: 'array', description: 'The array' },
      { name: 'empty', description: 'An optional value to return if the array is empty or contains all non numerical values. If not supplied 0 will be returned for an empty array' },
    ],
    method: Sum,
  },
  {
    name:  'ArrayConcat',
    description: 'Concatenates two arrays.',
    parameters: [
      { name: 'a', description: 'The first array' },
      { name: 'b', description: 'The second array' },
    ],
    method: ArrayConcat,
  },
  {
    name:  'ArrayGet',
    description: 'Nullsafe get item from array',
    parameters: [
      { name: 'array', description: 'The array' },
      { name: 'index', description: 'The array index to get' },
    ],
    method: ArrayGet,
  },
  {
    name:  'ArrayMap',
    description: "Map an array into a new array of items based on the selector. The resulting array will be a new array of values from the selector function called with each item of the input array. If no selector is supplied or the selector isn't a function a new array of the same items is returned.",
    parameters: [
      { name: 'array', description: 'The array to map' },
      { name: 'selector', description: 'The selector function which is called with each item of the array' },
    ],
    method: ArrayMap,
  },
  {
    name:  'ArrayFormat',
    description: 'Concatenate an array into a readable string while skipping null items.',
    parameters: [
      { name: 'array', description: 'The array to concatenate/format' },
      { name: 'glue', description: 'The glue used to concatenate the array (default: ", ")' },
      { name: 'glueLast', description: 'An optional other glue used to concatenate the last item' },
    ],
    method: ArrayFormat,
  },
  {
    name:  'ArrayFormat1',
    description: 'Concatenate an array into a readable string while skipping null items. Items are presented as "[first] ([second] [glue] [third] [glue] ...)".',
    parameters: [
      { name: 'array', description: 'The array to concatenate/format' },
      { name: 'glue', description: 'The glue used to concatenate the array (default: ", ")' },
    ],
    method: ArrayFormat1,
  },
  {
    name:  'ArrayLangFormat',
    description: 'Concatenate an array into a readable string while skipping null items. Items are concatenated using ", " as a separator for all but the last item which is concatenated with an "and" word, i.e. resulting in "[first], [second], and [third]".',
    parameters: [
      { name: 'array', description: 'The array to concatenate/format' },
      { name: 'andWord', description: 'The and word to use (default: ", and ")' },
    ],
    method: ArrayLangFormat,
  },
  {
    name:  'ArrayFilter',
    description: 'Filter the array using a filter method or regular expression. I.e possible usage is ArrayFilter(a, /test/i) or ArrayFilter(a, function(x){return x!=null && String(x).toLower().indexOf("test")>=0;}). ',
    parameters: [
      { name: 'array', description: 'The array to filter' },
      { name: 'expOrFilter', description: 'The expresion or filter method' },
      { name: 'retValues', description: 'Optional array to retrieve values from, if ommitted the array is used (first parameter)' },
    ],
    method: ArrayFilter,
  },
  {
    name:  'ArrayFilterNull',
    description: 'Remove null values from the array. ',
    parameters: [
      { name: 'array', description: 'The array to filter' },
    ],
    method: ArrayFilterNull,
  },
  {
    name:  'ArraySkip',
    description: "Remove a number of items from the beginning of the array. If skip isn't supplied or is an invalid value 1 item will be skipped.",
    parameters: [
      { name: 'array', description: 'The array' },
      { name: 'skipCount', description: 'The number of items to skip' },
    ],
    method: ArraySkip,
  },
  {
    name:  'ArrayFlatten',
    description: 'Flatten an array, i.e. convert an array of array of items into an array of these items.',
    parameters: [
      { name: 'array', description: 'The array to flatten' },
    ],
    method: ArrayFlatten,
  },

  /* String helpers */
  {
    name:  'ToLower',
    description: 'Make string lower case (localized). Can be applied to an array of strings as well.',
    parameters: [
      { name: 'str', description: 'The string' },
    ],
    method: ToLower,
  },
  {
    name:  'ToUpper',
    description: 'Make string upper case (localized). Can be applied to an array of strings as well.',
    parameters: [
      { name: 'str', description: 'The string' },
    ],
    method: ToUpper,
  },
  {
    name:  'Capitalize',
    description: 'Capitalize string (localized), i.e. make first character upper case. Can be applied to an array of strings as well.',
    parameters: [
      { name: 'str', description: 'The string' },
    ],
    method: Capitalize,
  },
  {
    name:  'Decapitalize',
    description: 'Decapitalize string (localized), i.e. make first character lower case. Can be applied to an array of strings as well.',
    parameters: [
      { name: 'str', description: 'The string' },
    ],
    method: Decapitalize,
  },
  {
    name:  'IndentText',
    description: 'Indent (i.e. add leading spaces) each line in a string (potentially having multiple lines, split by new line characters ("\\n"). ',
    parameters: [
      { name: 'text', description: 'The text (string) to process' },
      { name: 'indentSize', description: 'The number of spaces to add (default is 0, ie. doing nothing)' },
      { name: 'indentFirstLine', description: 'Boolean indicating whether the first line should be indented or not (default is false, i.e. not)' },
            
    ],
    method: IndentText,
  },
  {
    name:  'IndentLine',
    description: 'Indent (i.e. add leading spaces) all lines in a string (equal to IndentText(text, indentSize, true)).',
    parameters: [
      { name: 'text', description: 'The text (string) to process' },
      { name: 'indentSize', description: 'The number of spaces to add (default is 0, ie. doing nothing)' },
            
    ],
    method: IndentLine,
  },
  { 
    name: 'ToASCITable', 
    description: 'Format matrix (array of array) as table string. Should be wrapped in <pre>-tags to align correctly.',
    parameters: [
      { name: 'matrix', description: 'The table of data that should be formatted as text. This should be passed as a normal javascript array (rows) of arrays (columns)' },
      { name: 'styleOrOption', description: 'This object can either be a string representing the output style (default: "border") or an options object, output style can be "none", "simple", "border", "border2", "simpleb", "simplemb", "dashed" or "rounded". Options object props are: firstRowHeader: boolean - wether first row is the header or not, autoFormat: boolean - format columns based on data-type, decimalPlaces: number - number of fixed decimals to use in numerical table output (default: undefined - variable output), style: the output style)' },
      { name: 'firstRowHeader', description: 'Whether to treat first row as header or not (default: true)' },
    ],
    method: ToASCITable, 
  },
  {
    name: 'ToTable',
    description: 'Format matrix (array of array) as a table. NOTE: in order to use tables, the following Metadata property needs to be set: reportVersion: v2',
    parameters: [
      { name: 'matrix', description: 'The table of data that should be formatted as text. This should be passed as a normal javascript array (rows) of arrays (columns)' },
      { name: 'size', description: "The size of the table, one of 'xs', 'sm', 'md', 'lg' (Default: md)" },
      { name: 'minColumnWidth', description: 'Minimum width of a column in pixels (Default: 20)' },
      { name: 'columnWidths', description: 'Array of column sizes in pixels. Null will autosize the table and null values in the array will autosize respective column. (Default: null)' },
    ],
    method: ToTable,
  },
  {
    name: "GetOEhrOutput",
    description: "Output on openehr flat json format",
    method: GetOEhrOutput
  },
  {
    name: "GetOEhrDefaultOutput",
    description: "Get name: value of all fields",
    method: GetOEhrDefaultOutput
  },

  /* Date helpers */
  { 
    name: 'DateAsDateString', 
    description: 'Convert date to formatted date (no time part) string (localized toString)',
    parameters: [
      { name: 'date', description: 'The date' },
    ],
    method: DateAsDateString, 
  },
  { 
    name: 'DateAsDateTimeString', 
    description: 'Convert date to formatted date and time string (localized toString)',
    parameters: [
      { name: 'date', description: 'The date' },
    ],
    method: DateAsDateTimeString, 
  },


  /* Framework helpers */
  { 
    name: 'LogAndRet', 
    description: 'Log the supplied value to the console before returning. This is a useful debug method for expression where you while developing might want to log the value as the expression is resolved.',
    parameters: [
      { name: 'logValue', description: 'The value to log and return (unless a second return parameter is supplied)' },
      { name: 'retValue', description: 'The value to return (if not supplied the first parameter is return instead)' },
    ],
    method: LogAndRet, 
  },
  { 
    name: 'MirrorComponentValues', 
    description: 'Setup value mirroring between the supplied set of component ids, either as multiple parameter strings or as a single array of strings parameter.',
    method: MirrorComponentValues,
  },
  {
    name: 'RequestDataProviderUpdate',
    description: 'Request a data provider update from Sectra PACS',
    parameters: [
      { name: 'providerIds', description: 'A list of the providers to update (if empty, all providers will update)' },
    ],
    method: requestDataProviderUpdate,
  },

  /* Undocumented methods (listed here to be bound for script access) */
  {
    name: 'GetDynamicAll',
    method: GetDynamicAll,
  },
  {
    name: 'IsEvent',
    method: IsEvent,
  },
]);

export const methodsDict: { [name: string]: any } = {};
scriptHelperMethods.forEach(m => {
  methodsDict[m.name] = m.method; 
  if (m.alias != null) m.alias.forEach(a => methodsDict[a] = m.method);
}); 


/*
 Helpers that should be declared in the methods above
*/

export function IsEmptySingle(x: any) {
  return x == null || x === '' || (typeof x === 'number' && (isNaN(x) || !isFinite(x)));
}

export function IsEmpty(x: any) {
  return IsEmptySingle(x) || (Array.isArray(x) && (x.length === 0 || x.every(IsEmptySingle)));
}

export function IsEmptyExt(x: any) {
  return IsEmptySingle(x) || x === false || (Array.isArray(x) && (x.length === 0 || x.every(i => IsEmptySingle(i) || i === false)));
}

function IsNotEmpty(x: any) {
  return !IsEmpty(x);
}

function IsNotEmptyExt(x: any) {
  return !IsEmptyExt(x);
}

function Not(b: any) {
  return !b;
}

function And() {
  for (let i = 0; i < arguments.length; ++i) {
    if (!arguments[i]) {
      return false;
    }
  }
  return true;
}

function Or() {
  for (let i = 0; i < arguments.length; ++i) {
    if (arguments[i]) {
      return true;
    }
  }
  return false;
}

function IsTrue(a: any) {
  return a === true || a === 'true';
}

function IsFalse(a: any) {
  return a === false || a === 'false';
}

function Round(val: any, dec: any) {
  if (arguments.length === 0 || val == null) {
    return null;
  }
  if (arguments.length === 1 || isNaN(+dec) || !isFinite(+dec) || !(+dec > 0)) {
    return Math.round(+val);
  }

  let scale = Math.pow(10, Math.min(+dec, 15));

  return Math.round(val * scale) / scale;
}

function Max() {
  const a = arguments.length === 1 && Array.isArray(arguments[0])
    ? arguments[0]
    : [...arguments];
  const b = a.filter(x => x != null && !isNaN(+x) && isFinite(+x));
  return b.length > 0 ? Math.max(...b) : null;
}
function Min() {
  const a = arguments.length === 1 && Array.isArray(arguments[0])
    ? arguments[0]
    : [...arguments];
  const b = a.filter(x => x != null && !isNaN(+x) && isFinite(+x));
  return b.length > 0 ? Math.min(...b) : null;
}

function InArray(arr: any, val: any) {
  return arr != null && Array.isArray(arr) && arr.indexOf(val) >= 0;
}

function ArrayEq(a: any, b: any) {
  if (a === b) return true;
  if (a == null || b == null || !Array.isArray(a) || !Array.isArray(b) || a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function Eq(a: any, b: any) {
  return a == b || InArray(a, b) || InArray(b, a) || ArrayEq(a, b);
}

function EqS(a: any, b: any) {
  return a == b;
}

function NotEq(a: any, b: any) {
  return !Eq(a, b);
}


function If(exp: any, a: any, b: any){
  return exp ? a : b;
}

function IfEmpty(a: any, b: any, c: any) {
  if (arguments.length === 0) return undefined;
  if (arguments.length === 1) return IsEmpty(a) ? a : undefined;
  return IsEmpty(a) ? b : c;
}

function IfEmptyExt(a: any, b: any, c: any) {
  if (arguments.length === 0) return undefined;
  if (arguments.length === 1) return IsEmptyExt(a) ? a : undefined;
  return IsEmptyExt(a) ? b : c;
}

function IfNotEmpty(a: any, b: any, c: any) {
  if (arguments.length === 0) return undefined;
  if (arguments.length === 1) return IsNotEmpty(a) ? a : undefined;
  return IsNotEmpty(a) ? b : c;
}

function IfNotEmptyExt(a: any, b: any, c: any) {
  if (arguments.length === 0) return undefined;
  if (arguments.length === 1) return IsNotEmptyExt(a) ? a : undefined;
  return IsNotEmptyExt(a) ? b : c;
}

function Concat(a: any) {
  const arr = arguments.length === 1
    ? ToArray(a)
    : [...arguments];

  let ret = '';
  for (let i = 0; i < arr.length; ++i) {
    if (arr[i] != null) ret += String(arr[i]);
  }
  return ret;
}

function Length(x: any) {
  return x != null && x.length ? Math.max(x.length, 0) : 0;
}

function ToArray(x: any): any[] {
  return IsEmptySingle(x) ? [] : (!Array.isArray(x) ? [x] : x);
}

function Sum(a: any, empty: any) {
  const arr = ToArray(a);
  let ret = 0, c = 0;
  for (let i = 0; i < arr.length; ++i) {
    var n = Number(arr[i]);
    if (arr[i] != null && !isNaN(n) && isFinite(n)) {
      ret += n;
      ++c;
    }
  }

  return arguments.length > 1 && c == 0 ? empty : ret;
}

function ArrayConcat(a: any, b: any) {
  return ToArray(a).concat(ToArray(b));
}

function ArrayGet(a: any, index: any) {
  const idx = index != null && !isNaN(+index) && isFinite(+index) ? +index : 0;
  return a != null ? ToArray(a)[idx] : null;
}

function ArrayMap(a: any, selector: any) {
  a = ToArray(a); 
  if (!selector || !isFunction(selector)) {
    return [...a];
  }

  var r: any[] = []; 
  for (let i = 0; i < a.length; ++i) { 
    r.push(selector(a[i])); 
  }

  return r;
}

function ArrayFormat(a: any, glue: any, glast: any) {
  a = ToArray(a); 
  let b: any[] = []; 
  for (let i = 0; i < a.length; ++i) { 
    if (a[i] != null) {
      b.push(a[i]); 
    }
  } 
    
  let g = glue != null ? String(glue) : ', ', 
    gl = glast != null ? String(glast) : g; 
        
  return b != null && b.length 
    ? (g !== gl 
      ? (b.length > 1 
        ? (b.slice(0, -1).join(g) + gl + b[b.length - 1]) 
        : b.join(gl)) 
      : b.join(g)) 
    : '';
}

function ArrayFormat1(a: any, glue: any) {
  a = ToArray(a);
  let b: any[] = []; 
  for (let i = 0; i < a.length; ++i) { 
    if (a[i] != null) {
      b.push(a[i]); 
    }
  }

  let g = glue != null ? String(glue) : ', ';

  return b != null && b.length
    ? b[0] + (b.length > 1 ? ' (' + b.slice(1).join(g) + ')' : '')
    : '';
}

function ArrayLangFormat(a: any, andWord: any) {
  // @ts-ignore That this is hijacked here to pass the script runner context
  const runnerContext = (this as any) as ScriptRunnerContext;
  const defaultAndWord = runnerContext.templateDefaults.defaultAndWord ?? ', and ';

  return ArrayFormat(a, null, andWord != null ? andWord : defaultAndWord);
}

function ArrayFilter(a: any, b: any, aValues: any) { 
  var filter = b;
  if (b != null && b instanceof RegExp) {
    filter = (v: any) => b.test(v);
  } else if (b != null && !isFunction(b)) {
    const bStr = String(b);
    filter = (v: any) => String(v).indexOf(bStr) >= 0;
  } else if (b == null) {
    filter = () => true;
  }

  if (arguments.length < 3) {
    return ToArray(a).filter(filter);
  }

  const fa = ToArray(a), aV = ToArray(aValues);
  const ret = [];
  for (let i = 0; i < fa.length; ++i) {
    if (filter(a[i])) {
      ret.push(aV[i]);
    }
  }
  return ret;
}

function ArrayFilterNull(a: any) {
  return ToArray(a).filter(x => x != null);
}

function ArraySkip(a: any, skip: any) {
  let s = skip != null && !isNaN(+skip) && isFinite(+skip) ? +skip : 1;
  return ToArray(a).slice(s);
}

function ArrayFlatten(a: any) {
  return ToArray(a).flat();
}

function ToLower(s: any): any {
  return s != null
    ? (Array.isArray(s)
      ? s.map(as => ToLower(as))
      : String(s).toLocaleLowerCase())
    : s;
}

function ToUpper(s: any): any  {
  return s != null
    ? (Array.isArray(s)
      ? s.map(as => ToUpper(as))
      : String(s).toLocaleUpperCase())
    : s;
}


// eslint-disable-next-line no-redeclare
function Capitalize(s: any): any  {
  return s != null
    ? (Array.isArray(s)
      ? s.map(as => Capitalize(as))
      : String(s).substr(0, 1).toLocaleUpperCase() + String(s).substr(1))
    : s;
}

function Decapitalize(s: any): any  {
  return s != null
    ? (Array.isArray(s)
      ? s.map(as => Decapitalize(as))
      : String(s).substr(0, 1).toLocaleLowerCase() + String(s).substr(1))
    : s;
}

function IndentText(text: any, indentSize: any, indentFirstLine: any) {
  if (text == null) {
    return null;
  }
  var size = Number(indentSize);
  if (size <= 0 || isNaN(size) || !isFinite(size)) {
    return text;
  }

  var space = Array(size + 1).join(' ');
  var parts = String(text).split('\n');

  for (var i = 0; i < parts.length; ++i) {
    if (i === 0 && indentFirstLine !== true) {
      continue;
    }
    parts[i] = space + parts[i];
  }

  return parts.join('\n');
}

function IndentLine(line: any, indentSize:any){ 
  return IndentText(line, indentSize, true);
}

function ToASCITable(matrix: any[][], styleOrOption: any, firstRowHeader: any, pLangCode: any) {
  // @ts-ignore That this is hijacked here to pass the script runner context
  const runnerContext = (this as any) as ScriptRunnerContext;
  return createTable(matrix, styleOrOption, firstRowHeader, pLangCode ?? runnerContext.langCode);
}

let lastOehrFetch = new Date(-1);
let lastOehrResult: string = "";

function GetOEhrOutput() {
    // @ts-ignore That this is hijacked here to pass the script runner context
    const runnerContext = (this as any) as ScriptRunnerContext;
    let dateNow = new Date();
    if (dateNow.getTime() - lastOehrFetch.getTime() < 200) {
        return lastOehrResult;
    }
    const oEhrOutput: any = {};
    runnerContext.extensions.getOEhrInfos().forEach(id => {
      const value = runnerContext.get(id.id, 'value');
      if (value != null) {
        oEhrOutput[id.ehrId] = value;
      }
    });
    lastOehrFetch = dateNow;
    lastOehrResult = JSON.stringify(oEhrOutput);
    return lastOehrResult;
}

function GetOEhrDefaultOutput() {
    // @ts-ignore That this is hijacked here to pass the script runner context
    const runnerContext = (this as any) as ScriptRunnerContext;
    const retval = runnerContext.extensions.getOEhrInfos()
        .map(ids => ids.id + ": " + runnerContext.get(ids.id, 'value'))
        .join(",\n");
    return retval;
}

function ToTable(matrix: any[][], size: string | null, minColumnWidth: number | null, columnWidths: number[] | null) {
  let result = '<table';
  if (size != null) {
    result += ' width="' + size + '"';
  }
  if (minColumnWidth != null) {
    result += ' minColumnWidth="' + minColumnWidth + '"';
  }
  if (columnWidths != null && columnWidths.length > 0) {
    result += ' columnWidths="';
    result += columnWidths.map(x => '' + x).reduce((x, y) => x + ', ' + y);
    result += '"';
  }
  result += '>';
  for (let y = 0; y < matrix.length; y++) {
    result += `<tr key="row${y}">`;
    for (let x = 0; x < matrix[y].length; x++) {
      if (matrix[y][x] == null) {
        result += `<td key="col${x}"></td>`;
      } else {
        result += `<td key="col${x}">${matrix[y][x]}</td>`;
      }
    }
    result += '</tr>';
  }
  result += '</table>';
  return result;
}

function DateAsDateString(d: any) {
  if (d == null) {
    return null;
  }
  d = new Date(d);

  return isNaN(+d) ? null : d.toLocaleDateString();
}

function DateAsDateTimeString(d: any) {
  if (d == null) {
    return null;
  }
  d = new Date(d);

  return isNaN(+d) ? null : d.toLocaleString();
}

function LogAndRet(l: any, r: any) {
  console.log(l);
  return arguments.length > 1 ? r : l;
}

function MirrorComponentValues() {
  // @ts-ignore That this is hijacked here to pass the script runner context
  const runnerContext = (this as any) as ScriptRunnerContext;

  const ids = arguments.length === 1 && Array.isArray(arguments[0])
    ? arguments[0]
    : [...arguments];

  if (ids == null || ids.length === 0) return;

  // some id validation
  ids.filter(id => isSpecialVariable(String(id))).forEach(disallowedId => addRuntimeError({
    message: `Highly NOT recommended to use MirrorComponentValues for special component id: "${disallowedId}"`,
  }));
  ids.filter(id => !runnerContext.componentExists(String(id))).forEach(id => addRuntimeError({
    message: `Reference to non-existing component id in call to MirrorComponentValues: "${id}"`,
  }));


  const getter = runnerContext.get;
  const setter = runnerContext.set;
  const extensions = runnerContext.extensions;
  if (extensions == null || getter == null || setter == null) {
    return;
  }
   
  // check if reason is some of the ids
  const idReasons = ids.filter(id =>  extensions.isReason(String(id)));
  const srcId = idReasons.length > 0 ? String(idReasons[0]) : String(ids[0]);
  const value = getter(srcId, 'value', true);

  // now update all items
  ids.forEach(id => setter(id, 'value', value, true));
}

function IsEvent(componentId: any) {
  // @ts-ignore That this is hijacked here to pass the script runner context
  const context = (this as any) as ScriptRunnerContext;
  const extensions = context.extensions;
  if (extensions == null) {
    return false;
  }
  const reason = extensions.getReason();
  return extensions.isReason(componentId, 'event')
        && reason.iteration === 1;
}

function GetDynamicAll(scope: any, componentId: any, propName: any, getter: any) {
  // @ts-ignore That this is hijacked here to pass the script runner context
  const context = this as ScriptRunnerContext;

  getter = getter ?? context.get;
  if (scope == null || !Array.isArray(scope) || !isFunction(getter)) {
    return [];
  }

  let r: any[] = [];
  function sp(prefix: string, index: number) {
    var c = getter(prefix + scope[index], 'count', false);
    for (let i = 0; i < c; ++i) {
      let sPrefix = prefix + scope[index] + (i + 1);
      if (index >= scope.length - 1) {
        r.push(getter(sPrefix + componentId, propName != null ? propName : 'value', false));
      } else {
        // recursive walk to bottom of scope array
        sp(sPrefix, index + 1);
      }
    }
  }
  sp('', 0);

  return r;
}

function isFunction(functionToCheck: any) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}
