import { applogger } from '../../applogger';

// schema interfaces
export interface SchemaProperties {
  [key: string]: ComplexSchemaProperty;
}

export type ComplexSchemaProperty = SchemaProperty | { anyOf: SchemaProperty[], description: string } | null;

export interface SchemaProperty {
  type: string[] | string;
  description: string;
  enum?: string[];
  pattern?: string;
  enumDescription?: string[];
}

export const expressionPattern = '^=|:=';

const valueIsBooleanOrExpression = "Value: true/false or expression calculating display (JavaScript one liner i.e. Eq(ComponentID, 'val')";

const idProp: SchemaProperty = {
  'type': 'string',
  'description': 'The id of the element',
};
const hiddenProp: SchemaProperty = {
  'type': ['string', 'boolean'],
  'enumDescription': ['Component is hidden (structured information will still be present)', 'Component is visable (default)'],
  'description': 'Whether this component is hidden in the form or not (potential input data _will_ still be part of form data). ' + valueIsBooleanOrExpression,
};
const displayProp: SchemaProperty = {
  'type': ['string', 'boolean'],
  'enumDescription': [
    'Component is displayed as normal (default)',
    'Component is not part of the form, resulting form data is neither part of the structured information generated',
  ],
  'description': 'Whether to display this component or not (when not displayed, any potential input data will _not_ be part of the form data, i.e. totally omitted, not just hidden). ' + valueIsBooleanOrExpression,
};
const disabledProp: SchemaProperty = {
  'type': ['string', 'boolean'],
  'enumDescription': ['Component is disabled',  'Not disabled as normal (default)'],
  'description': 'Whether the component is disabled (not editable) or not. ' + valueIsBooleanOrExpression,
};

const mandatoryProp: ComplexSchemaProperty = {
  'anyOf': [
    {
      'type': 'string',
      'enum': ['alert', 'prohibit', 'none'],
      'enumDescription': ['A warning is given if the field is empty.', 'Not possible to proceed unless a value is entered', 'Not mandatory'],
      'description': "The field's mandatory level (default: none).",
    },
    { type: 'string', 'description': 'Mandatory as an expression', pattern: expressionPattern },
  ],
  'description': "The field's mandatory level as either none, alert or prohibit or as an expression",
};

const prefixProp: SchemaProperty = {
  'type': 'string',
  'description': 'Text before the field.',
};

const prefixStyleProp: SchemaProperty = {
  'type': 'string',
  'enum': ['normal', 'label'],
  'enumDescription': ['Normal styling', 'Styled as a label'],
  'description': 'Style of the prefix (default: normal).',
};

const suffixProp: SchemaProperty = {
  'type': 'string',
  'description': 'Text after the field.',
};

const suffixStyleProp: SchemaProperty = {
  'type': 'string',
  'enum': ['normal', 'label'],
  'enumDescription': ['Normal styling', 'Styled as a label'],
  'description': 'Style of the suffix (default: normal).',
};

const valueDescription =  "Preset value for the component or a calculated value (start with '=', JavaScript one liner) i.e. floor(ComponentID + 3)";



const sizes = [
  { id: 'xxs', 'value': 'Very small (2-4 letters)' },
  { id: 'xs', 'value': 'Smaller (4-5 letters)' },
  { id: 's', 'value': 'Small (9-12 letters)' },
  { id: 'm', 'value': 'Medium (18-22 letters)' },
  { id: 'l', 'value': 'Large' },
  { id: 'xl', 'value': 'Larger' },
  { id: 'xxl', 'value': 'Very large (almost full page width)' },
  { id: 'fill', 'value': 'Fill (filling page width)' },
];

const schemaSizeProp: SchemaProperties = {
  'size': {
    'anyOf': [
      { type: 'string', 'enum': sizes.map(s => s.id), 'enumDescription': sizes.map(s => s.value), 'description': '' },
      { type: 'number', 'description': 'Exact size' },
      { type: 'string', 'description': 'Size as an expression', pattern: expressionPattern },
    ],
    'description': 'Component size (width), default is fill. Can be predefined value (e.g. s, m or l) or a number for exact width.',
  },
};

const defaultChildComponentSchemaProp = {
  'components': {
    'type': 'array',
    'items': {
      '$ref': 'srt.builder.component.json#/definitions/component',
    },
    'minItems': 0,
    'uniqueItems': false,
    'description': 'A list of components for this child section.',
  },
};

// default schema props, should be reflected in above props base (SrComponentPropsBase)
const defaultSchemaProps: SchemaProperties = {
  'id': idProp,
  'display': displayProp,
};

function peekNextIdNum() {
  if (!window._nextIdIntForComponent_){
    return 0;
  }
  return window._nextIdIntForComponent_;
}

const defaultInputSchemaProps: SchemaProperties = mergeSchemaProps(defaultSchemaProps, {
  'name': { 'type': 'string', 'description': 'Name of the field (defaults to label or inherited label, for instance of a parent row, or component id)' },
  'dataarg': { 'type': 'string', 'description': 'The data provider merge identifier, e.g. SectraIds7.PatientAge' },
  'worklistAttribute': { 'type': 'string', 'description': 'Creates a Sectra worklist attribute with the given name' },
  'oEhrId': { 'type': 'string', 'description': 'Used to build openehr compositions.'},
  'hidden': hiddenProp,
  'disabled': disabledProp,
  'mandatory': mandatoryProp,
  'freeField': { 'type': 'object', 'description': 'Export the value of the input to a Free field', 'required': ['id', 'whenEmpty'], 'properties': {
    'id': { 'type': 'string', 'description': 'Free field id to export value to' },
    'whenEmpty': {
      'type': 'string',
      'enum': ['clear', 'noUpdate'],
      'enumDescription': ['Free field will be cleared.', 'Free field will be left without any update'],
      'description': 'How free fields should be handled when the output of the form field is empty',
    },
  } },
});


function mergeSchemaProps(baseProps: SchemaProperties, componentProps?: any) {
  let schemaProps = componentProps != null
    ? Object.assign({ ...baseProps }, componentProps)
    : defaultInputSchemaProps;

  // clear out any actively prohibited default props (communicated as declared null props in componentProps)
  for (var propName in schemaProps) {
    if (schemaProps[propName] === null || schemaProps[propName] === undefined) {
      delete schemaProps[propName];
    }
  }
  return schemaProps;
}

function schemaDropProps(props: SchemaProperties, keys: string[] | string) {
  if (keys == null || keys === '') {
    return props;
  }
  if (typeof keys === 'string') {
    delete props[keys];
  } else if (Array.isArray(keys)) {
    keys.forEach(k => { delete props[k]; });
  }

  return props;
}

// The schema defines what you can enter in the yaml. This method injects the common properties between all components
function getSchema(key: string, componentSchemaProps?: SchemaProperties, required?: string[], deriveDefaultInputSchema?: boolean){
  if (required == null) {
    required = deriveDefaultInputSchema !== false ? ['id'] : [];
  } else if (deriveDefaultInputSchema !== false && required.indexOf('id') < 0) {
    required.unshift('id');
  }

  let schemaProps = deriveDefaultInputSchema !== false
    ? mergeSchemaProps(defaultInputSchemaProps, componentSchemaProps)
    : componentSchemaProps;

  var schema = {
    'type': 'object',
    'required': [key],
    'properties': {
      [key]: {
        'type': 'object',
        'properties': schemaProps,
        'required': required,
        'additionalProperties': false,
      },
    },
  };

  return schema;
}

function getNextIdNum(){
  if (!window._nextIdIntForComponent_){
    window._nextIdIntForComponent_ = 0;
  }
  applogger.debug(window._nextIdIntForComponent_);
  return window._nextIdIntForComponent_++;
}

export const schema = {
  DefaultSchemaProps: defaultSchemaProps,
  DefaultInputComponentSchema: defaultInputSchemaProps,
  DefaultSizeSchemaPart: schemaSizeProp,
  DefaultComponentSchemaPart: defaultChildComponentSchemaProp,
  ValueDescription: valueDescription,
  PropDefinition: {
    hidden: hiddenProp,
    display: displayProp,
    disabled: disabledProp,
    mandatory: mandatoryProp,
    prefix: prefixProp,
    prefixStyle: prefixStyleProp,
    suffix: suffixProp,
    suffixStyle: suffixStyleProp,
  },
  getSchema,
  mergeSchemaProps,
  schemaDropProps,
  getNextIdNum,
  peekNextIdNum,
};

