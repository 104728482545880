/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SvgRef } from '../Components/Generic/SvgRef';
import { routeToPath } from '../Helpers/NavigationHelper';
import { CommunicationContext, CommunicationContextType } from './CommunicationContext';


interface TemplateSharerProps {
  templateId: string;
  templateVersion?: number;
}

export const TemplateSharer: React.FC<TemplateSharerProps> = (props) => {
  const [publicTemplate, setPublicTemplate] = useState<boolean>(false);
  const [isPreviewEnabled, setIsPreviewEnabled] = useState<boolean>(false);
  const communicationContext = useContext(CommunicationContext) as CommunicationContextType;

  function isSavedTemplate(templateId: string) {
    return templateId !== 'new' && templateId !== 'demo' && templateId !== 'fromFile';
  }

  useEffect(() => {
    async function getTemplateSharedWith() {
      const templateSharedWith = await communicationContext.getTemplateShare(props.templateId);
      setPublicTemplate(templateSharedWith === null);
    }

    async function getTemplatePreviewEnabled() {
      const previewEnabled = await communicationContext.getPreviewEnabled(props.templateId);
      setIsPreviewEnabled(previewEnabled);
    }

    if (isSavedTemplate(props.templateId)) {
      getTemplateSharedWith();
      getTemplatePreviewEnabled();
    }
  }, [props.templateId]);

  function togglePublicTemplate() {
    if (isSavedTemplate(props.templateId)) {
      const newPublicTemplate = !publicTemplate;
      communicationContext.setTemplateShare(props.templateId, newPublicTemplate ? null : []);
      setPublicTemplate(newPublicTemplate);
    }
  }

  function togglePreview() {
    if (isSavedTemplate(props.templateId)) {
      const newIsPreviewEnabled = !isPreviewEnabled;
      communicationContext.setPreviewEnabled(props.templateId, newIsPreviewEnabled);
      setIsPreviewEnabled(newIsPreviewEnabled);
    }
  }

  return (
      <>
        <h5>Preview built form</h5>
        <p>Previews can be viewed by anyone with the link and are therefore a great option for early user testing. The latest version link will always load the latest form version.</p>
        <span title={!isSavedTemplate(props.templateId) ? 'Form must be saved before toggling preview.' : 'Toggle preview.'} className={'svgcheckbox-button checkbox-no-left-margin btn' + (!isSavedTemplate(props.templateId) ? ' disabled' : '')} onClick={togglePreview}>
          <span style={{ marginRight: '1rem' }}>Preview enabled</span>
          <SvgRef id={isPreviewEnabled ? 'icon-CheckboxChecked' : 'icon-CheckboxUnchecked'} className="svg-icon svgcheckbox" />
        </span>
        {isPreviewEnabled ? 
          <>
            <p>Right click appropriate link and select either copy or open in new tab.</p>
            <Link to={routeToPath('/preview/' + props.templateId + '/' + props.templateVersion)} target="_blank" rel="noopener noreferrer">Version specific preview</Link>
            <Link to={routeToPath('/preview/' + props.templateId)} style={{ marginLeft: '2rem' }} target="_blank" rel="noopener noreferrer">Latest version preview</Link>
          </>
          : null
        }
        <hr />

        <h5>Share form</h5>
        <p>If you check the public form checkbox, this form can be copied by anyone belonging to any site.</p>
        <span title={!isSavedTemplate(props.templateId) ? 'Form must be saved before sharing it.' : 'Share form with all other sites.'} className={'svgcheckbox-button checkbox-no-left-margin btn' + (!isSavedTemplate(props.templateId) ? ' disabled' : '')} onClick={togglePublicTemplate}>
            <span style={{ marginRight: '1rem' }}>Public form</span>
            <SvgRef id={publicTemplate ? 'icon-CheckboxChecked' : 'icon-CheckboxUnchecked'} className="svg-icon svgcheckbox" />
        </span>
      </>
  );
};