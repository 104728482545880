import { ComponentRenderContext, CompositeActionItem, GetterType, Ids7ProviderData, Ids7ProviderDataResult, ITemplateRuntime, ParsedComponent, ScriptRunnerGetter, SetterType, StateChangeRunReason, TemplateContext, TemplateRuntimeInfo } from './BasicTypes';
import { DataSourceHelper } from './DataSourceHelper';
import { IScriptExecutor, ScriptExecutor } from './ScriptExecutor';

export class TemplateRuntime implements ITemplateRuntime {
  private templateContext: TemplateContext | null;

  private runtimeComponentsLookup: { [compId: string]: TemplateRuntimeInfo };

  private scriptExector: IScriptExecutor;

  private latestProviderData: Ids7ProviderDataResult[];

  constructor(scriptRunnerGetter: ScriptRunnerGetter | null) {
    this.templateContext = null;
    this.runtimeComponentsLookup = {};
    this.scriptExector = new ScriptExecutor(scriptRunnerGetter);
    this.latestProviderData = [];
  }

  setNewScriptRunnerGetter(scriptRunnerGetter: ScriptRunnerGetter | null): void {
    this.scriptExector.setNewScriptRunnerGetter(scriptRunnerGetter);
  }

  setParentContext(templateContext: TemplateContext) {
    this.templateContext = templateContext;
  }

  executeOnStateChangeCode(getter: GetterType, setter: SetterType, reason: StateChangeRunReason) {
    if (this.templateContext == null) {
      return;
    }

    this.scriptExector.executeOnStateChangeCode(this.templateContext, getter, setter, reason, this.latestProviderData);
  }

  executeOnBeforeGetState() {
    if (this.templateContext == null) {
      return;
    }

    this.scriptExector.executeOnBeforeGetState(this.templateContext, this.latestProviderData);
  }
    
  processProviderData(data: Ids7ProviderData) {
    if (this.templateContext == null) {
      return;
    }

    if (DataSourceHelper.processProviderData(this.templateContext, data)) {
      this.latestProviderData = data.results;
      this.scriptExector.executeOnDataRetrieve(this.templateContext, data.results);
            
    }
  }

  getCurrentProviderData() {
    return this.latestProviderData;
  }

  registerRuntimeComponent(compId: string, context: ComponentRenderContext): boolean {
    this.scriptExector.registerComponent(compId, context.currentParsedComponent);

    const currentLookup = this.runtimeComponentsLookup[compId];
    if (currentLookup == null) {
      this.runtimeComponentsLookup[compId] = { 
        componentSourceId: context.currentParsedComponent.id,
        componentParentId: context.parentComponentId,
        component: context.currentParsedComponent, 
        context,
      };
      return true;
    }

    // Handle component updates, only happens in template edit mode
    const oldComponent = currentLookup.component;
    const newComponent = context.currentParsedComponent;
    if (oldComponent != null && oldComponent !== newComponent) {
      // Drop user input when spec has been changed for these properties
      const updates: CompositeActionItem[] = [ 'value' ] // list of properties to monitor
        .filter(propName => newComponent.args[propName] !== oldComponent.args[propName])
        .map(propName => ({ 'componentId': oldComponent.id, propName, value: undefined, runScripts: false }));

      if (updates.length > 0) {
        // Update the store
        //store.compositeUpdate(templateContext, updates);
      }
    }

    // update current lookup value with new value
    currentLookup.component = context.currentParsedComponent;
    currentLookup.context = context;

    return oldComponent !== newComponent;
  }

  registerRuntimeComponentInfo(compId: string, sourceId: string, parentId: string) {
    const currentLookup = this.runtimeComponentsLookup[compId];
    if (currentLookup == null) {
      this.runtimeComponentsLookup[compId] = { 
        componentSourceId: sourceId,
        componentParentId: parentId,
      };
    } else {
      currentLookup.componentSourceId = sourceId;
      currentLookup.componentParentId = parentId;
    }
  }

  unregisterRuntimeComponent(compId: string): boolean {
    const exists = compId in this.runtimeComponentsLookup;
    if (exists) {
      delete this.runtimeComponentsLookup[compId];    
    }

    this.scriptExector.unregisterComponent(compId);

    return exists;
  }

  clearRuntimeComponents() {
    this.runtimeComponentsLookup = {};
    this.latestProviderData = [];
    this.scriptExector.clear();
  }
    
  getAllRuntimeIds(): string[] {
    return Object.keys(this.runtimeComponentsLookup);
  }

  getOEhrInfos(): {id: string, ehrId: string}[] {
    const retval: {id: string, ehrId: string}[] = [];       
    for (const componentId of Object.keys(this.runtimeComponentsLookup)) {
      const runtimeInfo = this.runtimeComponentsLookup[componentId];
      if (runtimeInfo.component?.oEhrId) {
        let ehrId = runtimeInfo.component.oEhrId;
        if (runtimeInfo.componentParentId && runtimeInfo.componentSourceId != componentId) {
          let parentId: string|undefined = runtimeInfo.componentParentId;
          let parentInfo: TemplateRuntimeInfo | null = this.runtimeComponentsLookup[parentId];
          while (parentInfo != null && !parentInfo?.component?.component.dynamicChildScope) {
            parentId = parentInfo.componentParentId;
            parentInfo = parentId != null ? this.runtimeComponentsLookup[parentId] : null;
          }

          if (parentId != null) {
            let indexStr = componentId.substring(parentId.length);
            indexStr = indexStr.substring(0, indexStr.length - runtimeInfo.componentSourceId.length);
            let index = (Number(indexStr) - 1) /* naming convesion uses 1-based index, OpenEHR is zero-based */
            if (ehrId.indexOf("|") !== -1) {
              ehrId = ehrId.slice(0, ehrId.indexOf("|")) + ":" + index + ehrId.slice(ehrId.indexOf("|"));
            } else {
              ehrId = ehrId += ":" + index;
            }
            retval.push({id: componentId, ehrId});
          }
        }
        else {
            retval.push({id: componentId, ehrId});
        }
      }
    }
    return retval;
  }

  getRuntimeInfoById(compId: string): TemplateRuntimeInfo | null {
    const item = this.runtimeComponentsLookup[compId];
    return item != null ? { ...item } : null;
  }

  getRenderContextByComponentId(compId: string): ComponentRenderContext | null {
    return this.runtimeComponentsLookup[compId]?.context ?? null;
  }

  getComponentById(compId: string): ParsedComponent<any> | null {
    return this.runtimeComponentsLookup[compId]?.component ?? null;
  }

  getComponentBySourceId(compSrcId: string): ParsedComponent<any> | null {
    return this.templateContext?.templateSpec.ComponentLookup[compSrcId]?.[0] ?? null;
  }
}