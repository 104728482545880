import React from 'react';
import { SrComponent, SrComponentPropsBase } from '../BasicTypes';
import { ReactComponentContainerProps } from './SrtComponent';
import { schema } from './Schema';
import { Col, Grid, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { IsInDirectRowContext } from './SectraRow';
import { SvgRef } from '../Generic/SvgRef';

interface HelpProps extends SrComponentPropsBase, BasicHelpProps {
}

interface BasicHelpProps {
  id: string;
  trigger?: 'click' | 'hover';
  contentHtml?: string;
  content?: string;
  posAlignment?: string;
  isLabelHelp?: boolean;
  maxWidth?: number;
}

const helpSchema = schema.mergeSchemaProps(schema.DefaultSchemaProps, {
  'content': { 'type': 'string', 'description': 'Help content' },
  'contentHtml': { 'type': 'string', 'description': 'Help content as HTML' },
  'posAlignment': { 'type': 'string', 'enum': ['left', 'top', 'bottom', 'right'], 'description': 'Placement of popup' },
  'maxWidth': { 'type': 'number', 'description': 'max width (in pixels) of the help content. (Default is total width of form)' },
  'trigger': { 'type': 'string', 'enum': ['click', 'hover'], 'description': 'Trigger Help popover on click or hover' },
});

export const BasicHelpReactComponent: React.FC<BasicHelpProps> = (props) => {
  const popOverContent = props.contentHtml
    ? <div className="popoverContent" dangerouslySetInnerHTML={{ __html: props.contentHtml }} />
    : <div className="popoverContent">{props.content}</div>;
  const maxWidth = props.maxWidth ? props.maxWidth + 'px' : '100%';
  return (
    <OverlayTrigger placement={props.posAlignment || 'left'} trigger={props.trigger === 'hover' ? ['hover', 'focus'] : 'click'} rootClose
      overlay={<Popover id={`${props.id}-popover`} style={{ maxWidth: maxWidth }}>{popOverContent}</Popover>}>
      <span style={{ marginLeft: props.isLabelHelp ? '.3rem' : '0', cursor: 'pointer' }}><SvgRef className="svg-icon question" id="icon-Question"></SvgRef></span>
    </OverlayTrigger>
  );
};

export const HelpReactComponent: React.FC<ReactComponentContainerProps<HelpProps>> = (container) => {
  const props = container.props;
  const isRowContext = IsInDirectRowContext(container.context, container.templateContext);

  return (
    <>
        {props.display !== false ?
        <>
            {isRowContext ? <BasicHelpReactComponent {...props} /> : 
                <Grid><Row className={'show-grid fullwidth'}><Col xs={12} style={{ textAlign: 'right', marginBottom: '.4rem' }}><BasicHelpReactComponent {...props} /></Col></Row></Grid> }
        </>
          : null}
    </>
  );
};

const helpComponentKey = 'Help';
export const Help : SrComponent<HelpProps> = {
  key: helpComponentKey,
  render: (props, context, templateContext, functions) => <HelpReactComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: () => Promise.resolve(`- ${helpComponentKey}:
    id: help${schema.getNextIdNum()}
    content: Use the help component to further explain a clinical concept or how your input logic works. Click the question-mark to display the help text.
    maxWidth: 400
    trigger: click`),
  toolboxName: helpComponentKey,
  schema: schema.getSchema(helpComponentKey, helpSchema, undefined, false),
};
