/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { SrtGenTemplate, SrtGenTemplateInfo } from '../ServerCommunication';
import { GenericTable, GenericTableRowData } from './Generic/GenericTable';
import { parseCreatedDate } from '../Helpers/DateHelper';
import { routeToPath } from '../Helpers/NavigationHelper';
import { SvgRef } from './Generic/SvgRef';

export interface TemplateVersionsProps {
  currentTemplateId: string | null;
  templateVersion?: number | null;
  currentVersionHasBuild?: boolean;
  onCurrentTemplateVersionDeleted: () => void;
  getTemplateVersionsById: (templateId: string) => Promise<SrtGenTemplate[]>;
  deleteTemplateVersions: (templateId: string, deletedVersions: number[]) => Promise<void>;
  pushHistory: (url: string) => void;
  header?: JSX.Element;
}

export const TemplateVersions: React.FC<TemplateVersionsProps> = (props) => {
  const [currentTemplateVersions, setCurrentTemplateVersions] = useState<SrtGenTemplateInfo[] | null>(null);
  const [rowIndexIsSelected, setRowIndexIsSelected] = useState<boolean[]>([]);
  const [allVersionsSelected, setAllVersionsSelected] = useState<boolean>(false);

  useEffect(() => {
    if (currentTemplateVersions) {
      if (rowIndexIsSelected.every((x, i) => currentTemplateVersions[i].BuildNumber || x || i === 0)) {
        setAllVersionsSelected(true);
      } else if (allVersionsSelected) {
        setAllVersionsSelected(false);
      }
    }
  }, [rowIndexIsSelected]);

  useEffect(() => {
    async function loadCurrentTemplateVersions() {
      if (props.currentTemplateId === null) return;
      const currentBuildVersions = await props.getTemplateVersionsById(props.currentTemplateId);
      setCurrentTemplateVersions(currentBuildVersions);
    }
    if (props.templateVersion !== null) {
      loadCurrentTemplateVersions();
    }
  }, [props.getTemplateVersionsById, props.currentTemplateId, props.templateVersion, props.currentVersionHasBuild]);

  useEffect(() => {
    setRowIndexIsSelected(currentTemplateVersions?.map(x => false) ?? []);
  }, [currentTemplateVersions]);

  async function onPermDelete() {
    if (currentTemplateVersions != null && props.currentTemplateId !== null
            && confirm('Do you really want to permanently delete the selected versions?')) {
      const deletedVersions = currentTemplateVersions
        .filter((_, i) => rowIndexIsSelected[i])
        .map(x => x.Version ?? -1);

      await props.deleteTemplateVersions(props.currentTemplateId, deletedVersions);

      setCurrentTemplateVersions(currentTemplateVersions.filter((_, i) => !rowIndexIsSelected[i]));

      if (props.templateVersion && deletedVersions.some(x => x === props.templateVersion) && props.onCurrentTemplateVersionDeleted) {
        props.onCurrentTemplateVersionDeleted();
      }
    }
  }

  async function onPermDeleteTemplate() {
    if (!currentTemplateVersions || !props.currentTemplateId) {
      return;
    }
    if (confirm('Do you really want to permanently delete the WHOLE form?')) {
      await props.deleteTemplateVersions(props.currentTemplateId, currentTemplateVersions.map(x => x.Version ?? -1));
      props.onCurrentTemplateVersionDeleted();
    }
  }

  function onCheckboxClick(clickedRowIndex: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    setRowIndexIsSelected(rowIndexIsSelected.map((x, i) => i === clickedRowIndex ? !rowIndexIsSelected[i] : x));
  }

  function shouldPermDeleteBeEnabled(): boolean {
    const selected = currentTemplateVersions?.filter((_, i) => rowIndexIsSelected[i]) ?? [];
    return !!selected.length && selected.every(x => !x.BuildNumber);
  }

  function checkboxEnabled(index: number) {
    if (!currentTemplateVersions) {
      return;
    }
    if (index === 0) {
      return false;
    }
    return !currentTemplateVersions[index].BuildNumber;
  }

  function selectAllVersionsClicked() {
    const newval = !allVersionsSelected;
    setRowIndexIsSelected(rowIndexIsSelected.map((x, i) => checkboxEnabled(i) ? newval : false));
    setAllVersionsSelected(newval);
  }

  const templateData: GenericTableRowData[] | undefined = currentTemplateVersions
    ?.map((x, index) => ({
      values: [x.Version + '', x.BuildNumber ? '' + x.BuildNumber : '-', parseCreatedDate(x?.Created ?? null)],
      titles: [undefined, undefined, 'Version created by ' + x.CreatedBy ?? ''],
      extraButtons: [{
        iconId: (rowIndexIsSelected[index] ? 'icon-CheckboxChecked' : 'icon-CheckboxUnchecked'),
        disabled: !checkboxEnabled(index),
        title: !x.BuildNumber ? '' : 'Can not delete form versions that has a build.',
        onClick: onCheckboxClick, iconClassName: 'svgcheckbox',
      }],
    } as GenericTableRowData));

  const getTemplateRelativeRoute = (idx: number) => currentTemplateVersions != null 
    ? currentTemplateVersions[idx].Id + '/' + currentTemplateVersions[idx].Version
    : '';

  const canPermDeleteTemplate = currentTemplateVersions?.every(x => !x.BuildNumber) ?? false;
  const isSelectAllDisabled = currentTemplateVersions ? currentTemplateVersions.every((x, i) => !checkboxEnabled(i)) : false;
  return (
        <>
            <button onClick={() => selectAllVersionsClicked()} style={{ marginRight: '-4px', backgroundColor: 'transparent' }}
                disabled={isSelectAllDisabled} className="pull-right svgcheckbox-button svgcheckbox VersionsSelectAll btn">
                <span>Select all</span> <SvgRef id={!isSelectAllDisabled && allVersionsSelected ? 'icon-CheckboxChecked' : 'icon-CheckboxUnchecked'} className="svg-icon svgcheckbox" />
            </button>
            {props.header}
            <GenericTable
                emptyMessage="No versions available..."
                loadingMessage="Loading versions, please wait..."
                headers={['Version', 'Build', 'Modified']}
                hiddenXsColumns={[2]}
                tableRows={templateData}
                onClick={i => props.pushHistory(routeToPath('/' + getTemplateRelativeRoute(i)))}
            />
            <div className="container" style={{ padding: 0 }}>
                <div className="col-xs-6" style={{ padding: 0 }}>
                    <button className="btn btn-danger" disabled={!canPermDeleteTemplate} onClick={onPermDeleteTemplate}
                    title={canPermDeleteTemplate ? 'Permanently delete the whole form (including all versions)' :
                      'Cannot permanently delete the form since there are versions that has builds'}>
                            Permanently delete form
                    </button>
                </div>
                <div className="right col-xs-6" style={{ padding: 0 }}>
                    <button title={shouldPermDeleteBeEnabled() ? 'Permanently delete selected form versions' : 'Select form versions to permanently delete'}
                        className="btn btn-warning" disabled={!shouldPermDeleteBeEnabled()} onClick={() => onPermDelete()}>Permanently delete selected</button>
                </div>
            </div>
        </>
  );
};