import { createContext } from 'react';
import { TemplateField, TemplateMetadata } from '../Components/BasicTypes';
import { AppColorMode } from '../NavigatonBar';
import { SrtGenTemplate, SrtGenBuild, SrtSite } from '../ServerCommunication';

export type CommunicationContextType = {
  setNewSrtGenTemplateObject: (template: SrtGenTemplate) => void;
  setUnsaved: (unsaved: boolean) => void;
  saveChanges: () => Promise<SrtGenTemplate | null>;
  isUnsaved: boolean;
  folderId: number;
  appColorMode: AppColorMode;
  providerNames: string[];
  getBuildInfo: (templateId: string, templateVersion: number) => Promise<SrtGenBuild | null>;
  buildIds7Package: (templateId: string, templateVersion: number, jsCode: string, templateFields: TemplateField[],
    reportVersion: number, templateMetadata: TemplateMetadata) => Promise<void>;
  getTemplateVersionsById: (templateId: string) => Promise<SrtGenTemplate[]>;
  deleteTemplateVersions: (templateId: string, deletedVersions: number[]) => Promise<void>;
  pushHistory: (url: string, options?: any) => void;
  onUpdateProviderData: () => void;
  setTemplateShare: (templateId: string, sites: SrtSite[] | null) => void;
  getAllSites: () => Promise<SrtSite[]>;
  getTemplateShare: (templateId: string) => Promise<number[] | null>;
  getPreviewEnabled: (templateId: string) => Promise<boolean>;
  setPreviewEnabled: (templateId: string, enabled: boolean) => void;
};

export const CommunicationContext = createContext<CommunicationContextType | null>(null);