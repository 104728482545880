/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useCookies } from 'react-cookie';

export function usePrevious<T>(value: T, init?: T): T | undefined {
  const ref = init !== undefined ? useRef<T>(init) : useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current != null) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useRefState<S>(initialState: S | (() => S)): [React.MutableRefObject<S>, (val: S)=>void] {
  const [state, setState] = useState<S>(initialState);
  const ref = useRef(state);
  const setter = (val: S) => {
    setState(ref.current = val);
  };

  return [ref, setter];
}

export function useCookieState<T>(def: T, cookieName: string): [T, (val: T)=>void]  {
  const [cookies, setCookie] = useCookies([cookieName]);
  const setValue = (val: T) => {
    setCookie(cookieName, String(val), { path: '/' });
  };

  let ret: any = cookies != null ? (cookies[cookieName] ?? def) : def;
  if (typeof(def) === 'boolean') {
    ret = (String(ret) === 'true');
  } else if (typeof(def) === 'number') {
    ret = Number(ret);
  } else if (typeof(def) !== 'string') {
    throw new Error('Other types than boolean, number, string currently not supported');
  }

  return [ret as T, setValue];
}

export function useOnContainerScroll(effect: (scrollTop: number) => void) {
  // get relevant container to track
  const container = document.querySelector('.srt-container')?.parentElement;

  const handleScroll = () => {
    effect((container as HTMLElement).scrollTop ?? 0);
  };

  useLayoutEffect(() => {
    if (container == null) {
      return;
    }

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  });
}