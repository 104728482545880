/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { MouseEventHandler } from 'react';
import { asControllableElement, OptionsContentController } from './ContentController';
import { INullableOptionValue, IOptionValues, ISingleOptionValue, SectraOptionHelper } from './SectraOptionBase';

interface SectraButtonGroupState {
  value: INullableOptionValue;
}

export interface SectraButtonGroupProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
  buttonValues: IOptionValues;
  buttonTexts: string[];
  descriptions?: (string | undefined | null)[];
  checkedButton?: INullableOptionValue;
  markedButton?: INullableOptionValue;
  disabledOptions?: boolean[];
  onStateChange?: (val: INullableOptionValue, addedValue: ISingleOptionValue | undefined) => void;
  optionLayout?: 'fill-auto' | 'fill-equal' | 'no-fill' | 'stack';
  preventOutput?: boolean;
  allowMultiple?: boolean;
  showDescription?: boolean;
  onMouseDown?: MouseEventHandler<HTMLElement>;
  onMouseUp?: MouseEventHandler<HTMLElement>;
  onMouseOver?: MouseEventHandler<HTMLElement>;
  onMouseOut?: MouseEventHandler<HTMLElement>;
}

export class SectraButtonGroupOverride extends React.Component<SectraButtonGroupProps, SectraButtonGroupState> {
  el = React.createRef<HTMLDivElement>();

  constructor(props: SectraButtonGroupProps, context: any) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);

    this.state = {
      value: SectraOptionHelper.normalizeValue(this.props.checkedButton, this.props.buttonValues, this.props.allowMultiple === true),
    };
  }

  select(item: ISingleOptionValue, select: boolean) {
    const newValue = select
      ? SectraOptionHelper.addToCurrent(this.state.value, item, this.props.buttonValues, this.props.allowMultiple === true) 
      : SectraOptionHelper.removeFromCurrent(this.state.value, item, this.props.allowMultiple === true);

    if (!SectraOptionHelper.valueEq(newValue, this.state.value)) {
      this.setState({ value: newValue });
      this.props.onStateChange?.(newValue, select ? item : undefined);
    }
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange?.(e);

    const updatedItem: ISingleOptionValue = e.target.dataset.vindex != null
      ? this.props.buttonValues[Number(e.target.dataset.vindex)] ?? e.target.value
      : e.target.value;

    this.select(updatedItem, e.target.checked);
  }

  handleOnClick(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    this.props.onClick?.(e);
    if (e.ctrlKey && this.props.allowMultiple !== true) {
      this.setState({ value: null });
      this.props.onStateChange?.(null, undefined);
            
      e.preventDefault();
      return false;
    }
  }

  componentDidUpdate() {
    const newValue = SectraOptionHelper.normalizeValue(this.props.checkedButton, this.props.buttonValues, this.props.allowMultiple === true);
    if (!SectraOptionHelper.valueEq(this.state.value, newValue)) {
      this.setState({ value: newValue });
    }
  }

  componentDidMount() {
    if (this.el.current != null) {
      asControllableElement(this.el.current).contentController = new OptionsContentController(
        () => this.props.allowMultiple === true,
        () => this.props.buttonValues
          .map((v, i) => ({
            id: v?.toString() ?? '', 
            names: [this.props.buttonTexts?.[i] ?? v?.toString() ?? ''],
          })),
        () => this.props.buttonValues
          .filter(id => SectraOptionHelper.matchSingle(id, this.state.value, this.props.allowMultiple === true))
          .map(v => v?.toString() ?? ''),
        (id: string | null | undefined, selected: boolean) => {
          if (id == null && selected !== false && this.state.value != null) {
            // we're clearing everything (unless we're trying to deselect null, which doesn't make sense)
            this.setState({ value: null });
            this.props.onStateChange?.(null, undefined);
            return;
          }

          const item = this.props.buttonValues.find(v => v?.toString() === id);
          if (item != null) {
            this.select(item, selected !== false);
          }                    
        },
      );
    }
  }

  componentWillUnmount() {
    if (this.el.current != null) delete asControllableElement(this.el.current).contentController;
  }

  render() {
    const {
      name,
      buttonValues,
      checkedButton,
      onChange,
      onClick,
      onStateChange,
      onMouseDown,
      onMouseUp,
      onMouseOver,
      onMouseOut,
      ref,
      id,
      optionLayout: optionSize,
      preventOutput,
      buttonTexts,
      descriptions,
      markedButton,
      allowMultiple,
      disabled,
      disabledOptions,
      showDescription,
      ...htmlProps
    } = this.props;
        
    const stateValue = this.state.value;
    const stateValueIndex = SectraOptionHelper.valueAsOptionValues(stateValue)
      .map(v => buttonValues.indexOf(v));

    let currentDescriptions = descriptions != null 
      ? stateValueIndex
        .map(vi => descriptions[vi])
        .filter(desc => desc != null && desc.length > 0)
      : [];

    if (allowMultiple === true && descriptions != null && showDescription !== false
            && currentDescriptions.length > 0 && currentDescriptions.length !== stateValueIndex.length) {
      // Descriptions are missing from some of the selected options. Showing just the descriptions 
      // of the options with a defined description might be misleading, thus we'll use texts as a fallback.
      currentDescriptions = stateValueIndex
        .map(vi => {
          const desc = descriptions[vi];
          return desc != null && desc.length > 0 ? desc : buttonTexts[vi];
        });
    }

    const description = currentDescriptions.length > 0 
      ? currentDescriptions.join(', ')
      : null;

    let classNames = 'btn-group btn-group-xs';
    switch (optionSize) {
      case 'no-fill': 
        break;
      case 'fill-equal': 
        classNames += ' btn-group-justified btn-fill-equal';
        break;
      case 'stack':
        classNames += ' btn-group-stacked';
        break;
      default: 
        classNames += ' btn-group-justified';
        break;
    }

    return (<>
            <div ref={this.el} className={classNames} data-toggle="buttons" data-component-id={id} onMouseOver={onMouseOver} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseOut={onMouseOut} >
                {this.props.buttonValues.map((value, index) => {
                  const text = buttonTexts?.[index] ?? value?.toString() ?? '';

                  return <SectraToggleButton id={(value === buttonValues[0] ? id : undefined) as string | undefined } key={String(value)} name={name}
                        value={String(value)}
                        data-vindex={index}
                        onChange={this.handleChange} 
                        onClick={this.handleOnClick}
                        buttonText={text}
                        disabled={disabled || disabledOptions?.[index] === true}
                        description={descriptions?.[index]}
                        checked={SectraOptionHelper.matchSingle(value, stateValue, this.props.allowMultiple === true)} 
                        marked={SectraOptionHelper.matchSingle(value, markedButton, this.props.allowMultiple === true)} 
                        inputType={allowMultiple !== true ? 'radio' : 'checkbox'}
                        preventOutput={preventOutput}
                        {...htmlProps}></SectraToggleButton>;
                })}
            </div>{showDescription !== false && description != null ? <p className={'info-text'}>{description}</p> : null}</>
    );
  }
}

interface SectraToggleButtonState {
  focused: boolean
}

export interface SectraToggleButtonProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
  checked?: boolean;
  disabled?: boolean;
  marked?: boolean;
  preventOutput?: boolean;
  buttonText: string;
  description?: string | null;
  inputType?: 'radio' | 'checkbox';
}

export class SectraToggleButton extends React.Component<SectraToggleButtonProps, SectraToggleButtonState> {
  constructor(props: any, context: any) {
    super(props, context);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.state = { focused: false };
  }

  handleFocus(event: React.FocusEvent<HTMLInputElement>) {
    this.setState({ focused: true });
    this.props.onFocus?.(event);
  }

  handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    this.setState({ focused: false });
    this.props.onBlur?.(event);
  }

  render() {
    const {
      name,
      inputType,
      checked,
      disabled,
      marked,
      preventOutput,
      buttonText,
      description,
      onFocus,
      onBlur,
      ...htmlProps
    } = this.props;

    let labelClass = 'btn-default btn btn-secondary';
    if (marked) {
      labelClass += ' marked';
    }
    if (checked) {
      labelClass += ' active';
    }
    if (this.state.focused && !disabled) {
      labelClass += ' focus';
    }

    const dataFieldType = preventOutput ? null : (inputType === 'checkbox' ? 'checkbox' : 'radio button');

    if (disabled) {
      labelClass += ' disabled';
      return (
                <label className={labelClass} title={description != null ? description : undefined}>
                    <input type={inputType === 'checkbox' ? 'checkbox' : 'radio'} 
                            name={name} 
                            disabled={disabled} 
                            data-field-type={dataFieldType}
                            autoComplete="off" 
                            checked={false}
                            {...htmlProps} 
                        />
                    {buttonText}
                </label>
      );
    }

    return (
            <label className={labelClass} title={description != null ? description : undefined}>
                <input type={inputType === 'checkbox' ? 'checkbox' : 'radio'} 
                        onBlur={this.handleBlur} 
                        onFocus={this.handleFocus} 
                        name={name}
                        data-field-type={dataFieldType} 
                        autoComplete="off" 
                        checked={Boolean(checked)} 
                        {...htmlProps}
                    />
                {buttonText}
            </label>
    );
  }
}