import { applogger } from '../../applogger';
import { expressionPattern } from '../SrtComponent/Schema';
import { getSchemaInfo, ISchema, IValidationContext } from '../Validator';
import { codeCompletionDataArg } from './EditorCodeCompletionDataArg';

export function setupCodeMirrorAutocomplete(codeMirror: any, getValidationContext: ()=>IValidationContext) {
  const customRender = (dom: Element, data: any, element: any) => {
    //applogger.debug({ dom: dom, data: data, element: element });
    dom.append(element.displayText);
    if (element.description != null) {
      var span: Element = document.createElement('span');
      span.setAttribute('class', 'hint-description');
      span.append(element.description);

      dom.append(span);
    }
  };

  codeMirror.registerHelper('hint', 'yaml', (editor: any) => {
    const schemaInfo = getSchemaInfo(getValidationContext());

    // read all the lines from editor
    let sLines: string[] = [];
    for (let i = 0; i < editor.lineCount(); ++i) {
      sLines.push(editor.getLine(i));
    }

    // Parse the lines and get the current context
    const indentSize = editor.getOption('indentUnit');
    const lines = getParsedLines(sLines, indentSize);
    const cur = editor.getCursor();
    const curLine = lines[cur.line];
    const context = getContext(lines, cur.line);

    let end = cur.ch;
    let start = end;
    //applogger.debug(context);
    let schemaContext = getSchemaNodesForPathContext(schemaInfo, context);
    if (schemaContext == null) {
      // no schema matching the context path, return empty code completion list
      return { list: [], from: codeMirror.Pos(cur.line, start), to: codeMirror.Pos(cur.line, end) };
    }

    const getSharedPrefixLength = (a: string, b: string) => {
      const l = Math.min(a.length, b.length);
      for (let j = 0; j < l; ++j) {
        if (a[j].toLowerCase() !== b[j].toLowerCase()) return j;
      }
      return l;
    };

    const parentLine = curLine.parent;
    const siblingLine = parentLine?.children.filter(x => x != curLine && x.key != null && x.key !== '')?.[0];

    // Format list options as code completion options
    const indent = siblingLine != null
      ? siblingLine.rawIndent.replace('-', ' ')
      : (parentLine != null 
        ? Array(parentLine.rawIndent.length + indentSize + 1).join(' ') 
        : Array(curLine.indent * indentSize + 1).join(' '));

    // Check current line whether we want to autocomplete key or property value
    if (!(curLine.sep.length > 0 && curLine.key.match(/\S/) != null)) {
      //
      // Get completion options for key/property lookup
      //
      let contextNode = schemaContext[schemaContext.length - 1];
      if (Array.isArray(contextNode.anyOf)) {
        // we're in a none-inline context, expect object properties or array
        contextNode = { ...contextNode, anyOf: (contextNode.anyOf as any[]).filter(x => x.type === 'array' || x.type === 'object') };
        if (contextNode.anyOf.length === 1) {
          contextNode = contextNode.anyOf[0];
        }
      }

      let compOptions = getCompletionOptionsFor(schemaInfo, contextNode, false);

      // handle whether the option is a newArrayItem
      if (contextNode.type === 'array' && contextNode.items != null) {
        let arrayNode = followRef(schemaInfo, contextNode.items);
        if (arrayNode?.type === 'object' || (arrayNode?.anyOf != null && arrayNode?.anyOf.some((x: any) => x?.type === 'object'))) {
          // suggest new options for those already defined with parent object, 
          // unless none is defined, then we have to start a new object
          let type: any | null = null;
          if (context.ParentObjProps.length > 0) {
            if (Array.isArray(arrayNode.anyOf)) {
              type = arrayNode.anyOf.find((x: any) => x.type === 'object' && context.ParentObjProps.every(p => x?.properties[p] != null));
            }
          }

          compOptions.forEach(opt => {
            // set it as a new array item if,
            opt.newArrayItem = 
                            // not an object, i.e. each row must be a new array item and not an additional property to the existing array item
                            opt.srcNode?.type !== 'object' 
                            // it must be a new array item if the object doesn't contain any props already
                            || context.ParentObjProps.length === 0
                            // also it should be a new array item if this property is already in use in the existing object
                            || context.ParentObjProps.indexOf(opt.key) >= 0
                            // or if the current type I'm extending don't support this property
                            || (type != null && type.properties[opt.key] == null);
          });
        } else {
          // handle all options as new array items
          compOptions.forEach(opt => { opt.newArrayItem = true; });                    
        }
      } else if (context.ParentObjProps.length > 0) {
        // Check whether we should filter option list for items that's already in use
        compOptions = compOptions.filter(opt => context.ParentObjProps.indexOf(opt.key) === -1);
      }
            
      // Sort options by relevant (prefix matching to what's currently on the line)
      const currentOnLine = curLine.key.trim();
      if (currentOnLine.length > 0) {
        compOptions = compOptions
          .map(o => ({ o, d: getSharedPrefixLength(o.key, currentOnLine) }))
          .sort((a, b) => b.d - a.d)
          .map(x => x.o);         
      }
            
      let formattedOptions = compOptions.map(option => {
        // check whether we suppose to handle defaults for this option
        let defaults: string[] = [];
        let optionSchemaNode = matchLineKeyToSchema(schemaInfo, option.key, contextNode);
        if (optionSchemaNode != null && optionSchemaNode.type === 'object' && optionSchemaNode.required && optionSchemaNode.required.length > 0) {
          // build indent
          let childPropIndent = Array((curLine.indent + 1) * indentSize + 1).join(' ');
          defaults = optionSchemaNode.required.map((k: string) => childPropIndent + k + ': ');
        }

        return {
          defaults: defaults,
          nodeType: optionSchemaNode?.type,
          text: (option.newArrayItem ? indent.replace(/ {2}$/, '- ') : indent) + option.key + (!option.inlineOption ? ': ' : ''),
          description: option.description,
          displayText: option.displayKey ?? option.key,
          render: customRender,
          hint: (_editor: any, data: any, element: any) => {
            // update UI with chosen option
            _editor.replaceRange(element.text, data.from, data.to);

            if (element.defaults && element.defaults.length > 0) {
              // keep track of cursor so that we reset it to the first default property that we're adding
              let firstCurPos = null;
              for (let i = 0; i < element.defaults.length; ++i) {
                _editor.replaceRange('\n' + element.defaults[i], _editor.getCursor());
                if (firstCurPos == null) firstCurPos = _editor.getCursor();
              }

              // restore cursor position to first property inserted
              if (firstCurPos != null) _editor.setCursor(firstCurPos);
            } else if (element.nodeType === 'array') {
              _editor.replaceRange('\n' + indent + '- ', _editor.getCursor());
            }

            // pop hints in case there are hints to be shown
            if (!option.inlineOption) codeMirror.showHint(_editor, codeMirror.hint.yaml);
          },
        };
      });

      return {
        list: formattedOptions,
        from: codeMirror.Pos(cur.line, 0),
        to: codeMirror.Pos(cur.line, curLine.rawIndent.length + curLine.key.length),
      };

    } else {
      //
      // Get completion options for key/property values, i.e. inline value options
      //
      let valueNode = matchLineKeyToSchema(schemaInfo, curLine.key, schemaContext[schemaContext.length - 1]);
      if (valueNode != null) {
        // value hints, set start/end to cover the value we're replacing
        start = curLine.rawIndent.length + curLine.key.length + curLine.sepWSpace.length;
        end = start + curLine.value.length;

        const leftOnCursorMark = curLine.line.length - cur.ch;
        const currentOnLine = leftOnCursorMark > 0 
          ? (curLine.value.length > leftOnCursorMark 
            ? curLine.value.substr(0, curLine.value.length - leftOnCursorMark).trim() 
            : '')
          : curLine.value.trim();

        let padding = (curLine.sepWSpace.match(/:$/)) ? ' ' : '';
        let compOptions = getCompletionOptionsFor(schemaInfo, valueNode, true).filter(opt => opt.inlineOption);
        if (compOptions.length === 0 && curLine.key === 'dataarg') {
          let dataargOpts = codeCompletionDataArg(currentOnLine);
          if (dataargOpts != null && dataargOpts.items.length > 0) {
            compOptions = dataargOpts.items.map(dao => ({ 
              inlineOption: true, 
              reHintOnInsert: dao.hasChildren === true,
              key: dataargOpts.context + dao.name + (dao.hasChildren === true ? '.' : ''), 
              displayKey: dao.name, 
              description: dao.description ?? '',
              srcNode: curLine,
            }));
          }
        }

        // Sort options by relevant (prefix matching to what's currently on the line)
        if (currentOnLine.length > 0) {
          compOptions = compOptions
            .map(o => ({ o, d: getSharedPrefixLength(o.key, currentOnLine) }))
            .sort((a, b) => b.d - a.d)
            .map(x => x.o);
        }

        // Format list options as code completion options
        let formattedOptions = compOptions.map(option => ({
          text: padding + option.key.split('\n').map((l, i) => i === 0 ? l : indent + l).join('\n'), 
          displayText: option.displayKey ?? option.key,
          description: option.description,
          render: customRender, 
          select: option.select,
          reHintOnInsert: option.reHintOnInsert,
          hint: (_editor: any, data: any, element: any) => {
            // update UI with chosen option
            _editor.replaceRange(element.text, data.from, data.to);
            if (element.select) {
              _editor.setSelection(
                codeMirror.Pos(data.from.line, data.from.ch + element.select.from), 
                codeMirror.Pos(data.from.line, data.from.ch + element.select.to));
            }
            if (element.reHintOnInsert === true) {
              // pop hints in case there are hints to be shown
              codeMirror.showHint(_editor, codeMirror.hint.yaml);
            }
          },
        }));

        return {
          list: formattedOptions,
          from: codeMirror.Pos(cur.line, start),
          to: codeMirror.Pos(cur.line, end),
        };
      }
    }
  });
}

interface ICompletionOption {
  key: string;
  displayKey?: string;
  description?: string;
  newArrayItem?: boolean;
  inlineOption: boolean;
  reHintOnInsert?: boolean;
  select?: { from: number, to: number };
  srcNode: any;
}

// simple parsing of yaml
export interface IParsedLine {
  isList: boolean;
  isListItemHead: boolean;
  isListItem: boolean;
  parent: IParsedLine | null;
  children: IParsedLine[];
  indent: number;
  key: string;
  sep: string;
  value: string;
  comment: string;
  rawIndent: string;
  sepWSpace: string;
  line: string;
  path?: string;
}

interface ILineContext {
  Current: IParsedLine;
  Parent: IParsedLine | null;
  ParentObjProps: string[];
  ParentListProps: string[];
  Path: string;
  PathObj: IParsedLine[];
}

function getCompletionOptionsFor(schemaInfo: ISchema, node: any, expectInline: boolean): ICompletionOption[]  {
  let jType = JSON.stringify(node.type);
  const expressionDisplayKey = '=<expression>';
  const expressionKey = '"=<expression>"';

  if (node.type === 'object' && node.properties != null) {
    const items: ICompletionOption[] = [];
    for (const [key, value] of Object.entries(node.properties)) {
      let desc = (value as any)?.description ?? ((value as any)?.anyOf ?? [])[0]?.description;
      items.push({ key: key, description: desc != null ? String(desc) : undefined, inlineOption: false, srcNode: node });
    }
    return items;
  } else if (node.type === 'string' && node.enum != null) {
    const desc = node.enumDescription;
    return node.enum.map((val: string, idx: number) => ({ key: val, description: desc?.[idx], inlineOption: true } as ICompletionOption));
  } else if (node.type === 'string' && node.pattern === expressionPattern) {
    return [{
      key: expressionKey, 
      displayKey: expressionDisplayKey, 
      description: node.description ?? 'Defined by an expression', 
      select: { from: 2, to: expressionKey.length - 1 }, 
      inlineOption: true, 
      srcNode: node,
    }];
  } else if (node.type === 'boolean' || jType === '["string","boolean"]') {
    const desc = node.enumDescription;
    const items: ICompletionOption[] = [
      { key: 'true', inlineOption: true, description: desc?.[0], srcNode: node },
      { key: 'false', inlineOption: true, description: desc?.[1], srcNode: node },
    ];
    if (jType === '["string","boolean"]') {
      items.push({ key: expressionKey, displayKey: expressionDisplayKey, description: desc?.[3] ?? 'Defined by an expression', select: { from: 2, to: expressionKey.length - 1 }, inlineOption: true, srcNode: node });
    }

    return items;
  } else if (node.$ref != null) {
    return getCompletionOptionsFor(schemaInfo, followRef(schemaInfo, node), expectInline);
  } else if (node.type === 'array' && node.items) {
    if (expectInline) {
      return [{
        inlineOption: true,
        key: '\n- ',
        displayKey: 'array',
        description: node.description,
        reHintOnInsert: true,
        srcNode: node,
      }];
    }
    return getCompletionOptionsFor(schemaInfo, followRef(schemaInfo, node.items), expectInline);
  } else if (node.anyOf != null) {
    let anyOfPart = (node.anyOf as any[]).map((n, i) => ({
      i, n, opts: getCompletionOptionsFor(schemaInfo, n, expectInline),
    }));
    if (anyOfPart.some(x => x.opts.length > 0)) {
      anyOfPart = anyOfPart.map(x => x.opts.length > 0 ? x : { ...x, opts: [{
        // add a placeholder for non completing types
        inlineOption: true,
        key: '',
        displayKey: x.n.type,
        description: x.n.enumDescription != null && x.n.enumDescription !== '' 
          ? String(x.n.enumDescription)
          : (x.n.description != null && x.n.description !== '' ? String(x.n.description) : undefined),
        srcNode: x.n,
      }] });
    }

    let items = anyOfPart.flatMap(x => x.opts);
    return items;
  }

  applogger.debug(`no handler for ${jType}`);
  applogger.debug(node);
  return [];
}

function followRef(schemaInfo: ISchema, node: any): any {
  if (node.$ref == null) {
    return node;
  }

  // follow ref
  let path = node.$ref;
  if (path.indexOf('#') > 0) {
    path = path.substr(path.indexOf('#') + 2); // +2 to remove the # and we expect absolute path starting with a /
  }

  let parts = path.split('/');
  let refNode = schemaInfo.Definitions;
  for (let i = 0; i < parts.length && refNode != null; ++i) {
    refNode = refNode[parts[i]];
  }

  // keep processing given using ref
  return refNode != null ? refNode : null;
}

function matchLineKeyToSchema(schemaInfo: ISchema, key: string, node: any): any {
  if (node.type === 'object' && node.properties) {
    // matching path object against properties
    let subNode = node.properties[key];
    return subNode != null ? subNode : null;
  } else if (node.type === 'array' && node.items) {
    // matching path object against valid array items
    return matchLineKeyToSchema(schemaInfo, key, node.items);
  } else if (node.$ref != null) {
    // keep processing after ref is followed
    return matchLineKeyToSchema(schemaInfo, key, followRef(schemaInfo, node));
  } else if (node.anyOf != null) {
    // we need to search a matching node, currently we here do greedy search picking the first locally matching schema
    for (let i = 0; i < node.anyOf.length; ++i) {
      let subNode = matchLineKeyToSchema(schemaInfo, key, node.anyOf[i]);
      if (subNode != null) {
        return subNode;
      }
    }

    // none of the objects match, returning null
    return null;
  }

  applogger.debug('no handler for');
  applogger.debug(node);
  applogger.debug('matching line key: ' + key);
  return null;
}

function getSchemaNodesForPathContext(schemaInfo: ISchema, context: ILineContext): any[] | null {
  let path = context.PathObj;
  let nodes: any[] = [schemaInfo.Schema];
  for (let i = 0; i < path.length; ++i) {
    let pl = path[i];
    if (pl.key == null || pl.key === '') {
      // no valid context given, return null
      return null;
    }

    let newNode = matchLineKeyToSchema(schemaInfo, pl.key, nodes[nodes.length - 1]);
    if (newNode == null) {
      // not matching context path, return null
      return null;
    }

    nodes.push(newNode);
  }

  return nodes;
}

export function getParsedLines(lines: string[], indentSize: number): IParsedLine[] {
  const yamlRegex = /^((?:[ \t]*-[ \t]+)|[ \t]*)((?:[^:#\n]*[^:#\n\s])?)([ \t]*(:?)[ \t]*)((?:[^#\n]+[^\s#])?)[ \t]*((?:#[^\n]*)?)[ \t]*$/;

  function parseLine(line: string, iSize: number): IParsedLine {
    const m = line.match(yamlRegex);
    if (m != null) {
      return {
        isList: false,
        parent: null,
        children: [],
        isListItemHead: m[1].indexOf('-') >= 0,
        isListItem: false,
        indent: Math.ceil(m[1].length / iSize),
        key: m[2],
        sep: m[4],
        value: m[5],
        comment: m[6],
        rawIndent: m[1],
        sepWSpace: m[3],
        line: line,
      };
    }

    applogger.warn('none matching line: ' + line);
    return { isList: false, parent: null, children:[], isListItemHead: false, isListItem: false, indent: 0, key: '', sep: '', value: '', comment: '', rawIndent: '', sepWSpace: '', line: line };
  }

  const base: IParsedLine = {
    isList: false,
    parent: null,
    children: [],
    isListItemHead: false,
    isListItem: false,
    indent: -2,
    key: '',
    sep: '',
    value: '',
    comment: '',
    rawIndent: '',
    sepWSpace: '',
    line: '',
  };

  let ret: IParsedLine[] = [];
  let listItemLevel: number | null = null;
  for (let i = 0; i < lines.length; ++i) {
    let l = parseLine(lines[i], indentSize);

    // Find parent
    if (l.indent > 0) {
      for (let j = ret.length - 1; j >= 0; --j) {
        if (ret[j].indent <= l.indent - 1 && ret[j].key.length > 0) { // find a line of at least indent-1 which isn't empty
          l.parent = ret[j];
          ret[j].children.push(l);
          break;
        }
      }
    } else {
      l.parent = base;
      base.children.push(l);
    }

    // Handle list item
    if (listItemLevel != null && l.indent < listItemLevel) {
      listItemLevel = null;
    }
    if (l.isListItemHead) {
      listItemLevel = l.indent;
      if (l.parent != null) {
        l.parent.isList = true;
      }
    }

    l.isListItem = l.indent === listItemLevel;

    ret.push(l);
  }

  return ret;
}

function getContext(pLines: IParsedLine[], currentLineIndex: number): ILineContext {
  let cPath: IParsedLine[] = [];
  let current = pLines[currentLineIndex];
  let cp = current.parent;
  while (cp != null && cp.indent >= 0) {
    cPath.push(cp);
    cp = cp.parent;
  }
  cPath = cPath.reverse();

  let parentObjProps: string[] = [];
  let parentListProps: string[] = [];
  if (current.parent != null) {
    let parentChildKeys = current.parent.children.map(l => l.key).filter(l => l.length > 0);
    if (!current.parent.isList) {
      parentObjProps = parentChildKeys;
    } else {
      parentListProps = parentChildKeys;

      // find myself among children
      let i = current.parent.children.indexOf(current);
      if (i >= 0) {
        let children = current.parent.children;
        let from: number = i;
        let to: number = i;
        // search backwards until we find the list head
        for (from = i; from >= 0 && !children[from].isListItemHead; --from); if (from < 0) ++from;
        // search forward until next list head
        for (to = i + 1; to < children.length && !children[to].isListItemHead; ++to); --to;

        for (let j = from; j <= to; ++j) {
          // skip ourselves
          if (j === i) continue;
          if (children[j].sep.length > 0) {
            parentObjProps.push(children[j].key);
          }
        }
      }
    }
  }

  return {
    Current: current,
    Parent: current.parent,
    ParentObjProps: parentObjProps,
    ParentListProps: parentListProps,
    Path: cPath.map(l => '::' + l.key).join(''),
    PathObj: cPath,
  };
}
