/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './BootstrapSectra/sectra-styling.css';

import './Ids7App';

import './index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookieKeepAliveAuthProvider } from './Auth';
import { Alert, Button } from 'react-bootstrap';
import { applogger } from './applogger';
import { useRefState } from './Components/ReactExt';
import { baseUrl } from './ServerCommunication';
import { getReduxStoreForProvider } from './Components/ReduxStateStore';
import { Root } from './Root';

// import * as serviceWorker from './serviceWorker';

export const loadWebApp = () => {
  if (process.env.NODE_ENV === 'production') {
    document.querySelector('style')?.remove();
  }

  ReactDOM.render(
        <Router>
            <Provider store={getReduxStoreForProvider()}>
                <Root />
            </Provider>
        </Router>, document.getElementById('app-container'));
};

window._loadWebApp_ = loadWebApp;

const BrowserAppBase: React.FC = ({ children }) => {
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false);
  const [showImminentLogoutWarning, setShowImminentLogoutWarning] = useRefState<boolean | null>(null); // use a ref state to ensure that check in onTimeoutWarning refs the latest value, without this it reads an old value
  const [tryStayLoggedIn, setTryStayLoggedIn] = useState<boolean>(false);

  if (window.SRTBSettings?.KeepAliveTimeout != null && (+window.SRTBSettings?.KeepAliveTimeout) <= 0) {
    return <>{children}</>;
  }

  const showImminentLogoutAtMsLeft = 5 * 60 * 1000; /* show immentent logout at 5 minutes left on session*/
  const onTimeoutWarning = () => {   
    if (showImminentLogoutWarning.current === null) {
      applogger.info('set to show imminent logout warning');
      setShowImminentLogoutWarning(true);
    }
  };

  let additionalAuthContent: JSX.Element | null = null;
  if (showLoginForm) {
    additionalAuthContent = <Login 
            onSuccessfulLogin={()=>{
              setShowLoginForm(false);
              setShowImminentLogoutWarning(null);
              applogger.log('user logged in from inactivity dialog');
            }}
        />;
  } else if (showImminentLogoutWarning.current) {
    applogger.info('showing imminent logout warning');
    additionalAuthContent = <SessionTimeoutWarning 
            stayLoggedIn={() => { 
              setTryStayLoggedIn(true);
              setShowImminentLogoutWarning(false);
            }} 
            dismiss={() => {
              setShowImminentLogoutWarning(false);
            }} 
        />;
  } else if (tryStayLoggedIn) {
    // try load a page from the server to
    additionalAuthContent = <TryStayLoggedIn onSuccesfulLoad={() => {
      applogger.log('call to server resulting in a valid page!');
      setShowImminentLogoutWarning(null);
      setTryStayLoggedIn(false);
    }} onFailedLoad={() => {
      applogger.log('stay logged in seems to have failed!');
      setTryStayLoggedIn(false);
    }} />;
  }

  return (
        <CookieKeepAliveAuthProvider 
            doKeepAlive={!showLoginForm} 
            username={window.SRTBSettings?.User ?? 'Unknown user'} 
            intervalMs={window.SRTBSettings?.KeepAliveTimeout} 
            timeoutWarningMs={showImminentLogoutAtMsLeft} 
            onSessionTimeoutWarning={onTimeoutWarning} 
            onExpiredSession={()=>setShowLoginForm(true)}
        >
            {additionalAuthContent}
            {children}
        </CookieKeepAliveAuthProvider>
  );
};

interface SessionTimeoutWarningProps {
  dismiss: ()=>void;
  stayLoggedIn: ()=>void;
}

const SessionTimeoutWarning: React.FC<SessionTimeoutWarningProps> = (props) => {
  return (
        <div className="container timeout-dialog">
            <Alert bsStyle="warning" onDismiss={props.dismiss}>
                <h4>Automatic logout imminent</h4>
                <p>Your session is about to expire.</p>
                <p>
                    <Button bsStyle="primary" onClick={props.stayLoggedIn}>
                        Try stay logged in
                    </Button>
                    <Button bsStyle="info" style={{ marginLeft: '1rem' }} onClick={props.dismiss}>
                        Dismiss
                    </Button>
                </p>
            </Alert>
        </div>
  );
};

interface LoginProps {
  onSuccessfulLogin: () => void;
}

const Login: React.FC<LoginProps> = (props) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<boolean>(false);
  const [frameHeight, setFrameHeight] = useState<number>(400);

  const returnToUrl = baseUrl() + '/Login/SPALoginSuccess';
  const iframeSrc = baseUrl() + '/Login?returnUrl=' + escape(returnToUrl);
  const reloadIframe = (target: EventTarget) => {
    const iframe = (target as HTMLElement).closest?.('#auth-modal-overlay-container')?.querySelector('iframe');
    if (iframe != null) {
      iframe.src = iframeSrc;
    }
  };
    
  return <div id="auth-modal-overlay-container">
        <div className={isLoaded ? 'display' : undefined}>
            <div className="alert alert-warning">
                <h4>
                    <span>{!loadError ? 'You have been logged out' : 'Server connection lost or session timout'}</span>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" onClick={(e) => reloadIframe(e.currentTarget)}>
                        <path fill="#000" d="M444.84 83.16c-46.804-51.108-114.077-83.16-188.84-83.16-141.385 0-256 114.615-256 256h48c0-114.875 93.125-208 208-208 61.51 0 116.771 26.709 154.848 69.153l-74.848 74.847h176v-176l-67.16 67.16z"></path>
                        <path fill="#000" d="M464 256c0 114.875-93.125 208-208 208-61.51 0-116.771-26.709-154.847-69.153l74.847-74.847h-176v176l67.16-67.16c46.804 51.108 114.077 83.16 188.84 83.16 141.385 0 256-114.615 256-256h-48z"></path>
                    </svg>
                </h4>
                <p className="errorMessage" onClick={(e) => reloadIframe(e.target)}>
                    {loadError ? 'Failed to load login page! Click to try again.' : null}
                </p>
                <iframe style={{ display: loadError ? 'none' : 'block', height: frameHeight + 'px' }} onError={()=>{ setLoadError(false); }} src={iframeSrc} onLoad={(e)=>{
                  setIsLoaded(true);

                  const iframe = e.currentTarget;
                  const frameDoc = iframe.contentDocument;
                  if (frameDoc == null) {
                    setLoadError(true);
                    return;
                  } else {
                    setLoadError(false);
                  }

                  // Verify success by checking if the successFromIframe page is loaded. Better option then calling up from page since we keep the logic here
                  if (frameDoc?.getElementById('SPALoginSuccess') != null) {
                    props.onSuccessfulLogin();
                  } else {
                    applogger.log('not in success yet..');
                    const container = document.querySelector('#auth-modal-overlay-container > div');
                    if (container) {
                      container.className = 'display';
                      const bodyHeight = frameDoc?.querySelector('body')?.clientHeight;
                      if (bodyHeight) {
                        setFrameHeight(bodyHeight + 1);
                      }
                    }
                  }
                }} />
            </div>
        </div>
    </div>;
};

interface TryStayLoggedInProps {
  onSuccesfulLoad: () => void;
  onFailedLoad: () => void;
}

const TryStayLoggedIn: React.FC<TryStayLoggedInProps> = (props) => {
  return <iframe src={baseUrl() + '/Login/SPALoginSuccess'} style={{ visibility: 'hidden', border: 'none', margin: 0, padding: 0, height: 0, width: 0 }} onLoad={(e)=>{
    if (e.currentTarget.contentDocument?.getElementById('SPALoginSuccess') != null) {
      props.onSuccesfulLoad();
    } else {
      props.onFailedLoad();
    }
  }} />;
};



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
