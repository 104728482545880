/* eslint-disable no-unused-vars */
import * as React from 'react';

export interface SectraCheckButtonProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
  value: string;
  type?: 'checkbox' | 'radio';
  checked?: boolean;
  onStateChange?: (isChecked: boolean) => void;
  partOfGroup?: boolean;
  description?: string;
}

interface SectraCheckButtonState {
  checked: boolean,
  focused: boolean
}

export class SectraCheckButton extends React.Component<SectraCheckButtonProps, SectraCheckButtonState> {
  constructor(props: any, context: any) {
    super(props, context);

    this.state = { checked: !!this.props.checked, focused: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange?.(event);

    const checked = event.target.checked;
    this.setState(() => ({ checked: checked }));

    // issue a disconnected update since we need to re-render in order to change the UI and have a response UI experience
    setTimeout(() => {
      if (this.props.onStateChange) {	
        this.props.onStateChange(checked);
      }
    }, 0);
  }

  handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    this.props?.onFocus?.(e);
    this.setState({ focused: true });
  }

  handleBlur(e: React.FocusEvent<HTMLInputElement>) {
    this.props?.onBlur?.(e);
    this.setState({ focused: false });
  }

  handleOnClick(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    this.props.onClick?.(e);
        
    if (e.ctrlKey && this.props.type === 'radio' && this.state.checked) {
      this.setState(() => ({ checked: false }));

      // issue a disconnected update since we need to re-render in order to change the UI and have a response UI experience
      setTimeout(() => {
        if (this.props.onStateChange) {	
          this.props.onStateChange(false);
        }
      }, 0);
            
      e.preventDefault();
      return false;
    }
  }

  componentDidUpdate(prevProps: SectraCheckButtonProps) {
    if (prevProps.checked !== this.props.checked || prevProps.value !== this.props.value) {
      this.setState({ checked: this.props.checked ?? false });
    }
  }

  render() {
    const {
      name,
      onClick,
      onChange,
      onBlur,
      onFocus,
      onStateChange,
      checked,
      value,
      type,
      description,
      partOfGroup,
      className,
      ...htmlProps
    } = this.props;
        
    const inputType = type ?? 'checkbox';
    let labelClass = 'btn-' + inputType;
    if (this.state.checked) {
      labelClass += ' active';
    }
    if (this.state.focused) {
      labelClass += ' focus';
    }
    if (htmlProps.disabled === true) {
      labelClass += ' disabled';
    }
    if (className != null) {
      labelClass += ' ' + className;
    }

    const svgIcon = inputType == 'checkbox'
      ? (this.state.checked ? '#icon-CheckboxChecked' : '#icon-CheckboxUnchecked')
      : (this.state.checked ? '#icon-RadiobuttonChecked' : '#icon-RadiobuttonUnchecked');

    const element = <label className={labelClass} title={description}>
            <div><svg className={'svg-icon'}><use xlinkHref={svgIcon}></use></svg></div>
            <input type={inputType} 
                    name={name} 
                    autoComplete="off" 
                    value={value} 
                    checked={this.state.checked}
                    onBlur={this.handleBlur} 
                    onFocus={this.handleFocus} 
                    onChange={this.handleChange} 
                    onClick={this.handleOnClick}
                    {...htmlProps}
                />
            {value != null && value !== '' ? <span>{value}</span> : null}
        </label>;

    return (
      partOfGroup ? element
        : <div className="btn-group" data-toggle="buttons" style={htmlProps.style}>{element}</div>

    );
  }
}