import React from 'react';
import { ReactComponentContainerProps } from './SrtComponent';
import { SrComponent } from '../BasicTypes';
import { getReport, getTextOutputItems, Summary, SummaryComp, SummaryProps } from './Summary';

export const ReportOutputComp: React.FC<ReactComponentContainerProps<SummaryProps>> = (container) => {
  const props = container.props;
  const items = getTextOutputItems(props.calculatedReport);

  const templateDefaults = container.templateContext.defaults;
  const preFont = templateDefaults.defaultPreFont;
  const normalFontFamily = templateDefaults.reportNormalFont;
  const normalFontSize = templateDefaults.reportNormalTextSize != null
    ? getAbsolutFontSize(templateDefaults.reportNormalTextSize) 
    : null;

         
  const reportElements = getReport(items, {
    pre: preFont,
    normalFont: normalFontFamily,
    normalSize: normalFontSize,
    h1Size:  getAbsolutFontSize(templateDefaults.reportH1TextSize, normalFontSize),
    h2Size:  getAbsolutFontSize(templateDefaults.reportH2TextSize, normalFontSize),
    h3Size:  getAbsolutFontSize(templateDefaults.reportH3TextSize, normalFontSize),
    smallSize:  getAbsolutFontSize(templateDefaults.reportSmallTextSize, normalFontSize),
  });

  return <><div id={props.id}>{reportElements}</div><SummaryComp props={{ ...props, display: false }} context={container.context} templateContext={container.templateContext} functions={container.functions}/></>;
};

export const ReportOutput : SrComponent<SummaryProps> = {
  getCodeSections: Summary.getCodeSections,
  key: 'Report',
  render: (props, context, templateContext, functions) => <ReportOutputComp props={props} context={context} templateContext={templateContext} functions={functions} />,
  // Should not be used for report
  template: '', 
  toolboxName: '',
  schema: undefined,
};

// IDS7 can only handle absolut font-size as an integer and thus we will need to convert any font size given in percent
function getAbsolutFontSize(font: string | number, parentFontSize?: number | null): number | null {
  if (typeof font === 'number') return font;

  const containerSize = parentFontSize ?? 9;
  if (String(font).endsWith('%')) {
    const rel = parseInt(String(font));
    if (isNaN(rel) || !isFinite(rel) || rel <= 0 || rel > 100) return null;
    return containerSize * rel  / 100;
  }
  const val = parseInt(String(font));
  return !isNaN(val) && isFinite(val) && val > 0 && val < 200 ? val : null;
}
