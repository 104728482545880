import React, { useState } from 'react';
import { ControlLabel, FormControl, FormGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { isGenericDataProvider, isInternalProvider } from '../../Helpers/DataproviderHelper';
import { DataproviderServerType } from '../BasicTypes';
import { ExpandableTextarea } from '../Generic/ExpandableTextarea';

export interface DataproviderProps {
  dataprovider: DataproviderServerType;
  onSave: (dataprovider: DataproviderServerType) => void;
  onCancel: () => void;
}

export const Dataprovider: React.FC<DataproviderProps> = (props) => {
  const exampleMockData =
`{
  "testNumber": 3,
  "testString": "Thats good!"
}`;
  const [id, setId] = useState<string>(props.dataprovider.Id);
  const [uid, setUid] = useState<string>(props.dataprovider.Uid);
  const [version, setVersion] = useState<number>(props.dataprovider.Version);
  const [mockData, setMockData] = useState<string>(props.dataprovider.MockData ?? exampleMockData);
  const [error, setError] = useState<string>('');
  const disabledProviderInfo = isInternalProvider(props.dataprovider) || isGenericDataProvider(props.dataprovider);

  const onSave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      JSON.parse(mockData);
      props.onSave({ Id: id, Uid: uid, Version: version, MockData: mockData, DbId: props.dataprovider.DbId });
    } catch (ex) {
      setError(ex.message);
      return false;
    }
  };


  return (
    <form>
      <FormGroup >
        <ControlLabel>Id</ControlLabel>
        <FormControl type="text" value={id} onChange={(ev: any) => setId(ev.currentTarget.value)} disabled={disabledProviderInfo} />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Uid</ControlLabel>
        <FormControl type="text" value={uid} onChange={(ev: any) => setUid(ev.currentTarget.value)} disabled={disabledProviderInfo} />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Version</ControlLabel>
        <FormControl type="number" value={version} onChange={(ev: any) => setVersion(ev.currentTarget.value)} disabled={disabledProviderInfo} />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Test data</ControlLabel>
        <ExpandableTextarea className="form-control" rows={10} value={mockData} onChange={(ev: any) => setMockData(ev.currentTarget.value)} disabled={isGenericDataProvider(props.dataprovider)} />
      </FormGroup>
      {error.length ? 
        <OverlayTrigger trigger="click" placement="top" overlay={<Popover id="test-data-error"><p>{error}</p></Popover>}>
          <p>Invalid Test data JSON. (Click here to see exception)</p>
        </OverlayTrigger>
        : null}
      <button className="btn btn-primary" onClick={e => onSave(e)}>Save</button>
      <button className="btn btn-secondary" style={{ marginLeft: '1rem' }} onClick={() => props.onCancel()}>Cancel</button>
    </form>
  );
};