/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { SrComponent, SrComponentPropsBase, SrtMandatoryFieldsDisplay, TemplateContext, UserInputSetterType } from '../BasicTypes';
import { ReactComponentContainerProps } from './SrtComponent';
import { IsEmptySingle } from './ScriptHelperMethods';
import { useEffect, useState } from 'react';
import { applogger } from '../../applogger';

export interface MandatoryFieldProps extends SrComponentPropsBase {
  displayType: SrtMandatoryFieldsDisplay;
}

interface EmptyFieldInfo {
  id: string;
  name: string;
  level: 'warning' | 'error';
}

export const MandatoryFieldsComponent: React.FC<ReactComponentContainerProps<MandatoryFieldProps>> = (container) => {
  const [emptyFields, setEmptyFields] = useState([] as EmptyFieldInfo[]);
  useEffect(() => {
    const mandatoryFields = document.querySelectorAll('[data-field-completion-action]');
    const fields = [] as EmptyFieldInfo[];
    for (let i = 0; i < mandatoryFields.length; ++i) {
      const field = mandatoryFields[i] as HTMLInputElement;
      const id = field.dataset.srcComponentId ?? field.id;
      const isEmpty = field.dataset.isEmpty != null
        ? field.dataset.isEmpty === 'true'
        : IsEmptySingle(field.value);

      if (id != null && isEmpty) {
        fields.push({ id: id, name: field.name, level: field.dataset.fieldCompletionAction === 'prohibit' ? 'error' : 'warning' });
      }
    }

    if (!IsEqualEmptyFields(fields, emptyFields)) {
      setEmptyFields(fields);
    }
  });

  const props = container.props;
  if (props.display === false) {
    return null;
  }

  const items: JSX.Element[] = [];
  const alertFields = props.displayType !== 'prohibit-only' ? emptyFields.filter(x => x.level === 'warning') : [];
  if (alertFields.length > 0) {
    items.push(
        <div key="alert" className="fields-alert">
            <span>{container.templateContext.defaults.mandatoryAlertFieldsText} </span>
            <ul>
            {alertFields.map((x, i) =>
                <li key={x.id} ><a href="#" onClick={(e)=>TryNavigate(e, x.id, container.functions.setUserInput, container.templateContext)}>{x.name}</a>{i != alertFields.length - 1 ? <span>, </span> : null}</li>)}
            </ul>
        </div>);
  }

  const prohibitFields =  emptyFields.filter(x => x.level === 'error');
  if (prohibitFields.length > 0) {
    items.push(
        <div key="prohibit" className="fields-prohibit">
            <span>{container.templateContext.defaults.mandatoryProhibitFieldsText} </span>
            <ul>
            {prohibitFields.map((x, i) =>
                <li key={x.id}><a href="#" onClick={(e)=>TryNavigate(e, x.id, container.functions.setUserInput, container.templateContext)}>{x.name}</a>{i != prohibitFields.length - 1 ? <span>, </span> : null}</li>)}
            </ul>
        </div>);
  }

  if (prohibitFields.length === 0 && alertFields.length === 0) {
    return null;
  }

  return <div id={props.id} className="sectra-mandatory-fields">
        {items}
    </div>;
};

export const MandatoryFields : SrComponent<MandatoryFieldProps> = {
  key: 'MandatoryFields',
  render: (props, context, templateContext, functions) => <MandatoryFieldsComponent props={props} context={context} templateContext={templateContext} functions={functions} />,
  onStateChangeRunner: (props, set, _get, _rContext, _tContext, rContext) => {
    // trigger render at each state change
    const reason = rContext.extensions.getReason();
    if (reason != null) {
      set(props.id, 'rBit', reason.changeNumber % 2, true);
    }
  },
  // Should not be used manually in spec
  template: '', 
  toolboxName: '',
  schema: undefined,
};

function TryNavigate(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: string, setter: UserInputSetterType, tContext: TemplateContext) {
  e.preventDefault();

  try {
    const componentContainer = (document.querySelector("div[data-component-id='" + id + "']")
            ?? document.querySelector("[data-component-id='" + id + "']")) as HTMLElement;

    // check any dynamic hidden
    let parentContainer: HTMLElement | null = componentContainer.closest('[data-dynamic-hidden]') as HTMLElement;
    while (parentContainer != null) {
      const [hContainerId, hContainerContext] = (parentContainer.dataset.dynamicHidden ?? ',').split(',');
      if (hContainerId == null) break;
      const comp = tContext.runtime.getComponentById(hContainerId);
      const unhideMethod = comp?.component.dynamicUnhide;
      if (unhideMethod != null) {
        unhideMethod(parentContainer, hContainerId, hContainerContext, setter, tContext.componentStore.getStoreValue);
      }

      parentContainer = parentContainer.parentElement?.closest('[data-dynamic-hidden]') ?? null;
    }

    if (componentContainer == null) return;
    componentContainer.scrollIntoView();
    const element = componentContainer.querySelector("[id='" + id + "']") as HTMLElement;
    element?.focus();
  } catch (ex) {
    applogger.warn('Error while trying to show component: ' + id);
  }
}

function IsEqualEmptyFields(a: EmptyFieldInfo[], b: EmptyFieldInfo[]) {
  if (a === b) return true;
  if (a == null || b == null || !Array.isArray(a) || !Array.isArray(b) || a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i].id !== b[i].id) return false;
    if (a[i].name !== b[i].name) return false;
  }
  return true;
}
