import React from 'react';
import { Route, Switch } from 'react-router';
import { FramedTemplateAdmin } from './FramedComponents/FramedTemplateAdmin';
import { routeToPath } from './Helpers/NavigationHelper';
import { Main } from './Main';


export const Root: React.FC = () => {
  return (

  <Switch>
    <Route exact path={routeToPath('/TemplateView')}>
      <FramedTemplateAdmin />
    </Route>
    <Route path="*">
      <Main />
    </Route>
    </Switch>
  );
};