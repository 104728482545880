import React from 'react';
import { ReactComponentContainerProps } from './SrtComponent';
import { expressionPattern, schema } from './Schema';
import { GetRowComponent } from './SectraRow';
import { SectraButtonGroupOverride } from './SectraBaseComponent/SectraButtonGroupOverride'; 
import { INullableOptionValue, IOptionValues, ISingleOptionValue, SectraOptionHelper } from './SectraBaseComponent/SectraOptionBase'; 
import { FormDataOutput } from './FormDataOutput';
import { SchemaOptionsProperty, ISectraOptionsValue, SectraOptionsHelper, ISectraOptionsComplexValue } from './SectraOptionsBase';
import { applogger } from '../../applogger';
import { SrValueComponentPropsBase, SrComponent } from '../BasicTypes';

interface OptionsProps extends SrValueComponentPropsBase {
  options: ISectraOptionsValue[];
  optionsDisableFilter?: RegExp | string | null;
  allowDisabledOptionValue?: boolean;
  prefix?: string;
  suffix?: string;
  size?: string;
  multichoice?: boolean;
  optionLayout: 'fill-auto' | 'fill-equal' | 'stack' | 'no-fill';
  suggestedValue?: string | string[];
  showDescription?: boolean;

  isFocused?: boolean;
  isOverAndDown?: boolean;
}

const componentOptionsSchema = schema.mergeSchemaProps(schema.DefaultSizeSchemaPart, {
  'options': SchemaOptionsProperty,
  'optionsDisableFilter': { 'type': 'string', 'description': 'A regular expression (RegEx) allowing options through setting disable. Only entries matching the expression is disabled (note: if null or undefined no filtering is applied and property value can be a Sectra Forms expression returning different filters based on current state)' },
  'allowDisabledOptionValue': { 'type': ['string', 'boolean'], 'description': 'Whether to allow a disabled option value or not (default: true)', 'pattern': expressionPattern },
  'value': { 'type': ['boolean', 'number', 'string', 'array'], 'description': schema.ValueDescription },
  'suggestedValue': { type: ['number', 'string'], 'description': "Suggested value, fixed or calculated value (start with '=', JavaScript one liner) i.e. floor(ComponentID + 3)" },
  'prefix': schema.PropDefinition.prefix,
  'prefixStyle': schema.PropDefinition.prefixStyle,
  'suffix': schema.PropDefinition.suffix,
  'suffixStyle': schema.PropDefinition.suffixStyle,
  'optionLayout': { 'type': 'string', 'enum': ['fill-auto', 'fill-equal', 'stack', 'no-fill'], 'enumDescription': ['Automatically distribute available space to best respond to content size (default)', 'Distribute available space equally among options (might result in text outside the option button)', 'Rendered in a column instead of a row', 'Not filling, i.e. using minimal space'], 'description': 'Option size setting (default: fill-auto)' },
  'multichoice': { 'type': ['string', 'boolean'], 'description': 'Whether to allow multiple choices or not (default: false)', 'pattern': expressionPattern },
  'showDescription': { 'type': ['string', 'boolean'], 'description': 'Whether to show option descriptions bellow selected options or not (default: false for multichoice options otherwise default is true)', 'pattern': expressionPattern },
});

// The React options component
const SectraOptionsComponent: React.FC<ReactComponentContainerProps<OptionsProps>> = (container) => {
  const props = container.props;
  const options = SectraOptionsHelper.getOptionsWithDisabledFilter(props.options, props.optionsDisableFilter);
  const buttonValues = SectraOptionsHelper.getValues(options, true);
  const validButtonValues = SectraOptionsHelper.getValues(options, props.allowDisabledOptionValue !== false);
  const buttonTexts = SectraOptionsHelper.getTexts(options);
  const checkedButton = SectraOptionHelper.normalizeValue(props.value, validButtonValues, props.multichoice);
  const suggestedValue = SectraOptionHelper.normalizeValue(props.suggestedValue, validButtonValues, props.multichoice);

  const setNewValue = (newValue: INullableOptionValue, addedValue: ISingleOptionValue | undefined) => {
    applogger.debug('setting new value: ', newValue, addedValue);
    newValue = SectraOptionHelper.normalizeValue(newValue, validButtonValues, props.multichoice);
        
    if (props.multichoice === true && addedValue != null) {
      // check whether the added option is an exclusive option, then only allow this one
      if ((options[buttonValues.indexOf(addedValue)] as ISectraOptionsComplexValue)?.exclusive === true) {
        newValue = addedValue;
      } else if (Array.isArray(newValue)) {
        // filter out all exclusive values from newValue
        newValue = SectraOptionHelper.normalizeValue(newValue
          .filter(val => (options[buttonValues.indexOf(val)] as ISectraOptionsComplexValue)?.exclusive !== true), buttonValues, props.multichoice);
      }
    }
        
    container.functions.setUserInput(props.id, 'value', newValue);
  };
    
  const setFocus = (focus: boolean) => {
    container.functions.setUserInput(props.id, 'isFocused', focus ? focus : undefined, false);
  };
  const setMouseIsOverAndDown = (isOverAndDown: boolean) => {
    container.functions.setUserInput(props.id, 'isOverAndDown', isOverAndDown ? isOverAndDown : undefined, false);
  };
    
  return GetRowComponent(props, container.context, container.templateContext, <>
        <FormDataOutput id={props.id} value={props.value} name={props.name ?? props.inherritedLabel ?? props.id} mandatory={props.mandatory}
            worklistAttribute={props.worklistAttribute} freeField={props.freeField} />
        <SectraButtonGroupOverride
            id={props.id}
            name={props.name ?? props.inherritedLabel ?? props.id}
            onStateChange={setNewValue}
            buttonValues={buttonValues}
            buttonTexts={buttonTexts}
            optionLayout={props.optionLayout}
            checkedButton={checkedButton}
            markedButton={suggestedValue}
            onFocus={()=>setFocus(true)}
            onBlur={()=>setFocus(false)}
            onMouseDown={e => e.button !== 0 || setMouseIsOverAndDown(true)} /* set true if left button is down */
            onMouseUp={()=>setMouseIsOverAndDown(false)}
            onMouseOver={e=> (e.buttons !== 3 && e.buttons !== 1) || setMouseIsOverAndDown(true)} /* set true if left button is down on enter */
            onMouseOut={()=>setMouseIsOverAndDown(false)}
            descriptions={SectraOptionsHelper.getDescriptions(options)}
            showDescription={props.showDescription ?? props.multichoice !== true}
            style={{ maxWidth: '100%' }}
            disabled={props.disabled === true}
            disabledOptions={SectraOptionsHelper.getDisabled(options)}
            preventOutput={true}
            allowMultiple={props.multichoice === true} />
    </>, 'sectra-options' + (props.multichoice === true ? ' multichoice' : '') + (props.isFocused === true || props.isOverAndDown ===  true ? ' focused' : ''));
};

//
// The options component
//

const optionComponentKey = 'Option';
export const Options : SrComponent<OptionsProps> = {
  key: optionComponentKey,
  render: (props, context, templateContext, functions) => <SectraOptionsComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: () => Promise.resolve(`- ${optionComponentKey}:
    id: option${schema.getNextIdNum()}
    size: l
    options:
    - Malignant
    - Benign`),
  toolboxName: optionComponentKey,
  schema: schema.getSchema(optionComponentKey, componentOptionsSchema, ['options']),
  getInitValues: () => ({ 'value': null }),
  onStateChangeRunner: (props, set) => {
    const options = SectraOptionsHelper.getOptionsWithDisabledFilter(props.options, props.optionsDisableFilter);
    const buttonValues = SectraOptionsHelper.getValues(options, true);
    const validButtonValues = SectraOptionsHelper.getValues(options, props.allowDisabledOptionValue !== false);
    const normalizedValue = SectraOptionHelper.normalizeValue(props.value, validButtonValues, props.multichoice);

    // setup display value and description properties
    let dpValue: string | string[] | undefined = undefined;
    let description: string | string[] | undefined = undefined;
    if (SectraOptionHelper.isSingleValue(normalizedValue)) {
      const singleValue = normalizedValue as ISingleOptionValue;
      const option = (options[buttonValues.indexOf(singleValue)] as ISectraOptionsComplexValue | undefined);
      dpValue = option?.text ?? singleValue.toString();
      description = option?.description ?? dpValue;
    } else if (Array.isArray(normalizedValue)) {
      const arrValue = normalizedValue as IOptionValues;
      const arrItems = arrValue
        .map(v => {
          const option = v != null ? (options[buttonValues.indexOf(v)] as ISectraOptionsComplexValue | undefined) : null;
          const display = option?.text ?? v?.toString() ?? '';
          return {
            display: display,
            description: option?.description ?? display,
          };
        });

      dpValue = arrItems.map(x => x.display);
      description = arrItems.map(x => x.description);
    }

    set(props.id, 'value', normalizedValue, false);
    set(props.id, 'displayValue', dpValue, 'force');
    set(props.id, 'description', description, 'force');
  },
};
