import React from 'react';
import { ReactComponentContainerProps, shouldDisplay } from './SrtComponent';
import { SrComponent, SrValueComponentPropsBase, ComponentRenderContext, CompositeActionItem, CodeSectionPart, DeleteItem } from '../BasicTypes';
import { schema } from './Schema';
import { SrtContainer } from './SrtContainer';
import { Col, Grid, Row } from 'react-bootstrap';
import { dynamicContextVariable } from '../SrtTemplateSpec';
import { TextAndCondition } from './Summary';
import { FormDataOutput } from './FormDataOutput';
import { GetterType, ExplicitSetterType } from '../BasicTypes';
import { applySmartCodeSectionFilter, codeSectionPartsToText } from '../CodeSections';
import { usePrevious } from '../ReactExt';
import { getLabelColSizeFromSplit } from './SectraRow';

interface ListProps extends SrValueComponentPropsBase {
  itemName: string;
  addButtonText?: string;
  numItems?: number;
  collapsable?: boolean | 'hide';
  collapseSummary?: string;
  collapseSummaryAutoFilter?: boolean;
  minCount?: number;
  maxCount?: number;
  reportSummary?: TextAndCondition[];
  reportSummarySeparator?: string;
  reportSummarySmartFilterStyle?: boolean;

  // internal property handling the list state
  collapsableItemIsOpen?: boolean[];
  collapseSummaryResult: CodeSectionPart[][]; // collapseSummary is set into this property, see code section SrComponent-declaration below
  reportSummaryResult: string[];
}

const listComponentKey = 'List';
const listComponentSchema = schema.mergeSchemaProps(schema.DefaultComponentSchemaPart, {
  'id': { 'type': 'string', 'description': 'The id of the element' },
  'itemName': { 'type': ['string', 'null'], 'description': 'Name of each item, available variables are: {index}, {zindex} and {count}' }, 
  'addButtonText': { 'type': ['string', 'null'], 'description': 'Text displayed on the add button' }, 
  'collapsable': { 
    'anyOf': [
      { 'type': 'boolean', 'enumDescription': ['Section is collapsable (default), where an optional summary (_collapseSummary_) is shown in the collapsed state', 'List sections cannot collapse'] },
      { 'type': 'string', 'enum': ['hide'], 'enumDescription': ["Don't show the header at all"] },
    ],
    'description': 'Whether list items should be collapsable or not, or be hidden all together (default true)',
  },
  'collapseSummary': { 'type': 'string', 'description': 'The list item summary shown in collapsed mode. This property value is treated as a report text property.' },
  'collapseSummaryAutoFilter': { 'type': 'boolean', 'enumDescription': ['Apply smart filtering (default)', 'No smart filtering'], 'description': 'Automatically filter (smart filter style) the summary item list for empty values when on the form label: value, label: value, ... (default: true)' },
  'numItems': { 'type': ['string', 'integer'], 'description': 'Number of entries in list at initialization (default 0)' }, 
  'minCount': { 'type': ['string', 'integer'], 'description': 'Number of entries minimum allowed in the list (default 0)' }, 
  'maxCount': { 'type': ['string', 'integer'], 'description': 'Number of entries maximum allowed in the list (default no limit)' }, 
  'display': schema.PropDefinition.display,
  'reportSummary': {
    'type': 'array',
    'items': {
      'type': 'object',
      'required': ['text'],
      'properties': {
        'text': { 'type': 'string', 'description': 'Text to go into the report. If multiple they will be appended.' },
        'if': { 'type': 'string', 'description': 'If the text should be included or not' },
        'separator': { 'type': 'string', 'description': 'The separator to have between this text item and the next. Defaults to newline' },
        'smartFilter': { 'type': 'boolean', 'enumDescription': ['Apply smart filtering (default) for this report summary item', 'No smart filtering for this report summary item'], 'description': 'Use smart filter style for this report summary item (overrides any setting from _reportSummarySmartFilterStyle_, default: use _reportSummarySmartFilterStyle_ setting)' },
      },
    },
    // "minItems": 0,
    'description': 'The text to go to the report',
  },
  'reportSummarySeparator': { 'type': 'string', 'description': 'The separator used between each list item in the report: (default: newline)' },
  'reportSummarySmartFilterStyle': { 'type': 'boolean', 'enumDescription': ['Apply smart filtering (default)', 'No smart filtering'], 'description': 'Use smart filter style for this report summary (default: true)' },
});

export const ListReactComponent: React.FC<ReactComponentContainerProps<ListProps>> = (container) => {
  const props = container.props;
  const prefixBase = getPrefixBase(container.context, props);

  const numItems = getNumItems(props);
  const collapsableItemIsOpen = props.collapsableItemIsOpen != null ? [...props.collapsableItemIsOpen] : new Array(numItems).fill(true);

  const removeItem = (idx: number) => {
    const actions: CompositeActionItem[] = [];
    const ids = container.functions.getAllIds().filter(id=>id.startsWith(prefixBase));

    if (idx < numItems - 1) {
      // Since we're not removing the list item in the list we'll have to handle some state copying..
      let toCopy: { sourceCompId: string, destCompId: string }[] = [];

      for (let i = idx + 1; i < numItems; ++i) {
        // we need to copy props from index i=>(i-1), thus prefixed as (i+1)=>(i)
        let sourceIdBase = getIdPrefixForChild(prefixBase, i + 1);
        let targetIdBase = getIdPrefixForChild(prefixBase, i);
        ids.filter(id => id.startsWith(sourceIdBase)).forEach(sourceId => {
          let targetId = targetIdBase + sourceId.substr(sourceIdBase.length);
          toCopy.push({ sourceCompId: sourceId, destCompId: targetId });
        });
      }

      if (toCopy.length > 0) {
        actions.push(toCopy);
      }
    }

    // we're dropping the last list item, setup all child ids for deletion
    const lastItemPrefix = prefixBase + numItems.toString();
    const deleteItems = ids.filter(id => id.startsWith(lastItemPrefix)).map(compId => ({ compId } as DeleteItem));
    if (deleteItems.length > 0) {
      actions.push(deleteItems);
    }

    // update state
    collapsableItemIsOpen.splice(idx, 1);
    actions.push({ componentId: props.id, propName: 'numItems', value: numItems - 1 });
    actions.push({ componentId: props.id, propName: 'collapsableItemIsOpen', value: collapsableItemIsOpen });
    container.functions.compositeUpdate(actions);
  };

  const addNewItem = () => {
    collapsableItemIsOpen.push(true);
    container.functions.compositeUpdate([
      { componentId: props.id, propName: 'numItems', value: numItems + 1 },
      { componentId: props.id, propName: 'collapsableItemIsOpen', value: collapsableItemIsOpen },
    ]);
  };

  const setItemStateIsOpen = (idx: number, open: boolean) => {
    collapsableItemIsOpen[idx] = open;
    container.functions.setUserInput(props.id, 'collapsableItemIsOpen', collapsableItemIsOpen);
  };

  // handle a programatic reduce of items and clean-up scope
  const prevNumItems = usePrevious(numItems) ?? numItems;

  if (!shouldDisplay(props.display, container.context)) {
    return null;
  }

  if (container.context.listCount != null) {
    // already in a list! not tested thus until actually needed lets disallow it. the painter for instance will assume it can use source id for the image when running in communicating mode
    // if to enable one must also consider handling get with a dynamic scope
    return <div>{'List within lists not supported'}</div>;
  }

  if (prevNumItems > numItems) {
    const ids = container.functions.getAllIds().filter(id=>id.startsWith(prefixBase));
    const idsToDelete: string[] = [];
    for (let i = prevNumItems; i > numItems; --i) {
      const clearWithPrefix = prefixBase + i.toString();
      idsToDelete.push(...ids.filter(id => id.startsWith(clearWithPrefix)));
    }
    if (idsToDelete.length > 0) {
      setTimeout(() => container.functions.deleteComponentValues(idsToDelete), 0);
    }
  }
    
  // whether the number of items are set by an expression or not (if set by expression the user should not be able to add/remove items)
  const numItemsByExpression = container.context.currentParsedComponent.expresionByPropName.numItems != null;
  const templateDefaults = container.templateContext.defaults;

  // build child components from template
  const listItems: JSX.Element[] = [];
  for (let li = 0; li < numItems; ++li) {
    const prefix = getIdPrefixForChild(prefixBase, li + 1); // 1-indexed children //prefixBase + (li + 1).toString();
    const baseItemName = props.itemName !== undefined ? props.itemName : templateDefaults.listSectionItemName;
    const itemName = String(baseItemName ?? '')
      .replace('{index}', (li + 1).toString())
      .replace('{count}', numItems.toString())
      .replace('{zindex}', li.toString());

    const showCollapsableSection = props.collapsable !== 'hide';
    const collapsable = showCollapsableSection && props.collapsable !== false;
    const itemOpen = !collapsable || (collapsableItemIsOpen[li] !== false);
        
    // the child should have the same render context as me, except that its prefix is unique, and it has a listIndex
    const renderContext: ComponentRenderContext = {
      ...container.context, 
      prefix: prefix, 
      prefixBase: prefixBase, 
      listIndex: li, 
      listCount: numItems,
    };

    const labelColSize = getLabelColSizeFromSplit(templateDefaults.defaultColSplit);
    listItems.push(<li key={li} className={(itemOpen ? 'open' : 'summary')} data-dynamic-hidden={!itemOpen ? props.id + ',' + li : undefined}>
            <Grid className={'list-header'}>
                <Row className="show-grid" style={collapsable ? { 'cursor' : 'pointer' } : {}} onClick={collapsable ? () => setItemStateIsOpen(li, !itemOpen) : undefined}>
                    <Col xs={labelColSize}>
                        {showCollapsableSection
                          ? <>
                                {collapsable
                                  ? <button className="btn btn-link chevron"><span>{itemName}</span><svg className={'svg-icon list-collapse'}><use xlinkHref={itemOpen ? '#icon-ListCollapse' : '#icon-ListExpand'}></use></svg></button>
                                  : <span style={{ opacity: .75, verticalAlign: 'middle', fontSize: '9pt' }}>{itemName}</span>
                                }
                            </> : null}
                    </Col>
                    <Col xs={12 - labelColSize}>
                        {!numItemsByExpression && itemOpen && numItems > (props.minCount ?? 0) 
                          ? <button className="btn btn-link chevron delete" onClick={e => { removeItem(li); e.stopPropagation(); }}>
                                     <svg className={'svg-icon delete'}><use xlinkHref={'#icon-ListDelete'}></use></svg>
                                </button>
                          : null}
                        
                        {!itemOpen ? <div className={'list-summary'}>{getSummaryHtml(props.collapseSummaryResult, li)}</div> : null}
                    </Col>
                </Row>
            </Grid>
            <div className={'list-components'}>
                <SrtContainer parentId={props.id} renderContext={renderContext} templateContext={container.templateContext} functions={container.functions} />
            </div>
        </li>);
  }

  const addButtonText = props.addButtonText !== undefined ? props.addButtonText : templateDefaults.listSectionAddButtonText;
  return <>
        <FormDataOutput id={props.id} worklistAttribute={props.worklistAttribute} freeField={props.freeField} value={numItems} />
        { numItems > 0 ? <ul className={'container sectra-component-list'} data-component-id={props.id}>{listItems}</ul> : null }
        {!numItemsByExpression && addButtonText != null ?
        <div className={'container sectra-component-list-add' + (numItems === 0 ? ' empty' : '')}>
            <button className={'btn btn-click btn-secondary'} onClick={() => addNewItem()} disabled={props.maxCount != null && numItems >= props.maxCount}>{addButtonText}</button>
        </div>
          : null}
    </>;
};

export const ListSection : SrComponent<ListProps> = {
  key: listComponentKey,
  render: (props, context, templateContext, functions) => <ListReactComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  getCodeSections: (yamlProps) => [
    // Code section for handling collapse summary
    {
      executeInParentDyanmicContext: true,
      codeSections: [{ sectionText: yamlProps.collapseSummary ?? '' }], // the full summary text row from yaml i.e. "{Size.label}: {Size}, {SizeComment.label}: {SizeComment}"
      propNameRead: 'collapseSummary',
      propNameToSet: 'collapseSummaryResult', // the property that should be set after run of code (in our case CodeSectionPart[][])
      // called render time. For ListSection this is to run the yamlCode expressions (i.e. collapseSummary) for all list items (since they are dynamically added)
      customEvaluate: (renderTimeProps, prefixBase, expressionRunner, _get, _set, templateContext) => {
        if (!shouldDisplay(renderTimeProps.display, templateContext.runtime.getRenderContextByComponentId(renderTimeProps.id))) {
          return;
        }
                
        // run this expression for each list item
        const count = getNumItems(renderTimeProps);
        let ret: CodeSectionPart[][] = new Array(count);
        for (let i = 0; i < count; ++i) {
          const parts = expressionRunner(prefixBase + renderTimeProps.id + (i + 1).toString());
          const filteredParts = applySmartCodeSectionFilter(parts, renderTimeProps.collapseSummaryAutoFilter !== false);
          ret[i] = filteredParts;
        }

        return ret;
      },
    },
    // Code section for handling report summary
    {
      executeInParentDyanmicContext: true,
      codeSections: yamlProps.reportSummary?.map(tnc => {
        return {
          sectionText: tnc.text,
          condition: tnc.if,
          codeSectionSpacerString: tnc.separator ?? '\n',
          smartFilter: tnc.smartFilter,
        };
        // Fallback to collapse summary if no report summary is defined
      }) ?? (yamlProps.collapseSummary != null 
        ? [{ sectionText: yamlProps.collapseSummary }] 
        : []),
      propNameRead: yamlProps.reportSummary != null ? 'reportSummary' : 'collapseSummary',
      propNameToSet: 'reportSummaryResult',
      customEvaluate: (renderTimeProps, prefixBase, expressionRunner, _get, _set, templateContext) => {
        const context = templateContext.runtime.getRenderContextByComponentId(renderTimeProps.id);
        if (!shouldDisplay(renderTimeProps.display, context)) {
          return;
        }

        // run this expression for each list item
        const count = getNumItems(renderTimeProps);
        let ret: string[] = [];
        for (let i = 0; i < count; ++i) {
          const parts = expressionRunner(prefixBase + renderTimeProps.id + (i + 1).toString());
          const filteredParts = applySmartCodeSectionFilter(parts, renderTimeProps.reportSummarySmartFilterStyle !== false);
          ret.push(codeSectionPartsToText(filteredParts, templateContext.langCode));
        }

        return ret;
      },
    },
  ],
  template: ()=>Promise.resolve(`- ${listComponentKey}:
    id: list${schema.getNextIdNum()}
    components:
    - Text:
        id: text${schema.getNextIdNum()}
    - Number:
        id: number${schema.getNextIdNum()}
    `),
  dynamicChildScope: true,
  toolboxName: listComponentKey,
  schema: schema.getSchema(listComponentKey, listComponentSchema, ['id'], false),
  onStateChangeRunner: onStateChangeRunner,
  dynamicUnhide: (node, id, context, setter, getter) => {
    // uncollapse list item so that the consequent focus will work
    node.className = 'open';

    // update state reflecting the uncollapse of this list item
    const index = Number(context);
    const collapsableItemIsOpen = getter(id, 'collapsableItemIsOpen') as boolean[];
    if (collapsableItemIsOpen != null && Array.isArray(collapsableItemIsOpen) && index >= 0 && !isNaN(index)) {
      let newItems = [...collapsableItemIsOpen];
      newItems[index] = true;
      setter(id, 'collapsableItemIsOpen', newItems);
    }
  },
};

export function getSiblingIds(prefixBase: string, sourceId: string, listCount: number): string[] {
  let keys: string[] = [];
  for (let i = 0; i < listCount; ++i) {
    let id = prefixBase + (i + 1).toString() + sourceId;
    keys.push(id);
  }
  return keys;
}

function getPrefixBase(context: ComponentRenderContext, props: ListProps) {
  return (context.prefix ? context.prefix : '') + props.id;
}

function getIdPrefixForChild(prefixBase: string, itemNo: number) {
  return prefixBase + itemNo.toString();
}

function onStateChangeRunner(props: ListProps, set: ExplicitSetterType, get: GetterType, context: ComponentRenderContext) {
  const numItems = getNumItems(props);
  set(props.id, 'count', numItems, true);

  if (context != null) {
    let prefixBase = getPrefixBase(context, props);
    for (let i = 0; i < numItems; ++i) {
      let prefix = getIdPrefixForChild(prefixBase, i + 1);
      let id = prefix + dynamicContextVariable;
      set(id, 'value', i + 1, true);
      set(id, 'index', i + 1, true);
      set(id, 'zindex', i, true);
      set(id, 'count', numItems, true);
      set(id, 'prefix', prefix, true);
      set(id, 'prefixBase', prefixBase, true);
    }
  }

  let collapseSummary = '';
  if (props.collapseSummaryResult) {
    const summaryParts: string[] = [];
    for (let i = 0; i < numItems; ++i) {
      var text = getSummaryText(props.collapseSummaryResult, i);
      summaryParts.push(text);
    }
    collapseSummary = summaryParts.join('\n');
  }

  const separator = props.reportSummarySeparator != null 
    ? String(props.reportSummarySeparator).replace(/newline/g, '\n') 
    : '\n';

  set(props.id, 'collapseSummary', collapseSummary, false);
  set(props.id, 'value', props.reportSummaryResult?.join(separator), true);
}

function getNumItems(props: ListProps) {
  const numItems = props?.numItems ?? props?.minCount ?? 0;
  return !isNaN(numItems) ? numItems : 0;
}

function getSummaryHtml(summaryParts: CodeSectionPart[][], idx: number): JSX.Element[] | null {
  const itemParts = summaryParts[idx];
  if (!Array.isArray(itemParts)) {
    return null;
  }

  return itemParts?.map((p, keyIdx) => p.type === 'value'
    ? <span key={keyIdx++}>{p.value}</span>
    : <span key={keyIdx++} className={'info-text'}>{p.value}</span>) ?? null;
}

function getSummaryText(summaryParts: CodeSectionPart[][], idx: number): string {
  const itemParts = summaryParts[idx];
  if (!Array.isArray(itemParts)) {
    return '';
  }

  return itemParts?.map(p => p.value).join('') ?? '';
}