import React from 'react';
import { FormDataOutput } from './FormDataOutput';
import { GetterType, ExplicitSetterType, TemplateContext } from '../BasicTypes';
import { GetRowComponent } from './SectraRow';
import { SrComponent, SrValueComponentPropsBase, ComponentRenderContext } from '../BasicTypes';
import { ReactComponentContainerProps, shouldDisplay } from './SrtComponent';
import { schema } from './Schema';
import { IsEmpty } from './ScriptHelperMethods';
import { NumberAsString } from '../NumberHelper';

// Compontent base props
interface StaticTextProps extends SrValueComponentPropsBase {
  value: any;
  hidden?: boolean;
  formatter?: string;
  emptyFormatter?: string;
  nullFormatter?: string;
  formField?: 'format' | 'value' | 'none';
  valueHighlight?: boolean;
  emptyStyling?: string;
  size?: string | number;
}

const staticTextSchema = schema.mergeSchemaProps(schema.DefaultSizeSchemaPart, {
  'id': { 'type': 'string', 'description': 'The id of the element' },
  'value': { 'type': ['string', 'number', 'boolean', 'null'], 'description': schema.ValueDescription },
  'display': schema.PropDefinition.display,
  'hidden': schema.PropDefinition.hidden,
  'formatter':  { 'type': 'string', 'description': 'An optional value formatter string ({value} is used to refer to the component value)' },
  'emptyFormatter':  { 'type': 'string', 'description': 'An optional value formatter string, for when the value is null or empty string ({value} is used to refer to the component value)' },
  'nullFormatter':  { 'type': 'string', 'description': 'An optional value formatter string, for when the value is null ({value} is used to refer to the component value)' },
  'formField': { 'type': 'string', 'enum': ['format', 'value', 'none'], 'enumDescription': ['Use the formatted string (displayed) as form data (structured information stored)', 'Use the value as form data (structured information stored)', 'No affect on form data (default)'], 'description': 'Optional form data field (hidden, i.e. place value in form data, default: none)' },
  'valueHighlight': { 'type': 'boolean', 'enumDescription': ['Value is highlighted in the formatted text (i.e. {value} reference)', 'No special value styling applied'], 'description' : 'Whether to highlight value or not (default: true)' },
  'emptyStyling': { 'type': 'string', 'enum': ['normal', 'small', 'italic', 'small italic'], 'enumDescription': ['Normal styling', 'Slightly smaller text size is used', 'Italic styling applied', 'Slightly smaller text size and italic styling applied'], 'description': 'Styling used when the value is empty. Default: small italic' },
});

// The React input component
const StaticTextReactComponent: React.FC<ReactComponentContainerProps<StaticTextProps>> = (container) => {
  const props = container.props;
  if (!shouldDisplay(props.display, container.context)) {
    return null;
  }

  // getting display value (note: not stored in a state since it's soley defined by props, i.e. it won't be affected outside of this scope)
  const dv = getDisplayValue(props.value, props.formatter, props.nullFormatter, props.emptyFormatter, props.valueHighlight, props.emptyStyling, container.templateContext.langCode);
  const inner = <>
        {props.formField === 'format' || props.formField === 'value' 
          ? <FormDataOutput id={props.id} value={props.formField === 'format' ? dv.value : props.value}
                worklistAttribute={props.worklistAttribute} freeField={props.freeField} />
          : null }
        {dv.html.length > 0 && props.hidden !== true ? <>{dv.html}</> : null }
    </>;

  return GetRowComponent(props, container.context, container.templateContext, inner, 'sectra-static-text');
};

//
// The static text component
//
const staticTextComponentKey = 'StaticText';
export const StaticText : SrComponent<StaticTextProps> = {
  key: staticTextComponentKey,
  render: (props, context, templateContext, functions) => <StaticTextReactComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: () => Promise.resolve(`- ${staticTextComponentKey}:
    formatter: "Value: {value} unit"
    value: value`),
  toolboxName: staticTextComponentKey,
  schema: schema.getSchema(staticTextComponentKey, staticTextSchema, [], false),
  getInitValues: () => ({ 'value': null }),
  onStateChangeRunner: onStateChangeRunner,
};


function onStateChangeRunner(props: StaticTextProps, set: ExplicitSetterType, _get: GetterType, context: ComponentRenderContext, templateContext: TemplateContext) {
  const dv = getDisplayValue(props.value, props.formatter, props.nullFormatter, props.emptyFormatter, props.valueHighlight, props.emptyStyling, templateContext.langCode);

  // set hidden if the text is empty and we're not having an explicitly defined size
  if (dv.html.length === 0 && props.size != undefined) {
    set(props.id, 'hidden', true, false);
  }
  set(props.id, 'displayValue', dv.value, true);
}

interface StaticTextDisplayValue {
  html: JSX.Element[];
  value: string | null;
}

function getDisplayValue(
  rawValue: any, 
  defaultFormatter: string | undefined, 
  nullFormatter: string | undefined,
  emptyFormatter: string | undefined,
  valueHighlight: boolean | undefined, 
  emptyStyling: string | undefined, 
  langCode: string | undefined): StaticTextDisplayValue {
  if (typeof rawValue === 'number') {
    rawValue = NumberAsString(rawValue, langCode);
  }
    
  // ensure value i string or null
  const value: string | null = rawValue != null 
    ? String(rawValue)
    : null;

  const activeFormatter: string | undefined = (value == null ? nullFormatter : null)
        ?? (IsEmpty(value) ? emptyFormatter : null)
        ?? defaultFormatter;

  const textStyling = IsEmpty(value) ? emptyStyling ?? 'small italic' : null;
  const stylingClass = textStyling != null && textStyling.length > 0 && textStyling !== 'normal' ? ' ' + textStyling : '';

  const formatterValueKeyword = '{value}';
  const displayHtml: JSX.Element[] = [];
  const addValue = (val: string) => { displayHtml.push(<span key={displayHtml.length} className={'inline-text' + (valueHighlight === false ? ' info-text' : '') + stylingClass}>{val}</span>); };
  const addText = (text: string) => { displayHtml.push(<span key={displayHtml.length} className={'inline-text info-text' + stylingClass}>{text}</span>); };

  let displayValue: string | null = null;

  if (activeFormatter != null) {
    let remain = String(activeFormatter);
    while (remain.length > 0) {
      let idx = remain.indexOf(formatterValueKeyword);
      if (idx >= 0) {
        let before = remain.substr(0, idx);
        if (before.length > 0) {
          addText(before);
          displayValue = (displayValue ?? '') + before;
        }
        addValue(value ?? '');
        displayValue = (displayValue ?? '') + value;
        remain = remain.substr(idx + formatterValueKeyword.length);
      } else {
        addText(remain);
        displayValue = (displayValue ?? '') + remain;
        remain = '';
      }
    }
  } else {
    addValue(value ?? '');
    displayValue = value;
  }

  return { html: displayHtml, value: displayValue };
}