import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { SrValueComponentPropsBase, SrComponent } from '../BasicTypes';
import { ReactComponentContainerProps, shouldDisplay  } from './SrtComponent';
import { SchemaProperties, schema, expressionPattern } from './Schema';
import { SectraCheckButton } from './SectraBaseComponent/SectraCheckButtonOverride';
import { FormDataOutput } from './FormDataOutput';
import { GetRowComponent, IsInDirectRowContext } from './SectraRow';
import { checkboxComponentKey } from '../Toolbox/ComponentNames';

interface CheckboxProps extends SrValueComponentPropsBase {
  value: boolean;
  tooltip?: string;
}

const CheckboxComponent: React.FC<ReactComponentContainerProps<CheckboxProps>> = (container) => {
  const props = container.props;
  if (!shouldDisplay(props.display, container.context)) {
    return null;
  }

  let inner = <>
        <FormDataOutput id={props.id} worklistAttribute={props.worklistAttribute} freeField={props.freeField} type="checkbox" value={props.value} />
        <SectraCheckButton
            id={props.id}
            name={props.name ?? props.inherritedLabel ?? props.id}
            value={props.label ?? ''}
            checked={props.value}
            disabled={props.disabled === true}
            description={props.tooltip}
            onStateChange={(b: boolean) => { container.functions.setUserInput(props.id, 'value', b); }} />
    </>;

  if (IsInDirectRowContext(container.context, container.templateContext)) {
    return GetRowComponent(props, container.context, container.templateContext, <div>{inner}</div>, 'sectra-checkbox');
  }

  return <Grid className={'sectra-checkbox'} hidden={props.hidden}>
        <Row className="show-grid fullwidth">
            <Col xs={12}>
                {inner}
            </Col>
        </Row>
    </Grid>;
};

const checkboxProperties: SchemaProperties = {
  'label': { 'type': ['string', 'null'], 'description': 'Checkbox label' }, 
  'tooltip': { 'type': 'string', 'description': 'Text shown when hovering the checkbox' },
  'value': { 'type': ['string', 'boolean'], 'description': 'An initial value for the checkbox', 'enumDescription': ['Checked', 'Not checked (default)'], 'pattern': expressionPattern },
};

export const SectraCheckbox : SrComponent<CheckboxProps> = {
  key: checkboxComponentKey,
  getInitValues: () => ({ 'value': false }),
  render: (props, context, templateContext, functions) => <CheckboxComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: ()=>Promise.resolve(`- ${checkboxComponentKey}:
    id: checkbox${schema.getNextIdNum()}
    label: MyCheckbox
    `),

  toolboxName: checkboxComponentKey,
  schema: schema.getSchema(
    checkboxComponentKey, 
    schema.schemaDropProps(schema.mergeSchemaProps(schema.DefaultInputComponentSchema, checkboxProperties), ['mandatory']), 
    ['id'], 
    false),
};