export type DragEffectType = 'move' | 'none' | 'copy' | 'link';

export interface DropEffectMap {
  sectraAdmin: DropMapFromSite;
  other: DropMapFromSite;
}

export interface DropMapFileType {
  folder: DragEffectType;
  form: DragEffectType;
}

export interface DropMapToSite {
  site: DropMapFileType;
  shared: DropMapFileType;
  sectra: DropMapFileType;
}

export interface DropMapFromSite {
  site: DropMapToSite;
  shared: DropMapToSite;
  sectra: DropMapToSite;
}

// Accessed dropEffectMap[role][from][to][type]
export const dropEffectMap: DropEffectMap = {
  sectraAdmin: {
    site: {
      site: { folder: 'move', form: 'move' },
      shared: { folder: 'none', form: 'none' },
      sectra: { folder: 'none', form: 'copy' },
    },
    shared: {
      site: { folder: 'none', form: 'copy' },
      shared: { folder: 'none', form: 'none' },
      sectra: { folder: 'none', form: 'none' }
    },
    sectra: {
      site: { folder: 'none', form: 'copy' },
      shared: { folder: 'none', form: 'none' },
      sectra: { folder: 'move', form: 'move' }
    },
  },
  other: {
    site: {
      site: { folder: 'move', form: 'move' },
      shared: { folder: 'none', form: 'none' },
      sectra: { folder: 'none', form: 'none' },
    },
    shared: {
      site: { folder: 'none', form: 'copy' },
      shared: { folder: 'none', form: 'none' },
      sectra: { folder: 'none', form: 'none' }
    },
    sectra: {
      site: { folder: 'none', form: 'copy' },
      shared: { folder: 'none', form: 'none' },
      sectra: { folder: 'none', form: 'none' }
    },
  }
}
