/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useState } from 'react';
import { IServerCommunication, SharedTemplate, SrtTemplate, SrtTemplateFolder, UserResponse, UserRole } from '../ServerCommunication';
import { applogger } from '../applogger';
import { GenericTable, GenericTableRowData } from '../Components/Generic/GenericTable';
import { TreeNode, Treeview } from '../Components/Generic/Treeview';
import { AppColorMode } from '../NavigatonBar';
import { SvgSymbol } from '../Components/SvgSymbol';

import './MainTemplateList.scss';
import { Modal } from 'react-bootstrap';
import { parseCreatedDate } from '../Helpers/DateHelper';
import { TemplateVersions } from '../Components/TemplateVersions';
import { SvgRef } from '../Components/Generic/SvgRef';
import { useCookieState } from '../Components/ReactExt';
import { useHistory } from 'react-router-dom';
import { saveAllTemplatesToFile } from '../FileSaveLoad';
import { dropEffectMap } from './DropEffectMap';
import { Guide } from '../Tutorial/Guide';

export interface MainTemplateListProps {
  server: IServerCommunication;
  currentFolders: SrtTemplateFolder[];
  onFolderSelect: (folder: SrtTemplateFolder) => void;
  selectedFolder: SrtTemplateFolder;
  onNavigate: (path: string)=>void;
  refreshCnt?: number;
  appColorMode: AppColorMode;
  onRefresh: () => void;
  onFolderUpdate: (parentId: number, folderName: string, id: number | null) => void;
  onFolderDelete: (id: number) => void;
  user: UserResponse;
  wasLogin: boolean;
  onGuideShow: () => void;
}

interface DraggedType {
  topFolderType: TopFolderType;
  fileType: FileType;
}

type TopFolderType = 'site' | 'shared' | 'sectra';
type FileType = 'form' | 'folder';
const sectraTemplateDataKey = 'sectra/forms/template-folder';
const sectraTemplateDataValue = '1';


export const MainTemplateList: React.FC<MainTemplateListProps> = (props) => {
  const [templates, setTemplatesInternal] = useState<SrtTemplate[]>([]);
  const [sharedTemplates, setSharedTemplates] = useState<SharedTemplate[]>([]);
  const [sharedTemplatesRootNode, setSharedTemplatesRootNode] = useState<TreeNode>();
  const [selectedShared, setSelectedShared] = useState<string | null>();
  const [currentFolderTemplates, setCurrentFolderTemplates] = useState<SrtTemplate[]>();
  const [isAddingFolder, setIsAddingFolderInternal] = useState<boolean>(false);
  const [renamingFolderId, setRenamingFolderIdInternal] = useState<number | null>(null);
  const [renamingFolderName, setRenamingFolderNameInternal] = useState<string | null>(null);
  const [folderNameInputValue, setFolderNameInputValue] = useState<string>(props.selectedFolder.FolderName);
  const [rootFolder, setRootFolder] = useState<SrtTemplateFolder>();
  const [rootNode, setRootNode] = useState<TreeNode>();
  const [sectraRootNodes, setSectraRootNodes] = useState<TreeNode[]>([]);
  const [draggedItem, setDraggedItem] = useState<TreeNode | number | null>(null);
  const [draggedType, setDraggedType] = useState<DraggedType>();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [showVersionModalForTemplate, setShowVersionModalForTemplate] = useState<string | null>(null);
  const [showDeletedTemplates, setShowDeletedTemplates] = useCookieState<boolean>(false, 'sdt');
  const [sectraFolders, setSectraFolders] = useState<SrtTemplateFolder[]>([]);
  const [sectraTemplates, setSectraTemplates] = useState<SrtTemplate[]>([]);
  const [isAddingTopLevelFolder, setIsAddingTopLevelFolder] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (selectedShared == null) {
      setCurrentFolderTemplates(templates?.filter(x => x.FolderId === props.selectedFolder.Id) || []);
      setFolderNameInputValue(props.selectedFolder.FolderName);
    } else if (!isSectraFolder(selectedShared)) {
      setCurrentFolderTemplates(sharedTemplates.filter(x => '' + x.SiteId === selectedShared)
        .map(x => ({ Id: x.Id, Name: x.Name, Created: x.Created, CreatedBy: '', Deleted: false, FolderId: x.SiteId, MaxVersion: x.LatestVersion, SpecVersion: '' } as SrtTemplate)));
    } else {
      setCurrentFolderTemplates(sectraTemplates.filter(x => '' + x.FolderId === selectedShared));
    }
  }, [props.selectedFolder, templates, selectedShared, sectraTemplates]);

  useEffect(() => {
    const rf = props.currentFolders.find(x => x.ParentId === null);
    setRootFolder(rf);
    setRootNode(rf ? generateFolderTree(rf, null, props.currentFolders) : undefined);
  }, [props.currentFolders]);

  useEffect(() => {
    const rootFolders = sectraFolders.filter(x => x.ParentId === null);
    const nodes = rootFolders.map(x => generateFolderTree(x, null, sectraFolders));
    setSectraRootNodes(nodes);
  }, [sectraFolders]);

  useEffect(() => {
    async function getSharedTemplates() {
      const st = await props.server.getSharedTemplates();
      setSharedTemplates(st);
      const sharedRoot: TreeNode = { id: '-1', children: [], parent: null, text: 'Shared Forms' };
      const sharedFolders: TreeNode[] = st.filter((val, i, arr) => arr.map(x => x.SiteId).indexOf(val.SiteId) === i)
        .map(x => { return { id: '' + x.SiteId, children: [], parent: sharedRoot, text: x.SiteName };});
      sharedRoot.children = sharedFolders;
      setSharedTemplatesRootNode(sharedRoot.children.length > 0 ? sharedRoot : undefined);
    }

    async function getSectraFolders() {
      const folders = await props.server.getSectraFolders();
      setSectraFolders(folders);
    }

    getSharedTemplates();
    getSectraFolders();
  }, []);

  function setTemplates(x: SrtTemplate[]) {
    applogger.info('setTemplates');
    setTemplatesInternal(x);
  }

  async function updateTemplateList() {
    try {
      const [siteTemplates, sectraTemplates] = await Promise.all([props.server?.getAllTemplates(), props.server?.getSectraTemplates()]);
      setTemplates(siteTemplates);
      setSectraTemplates(sectraTemplates);
    } catch (ex) {
      applogger.error('Could not fetch forms', ex);
    }
  }
  useEffect(()=>{ updateTemplateList(); }, [props.refreshCnt ?? 0]);

  function deleteTemplate(id: string, isDelete: boolean) {
    try {
      props.server?.deleteTemplate(id, isDelete)
        .then(() => updateTemplateList());
    } catch (ex) {
      applogger.error('Could not delete form from server', ex);
    }
  }

  function onRowClick(rowIndex: number) {
    if (currentFolderTemplates == null) {
      return;
    }
    const template = getTemplate(rowIndex, currentFolderTemplates);
    if (selectedShared == null || isSectraAdminModifyingSectraFolder(selectedShared)) {
      props.onNavigate(template.Id + '/' + template.MaxVersion);
    } else if (rootFolder != null) {
      if (confirm(`Do you want to copy this form into your site root folder (${rootFolder.FolderName})?`)) {
        props.server.copyTemplate(template.Id, rootFolder.Id).then(() => props.onRefresh());
      }
    }
  }

  function onDelete(rowIndex: number, isDelete: boolean) {
    if (currentFolderTemplates) {
      const template = getTemplate(rowIndex, currentFolderTemplates);
      deleteTemplate(template.Id, isDelete);
    }
  }

  function getTemplate(rowIndex: number, givenTemplates: SrtTemplate[]) {
    return showDeletedTemplates ? givenTemplates[rowIndex] : givenTemplates.filter(x => !x.Deleted)[rowIndex];
  }

  function generateFolderTree(folder: SrtTemplateFolder, parent: TreeNode | null, folders: SrtTemplateFolder[]): TreeNode {
    const node: TreeNode = { id: '' + folder.Id, text: folder.FolderName, children: [], parent: parent };
    const children = folders.filter(x => x.ParentId === folder.Id).map(x => generateFolderTree(x, node, folders));
    node.children = children;
    return node;
  }

  function isSectraFolder(folderId: string) {
    return sectraFolders.some(x => '' + x.Id === folderId);
  }

  function isSiteFolder(folderId: string) {
    return props.currentFolders.some(x => '' + x.Id === folderId);
  }

  function isSectraAdminModifyingSectraFolder(folderId: string) {
    return props.user.Role === UserRole.SectraAdmin && isSectraFolder(folderId);
  }

  function setIsAddingFolder() {
    setFolderNameInputValue('New Folder');
    setIsAddingFolderInternal(true);
    setRenamingFolderIdInternal(null);
  }

  function setRenamingFolder(id: number, name: string) {
    if (selectedShared != null) {
      id = parseInt(selectedShared);
      name = 'new name';
    }
    setIsAddingFolderInternal(false);
    setRenamingFolderIdInternal(id);
    setRenamingFolderNameInternal(name);
    setFolderNameInputValue(name);
  }

  function resetFolderSettings() {
    setIsAddingFolderInternal(false);
    setRenamingFolderIdInternal(null);
    setIsAddingTopLevelFolder(false);
  }

  function onFolderSave() {
    if (selectedShared != null || isAddingTopLevelFolder) {
      let parent: number | null = null;
      if (selectedShared != null && isSectraFolder(selectedShared) && !isAddingTopLevelFolder) {
        parent = parseInt(selectedShared);
      }
      props.server.saveSectraFolder(parent, folderNameInputValue, renamingFolderId).then(x => props.server.getSectraFolders().then(f => setSectraFolders(f)));
    } else {
      props.onFolderUpdate(props.selectedFolder.Id, folderNameInputValue, renamingFolderId);
    }
    resetFolderSettings();
  }

  async function onFolderDelete() {
    if (selectedShared != null) {
      await props.server.deleteFolder(parseInt(selectedShared));
      const newSectraFolders = await props.server.getSectraFolders();
      setSelectedShared(null);
      setSectraFolders(newSectraFolders);
      props.onRefresh();
    } else {
      props.onFolderDelete(props.selectedFolder.Id);
    }
  }

  async function onDroppedItem(event: React.DragEvent, droppedItem: TreeNode | number | null, newParent: TreeNode) {
    event.stopPropagation();
    if (event.dataTransfer.getData(sectraTemplateDataKey) !== sectraTemplateDataValue) {
      return;
    }
    if (typeof droppedItem === 'number') {
      if (!currentFolderTemplates) {
        return;
      }
      const template = getTemplate(droppedItem, currentFolderTemplates);
      if (!template?.Id) {
        return;
      }
      if (selectedShared == null) {
        await props.server.moveTemplate(template.Id, parseInt(newParent.id));
      } else {
        await props.server.copyTemplate(template.Id, parseInt(newParent.id));
      }
    } else if (droppedItem !== null && isSiteFolder(droppedItem.id)) {
      if (droppedItem.id === newParent.id || itemExists(droppedItem, parseInt(newParent.id))) {
        return;
      }
      await props.server.moveFolder(parseInt(droppedItem.id), parseInt(newParent.id));
    }
    props.onRefresh();
  }

  async function onSectraFolderDrop(event: React.DragEvent, droppedItem: TreeNode | number | null, newParent: TreeNode) {
    event.stopPropagation();
    if (event.dataTransfer.getData(sectraTemplateDataKey) !== sectraTemplateDataValue) {
      return;
    }
    if (props.user.Role !== UserRole.SectraAdmin || selectedShared != null && !isSectraFolder(selectedShared)) {
      return;
    }
    if (typeof droppedItem === 'number') {
      if (!currentFolderTemplates) {
        return;
      }
      const template = getTemplate(droppedItem, currentFolderTemplates);
      if (!template?.Id) {
        return;
      }
      await props.server.moveTemplate(template.Id, parseInt(newParent.id));
    } else if (droppedItem != null && isSectraFolder(droppedItem.id)) {
      if (droppedItem.id === newParent.id || itemExists(droppedItem, parseInt(newParent.id))) {
        return;
      }
      await props.server.moveFolder(parseInt(droppedItem.id), parseInt(newParent.id));
      const newSectraFolders = await props.server.getSectraFolders();
      setSectraFolders(newSectraFolders);
    }
    props.onRefresh();
  }

  function itemExists(tree: TreeNode, itemId: number) {
    if (parseInt(tree.id) === itemId) {
      return true;
    }
    for (let child of tree.children) {
      if (itemExists(child, itemId)) {
        return true;
      }
    }
    return false;
  }

  function onDragStart(event: React.DragEvent, newDraggedItem: TreeNode | number | null, draggedType: DraggedType) {
    setIsDragging(true);
    setDraggedItem(newDraggedItem);
    setDraggedType(draggedType);
    event.dataTransfer.setData(sectraTemplateDataKey, sectraTemplateDataValue);
    event.dataTransfer.dropEffect = 'move';
    event.stopPropagation();
  }

  async function onVersionButtonClick(rowIndex: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    if (!currentFolderTemplates) return;
    setShowVersionModalForTemplate(getTemplate(rowIndex, currentFolderTemplates).Id);
  }

  function onPermDeletedTemplate() {
    setShowVersionModalForTemplate(null);
    props.onRefresh();
  }

  function selectedSharedIsUnmodifiable() {
    return selectedShared != null && !isSectraAdminModifyingSectraFolder(selectedShared);
  }

  const templateData: GenericTableRowData[] | undefined = currentFolderTemplates
    ?.filter(x => !x.Deleted || showDeletedTemplates)
    ?.map(x => ({
      values:  [ x.Name, (selectedShared == null ? x.SpecVersion + '.' : '')  + x.MaxVersion, parseCreatedDate(x?.Created ?? null) ],
      titles: [undefined, undefined, 'Version created by ' + (x.CreatedBy ?? 'Unknown')],
      deleted: x.Deleted,
      extraButtons: (selectedShared == null || isSectraAdminModifyingSectraFolder(selectedShared)) ?
        [{ iconId: 'icon-Versions', iconClassName: 'version', onClick: onVersionButtonClick }] : [],
    } as GenericTableRowData));

  return <>
        <Guide server={props.server} wasLogin={props.wasLogin} onGuideShow={props.onGuideShow} />
        <SvgSymbol />
        <div className="MainTemplateList" onDragEnd={ev => setIsDragging(false)}>
            <div key="main">
                <div key="treeView" className={props.appColorMode}>
                    {isAddingFolder || renamingFolderId !== null ?
                        <div className="FolderAdmin">
                            <div className="form-group">
                                <label htmlFor="folder-name">{isAddingFolder ? 'Create new folder' : "Renaming '" + renamingFolderName + "'-folder"}</label>
                                <input autoFocus id="folder-name" autoComplete="off" className="form-control" type="text" value={folderNameInputValue} onChange={e => setFolderNameInputValue(e.currentTarget.value)} />
                                <div>
                                    <button className="btn btn-primary" onClick={onFolderSave}>Save</button>
                                    <button style={{ marginLeft: 10 }} className="btn btn-secondary" onClick={resetFolderSettings}>Cancel</button>
                                </div>
                            </div>
                        </div>
                      : null
                    }
                    <h2>Folders</h2>
                    {rootNode && <Treeview highlightType="local" rootNode={rootNode} onNodeSelect={x => {
                      props.onFolderSelect(props.currentFolders.find(y => '' + y.Id === x) || props.currentFolders[0]);
                      resetFolderSettings();
                      setSelectedShared(null);
                    }} selectedId={selectedShared ? '' : '' + props.selectedFolder.Id}
                    onDragStart={(e, item) => onDragStart(e, item, { topFolderType: 'site', fileType: 'folder' })}
                    onDrop={(e, p) => onDroppedItem(e, draggedItem, p)}
                    onDragOver={e => {
                      e.preventDefault();
                      if (draggedType == null) {
                        e.dataTransfer.dropEffect = 'none';
                        return;
                      }
                      const userRole = props.user.Role === UserRole.SectraAdmin ? 'sectraAdmin' : 'other';
                      e.dataTransfer.dropEffect = dropEffectMap[userRole][draggedType.topFolderType].site[draggedType.fileType];
                    }}
                    disabled={isDragging && draggedType?.fileType === 'folder' && (draggedType.topFolderType === 'sectra' || draggedType?.topFolderType === 'shared')} />}
                    {sharedTemplatesRootNode && <Treeview highlightType="shared" rootNode={sharedTemplatesRootNode} selectedId={'' + selectedShared}
                      onNodeSelect={x => setSelectedShared(x)} onDragStart={(ev, it) => onDragStart(ev, it, { fileType: 'folder', topFolderType: 'shared' })} onDrop={(e) => e.dataTransfer.dropEffect} disabled={isDragging} />}
                    {sectraRootNodes.map(sectraRootNode => <Treeview highlightType="sectra" key={sectraRootNode.id} rootNode={sectraRootNode} selectedId={'' + selectedShared}
                      onNodeSelect={x => setSelectedShared(x)} onDragStart={(e, item) => onDragStart(e, item, { fileType: 'folder', topFolderType: 'sectra' })}
                      disabled={isDragging && (props.user.Role !== UserRole.SectraAdmin ||
                        draggedType?.topFolderType === 'shared' || draggedType?.fileType === 'folder' && draggedType?.topFolderType === 'site')}
                      onDrop={(e, p) => onSectraFolderDrop(e, draggedItem, p)} onDragOver={e => {
                        e.preventDefault();
                        if (draggedType == null) {
                          e.dataTransfer.dropEffect = 'none';
                          return;
                        }
                        const userRole = props.user.Role === UserRole.SectraAdmin ? 'sectraAdmin' : 'other';
                        e.dataTransfer.dropEffect = dropEffectMap[userRole][draggedType.topFolderType].sectra[draggedType.fileType];
                      }} />)}
                </div>
                <div key="templateView" className={props.appColorMode}>
                    <h2>{selectedShared == null ? 'Forms' : 'Shared Forms - Drag\'N\'Drop shared forms into standard folders to make copies.'}</h2>
                    <GenericTable 
                        emptyMessage="No forms exist yet, create one!" 
                        headers={['Name', 'Version', 'Modified']}
                        tableRows={templateData} 
                        onClick={ix => onRowClick(ix)} 
                        onDragStart={(ev, it) => {
                          const draggedTemplate = getTemplate(it, currentFolderTemplates ?? []);
                          if (selectedShared == null) {
                            onDragStart(ev, it, { fileType: 'form', topFolderType: 'site' });
                          } else if (sectraTemplates.some(x => x.Id === draggedTemplate.Id)) {
                            onDragStart(ev, it, { fileType: 'form', topFolderType: 'sectra' });
                          } else {
                            onDragStart(ev, it, { fileType: 'form', topFolderType: 'shared' });
                          }
                        }} 
                        onDelete={(selectedShared == null || isSectraAdminModifyingSectraFolder(selectedShared)) ? ix => onDelete(ix, true) : undefined}
                        onUndo={ix => onDelete(ix, false)} 
                        hiddenXsColumns={[1, 2]}
                    />
                </div>
            </div>
            <div key="toolbox">
                <div key="treeViewToolbox">
                    <div>
                        <div className="form-group">
                            <button className="btn btn-secondary" disabled={selectedSharedIsUnmodifiable()} onClick={() => setIsAddingFolder()}>New</button>
                            <button className="btn btn-secondary" style={{ marginLeft: 10 }} onClick={() => setRenamingFolder(props.selectedFolder.Id, props.selectedFolder.FolderName)}
                                disabled={props.selectedFolder.Id === rootFolder?.Id && selectedShared == null || selectedSharedIsUnmodifiable()}>Rename</button>
                            <button className="btn btn-sm btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => onFolderDelete()}
                                disabled={selectedShared == null && templates.filter(x => x.FolderId === props.selectedFolder.Id && !x.Deleted).length > 0 ||
                                    selectedShared == null && props.currentFolders.filter(x => x.ParentId === props.selectedFolder.Id).length > 0 ||
                                    selectedShared != null && sectraFolders.filter(x => '' + x.ParentId === selectedShared).length > 0 ||
                                    selectedShared == null && props.selectedFolder.ParentId === null || selectedSharedIsUnmodifiable()}>Delete</button>
                            {props.user.Role === UserRole.SectraAdmin ?
                            <button style={{ marginLeft: 10 }} className="btn btn-secondary" onClick={() => {
                              setIsAddingTopLevelFolder(true);
                              setIsAddingFolder();
                            }}>+</button>
                              : undefined}
                        </div>
                    </div>
                </div>
                <div key="templateViewToolbox">
                    <div>
                        <div className="form-group">
                            <button className="btn btn-secondary" disabled={selectedShared != null} onClick={() => props.onNavigate('new')}>New</button>
                            <button className="btn btn-secondary" disabled={selectedShared != null} style={{ marginLeft: 10 }} onClick={() => props.onNavigate('demo')}>Demo</button>
                            <button className="btn btn-secondary" disabled={selectedShared != null} style={{ marginLeft: 10 }} onClick={() => props.onNavigate('fromFile')}>Import from File</button>
                            <span className="svgcheckbox-button pull-right btn" onClick={() => setShowDeletedTemplates(!showDeletedTemplates)}>
                              <span style={{ marginRight: '1rem' }}>Show deleted forms</span>
                              <SvgRef id={showDeletedTemplates ? 'icon-CheckboxChecked' : 'icon-CheckboxUnchecked'} className="svg-icon svgcheckbox" />
                            </span>
                            {props.user.Role >= UserRole.Admin ?
                              <button className="btn btn-secondary pull-right" disabled={selectedShared != null} style={{ marginRight: 10 }}
                                onClick={() => props.server.getAllLatestVersions().then(x => saveAllTemplatesToFile(x))}>Export all forms</button>
                              : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal show={showVersionModalForTemplate !== null} onHide={() => setShowVersionModalForTemplate(null)}>
            <Modal.Header closeButton>
                <Modal.Title>Form versions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TemplateVersions currentTemplateId={showVersionModalForTemplate} deleteTemplateVersions={props.server.deleteTemplateVersions}
                    getTemplateVersionsById={props.server.getTemplateVersionsById} onCurrentTemplateVersionDeleted={onPermDeletedTemplate}
                    pushHistory={url => history.push(url)} />
            </Modal.Body>
        </Modal>
    </>;
};