/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IAnnotationInfo, ICanvasAnnotation, IPosition, MapItem, ModifyResult } from '../SectraCanvasAnnotation';

export class CanvasLineAnnotation implements ICanvasAnnotation {
  protected startPos: IPosition;

  protected endPos: IPosition | null = null;

  protected colorIndex: number;

  protected brushWidth: number;

  protected currentCtx: CanvasRenderingContext2D | null;

  protected lineMoveState: 'line' | 'start' | 'end' = 'line';

  protected maxDistToAcceptAsSamePoint = 6;

  constructor(colorIndex: number, brushWidth: number) {
    this.startPos = { x: 0, y: 0 };
    this.colorIndex = colorIndex;
    this.brushWidth = brushWidth != null && !isNaN(Number(brushWidth)) ? Number(brushWidth) : 2;
    this.currentCtx = null;
  }

  protected addGrabPoint(ctx: CanvasRenderingContext2D, pos: IPosition) {
    const pointMarkerSize = 4;
    ctx.fillRect(pos.x - pointMarkerSize / 2, pos.y - pointMarkerSize / 2, pointMarkerSize, pointMarkerSize);    
  }

  protected addCrossToPath(path: Path2D, pos: IPosition) {
    path.moveTo(pos.x - 2, pos.y - 2);
    path.lineTo(pos.x + 2, pos.y + 2);
    path.moveTo(pos.x - 2, pos.y + 2);
    path.lineTo(pos.x + 2, pos.y - 2);
  }

  protected positionDistance(pos1: IPosition, pos2: IPosition) {
    return Math.sqrt(Math.pow(pos2.x - pos1.x, 2) + Math.pow(pos2.y - pos1.y, 2));
  }

  startMove(pos: IPosition) : ModifyResult{
    if (this.startPos == null || this.endPos == null) {
      this.lineMoveState = 'line';
      return { isValid: true };
    }
    if (this.positionDistance(pos, this.startPos) <= this.maxDistToAcceptAsSamePoint) {
      this.lineMoveState = 'start';
    } else if (this.positionDistance(pos, this.endPos) <= this.maxDistToAcceptAsSamePoint) {
      this.lineMoveState = 'end';
    } else {
      this.lineMoveState = 'line';
    }
    return { isValid: true };
  }

  getType(): string {
    return 'Line';
  }

  setColorIndex(idx: number) { this.colorIndex = idx; }

  getColorIndex(): number { return this.colorIndex; }

  getPoints(): IPosition[] {
    if (this.startPos == null || this.endPos == null) {
      return [];
    }
    return [this.startPos, this.endPos];
  }

  startDraw(ctx: CanvasRenderingContext2D, pos: IPosition, map: MapItem[] | null | undefined): boolean {
    const oldLineWidth = ctx.lineWidth; ctx.lineWidth = this.brushWidth;
    const oldLineJoin = ctx.lineJoin; ctx.lineJoin = 'round';
    const oldLineCap = ctx.lineCap; ctx.lineCap = 'round';

    this.currentCtx = ctx;
    this.startPos = pos;
    //applogger.debug("start line");
    if (this.currentCtx != null) {
      this.currentCtx.beginPath();
      this.currentCtx.moveTo(pos.x, pos.y);
      this.currentCtx.lineTo(pos.x, pos.y);
      this.currentCtx.stroke();
    }

    ctx.lineWidth = oldLineWidth;
    ctx.lineJoin = oldLineJoin;
    ctx.lineCap = oldLineCap;

    return true;
  }

  stopDraw(): ModifyResult {
    return { isValid: this.endPos != null, redraw: true };
  }

  setPoint(pos: IPosition): boolean {
    this.endPos = pos;
    return true;
  }

  setPoints(points: IPosition[]) {
    this.startPos = points[0];
    this.endPos = points[1];
  }

  redrawToCanvas(ctx: CanvasRenderingContext2D, inFocus: boolean, map: MapItem[] | null | undefined): void {
    if (this.endPos == null) {
      return;
    }

    const oldLineWidth = ctx.lineWidth; ctx.lineWidth = this.brushWidth;
    const oldLineJoin = ctx.lineJoin; ctx.lineJoin = 'round';
    const oldLineCap = ctx.lineCap; ctx.lineCap = 'round';
    const path = new Path2D();
    path.moveTo(this.startPos.x, this.startPos.y);
    path.lineTo(this.endPos.x, this.endPos.y);
    if (inFocus) {
      this.addGrabPoint(ctx, this.startPos);
      this.addGrabPoint(ctx, this.endPos);
    }
    ctx.stroke(path);

    ctx.lineWidth = oldLineWidth;
    ctx.lineJoin = oldLineJoin;
    ctx.lineCap = oldLineCap;
  }

  getWithinActionBox(pos: IPosition): boolean {
    if (this.endPos == null) {
      return false;
    }
    let dist = Math.abs((this.endPos.x - this.startPos.x) * (this.startPos.y - pos.y) - (this.startPos.x - pos.x) * (this.endPos.y - this.startPos.y)) /
      Math.sqrt((this.endPos.x - this.startPos.x) * (this.endPos.x - this.startPos.x) + (this.endPos.y - this.startPos.y) * (this.endPos.y - this.startPos.y));
    return dist <= this.maxDistToAcceptAsSamePoint;
  }

  setNewBasePoint(pos: IPosition): boolean {
    if (this.endPos == null) {
      return false;
    }
    if (this.lineMoveState === 'start') {
      this.startPos = pos;
    } else if (this.lineMoveState === 'end') {
      this.endPos = pos;
    } else {
      let centerPoint: IPosition = { x: (this.startPos.x + this.endPos.x) / 2, y: (this.startPos.y + this.endPos.y) / 2 };
      let offsetX = centerPoint.x - pos.x;
      let offsetY = centerPoint.y - pos.y;
      if (offsetX !== 0 || offsetY !== 0) {
        this.startPos.x -= offsetX;
        this.startPos.y -= offsetY;
        this.endPos.x -= offsetX;
        this.endPos.y -= offsetY;
        return true;
      }
    }
    return true;
  }

  getAnnotationInfo(): IAnnotationInfo {
    return {
      type: this.getType(),
      colorIndex: this.getColorIndex(),
      points: this.getPoints(),
      metaData: this.brushWidth,
    };
  }
}