import * as logLevelLogger from 'loglevel';
export const applogger = logLevelLogger;

// setup log level and utilize chrome debug level logging when available (by default debug maps to console.log)
const orgSetLevelMethod = applogger.setLevel;
applogger.setLevel = function () {
  orgSetLevelMethod.apply(applogger, arguments as any);
  if (console.debug && applogger.getLevel() <= 1) {
    applogger.debug = console.debug;
  }
};

applogger.setLevel(process.env.NODE_ENV === 'development' ? 'DEBUG' : 'WARN', true);
window._applogger_ = applogger;