import { Canvas3pCurveAnnotation, Canvas4pCurveAnnotation } from './SectraCanvasAnnotations/CanvasCurveAnnotation';
import { CanvasFillAnnotation } from './SectraCanvasAnnotations/CanvasFillAnnotation';
import { CanvasLineAnnotation } from './SectraCanvasAnnotations/CanvasLineAnnotation';

export interface IAnnotationInfo {
  type: string;
  colorIndex: number;
  points: IPosition[];
  metaData?: any;
}

export type SectorData = { label: string, description: string, count: number }[];

export const CanvasAnnotationTransformer = {
  ToAnnotationInfo: (annotations: ICanvasAnnotation[]): IAnnotationInfo[] => annotations.map(a => a.getAnnotationInfo()),
  FromAnnotationInfo: (items: IAnnotationInfo[], imageResolver?: (name: string) => HTMLImageElement | null): ICanvasAnnotation[] => {
    let ret: ICanvasAnnotation[] = [];
    items.forEach((item: IAnnotationInfo) => {
      switch (item.type) {
        case 'Spray': {
          // Note, we don't care much about spray radius/density since we're readonly at this point and onwards
          let s = new CanvasSpayAnnotation(item.colorIndex, NaN, NaN); 
          s.setData(item);
          ret.push(s);
          break;
        }
        case 'Brush': {
          // We're storing brushSize in meta-data
          let b = new CanvasBrushAnnotation(item.colorIndex, item.metaData);
          b.setPoints(item.points);
          ret.push(b);
          break;
        }
        case 'Line': {
          let l = new CanvasLineAnnotation(item.colorIndex, item.metaData);
          l.setPoints(item.points);
          ret.push(l);
          break;
        }
        case 'FloodFill': {
          let f = new CanvasFillAnnotation(item.colorIndex);
          f.setPoints(item.points);
          ret.push(f);
          break;
        }
        case '4pCurve': {
          let c = new Canvas4pCurveAnnotation(item.colorIndex, item.metaData);
          c.setPoints(item.points);
          ret.push(c);
          break;
        }
        case '3pCurve': {
          let c3 = new Canvas3pCurveAnnotation(item.colorIndex, item.metaData);
          c3.setPoints(item.points);
          ret.push(c3);
          break;
        }
        case 'SectorFill': {
          if (item.points.length > 1) {
            ret.push(new CanvasSectorFillAnnotation(item.colorIndex, item.points));
          }
          break;
        }
        case 'Image': {
          let name = item.metaData;
          let image = name != null && imageResolver != null ? imageResolver(name) : null;
          if (name != null && image != null) {
            let i = new CanvasImageAnnotation(name, item.colorIndex, image);
            i.setPoint(item.points.length > 0 ? item.points[0] : { x: 0, y: 0 });
            ret.push(i);
          }
          break;
        }
      }
    });

    return ret;
  },
  Serialize: (annotations: ICanvasAnnotation[]): string | null => {
    return annotations.length > 0 ? JSON.stringify(CanvasAnnotationTransformer.ToAnnotationInfo(annotations)) : null;
  },
  Deserialize: (data: string | null | undefined, imageResolver?: (name: string) => HTMLImageElement | null): ICanvasAnnotation[] => {
    if (data == null || typeof (data) !== 'string') {
      return [];
    }
    let items = JSON.parse(data) as IAnnotationInfo[];
    if (!items.length) {
      return [];
    }

    return CanvasAnnotationTransformer.FromAnnotationInfo(items, imageResolver);
  },
  Merge: (values: (string | null)[]): string | null => {
    let merge: IAnnotationInfo[] = [];
    for (let i = 0; i < values.length; ++i) {
      let data = values[i];
      if (data == null || typeof (data) !== 'string') {
        continue;
      }
      let items = JSON.parse(data) as IAnnotationInfo[];
      merge = merge.concat(items);
    }

    return merge != null && merge.length > 0 ? JSON.stringify(merge) : null;
  },
};

export interface IPosition {
  x: number;
  y: number;
}

interface IMoveBox {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

export interface MapItem {
  label: string;
  description: string;
  polygon: IPosition[];
}

export type ModifyResult = { redraw?: boolean, isValid?: boolean };

export interface ICanvasAnnotation {
  // type of annotation?
  getType(): string;

  // get sets color index
  getColorIndex(): number;
  setColorIndex(colorIndex: number): void;

  // get all annotation points (used to calculate sector)
  getPoints(): IPosition[];

  // called before starting to draw also setting the first point, returns whether draw is possible or not (false should thus cancel further drawing)
  startDraw(ctx: CanvasRenderingContext2D, pos: IPosition, map: MapItem[] | null | undefined): boolean;

  // sets new point while painting, returns whether redraw is needed
  setPoint(pos: IPosition): boolean; 

  // called once draw is finished, should return whether the current annotation is valid
  stopDraw?(): ModifyResult;

  // re-draw the annotation
  redrawToCanvas(ctx: CanvasRenderingContext2D, inFocus: boolean, map: MapItem[] | null | undefined): void;

  // whether this position is within the "action-box" of this annotation, e.g. we can move
  getWithinActionBox(pos: IPosition): boolean;

  // sets a new base point when moving, returns whether redraw is needed (i.e. typically whether anything was changed)
  setNewBasePoint(pos: IPosition): boolean;

  // dump annotation info to info-structure
  getAnnotationInfo(): IAnnotationInfo;

  // called before move starts (returns if move to position is possible)
  startMove?(pos: IPosition): ModifyResult;

  // called once move ends
  stopMove?(): ModifyResult;

  // returns whether this annotation is valid or not, typically to implement too small to render
  isValid?(): boolean;

  isIncomplete?(): boolean;

  canFocus?(): boolean;
}

export class CanvasSpayAnnotation implements ICanvasAnnotation {
  private colorIndex: number;

  private density: number;

  private radius: number;

  private pBox: IMoveBox | null;

  private points: IPosition[];

  private currentPoint: IPosition;

  private currentCtx: CanvasRenderingContext2D | null;

  private intervalHandle: NodeJS.Timeout | null;

  constructor(colorIndex: number, sprayDensity: number, radius: number) {
    this.colorIndex = colorIndex;
    this.points = [];
    this.pBox = null;
    this.density = sprayDensity != null && !isNaN(Number(sprayDensity)) ? Number(sprayDensity) : 10;
    this.radius = radius != null && !isNaN(Number(radius)) ? Number(radius) : 8;
    this.currentCtx = null;
    this.currentPoint = { x: 0, y: 0 };
    this.intervalHandle = null;
    this.spray = this.spray.bind(this);
  }
    
  isValid(): boolean {
    return this.points != null && this.points.length > 0;
  }

  getType(): string { return 'Spray'; }

  startDraw(ctx: CanvasRenderingContext2D, pos: IPosition): boolean {
    this.currentCtx = ctx;
    this.currentPoint = pos;
    this.intervalHandle = setInterval(this.spray, 20);
    //applogger.debug("start:" + pos);
    return true;
  }

  stopDraw():ModifyResult {
    //applogger.debug("stop");
    if (this.intervalHandle !== null) {
      clearInterval(this.intervalHandle);
      this.currentCtx = null;
    }
        
    return { isValid: this.isValid() };
  }

  setPoint(pos: IPosition): boolean {
    this.currentPoint = pos;
    //applogger.debug("set: " + pos);
    return false;
  }

  redrawToCanvas(ctx: CanvasRenderingContext2D) {
    for (let i = 0; i < this.points.length; ++i) {
      let pos = this.points[i];
      ctx.fillRect(pos.x, pos.y, 1, 1);
    }
  }

  setColorIndex(idx: number) { this.colorIndex = idx; }

  getColorIndex(): number { return this.colorIndex; }

  getPoints(): IPosition[] { return this.points; }

  getWithinActionBox(pos: IPosition): boolean {
    if (this.pBox == null) {
      return false;
    }

    return pos.x >= this.pBox.xMin && pos.x <= this.pBox.xMax && pos.y >= this.pBox.yMin && pos.y <= this.pBox.yMax;
  }

  setNewBasePoint(pos: IPosition) {
    if (this.pBox == null || this.points == null) {
      return false;
    }

    // get current midpoint
    let centerPoint: IPosition = {
      x: this.pBox.xMin + (this.pBox.xMax - this.pBox.xMin) / 2,
      y: this.pBox.yMin + (this.pBox.yMax - this.pBox.yMin) / 2,
    };

    // get offset
    let offsetX = centerPoint.x - pos.x;
    let offsetY = centerPoint.y - pos.y;

    if (offsetX !== 0 || offsetY !== 0) {
      // update all points
      for (let i = 0; i < this.points.length; ++i) {
        this.points[i] = {
          x: this.points[i].x - offsetX,
          y: this.points[i].y - offsetY,
        };
      }
      this.pBox = this.getPointBox();
            
      return true;
    }

    return false;
  }

  setData(ai: IAnnotationInfo) {
    let base = ai.points[0];
    let xPositions: number[] = ai.metaData?.x ?? [];
    let yPositions: number[] = ai.metaData?.y ?? [];
    let points = xPositions.map((x, i) => {
      return { x: base.x + x / 10, y: base.y + (yPositions[i] ?? 0) / 10 };
    });
    this.points = points;
    this.pBox = this.getPointBox(); 
  }

  getAnnotationInfo(): IAnnotationInfo {
    let pBox = this.pBox ?? this.getPointBox();
    let basePoint = {
      x: pBox != null ? Math.round(pBox.xMin) : 0, 
      y: pBox != null ? Math.round(pBox.yMin) : 0,
    };
    let ai = {
      type: this.getType(),
      colorIndex: this.getColorIndex(),
      points: [basePoint],
      metaData: {
        x: this.points.map(p => Math.round((p.x - basePoint.x) * 10)),
        y: this.points.map(p => Math.round((p.y - basePoint.y) * 10)),
      },
    };
        
    return ai;
  }

  private getRandomOffset(): IPosition {
    const radius = this.radius;
    const randomAngle = Math.random() * 360;
    const randomRadius = Math.random() * radius;
    return { x: Math.cos(randomAngle) * randomRadius, y: Math.sin(randomAngle) * randomRadius };
  }

  private spray() {
    //applogger.debug("spray paint");
    for (let i = 0; i < this.density; ++i) {
      const offset = this.getRandomOffset();
      const x = this.currentPoint.x + offset.x, y = this.currentPoint.y + offset.y;
      this.points.push({ x, y });
      this.pBox = this.getPointBox();

      if (this.currentCtx != null) {
        this.currentCtx.fillRect(x, y, 1, 1);
        //applogger.debug("drawinging: " + {x, y});
      }
    }
  }

  private getPointBox(): IMoveBox | null {
    if (this.points == null || this.points.length === 0) {
      return null;
    }
    let fp = this.points[0];
    let ret: IMoveBox = { xMin: fp.x, xMax: fp.x, yMin: fp.y, yMax: fp.y };
    for (let i = 1; i < this.points.length; ++i) {
      let p = this.points[i];
      if (p.x < ret.xMin) ret.xMin = p.x;
      if (p.x > ret.xMax) ret.xMax = p.x;
      if (p.y < ret.yMin) ret.yMin = p.y;
      if (p.y > ret.yMax) ret.yMax = p.y;
    }
    return ret;
  }
}

export const annotationHelper = {
  pointsToPath: (points: IPosition[], closePath?: boolean): Path2D => {
    const path = new Path2D();
    if (!Array.isArray(points) || points.length === 0) {
      return path;
    }

    path.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; ++i) {
      path.lineTo(points[i].x, points[i].y);
    }
    if (closePath === true || points.length === 1) {
      path.closePath();
    }

    return path;
  },

  drawPolyLine: (ctx: CanvasRenderingContext2D, points: IPosition[], closePath?: boolean): Path2D => {
    const path = annotationHelper.pointsToPath(points, closePath);
    ctx.stroke(path);
    return path;
  },

  getSectorsForAnnotations(annotations: ICanvasAnnotation[], map: MapItem[]): SectorData {
    let labels: { [label: string]: number } = {};
    let descLookup: { [label: string]: string } = {};
    for (let i = 0; i < annotations.length; ++i) {
      let a = annotations[i];
      let points = a.getPoints();
      let lastMapIdx: number | null = null;
      for (let j = 0; j < points.length; ++j) {
        var p = points[j];
                
        // very likely that the next point is found in the same sector as before, try that first
        if (lastMapIdx != null) {
          let lastMapItem = map[lastMapIdx];
          if (annotationHelper.insidePolygon(p, lastMapItem.polygon)) {
            labels[lastMapItem.label] = (labels[lastMapItem.label] ?? 0) + 1;
            descLookup[lastMapItem.label] = lastMapItem.description;
            continue;
          }
        }

        // search among all sectors in map
        for (let k = 0; k < map.length; ++k) {
          if (k === lastMapIdx) continue;
          let mapItem = map[k];
          if (annotationHelper.insidePolygon(p, mapItem.polygon)) {
            labels[mapItem.label] = (labels[mapItem.label] ?? 0) + 1;
            descLookup[mapItem.label] = mapItem.description;
            lastMapIdx = k;
            break;
          }
        }
      }
    }
        
    let ret = Object.keys(labels).map(label => {
      return { label: label, count: labels[label], description: descLookup[label] };
    });

    ret.sort((a, b) => a.count < b.count ? 1 : (a.count > b.count) ? -1 : 0);
    return ret;
  },

  insidePolygon: (point: IPosition, vs: IPosition[]): boolean => {
    let x = point.x, y = point.y;

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i].x, yi = vs[i].y;
      var xj = vs[j].x, yj = vs[j].y;

      var intersect = ((yi > y) !== (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }

    return inside;
  },

  lineIntersection: (l1Start: IPosition, l1End: IPosition, l2Start: IPosition, l2End: IPosition): { x: number | null, y: number | null, onLine1: boolean, onLine2: boolean } => {
    // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
    let result: { x: number | null, y: number | null, onLine1: boolean, onLine2: boolean } = {
      x: null,
      y: null,
      onLine1: false,
      onLine2: false,
    };

    let denominator = ((l2End.y - l2Start.y) * (l1End.x - l1Start.x)) - ((l2End.x - l2Start.x) * (l1End.y - l1Start.y));
    if (denominator == 0) {
      return result;
    }

    let a = l1Start.y - l2Start.y;
    let b = l1Start.x - l2Start.x;
    let numerator1 = ((l2End.x - l2Start.x) * a) - ((l2End.y - l2Start.y) * b);
    let numerator2 = ((l1End.x - l1Start.x) * a) - ((l1End.y - l1Start.y) * b);
    a = numerator1 / denominator;
    b = numerator2 / denominator;
    
    // if we cast these lines infinitely in both directions, they intersect here:
    result.x = l1Start.x + (a * (l1End.x - l1Start.x));
    result.y = l1Start.y + (a * (l1End.y - l1Start.y));

    // if line1 is a segment and line2 is infinite, they intersect if:
    if (a > 0 && a < 1) {
      result.onLine1 = true;
    }
    // if line2 is a segment and line1 is infinite, they intersect if:
    if (b > 0 && b < 1) {
      result.onLine2 = true;
    }

    // if line1 and line2 are segments, they intersect if both of the above are true
    return result;
  },

  getDistance: (a: IPosition, b: IPosition) => {
    let xd = a.x - b.x;
    let yd = a.y - b.y;
        
    return Math.sqrt( xd * xd + yd * yd );
  },

  getSlop: (from: IPosition, to: IPosition) => {
    return (from.y - to.y) / (from.x - to.x);
  },

  projectPointToLine: (point: IPosition, lineFrom: IPosition, lineTo:IPosition): IPosition => {
    var atob = { x: lineTo.x - lineFrom.x, y: lineTo.y - lineFrom.y };
    var atop = { x: point.x - lineFrom.x, y: point.y - lineFrom.y };
    var len = atob.x * atob.x + atob.y * atob.y;
    var dot = atop.x * atob.x + atop.y * atob.y;
    var t = Math.min( 1, Math.max( 0, dot / len ) );
    
    dot = ( lineTo.x - lineFrom.x ) * ( point.y - lineFrom.y ) - ( lineTo.y - lineFrom.y ) * ( point.x - lineFrom.x );
        
    return {
      x: lineFrom.x + atob.x * t,
      y: lineFrom.y + atob.y * t,
    };
  },

  createLineAtPoint: (center: IPosition, radius: number, angle: number): IPosition[] => {
    let x1 = center.x + radius * Math.cos( angle );
    let y1 = center.y + radius * Math.sin( angle );
    let x2 = center.x - radius * Math.cos( angle );
    let y2 = center.y - radius * Math.sin( angle );
    return [{ x: x1, y: y1 }, { x: x2, y: y2 }];
  },

  getPolygonArea: (polygon: IPosition[]): number => {
    let total = 0;
    for (let i = 0; i < polygon.length; i++) {
      const addX = polygon[i].x;
      const addY = polygon[i === polygon.length - 1 ? 0 : i + 1].y;
      const subX = polygon[i === polygon.length - 1 ? 0 : i + 1].x;
      const subY = polygon[i].y;
      total += (addX * addY * 0.5) - (subX * subY * 0.5);
    }
    return Math.abs(total);
  },
};

export class CanvasBrushAnnotation implements ICanvasAnnotation {
  private colorIndex: number;

  private brushWidth: number;

  private points: IPosition[];

  private pBox: IMoveBox | null;

  private currentCtx: CanvasRenderingContext2D | null;

  constructor(colorIndex: number, brushWidth: number) {
    this.colorIndex = colorIndex;
    this.brushWidth = brushWidth != null && !isNaN(Number(brushWidth)) ? Number(brushWidth) : 2;
    this.points = [];
    this.pBox = null;
    this.currentCtx = null;
  }

  isValid(): boolean {
    return this.points != null && this.points.length > 0;
  }

  getType(): string { return 'Brush'; }

  startDraw(ctx: CanvasRenderingContext2D, pos: IPosition): boolean {
    const oldLineWidth = ctx.lineWidth; ctx.lineWidth = this.brushWidth;
    const oldLineJoin = ctx.lineJoin; ctx.lineJoin = 'round';
    const oldLineCap = ctx.lineCap; ctx.lineCap = 'round';

    this.currentCtx = ctx;
    this.points.push(pos);
    this.pBox = this.getPointBox();
    //applogger.debug("start line");
    if (this.currentCtx != null) {
      this.currentCtx.beginPath();
      this.currentCtx.moveTo(pos.x, pos.y);
      this.currentCtx.lineTo(pos.x, pos.y);
      this.currentCtx.stroke();
    }

    ctx.lineWidth = oldLineWidth;
    ctx.lineJoin = oldLineJoin;
    ctx.lineCap = oldLineCap;

    return true;
  }

  stopDraw(): ModifyResult {
    // end line
    //applogger.debug("ending line");

    if (this.currentCtx != null) {
      const ctx = this.currentCtx;
      const oldLineWidth = ctx.lineWidth; ctx.lineWidth = this.brushWidth;
      const oldLineJoin = ctx.lineJoin; ctx.lineJoin = 'round';
      const oldLineCap = ctx.lineCap; ctx.lineCap = 'round';

      ctx.closePath();

      ctx.lineWidth = oldLineWidth;
      ctx.lineJoin = oldLineJoin;
      ctx.lineCap = oldLineCap;
    }

    return { isValid: this.isValid(), redraw: true };
  }

  setPoint(pos: IPosition): boolean {
    this.points.push(pos);
    this.pBox = this.getPointBox();
    if (this.currentCtx != null) {
      const ctx = this.currentCtx;
      const oldLineWidth = ctx.lineWidth; ctx.lineWidth = this.brushWidth;
      const oldLineJoin = ctx.lineJoin; ctx.lineJoin = 'round';
      const oldLineCap = ctx.lineCap; ctx.lineCap = 'round';

      ctx.lineTo(pos.x, pos.y);
      ctx.stroke();

      ctx.lineWidth = oldLineWidth;
      ctx.lineJoin = oldLineJoin;
      ctx.lineCap = oldLineCap;
    }

    return false;
  }

  redrawToCanvas(ctx: CanvasRenderingContext2D) {
    if (this.points.length === 0) {
      return;
    }

    const oldLineWidth = ctx.lineWidth; ctx.lineWidth = this.brushWidth;
    const oldLineJoin = ctx.lineJoin; ctx.lineJoin = 'round';
    const oldLineCap = ctx.lineCap; ctx.lineCap = 'round';

    annotationHelper.drawPolyLine(ctx, this.points);

    ctx.lineWidth = oldLineWidth;
    ctx.lineJoin = oldLineJoin;
    ctx.lineCap = oldLineCap;
  }

  setColorIndex(idx: number) { this.colorIndex = idx; }

  getColorIndex(): number { return this.colorIndex; }

  getPoints(): IPosition[] { return this.points; }

  setPoints(points: IPosition[]) { this.points = points; this.pBox = this.getPointBox(); }

  getWithinActionBox(pos: IPosition): boolean {
    if (this.pBox == null) {
      return false;
    }

    return pos.x >= this.pBox.xMin && pos.x <= this.pBox.xMax && pos.y >= this.pBox.yMin && pos.y <= this.pBox.yMax;
  }
  
  setNewBasePoint(pos: IPosition) {
    if (this.pBox == null || this.points == null) {
      return false;
    }

    // get current midpoint
    let centerPoint: IPosition = {
      x: this.pBox.xMin + (this.pBox.xMax - this.pBox.xMin) / 2,
      y: this.pBox.yMin + (this.pBox.yMax - this.pBox.yMin) / 2,
    };

    // get offset
    let offsetX = centerPoint.x - pos.x;
    let offsetY = centerPoint.y - pos.y;

    if (offsetX !== 0 || offsetY !== 0) {
      // update all points
      for (let i = 0; i < this.points.length; ++i) {
        this.points[i] = {
          x: this.points[i].x - offsetX,
          y: this.points[i].y - offsetY,
        };
      }
      this.pBox = this.getPointBox();
      return true;
    }

    return false;
  }
        
  getAnnotationInfo(): IAnnotationInfo {
    return {
      type: this.getType(),
      colorIndex: this.getColorIndex(),
      points: this.getPoints(),
      metaData: this.brushWidth,
    };
  }

  private getPointBox(): IMoveBox | null {
    if (this.points == null || this.points.length === 0) {
      return null;
    }

    const fp = this.points[0];
    const ret: IMoveBox = { xMin: fp.x, xMax: fp.x, yMin: fp.y, yMax: fp.y };
    for (let i = 1; i < this.points.length; ++i) {
      let p = this.points[i];
      if (p.x < ret.xMin) ret.xMin = p.x;
      if (p.x > ret.xMax) ret.xMax = p.x;
      if (p.y < ret.yMin) ret.yMin = p.y;
      if (p.y > ret.yMax) ret.yMax = p.y;
    }

    const offset = Math.round(this.brushWidth / 2);
    return { xMin: ret.xMin - offset, xMax: ret.xMax + offset, yMin: ret.yMin - offset, yMax: ret.yMax + offset };
  }
}

export class CanvasImageAnnotation implements ICanvasAnnotation {
  private maxMoveSize = 15;

  private colorIndex: number;

  private image: HTMLImageElement;

  private position: IPosition | null;

  private name: string;

  constructor(name: string, colorIndex: number, image: HTMLImageElement) {
    this.name = name;
    this.colorIndex = colorIndex;
    this.image = image;
    this.position = null;
  }

  getType(): string { return 'Image'; }

  startDraw(ctx: CanvasRenderingContext2D, pos: IPosition): boolean {
    this.position = { x: pos.x - this.image.width / 2, y: pos.y - this.image.height / 2 };
    ctx.drawImage(this.image, this.position.x, this.position.y);
    return true;
  }

  setPoint(pos: IPosition): boolean {
    this.position = pos;
    return false;
  }

  redrawToCanvas(ctx: CanvasRenderingContext2D) {
    if (this.position === null) {
      return;
    }

    ctx.drawImage(this.image, this.position.x, this.position.y);
  }

  getWithinActionBox(pos: IPosition): boolean {
    var box = this.getActionBox();
    if (box == null) {
      return false;
    }

    // check if within bounding box
    return pos.x >= box.xMin && pos.x <= box.xMax
            && pos.y >= box.yMin && pos.y <= box.yMax;
  }

  setNewBasePoint(pos: IPosition) { this.position = { x: pos.x - this.image.width / 2, y: pos.y - this.image.height / 2 }; return true; }

  setColorIndex(idx: number) { this.colorIndex = idx; }

  getColorIndex(): number { return this.colorIndex; }

  getPoints(): IPosition[] { return this.position != null ? [this.position] : []; }

  getName(): string { return this.name; }

  getAnnotationInfo(): IAnnotationInfo {
    return {
      type: this.getType(),
      colorIndex: this.getColorIndex(),
      points: this.getPoints(),
      metaData: this.getName(),
    };
  }

  private getActionBox(): IMoveBox | null {
    const pos = this.position;
    if (pos == null) {
      return null;
    }

    const width = this.image.width;
    const height = this.image.height;

    const center: IPosition = { x: pos.x + width / 2, y: pos.y + height / 2 };
    const xSize = this.maxMoveSize < width ? this.maxMoveSize : width;
    const ySize = this.maxMoveSize < height ? this.maxMoveSize : height;
    const xMin = center.x - xSize / 2;
    const yMin = center.y - ySize / 2;

    return {
      xMin: xMin,
      xMax: xMin + xSize,
      yMin: yMin,
      yMax: yMin + ySize,
    };
  }
}

const sectorFillAnnotationMinAreaAllowed = 50;

export class CanvasSectorFillAnnotation implements ICanvasAnnotation {
  private points: IPosition[];

  private colorIndex: number;

  private polygon: IPosition[] | null;

  private moveExisting: number;

  private currentMap: MapItem | null;

  private ctx: CanvasRenderingContext2D | null;

  constructor(colorIndex: number, points?: IPosition[]) {
    this.colorIndex = colorIndex;
    this.points = points ?? [];
    this.polygon = null;
    this.moveExisting = -1;
    this.currentMap = null;
    this.ctx = null;
  }

  isValid(): boolean {
    if (this.points.length < 2) {
      return false;
    }

    const polygon = this.polygon ?? (this.currentMap != null ? this.polygon = this.getPolygon(this.points, this.currentMap) : null);
    if (polygon != null) {
      const area = annotationHelper.getPolygonArea(polygon);
      if (area < sectorFillAnnotationMinAreaAllowed) return false;
    }

    return true;
  }

  getType(): string { return 'SectorFill'; }

  /*
     * startDraw is fired when we create a new annotation
     * consequence setPos adds information
     */

  startDraw(ctx: CanvasRenderingContext2D, pos: IPosition, map: MapItem[] | null | undefined): boolean {
    this.ctx = ctx;

    // only allowed within a sector map, no map == disallowed
    if (map == null || map.length === 0) {
      return false;
    }

    // ensure that we are in a map
    this.currentMap = null;
    for (let i = 0; i < map.length; ++i) {
      if (annotationHelper.insidePolygon(pos, map[i].polygon)) {
        this.currentMap = map[i];
        break;
      }
    }

    if (this.currentMap == null) {
      return false;
    }

    this.points = [pos];
    this.polygon = null;
    return true;
  }

  stopDraw(): ModifyResult { 
    return { isValid: this.isValid() };
  }

  setPoint(pos: IPosition): boolean {
    if (this.currentMap != null && !annotationHelper.insidePolygon(pos, this.currentMap.polygon)) {
      // find a translation point
      let line = this.getClosestMapLine(pos, this.currentMap);
      if (line == null) {
        return false;
      }

      pos = annotationHelper.projectPointToLine(pos, line.from, line.to);
      if (!annotationHelper.insidePolygon(pos, this.currentMap.polygon)) {
        return false;
      }
    }

    if (this.points.length < 2) {
      // adding position
      this.points.push(pos);
    } else {
      // updating position
      this.points[1] = pos;
    }

    this.polygon = null;
    return true;
  }

  redrawToCanvas(ctx: CanvasRenderingContext2D, inFocus: boolean, map: MapItem[] | null | undefined) {       
    this.setupPolygon(map, ctx);
    if (this.polygon == null || this.polygon.length === 0) {
      return;
    }
        
    if (inFocus) {
      const pointMarkerSize = 4;
      this.points.forEach(p => {
        ctx.fillRect(p.x - pointMarkerSize / 2, p.y - pointMarkerSize / 2, pointMarkerSize, pointMarkerSize);    
      });
    }

    const path = annotationHelper.pointsToPath(this.polygon, true);
    const oldStyle = ctx.fillStyle;
    const oldLineWidth = ctx.lineWidth; ctx.lineWidth = 2;
    const oldLineJoin = ctx.lineJoin; ctx.lineJoin = 'round';
    const oldLineCap = ctx.lineCap; ctx.lineCap = 'round';

    ctx.fillStyle = this.getFillStyle(ctx) ?? oldStyle;
    ctx.fill(path);
    ctx.stroke(path);

    ctx.fillStyle = oldStyle;
    ctx.lineWidth = oldLineWidth;
    ctx.lineJoin = oldLineJoin;
    ctx.lineCap = oldLineCap;

    if (inFocus) {
      //ctx.fillStyle = "#000"; this.polygon.forEach(p => ctx.fillRect(p.x - 2, p.y - 2, 4, 4));ctx.fillStyle = oldStyle;
      //console.log(this.points.map(p => ({ x: p.x, y: p.y, inPloy: annotationHelper.insidePolygon(p, this.polygon) })));
    }
  }

  getWithinActionBox(pos: IPosition): boolean {
    if (this.polygon == null) {
      return false;
    }
        
    return annotationHelper.insidePolygon(pos, this.polygon);
  }

  setNewBasePoint(pos: IPosition) {
    if (this.currentMap != null && !annotationHelper.insidePolygon(pos, this.currentMap.polygon)) {
      let line = this.getClosestMapLine(pos, this.currentMap);
      if (line == null) {
        return false;
      }

      pos = annotationHelper.projectPointToLine(pos, line.from, line.to);
      if (!annotationHelper.insidePolygon(pos, this.currentMap.polygon)) {
        return false;
      }
    }

    if (this.moveExisting !== -1) {
      this.points[this.moveExisting] = pos;
      this.polygon = null;
    } else {
      this.points.push(pos);
      this.moveExisting = this.points.length - 1;
    }

    return true;
  }

  startMove(orgin: IPosition): ModifyResult {
    if (this.currentMap == null || !annotationHelper.insidePolygon(orgin, this.currentMap.polygon)) {
      return { isValid: false };
    }
    this.moveExisting = -1;

    const pDist = this.points
      .map((p, i)=>({ p, i, d: annotationHelper.getDistance(p, orgin) }))
      .sort((a, b) => a.d - b.d);

    const best = pDist[0];
    if (best?.d < 20 && (pDist[1]?.d == null || pDist[1].d / 2 > best.d)) {
      this.moveExisting = best.i;
    } else if (this.currentMap !== null) {
      const newPoly = this.getPolygon([...this.points, orgin], this.currentMap);
      const oldPoly = this.polygon ?? this.getPolygon(this.points, this.currentMap);
      if (newPoly != null && oldPoly != null && Math.abs(annotationHelper.getPolygonArea(newPoly) - annotationHelper.getPolygonArea(oldPoly)) < 0.1) {
        // new point isn't affecting polygon size, we must choose a point to move
        this.moveExisting = best.i;
      }
    }

    return { isValid: true };
  }

  stopMove(): ModifyResult {
    this.moveExisting = -1;

    if (this.currentMap != null) {
      const orgPoly = this.polygon ?? this.getPolygon(this.points, this.currentMap);
      if (orgPoly != null) {
        const orgSize = annotationHelper.getPolygonArea(orgPoly);
        for (let i = 0; i < this.points.length; ++i) {
          const newPoints = [...this.points]; newPoints.splice(i, 1);
          const newPoly = this.getPolygon(newPoints, this.currentMap);
          if (newPoly != null && Math.abs(orgSize - annotationHelper.getPolygonArea(newPoly)) < 0.1) {
            // this point isn't affecting polygon size, remove it
            this.points = newPoints;
            this.polygon = null;
            return { redraw: true };
          }
        }
      }
    }
    return { };
  }

  canFocus(): boolean { return true; }

  setColorIndex(idx: number) { this.colorIndex = idx; }

  getColorIndex(): number { return this.colorIndex; }

  getPoints(): IPosition[] { return this.points; }

  getAnnotationInfo(): IAnnotationInfo {
    return {
      type: this.getType(),
      colorIndex: this.getColorIndex(),
      points: this.getPoints(),
    };
  }

  private getFillStyle(ctx: CanvasRenderingContext2D): CanvasPattern | null {
    var p = document.createElement('canvas');
    if (p == null) return null;

    p.width = 32;
    p.height = 16;
    var pctx = p.getContext('2d');
    if (pctx == null) return null;
        
    var x0 = 36;
    var x1 = -4;
    var y0 = -2;
    var y1 = 18;
    var offset = 16;
        
    pctx.strokeStyle = ctx.fillStyle;
    pctx.lineWidth = 1;
    pctx.beginPath();
    pctx.moveTo(x0, y0);
    pctx.lineTo(x1, y1);
    pctx.moveTo(x0 - offset, y0);
    pctx.lineTo(x1 - offset, y1);
    pctx.moveTo(x0 + offset, y0);
    pctx.lineTo(x1 + offset, y1);
    pctx.stroke();

    return ctx.createPattern(p, 'repeat');
  }

  private setupPolygon(map: MapItem[] | null | undefined, ctx: CanvasRenderingContext2D) {
    console.debug(ctx);
    if (this.polygon == null) {
      if (this.points.length < 2 || map == null) {
        this.polygon = [];
        return;
      }

      // find map item which we're starting in
      if (this.currentMap == null) {
        for (let i = 0; i < map.length; ++i) {
          if (annotationHelper.insidePolygon(this.points[0], map[i].polygon)) {
            this.currentMap = map[i];
            break;
          }
        }
      }
      if (this.currentMap === null) {
        return;
      }

      // ensure stop pos is on same mapItem
      const mPoly = this.currentMap.polygon;
      if (this.points.some(p => !annotationHelper.insidePolygon(p, mPoly))) {
        return;
      }

      // Debug print
      //if (this.currentMap) for (let i = 0; i < this.currentMap.polygon.length; ++i) ctx.fillText(i.toString(), this.currentMap.polygon[i].x, this.currentMap.polygon[i].y);

      this.polygon = this.getPolygon(this.points, this.currentMap);
    }
  }

  private getPolygon(points: IPosition[], mapItem: MapItem): IPosition[] | null {
    // build list of cross points that we might want to traverse through
    const crossPoints = points.flatMap((p, i) => {
      const line = this.getMapIntersectLineForPos(p, mapItem);
      return line != null ? [
        { ...line.from, lineIndex: i, dist: annotationHelper.getDistance(line.from, mapItem.polygon[line.from.mpIdx]) }, 
        { ...line.to, lineIndex: i, dist: annotationHelper.getDistance(line.to, mapItem.polygon[line.to.mpIdx]) },
      ] : [];
    })
    // sort by index, otherwise be closest to point origin
      .sort((a, b) => a.mpIdx < b.mpIdx ? -1 : (a.mpIdx > b.mpIdx ? 1 : (a.dist < b.dist ? -1 : (a.dist > b.dist ? 1 : 0))));

    // build polygon by traversing through the points
    const mapSize = mapItem.polygon.length;
    const polygon: IPosition[] = [];
    for (let cp = 0; cp < crossPoints.length; ++cp) {
      const item = crossPoints[cp];
      polygon.push(item);

      let next = crossPoints[(cp + 1) % crossPoints.length];
      if (item.lineIndex != next.lineIndex) {
        // follow map to next item
        for (let i = item.mpIdx; i != next.mpIdx; i = (i + 1) % mapSize) {
          const p = mapItem.polygon[(i + 1) % mapSize];
          polygon.push(p);
        }
      }
    }
        
    return polygon;
  }

  private getMapIntersectLineForPos(pos: IPosition, mapItem: MapItem, nonCross?: { from: IPosition, to: IPosition }): { from: { x: number, y: number, mpIdx: number }, to: { x: number, y: number, mpIdx: number }, length: number } | null {
    let pi = Math.PI;
    let stepSize = Math.PI / 32;
    let lines = [];
    for (let angle = 0; angle < pi; angle += stepSize) {
      let line = annotationHelper.createLineAtPoint(pos, 10000, angle);
            
      // find all intersects with point and map
      let intersects: { x: number, y: number, mpIdx: number }[] = [];
      for (let polygonIndex = 0; polygonIndex < mapItem.polygon.length; ++polygonIndex) {
        let p1 = mapItem.polygon[polygonIndex];
        let p2 = mapItem.polygon[(polygonIndex + 1) % mapItem.polygon.length];
        let i = annotationHelper.lineIntersection(line[0], line[1], p1, p2);
        if (i.onLine1 && i.onLine2 && i.x != null && i.y != null) {
          intersects.push({ x: i.x, y: i.y, mpIdx: polygonIndex });
        }
      }

      // create lines with all intersects so that they cross point
      for (let i = 0; i < intersects.length; ++i) {
        let ii = intersects[i];
        for (let j = i + 1; j < intersects.length; ++j) {
          let ij = intersects[j];
                    
          // pos is on segment given within bounds of segment
          let xmin = ii.x < ij.x ? ii.x : ij.x;
          let xmax = ii.x > ij.x ? ii.x : ij.x;
          let ymin = ii.y < ij.y ? ii.y : ij.y;
          let ymax = ii.y > ij.y ? ii.y : ij.y;
          if (pos.x >= xmin && pos.x <= xmax && pos.y >= ymin && pos.y <= ymax) {
            let newLine = { from: ii.mpIdx < ij.mpIdx ? ii : ij, to: ii.mpIdx > ij.mpIdx ? ii : ij, length: annotationHelper.getDistance(ii, ij), angle: angle };
            if (nonCross != null) {
              let nCi = annotationHelper.lineIntersection(newLine.from, newLine.to, nonCross.from, nonCross.to);
              if (nCi.onLine1 && nCi.onLine2) {
                continue;
              }
            }

            const midpoint = this.getLineMidpoint(newLine.from, newLine.to);
            if (!annotationHelper.insidePolygon(midpoint, mapItem.polygon) ||
                            !annotationHelper.insidePolygon(this.getScaleMoveFrom(newLine.from, newLine.to), mapItem.polygon) ||
                            !annotationHelper.insidePolygon(this.getScaleMoveTo(newLine.from, newLine.to), mapItem.polygon)) {
              continue;
            }

            lines.push(newLine);
          }
        }
      }
    }

    // return best line
    lines = lines.sort((a, b) => a.length < b.length ? -1 : (a.length > b.length ? 1 : 0));

    return lines.length > 0 ? lines[0] : null;
  }

  private getLineMidpoint(from: IPosition, to: IPosition): IPosition {
    return { x: (from.x + to.x) / 2, y: (from.y + to.y) / 2 };
  }

  private getScaleMoveFrom(from: IPosition, to: IPosition, scale?: number): IPosition {
    const mp = this.getLineMidpoint(from, to);
    scale = scale ?? 0.000001;
    return { x: from.x + (mp.x - from.x) * scale, y: from.y + (mp.y - from.y) * scale };
  }

  private getScaleMoveTo(from: IPosition, to: IPosition, scale?: number): IPosition {
    const mp = this.getLineMidpoint(from, to);
    scale = scale ?? 0.000001;
    return { x: to.x + (mp.x - to.x) * scale, y: to.y + (mp.y - to.y) * scale };
  }

  // get the line in the map that is closest to our point
  private getClosestMapLine(pos: IPosition, mapItem: MapItem): { from: IPosition, to: IPosition } | null {
    let pi = Math.PI;
    let stepSize = Math.PI / 32;
    let ret: { from: IPosition, to: IPosition } | null = null;
    let pDist: number = 0;

    for (let angle = 0; angle < pi; angle += stepSize) {
      let line = annotationHelper.createLineAtPoint(pos, 10000, angle);
      for (let polygonIndex = 0; polygonIndex < mapItem.polygon.length; ++polygonIndex) {
        let p1 = mapItem.polygon[polygonIndex];
        let p2 = mapItem.polygon[(polygonIndex + 1) % mapItem.polygon.length];
        let i = annotationHelper.lineIntersection(line[0], line[1], p1, p2);
        if (i.onLine1 && i.onLine2 && i.x != null && i.y != null) {
          let iP = { x: i.x, y: i.y };
          let iDist =  annotationHelper.getDistance(pos, iP);
          if (ret == null || iDist < pDist) {
            ret = { from: p1, to: p2 };
            pDist = iDist;
          }
        }
      }
    }

    return ret;
  }
}
