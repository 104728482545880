import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal, Button } from 'react-bootstrap';

export function MyDropzone(onFile: (f:File)=>void) {
  const onDrop = useCallback(acceptedFiles => {
    let files = (acceptedFiles as File[]);
    if (files.length !== 1){
      alert('You shall only upload one file of type .ssrt');
      return;
    }
    onFile(files[0]);
  }, []);
    
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: ['.ssrt', '.fp', '.json'] });

  return (
        <div className={'DropzoneArea'} {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p className="DropzoneText">Drop the files here ...</p> :
                    <p className="DropzoneText">Drag and drop some files here, or click to select files</p>
            }
        </div>
  );
}

interface DropzoneModalProps {
  onFileAvailable: (file: File)=>void
  onCancel: ()=>void;
}

export const DropzoneModal: React.FC<DropzoneModalProps> = (props) => {
  return <Modal.Dialog>
        <Modal.Header closeButton onClick={props.onCancel}>
            <Modal.Title>Import File</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {MyDropzone(props.onFileAvailable)}
        </Modal.Body>

        <Modal.Footer>
            <Button onClick={props.onCancel}>Close</Button>
        </Modal.Footer>
    </Modal.Dialog>;
};