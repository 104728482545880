/* eslint-disable jsx-a11y/no-autofocus */
import React, { RefObject, useEffect, useRef } from 'react';
import { SrComponent, SrMandatoryType, SrValueComponentPropsBase } from '../BasicTypes';
import { ReactComponentContainerProps  } from './SrtComponent';
import { expressionPattern, schema } from './Schema';
import { GetRowComponent } from './SectraRow';
import { INullableOptionValue, ISingleOptionValue, SectraOptionHelper } from './SectraBaseComponent/SectraOptionBase'; 
import { FormDataOutput } from './FormDataOutput';
import { SchemaOptionsProperty, ISectraOptionsValue, SectraOptionsHelper, ISectraOptionsComplexValue } from './SectraOptionsBase';
import { SectraCheckButton } from './SectraBaseComponent/SectraCheckButtonOverride';
import { SectraInput } from './SectraBaseComponent/SectraInput';
import { IsEmptySingle } from './ScriptHelperMethods';
import { TemplateDefaults } from '../TemplateDefaults';
import { applogger } from '../../applogger';
import { asControllableElement, OptionsContentController } from './SectraBaseComponent/ContentController';

type OtherOptionType = 'no' | 'yes' | 'yes-alert' | 'yes-prohibit';

interface CheckboxListStateValues {
  internalValue?: INullableOptionValue;
  checkListValue?: INullableOptionValue;
  otherIsChecked?: boolean;
  textValue?: string;
  displayValue?: string[];
  description?: string[];
}

interface CheckboxListProps extends SrValueComponentPropsBase, CheckboxListStateValues {
  options: ISectraOptionsValue[];
  optionsDisableFilter?: RegExp | string | null;
  allowDisabledOptionValue?: boolean;
  prefix?: string;
  suffix?: string;
  size?: string;
  columns: 1 | 2 | 3 | 4;
  otherOption?: OtherOptionType;
  otherOptionName?: string;
  otherOptionPlaceholderText?: string;
  otherOptionValue?: string;
  multichoice?: boolean;
  spacing: 'tight' | 'small' | 'normal' | 'airy';

  otherOptionSpeechEnabled?: boolean; /* expose property? */
}

const componentCheckboxSchema = schema.mergeSchemaProps(schema.DefaultSizeSchemaPart, {
  'options': { ...SchemaOptionsProperty, 'minItems': 0 },
  'optionsDisableFilter': { 'type': 'string', 'description': 'A regular expression (RegEx) allowing options through setting disable. Only entries matching the expression is disabled (note: if null or undefined no filtering is applied and property value can be a Sectra Forms expression returning different filters based on current state)' },
  'allowDisabledOptionValue': { 'type': ['string', 'boolean'], 'description': 'Whether to allow a disabled option value or not (default: true)', 'pattern': expressionPattern },
  'value': { 'type': ['boolean', 'number', 'string', 'array'], 'description': schema.ValueDescription },
  'prefix': schema.PropDefinition.prefix,
  'prefixStyle': schema.PropDefinition.prefixStyle,
  'suffix': schema.PropDefinition.suffix,
  'suffixStyle': schema.PropDefinition.suffixStyle,
  'columns': { 'type': 'number', 'enum': [1, 2, 3, 4], 'description': 'Present checkbox options in columns' },
  'otherOption': { 'type': 'string', 'enum': ['no', 'yes', 'yes-alert', 'yes-prohibit'], 'enumDescription': ['No other option (default)', 'Yes - optional', 'Yes - Mandatory w. alert', 'Yes - Mandatory w. prohibit'], 'description': 'Whether to display an additional "other" free-text option in the list (always last) and potentially a mandatory option if checked (default: no).' },
  'otherOptionName': { 'type': 'string', 'description': 'The name of the option when used (default: "Other")' }, 
  'otherOptionPlaceholderText': { 'type': 'string', 'description': 'Placeholder text for the other free-text field (default: empty)' }, 
  'otherOptionValue': { 'type': 'string', 'description': 'An optional other value (default: value is same as text)' }, 
  'multichoice': { 'type': ['string', 'boolean'], 'description': 'Whether to allow multiple choices or not (default: true)', 'pattern': expressionPattern },
  'spacing': {
    'anyOf': [
      { 
        'type': 'string', 
        'enum': ['tight', 'small', 'normal', 'airy'], 
        'enumDescription': ['Tight vertical spacing', 'Less than normal vertical spacing', 'Normal vertical spacing', 'More than normal vertical spacing'], 
      },
      { 'type': 'string', 'description': 'Spacing given as an expression', 'pattern': expressionPattern },
    ],
    'description': 'Vertical spacing between rows (default: normal)', 
  },
});

// The React checkbox list component
const SectraCheckboxListComponent: React.FC<ReactComponentContainerProps<CheckboxListProps>> = (container) => {
  const props = container.props;
  const allowMultiple = props.multichoice !== false;
  const options = SectraOptionsHelper.getOptionsWithDisabledFilter(props.options, props.optionsDisableFilter);
  const buttonValues = SectraOptionsHelper.getValues(options, true);
  const validButtonValues = SectraOptionsHelper.getValues(options, props.allowDisabledOptionValue !== false);
  const currentValue = SectraOptionHelper.normalizeValue(props.checkListValue, validButtonValues, allowMultiple);
  const buttonTexts = SectraOptionsHelper.getTexts(options);

  const templateDefaults = container.templateContext.defaults;
  const ooInfo = getHasOtherOption(props.otherOption) ? getOtherOptionInfo(props, templateDefaults) : null;
  const showOtherInput = ooInfo != null && props.otherIsChecked === true;
  const componentName = props.name ?? props.inherritedLabel ?? props.id;

  // Adding a content controller
  const elementRef = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  useEffect(()=>{
    const otherOptionId = '01e429d3-e96e-4858-ba01-871e3973e938'; /* Just a GUID to avoid collision with normal option ids */
    if (elementRef.current != null) {
      asControllableElement(elementRef.current).contentController = new OptionsContentController(
        () => allowMultiple,
        () => {
          const items = buttonValues.map((v, i) => ({ 
            id: v?.toString() ?? '', 
            names: [buttonTexts[i] ?? v?.toString() ?? ''], 
          }));
                    
          if (ooInfo != null) {
            items.push({ id: otherOptionId, names: [ooInfo.checkboxText] });
          }

          return items;
        },
        () => {
          const result = SectraOptionHelper.valueAsOptionValues(currentValue).map(x => x?.toString() ?? '');
          if (ooInfo?.checked) {
            result.push(otherOptionId);
          }
          return result;
        },
        (id: string, selected: boolean) => {
          if (id === otherOptionId) {
            selectOtherOption(selected, container);
            return;
          }

          if (id == null && selected !== false && (currentValue != null || ooInfo?.checked)) {
            // we're clearing everything (unless we're trying to deselect null, which doesn't make sense)
            const value = SectraOptionHelper.normalizeValue(null, validButtonValues, allowMultiple);
            const output = getComponentOutput({ ...props, checkListValue: value, otherIsChecked: false }, container.templateContext.defaults);
            container.functions.compositeUpdate([
              { componentId: props.id, propName: 'checkListValue', value: output.checkListValue },
              { componentId: props.id, propName: 'otherIsChecked', value: output.otherIsChecked },
              { componentId: props.id, propName: 'value', value: output.value },
              { componentId: props.id, propName: 'internalValue', value: output.value },
              { componentId: props.id, propName: 'displayValue', value: output.displayValues },
              { componentId: props.id, propName: 'description', value: output.descriptions },
            ]);
          }

          const item = validButtonValues.find(v => v?.toString() === id);
          if (item != null) {
            selectNormalOption(item, selected !== false, container);
          }  
        },
      );
    }

    return () => {
      // unmount
      if (elementRef.current) {
        delete asControllableElement(elementRef.current).contentController;
      }
    };
  });

  let inner: JSX.Element[] = buttonValues.map((value, index) => {
    const text = buttonTexts?.[index] ?? value?.toString() ?? '';
    const description = (options[index] as ISectraOptionsComplexValue)?.description;
    const disabled = (options[index] as ISectraOptionsComplexValue)?.disabled;

    return <SectraCheckButton
            id={index === 0 && !showOtherInput ? props.id : undefined}
            type={allowMultiple ? 'checkbox' : 'radio'}
            key={props.id + '_' + index}
            name={componentName}
            className={props.spacing != null ? 'spacing-' + props.spacing : undefined}
            value={text}
            disabled={props.disabled === true || disabled === true}
            description={description}
            checked={SectraOptionHelper.matchSingle(value, currentValue, allowMultiple)} 
            onStateChange={(isChecked) => selectNormalOption(value, isChecked, container)} />;
  });

  const columns = props.columns != null && !isNaN(+props.columns) && isFinite(+props.columns) 
    ? Math.min(Math.max(1, +props.columns), 4) 
    : 1;

  if (ooInfo != null) {
    inner.push(<SectraCheckButton
            type={allowMultiple ? 'checkbox' : 'radio'}
            key={props.id + '_' + buttonValues.length}
            name={componentName}
            className={props.spacing != null ? 'spacing-' + props.spacing : undefined}
            value={ooInfo.checkboxText}
            disabled={props.disabled === true}
            checked={props.otherIsChecked === true}
            onStateChange={(isChecked) => selectOtherOption(isChecked, container)} />);
  }

  if (columns != 1) {
    inner = [<div className={'checkboxlistcolumns-' + columns} key={props.id + '_' + buttonValues.length + 2}>{inner}</div>];
  }

  if (ooInfo != null && showOtherInput) {
    const placeholderText = props.otherOptionPlaceholderText ?? templateDefaults.checkboxListOtherOptionPlaceholderText;

    inner.push(<SectraInput
            id={props.id}
            type={'text'}
            autoFocus={true}
            className={'form-control checklist-other'}
            key={props.id + '_' + buttonValues.length + 3}
            onInputChange={(val: string) => {
              const output = getComponentOutput({ ...props, textValue: val }, templateDefaults);
              container.functions.setUserInput(props.id, 'textValue', val, false);
              container.functions.setUserInput(props.id, 'displayValue', output.displayValues, false);
              container.functions.setUserInput(props.id, 'description', output.descriptions, false);
              container.functions.setUserInput(props.id, 'value', output.value, false);
              container.functions.setUserInput(props.id, 'internalValue', output.value, false);

              // delay script run to not slow down the text field input process
              container.functions.runScripts(200, [{ type: 'setUserInput', compId: props.id, propName: 'value' }]);
            }}
            value={props.textValue ?? ''}
            name={componentName + ' - ' + (IsEmptySingle(placeholderText) ? ooInfo.checkboxText : placeholderText)}
            style={{ maxWidth: '100%' }}
            placeholder={placeholderText}
            disabled={props.disabled === true}
            data-field-completion-action={ooInfo.mandatory !== 'none' ? ooInfo.mandatory : undefined} 
            speechEnabled={props.otherOptionSpeechEnabled !== false} />);
  }
    
  return GetRowComponent(props, container.context, container.templateContext, <>
        <FormDataOutput id={props.id} value={props.value} name={componentName}
        mandatory={ooInfo == null || props.otherIsChecked !== true ? props.mandatory : undefined}
        worklistAttribute={props.worklistAttribute} freeField={props.freeField}
        />
        <div ref={elementRef} className="sectra-checkboxlist">{inner}</div>
    </>);
};

//
// The checkbox list component
//

const checkboxListComponentKey = 'CheckboxList';
export const CheckboxList : SrComponent<CheckboxListProps> = {
  key: checkboxListComponentKey,
  render: (props, context, templateContext, functions) => <SectraCheckboxListComponent props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: () => Promise.resolve(`- ${checkboxListComponentKey}:
    id: checkboxlist${schema.getNextIdNum()}
    options:
    - Malignant
    - Benign`),
  toolboxName: checkboxListComponentKey,
  schema: schema.getSchema(checkboxListComponentKey, componentCheckboxSchema, ['options']),
  getInitValues: () => ({ 'value': null, 'internalValue': null }),
  onStateChangeRunner: (props, set, _get, _context, templateContext) => {
    if (!SectraOptionHelper.valueEq(props.value ?? null, props.internalValue ?? null)) {
      // external modification
      applogger.log('External modification of checkbox list value, update');
      props.otherIsChecked = false;
      props.checkListValue = props.value;
    }

    const output = getComponentOutput(props, templateContext.defaults);
    set(props.id, 'value', output.value, true);
    set(props.id, 'internalValue', output.value, true);
    set(props.id, 'checkListValue', output.checkListValue, true);
    set(props.id, 'otherIsChecked', output.otherIsChecked, true);
    set(props.id, 'displayValue', output.displayValues, true);
    set(props.id, 'description', output.descriptions, true);
  },
};


function selectNormalOption(value: ISingleOptionValue, isChecked: boolean, container: ReactComponentContainerProps<CheckboxListProps>) {
  const props = container.props;
  const allowMultiple = props.multichoice !== false;
  const options = SectraOptionsHelper.getOptionsWithDisabledFilter(props.options, props.optionsDisableFilter);
  const validOptions = SectraOptionsHelper.getValues(options, props.allowDisabledOptionValue !== false);
  const currentValue = SectraOptionHelper.normalizeValue(props.checkListValue, validOptions, allowMultiple);
  const buttonValues = SectraOptionsHelper.getValues(options, true);
    
  let newCheckListValue = isChecked
    ? SectraOptionHelper.addToCurrent(currentValue, value, validOptions, allowMultiple)
    : SectraOptionHelper.removeFromCurrent(currentValue, value, allowMultiple);

  if (isChecked && allowMultiple) {
    // check whether the added option is an exclusive option, then only allow this one
    if ((options[buttonValues.indexOf(value)] as ISectraOptionsComplexValue)?.exclusive === true) {
      newCheckListValue = newCheckListValue != null  
        ? SectraOptionHelper.valueAsOptionValues(newCheckListValue).filter(val => val === value)
        : null;
    } else if (Array.isArray(newCheckListValue)) {
      // filter out all exclusive values from newValue
      newCheckListValue = SectraOptionHelper.normalizeValue(newCheckListValue
        .filter(val => (options[buttonValues.indexOf(val)] as ISectraOptionsComplexValue)?.exclusive !== true), buttonValues, allowMultiple);
    }
  }

  const output = getComponentOutput({ ...props, checkListValue: newCheckListValue, otherIsChecked: allowMultiple && props.otherIsChecked === true }, container.templateContext.defaults);
  container.functions.compositeUpdate([
    { componentId: props.id, propName: 'checkListValue', value: output.checkListValue },
    { componentId: props.id, propName: 'otherIsChecked', value: output.otherIsChecked },
    { componentId: props.id, propName: 'value', value: output.value },
    { componentId: props.id, propName: 'internalValue', value: output.value },
    { componentId: props.id, propName: 'displayValue', value: output.displayValues },
    { componentId: props.id, propName: 'description', value: output.descriptions },
  ]);
}

function selectOtherOption(isChecked: boolean, container: ReactComponentContainerProps<CheckboxListProps>) {
  const props = container.props;
  const output = getComponentOutput({ ...props, otherIsChecked: isChecked },  container.templateContext.defaults);
  container.functions.compositeUpdate([
    { componentId: props.id, propName: 'checkListValue', value: output.checkListValue },
    { componentId: props.id, propName: 'otherIsChecked', value: output.otherIsChecked },
    { componentId: props.id, propName: 'value', value: output.value },
    { componentId: props.id, propName: 'internalValue', value: output.value },
    { componentId: props.id, propName: 'displayValue', value: output.displayValues },
    { componentId: props.id, propName: 'description', value: output.descriptions },
  ]);
}

function getHasOtherOption(opt?: OtherOptionType) {
  return opt === 'yes' || opt === 'yes-alert' || opt === 'yes-prohibit';
}

interface OtherOptionInfo {
  value: string;
  text: string;
  checkboxText: string;
  mandatory: SrMandatoryType;
  checked: boolean;
}

function getOtherOptionInfo(props: CheckboxListProps, defaults: TemplateDefaults): OtherOptionInfo {
  const checkboxText = props.otherOptionName ?? defaults.checkboxListOtherOptionName;
  const mandatory = props.otherOption === 'yes-alert' 
    ? 'alert' 
    : (props.otherOption === 'yes-prohibit' ? 'prohibit' : 'none');

  const hasUserInputText =  props.textValue != null && props.textValue !== '';
  const checked = props.otherIsChecked === true  /* mandatory option => we won't include until there is user input text */
        && (mandatory === 'none' || hasUserInputText);
  const text = hasUserInputText ? String(props.textValue) : checkboxText;
  const value = props.otherOptionValue != null ? props.otherOptionValue : text;

  return {
    value: value, 
    text: text,
    checkboxText: checkboxText,
    mandatory: mandatory,
    checked: checked,
  };
}


interface ComponentOutput {
  checkListValue: INullableOptionValue;
  otherIsChecked: boolean;
  value: INullableOptionValue;
  displayValues: string[];
  descriptions: string[];
}


function getComponentOutput(props: CheckboxListProps, templateDefaults: TemplateDefaults): ComponentOutput {
  const allowMultiple = props.multichoice !== false;
  const options = SectraOptionsHelper.getOptionsWithDisabledFilter(props.options, props.optionsDisableFilter);
  const buttonValues = SectraOptionsHelper.getValues(options, allowMultiple);
  const validButtonValues = SectraOptionsHelper.getValues(options, props.allowDisabledOptionValue !== false);
    
  let normalizedValue = SectraOptionHelper.normalizeValue(props.checkListValue !== undefined ? props.checkListValue : props.value, validButtonValues, allowMultiple);
  const arrItems = SectraOptionHelper.valueAsOptionValues(normalizedValue)
    .map(v => {
      const option = v != null ? (options[buttonValues.indexOf(v)] as ISectraOptionsComplexValue | undefined) : null;
      const display = option?.text ?? v?.toString() ?? '';
      return {
        display: display,
        description: option?.description ?? display,
      };
    }); 
  const dpValue = arrItems.map(x => x.display);
  const description = arrItems.map(x => x.description);

  let checkListValue: INullableOptionValue = SectraOptionHelper.valueAsOptionValues(normalizedValue);
  if (getHasOtherOption(props.otherOption) && props.otherIsChecked === true) {
    const ooInfo = getOtherOptionInfo(props, templateDefaults);
    if (allowMultiple) {
      if (ooInfo.checked) {
        normalizedValue = [...SectraOptionHelper.valueAsOptionValues(normalizedValue), ooInfo.value];
        dpValue.push(ooInfo.text);
        description.push(ooInfo.text);
      }
    } else {
      normalizedValue = ooInfo.checked ? ooInfo.value : null;
      checkListValue = null;
    }
  }

  return {
    otherIsChecked: props.otherIsChecked === true,
    checkListValue: checkListValue,
    value: normalizedValue,
    displayValues: dpValue,
    descriptions: description,
  };
}
