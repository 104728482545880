/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { applogger } from './applogger';
import { DataproviderServerType, TemplateMetadata, TemplateField } from './Components/BasicTypes';

export const inDemoMode = (window as any).SRTBSettings?.IsDemoMode ?? false;

// The server interface shall provide mechanisms for saving and loading templates
export interface IServerCommunication {
  save: (template: SrtGenTemplate)=> Promise<SrtGenTemplate>;
  getAvailableTemplatesSupported: ()=>boolean;
  getAllTemplates: ()=> Promise<SrtTemplate[]>;
  getSectraTemplates: () => Promise<SrtTemplate[]>;
  buildIds7Package: (id: string, version: number, fullScript: string, templateFields: TemplateField[], reportVersion: number)=> Promise<Blob>;
  getTemplateById: (id: string, version: number | undefined)=> Promise<SrtGenTemplate>;
  getTemplateVersionsById: (id: string) => Promise<SrtGenTemplate[]>;
  deleteTemplate: (id: string, shouldDelete: boolean)=>Promise<void>;
  deleteTemplateVersions: (id: string, versions: number[]) => Promise<void>;
  getAllBuilds: (templateId: string)=>Promise<SrtGenBuild[]>;
  getBuildInfo: (templateId: string, templateVersion: number)=>Promise<SrtGenBuild | null>;
  getSites: ()=>Promise<SrtSite[]>;
  setSite: (siteId: number)=>Promise<boolean>;
  isReady: ()=>boolean;
  isDemoInstance: ()=>boolean;
  getProviders: () => Promise<DataproviderServerType[]>
  saveProvider: (provider: DataproviderServerType) => Promise<boolean>;
  deleteProvider: (providerId: number) => Promise<boolean>;
  updateFolder: (parentId: number, folderName: string, id: number | null) => Promise<boolean>;
  deleteFolder: (id: number) => Promise<boolean>;
  moveFolder: (id: number, parentId: number) => Promise<boolean>;
  getSectraFolders: () => Promise<SrtTemplateFolder[]>;
  saveSectraFolder: (parentId: number | null, folderName: string, id: number | null) => Promise<boolean>;
  moveTemplate: (id: string, newFolderId: number) => Promise<boolean>;
  getPreviewTemplate: (id: string, version: number | null) => Promise<PreviewTemplate>;
  fetchUser: () => Promise<UserResponse>;
  addUsersToSite: (userIds: string[]) => Promise<UserResponse[]>;
  setUsersRole: (userIds: string[], role: UserRole) => Promise<UserResponse[]>;
  getCurrentSiteUsers: () => Promise<UserResponse[]>;
  removeUserFromSite: (user: UserResponse) => Promise<void>;
  getAllSites: () => Promise<SrtSite[]>;
  createSite: (name: string, description: string, emailDomain?: string) => Promise<SrtSite>;
  setUser: (user: UserResponse, siteId: number) => Promise<void>;
  getUnverifiedUsers: () => Promise<UserResponse[]>;
  setSiteEmailDomain: (siteId: number, emailDomain: string | null) => Promise<SrtSite>;
  getIsServerUpgrading: () => Promise<boolean>;
  shareTemplate: (templateId: string, siteIds: number[] | null) => Promise<void>;
  getTemplateShare: (templateId: string) => Promise<number[] | null>;
  getCsrfToken: () => Promise<string>;
  getSharedTemplates: () => Promise<SharedTemplate[]>;
  copyTemplate: (templateId: string, folderId: number) => Promise<void>;
  debugLogin: (email: string) => Promise<void>;
  debugRegister: (email: string, name: string, familyName: string) => Promise<void>;
  importFormPackage: (folderId: number, forms: SrtGenTemplate[]) => Promise<SrtTemplate[]>;
  getAllLatestVersions: () => Promise<SrtGenTemplate[]>;
  getActiveUsersForTemplate: (templateId: string) => Promise<string[]>; 
  disconnectCurrentUserFromTemplates: () => Promise<void>;
  getAvailableForPreview: (id: string) => Promise<boolean>;
  setAvailableForPreview: (id: string, availableForPreview: boolean) => Promise<void>;
  getShowTutorial: () => Promise<boolean>;
  setShowTutorial: (showTutorial: boolean) => Promise<void>;
}

// NOTE number order is important, higher number => more access. Must match server as well.
export enum UserRole {
  None = 0,
  User = 1,
  Admin = 2,
  SectraAdmin = 3,
}

export interface UserResponse {
  Guid: string;
  GivenName?: string | null;
  FamilyName?: string | null;
  Role: UserRole;
  Email?: string | null;
}

export interface SrtSite {
  Id: number;
  Name: string;
  Description: string;
  Folders: SrtTemplateFolder[];
  IsCurrent: boolean;
  EmailDomain?: string;
}

export interface SrtTemplateFolder {
  Id: number;
  SiteId: number;
  FolderName: string;
  ParentId: number | null;
}

export interface SrtGenBuild {
  BuildNumber: number;
  TemplateId: string;
  TemplateVersion: number;
  AppVersion: string;
  Created: string;
  CreatedBy: string | null;
}

export interface SrtGenTemplateInfo {
  Id: string;
  Version?: number | null;
  FolderId: number;
  Name: string;
  Description: string | null;
  SpecVersion?: string | null;
  Created?: Date;
  CreatedBy: string | null;
  CurrentMaxVersion?: number | null;
  Deleted: boolean;
  BuildNumber?: number | null;
}

export interface SrtTemplate {
  Id: string;
  FolderId: number;
  Name: string;
  Created?: Date;
  CreatedBy: string | null;
  Deleted: boolean;
  MaxVersion: number;
  SpecVersion: string;
}

export interface SharedTemplate {
  Id: string;
  Name: string;
  Created: Date;
  SiteId: number;
  SiteName: string;
  LatestVersion: number;
}

export interface SrtGenTemplate extends SrtGenTemplateInfo {
  Template: SrtTemplateDto | undefined;
}

export interface SrtTemplateDto {
  JsonSpec: string,
  Yaml: string,
  JsCode: string,
}

export interface SrtTemplateDtoExtended extends SrtTemplateDto {
  metadata: TemplateMetadata,
  dataProviders: string[],
}

export interface PreviewTemplate {
  Yaml: string;
  FullCode: string;
}

const trimTrailingSlash = (s: string): string  => {
  if (s == null || !s.endsWith('/')) {
    return s;
  }
  return s.slice(0, s.length - 1);
};

export const baseUrl: ()=>string = () => {
  if (process.env.PUBLIC_URL) {
    return trimTrailingSlash(process.env.PUBLIC_URL);
  }
  let v = window.location.href;
  let reverse = v.split('').reverse().join('');
  if (reverse.indexOf('?') > 0){
    reverse = reverse.substr(reverse.indexOf('?') + 1);
    v = reverse.split('').reverse().join('');
  }
  return trimTrailingSlash(v);
};

export class ExceptionServer implements IServerCommunication {
  getShowTutorial = () => {
    throw new Error('Not ready');
  };

  setShowTutorial = () => {
    throw new Error('Not ready');
  };

  getSectraTemplates = () => {
    throw new Error('Not ready');
  };

  saveSectraFolder = (parentId: number | null, folderName: string, id: number | null) => {
    throw new Error('Not ready');
  };

  getSectraFolders = () => {
    throw new Error('Not ready');
  };

  getAvailableForPreview = (id: string) => {
    throw new Error('Not ready');
  };

  setAvailableForPreview = (id: string, availableForPreview: boolean) => {
    throw new Error('Not ready');
  };

  disconnectCurrentUserFromTemplates = () => {
    throw new Error('Not ready');
  };
  
  getActiveUsersForTemplate = (templateId: string) => {
    throw new Error('Not ready');
  };

  getAllLatestVersions = () => {
    throw new Error('Not ready');
  };

  importFormPackage = (folderId: number, forms: SrtGenTemplate[]) => {
    throw new Error('Not ready');
  };

  debugLogin = (email: string) => {
    throw new Error('Not ready');
  };

  debugRegister = (email: string, name: string, familyName: string) => {
    throw new Error('Not ready');
  };

  copyTemplate = (templateId: string, folderId: number) => {
    throw new Error('Not ready');
  };

  getSharedTemplates = () => {
    throw new Error('Not ready');
  };

  getCsrfToken = () => {
    throw new Error('Not ready');
  };
  
  getTemplateShare = (templateId: string) => {
    throw new Error('Not ready');
  };

  shareTemplate = (templateId: string, siteIds: number[] | null) => {
    throw new Error('Not ready');
  };

  getIsServerUpgrading = () => {
    throw new Error('Not ready');
  };

  setSiteEmailDomain = (siteId: number, emailDomain: string | null) => {
    throw new Error('Not ready');
  };

  setUsersRole = (userIds: string[], role: UserRole) => {
    throw new Error('Not ready');
  };

  getUnverifiedUsers = () => {
    throw new Error('Not ready');
  };

  setUser = async (user: UserResponse, siteId: number) => {
    throw new Error('Not ready');
  };

  getAllSites = async () => {
    throw new Error('Not ready');
  };

  createSite = async (name: string, description: string, email?: string) => { throw new Error('Not ready'); };

  removeUserFromSite = async () => {
    throw new Error('Not ready');
  };

  getCurrentSiteUsers = async () => {
    throw new Error('Not ready');
  };

  addUsersToSite = async (userIds: string[]) => {
    throw new Error('Not ready');
  };

  getPreviewTemplate = async () => {
    throw new Error('Not ready');
  };

  getProviders = async () => {
    throw new Error('Not ready');
  };

  saveProvider = async () => {
    throw new Error('Not ready');
  };

  deleteProvider = async () => {
    throw new Error('Not ready');
  };

  save = async (t: SrtGenTemplate)=>{
    throw new Error('Not ready');
  };

  getSites = async ()=> {
    throw new Error('Not ready');
  };

  getAvailableTemplatesSupported = () => { return false; };

  getAllTemplates = async ()=> {
    throw new Error('Not ready');
  };

  buildIds7Package = async ()=>{
    throw new Error('Not ready');
  };

  getTemplateById = async ()=>{
    throw new Error('Not ready');
  };

  deleteTemplate = async ()=> {
    throw new Error('Not ready');
  };

  deleteTemplateVersions = async ()=> {
    throw new Error('Not ready');
  };

  getAllBuilds = async ()=> {
    throw new Error('Not ready');
  };

  getBuildInfo = async ()=> {
    throw new Error('Not ready');
  };

  setSite = async ()=> {
    throw new Error('Not ready');
  };

  updateFolder = async ()=> {
    throw new Error('Not ready');
  };

  deleteFolder = async ()=> {
    throw new Error('Not ready');
  };

  moveFolder = async ()=> {
    throw new Error('Not ready');
  };

  moveTemplate = async () => {
    throw new Error('Not ready');
  };

  getTemplateVersionsById = async () => {
    throw new Error('Not ready');
  };

  fetchUser = async () => {
    throw new Error('Not ready');
  };
    
  isReady = () => false;

  isDemoInstance = () => inDemoMode;
}

export class ServerCommunication implements IServerCommunication {
  getShowTutorial = async () => {
    const resp = await this.get('/api/user/showTutorial');
    return resp.json();
  };

  setShowTutorial = async (showTutorial: boolean) => {
    await this.post('/api/user/showTutorial/' + showTutorial, {});
  };

  /* eslint-disable @typescript-eslint/no-unused-vars */
  getSectraTemplates = async () => {
    const resp = await this.get('/api/templates/sectratemplates');
    return resp.json();
  };
  
  getAvailableForPreview = async (id: string) => {
    const resp = await this.get('/api/templates/availableForPreview/' + id);
    applogger.debug('Successfully got template available for preview info');
    return resp.json();
  };

  setAvailableForPreview = async (id: string, availableForPreview: boolean) => {
    await this.post('/api/templates/availableForPreview/' + id, availableForPreview);
  };

  disconnectCurrentUserFromTemplates = async () => {
    await this.delete('/api/templates/currentUser', {});
  };

  getActiveUsersForTemplate = async (templateId: string) => {
    const resp = await this.post(`/api/templates/${templateId}/currentUsers`, {});
    applogger.debug('Successfully got current template users');
    return resp.json();
  };

  getAllLatestVersions = async () => {
    const resp = await this.post('/api/admin/getallsitetemplates', {});
    applogger.debug('Successfully downloaded all site templates');
    return resp.json();
  };

  importFormPackage = async (folderId: number, forms: SrtGenTemplate[]) => {
    const resp = await this.post('/api/templates/importPackage/' + folderId, forms);
    applogger.debug('Successfully imported form package');
    return resp.json();
  };

  debugLogin = async (email: string) => {
    await this.post('/api/debugauthentication', email);
  };

  debugRegister = async (email: string, name: string, familyName: string) => {
    await this.post('/api/debugauthentication/register', { email: email, name: name, familyName: familyName });
  };

  copyTemplate = async (templateId: string, folderId: number) => {
    await this.post('/api/templates/copy', { Id: templateId, FolderId: folderId, MaxVersion: 1, Deleted: false } as SrtTemplate);
    applogger.debug('successfully copied form');
  };

  getSharedTemplates = async () => {
    const resp = await this.get('/api/templates/shared');
    applogger.debug('successfully got shared forms');
    return resp.json();
  };

  getCsrfToken = async () => {
    const resp = await this.get('/api/auth/csrf');
    applogger.debug('successfully got csrf token');
    return resp.text();
  };

  getTemplateShare = async (templateId: string) => {
    const resp = await this.get(`/api/templates/share/${templateId}`);
    applogger.debug('successfully got form share');
    return resp.status == 204 ? null : resp.json();
  };

  shareTemplate = async (templateId: string, siteIds: number[] | null) => {
    await this.post(`/api/templates/share/${templateId}`, siteIds);
    applogger.debug('successfully shared form');
  };

  getIsServerUpgrading = async () => {
    const resp = await this.get('/api/status/isupgrading');
    applogger.debug('successfully got isUpgrading message');
    return resp.json();
  };

  setSiteEmailDomain = async (siteId: number, emailDomain: string | null) => {
    const resp = await this.post('api/sectraadmin/setemail', { Id: siteId, EmailDomain: emailDomain } as SrtSite);
    applogger.debug('successfully updated email domain');
    return resp.json();
  };

  setUsersRole = async (userIds: string[], role: UserRole) => {
    const resp = await this.post(`api/admin/setrole/${role}`, userIds);
    applogger.debug('successfully set user roles');
    return resp.json();
  };

  getUnverifiedUsers = async () => {
    const resp = await this.get('api/admin/unverifiedusers');
    applogger.debug('successfully got unverified users');
    return resp.json();
  };

  setUser = async (user: UserResponse, siteId: number) => {
    const resp = await this.post(`api/sectraAdmin/${siteId}/setUser`, user);
    applogger.debug('successfully set user');
    return resp.json();
  };
  
  getAllSites = async () => {
    const resp = await this.get('api/sectraAdmin');
    applogger.debug('successfully got all sites');
    return resp.json();
  };

  createSite = async (name: string, description: string, emailDomain?: string) => {
    const resp = await this.post('api/sectraAdmin/createSite', { Name: name, Description: description, EmailDomain: emailDomain });
    applogger.debug('successfully created site');
    return resp.json();
  };

  removeUserFromSite = async (user: UserResponse) => {
    const resp = await this.post('/api/admin/remove', user);
    applogger.debug('successfully added user to site');
    return resp.json();
  };

  getCurrentSiteUsers = async () => {
    const resp = await this.get('/api/admin');
    applogger.debug('Successfully called get current site users');
    return resp.json();
  };

  addUsersToSite = async (userIds: string[]) => {
    const resp = await this.post('/api/admin/add', userIds);
    applogger.debug('successfully added user to site');
    return resp.json();
  };

  fetchUser = async () => {
    const resp = await this.get('/api/auth');
    applogger.debug('Successfully called fetch user');
    return resp.json();
  };

  save = async (template: SrtGenTemplate) => {
    applogger.debug('saving form to server');
    const resp = await this.post('/api/Templates', template);
    applogger.debug('successful save to server');
    return resp.json();
  };

  getAvailableTemplatesSupported = () => { return true; };

  getSites = async () => {
    applogger.debug('fetching sites from server');
    const resp = await this.get('/api/sites');
    applogger.debug('retrieved export from server');
    return resp.json();
  };
  
  getAllTemplates = async ()=> {
    applogger.debug('fetching forms from server');
    const resp = await this.get('/api/Templates');
    applogger.debug('retrieved export from server');
    return resp.json();
  };
    
  getTemplateById = async (id: string, version: number | undefined) => {
    applogger.debug(`fetching form ${id} from server`);
    let relUrl = '/api/Templates/' + id + (version != null ? '/' + version : '');
    const resp = await this.get(relUrl);
    applogger.debug('retrieved form from server');
    return resp.json();
  };

  getTemplateVersionsById = async (id: string) => {
    let relUrl = '/api/templates/versions/' + id;
    const resp = await this.get(relUrl);
    applogger.debug('retrieved form versions from server');
    return resp.json();
  };

  buildIds7Package = async (id: string, version: number, fullScript: string, templateFields: TemplateField[], reportVersion: number) => {
    let relUrl = '/api/Build/' + id + (version != null ? '/' + version : '');
    const resp = await this.post(relUrl, { FullScript: fullScript, TemplateFields: templateFields, ReportVersion: reportVersion } );
    applogger.debug('retrieved IDS7 form from server');
    let json = await resp.json();
    applogger.debug('decoded to json');
    const b64toBlob = (base64: string, type = 'application/octet-stream') =>
      fetch(`data:${type};base64,${base64}`, {
        headers: { 'x-csrf-token': getCsrfToken() ?? '' },
      }).then(res => res.blob());
    let ret = await b64toBlob(json.Base64Data);
    applogger.debug('ret', ret);
    return ret;
  };

  deleteTemplate = async (id: string, shouldDelete: boolean) => {
    applogger.debug(`deleting form ${id}`);
    let url = baseUrl() + '/api/Templates/' + id + '/' + shouldDelete;
    const resp = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': getCsrfToken() ?? '',
      },
    });

    if (resp.ok) {
      applogger.debug('sucessfully deleted');
    } else {
      throw Error('Could not delete form from server');
    }
  };

  deleteTemplateVersions = async (id:string, versions: number[]) => {
    const url = baseUrl() + '/api/Templates/delete';
    const resp = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': getCsrfToken() ?? '',
      },
      body: JSON.stringify({ 'Id': id, 'Versions': versions }),
    });

    if (resp.ok) {
      applogger.debug('Sucessfully deleted form versions');
    } else {
      throw Error('Could not delete form versions from server');
    }
  };

  getAllBuilds = async (templateId: string) => {
    applogger.debug('fetching all build for form');
    let relUrl = '/api/Build/' + templateId;
    const resp = await this.get(relUrl);
    applogger.debug('retrieved builds from server');
    return resp.json();
  };

  getBuildInfo = async (templateId: string, templateVersion: number) => {
    applogger.debug('fetching build for form id and version');
    let relUrl = '/api/Build/' + templateId + '/' + templateVersion;
    const resp = await this.get(relUrl);
    applogger.debug('retrieved build from server');
    if (resp.status === 204) {
      return null;
    }
    return resp.json();
  };

  setSite = async (siteId: number)=> {
    let relUrl = '/api/sites/set/' + siteId;
    const resp = await this.post(relUrl, undefined);
    return true;
  };

  getProviders = async () => {
    const resp = await this.get('/api/Dataproviders');
    const result: DataproviderServerType[] = await resp.json();
    return result;
  };

  saveProvider = async (provider: DataproviderServerType) => {
    const resp = await this.post('/api/Dataproviders/save', provider);
    return true;
  };

  deleteProvider = async (providerId: number) => {
    let url = baseUrl() + '/api/Dataproviders/' + providerId;
    const resp = await fetch(url, {
      method: 'DELETE',
      headers: { 'x-csrf-token': getCsrfToken() ?? '' },
    });

    if (!resp.ok) {
      throw Error('Unable to delete provider, server error');
    }
    return true;
  };

  updateFolder = async (parentId: number, folderName: string, id: number | null) => {
    const resp = await this.post('/api/Templates/folder', { ParentId: parentId, Name: folderName, Id: id });
    return true;
  };

  moveFolder = async (id: number, parentId: number) => {
    const resp = await this.post('/api/Templates/folder/move', { Id: id, ParentId: parentId });
    return true;
  };

  deleteFolder = async (id: number) => {
    let url = baseUrl() + '/api/Templates/folder/' + id;
    const resp = await fetch(url, {
      method: 'DELETE',
      headers: { 'x-csrf-token': getCsrfToken() ?? '' },
    });

    if (!resp.ok) {
      throw Error('Unable to delete folder, server error');
    }
    return true;
  };

  getSectraFolders = async () => {
    const resp = await this.get('/api/Templates/folder/sectra');
    const result : SrtTemplateFolder[] = await resp.json();
    return result;
  };

  saveSectraFolder = async (parentId: number | null, folderName: string, id: number | null) => {
    const resp = await this.post('/api/sectraadmin/addsectrafolder', { Id: id, ParentId: parentId, Name: folderName });
    return true;
  };

  moveTemplate = async (id: string, newFolderId: number) => {
    const resp = await this.post('/api/Templates/move', { Id: id, FolderId: newFolderId });
    if (!resp.ok) {
      throw Error('Unable to move form, server error');
    }
    return true;
  };

  getPreviewTemplate = async (id: string, version: number | null) => {
    let relUrl = `/api/Templates/preview/${id}/${version == null ? '' : version}`;
    const resp = await this.get(relUrl);
    const previewTemplate: PreviewTemplate = await resp.json();
    return previewTemplate;
  };

  private get = async (relUrl: string): Promise<Response> => {
    const fullUrl = baseUrl() + relUrl;
    const resp = await fetch(fullUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': getCsrfToken() ?? '',
      },
    });
    if (resp.ok) {
      return resp;
    } else {
      throw Error('Error when calling (GET) ' + fullUrl);
    }
  };

  private post = async (relUrl: string, body: any): Promise<Response> => {
    const fullUrl = baseUrl() + relUrl;
    const resp = await fetch(fullUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': getCsrfToken() ?? '',
      },
      body: JSON.stringify(body),
    });
    if (resp.ok) {
      return resp;
    } else {
      throw Error('Error when calling (POST) ' + fullUrl);
    }
  };

  private delete = async (relUrl: string, body: any): Promise<Response> => {
    const fullUrl = baseUrl() + relUrl;
    const resp = await fetch(fullUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': getCsrfToken() ?? '',
      },
      body: JSON.stringify(body),
    });
    if (resp.ok) {
      return resp;
    } else {
      throw Error('Error when calling (DELETE) ' + fullUrl);
    }
  };

  isReady = () => true;
    
  isDemoInstance = () => inDemoMode;
}



export async function GetServerCommunication(): Promise<IServerCommunication> {
  try {
    let url = baseUrl() + '/api/status';
    applogger.debug('trying fetch from ', url);
    const resp = await fetch(url, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'x-csrf-token': getCsrfToken() ?? '',
      },
    });
    
    if (resp.ok) {
      let status = await resp.json();
      if (status.Status.toUpperCase() === 'OK'){
        applogger.debug('Found server, using server communication');
        return new ServerCommunication();
      }
    }
  } catch (e) {
    applogger.info('When looking for server: ', e);
  }
  return new ExceptionServer();
}

function getCsrfToken() {
  return localStorage.getItem('csrf');
}